import type {PhysicianFormAvailable} from "@/ts/types/dto/biometric-screening.dto";
import type {GetPhysicianFormBiometricResponse} from "@/ts/types/dto/physician-form.dto";
import {PhysicianFormStatus} from "@/ts/types/dto/physician-form.dto";

export function isPhysicianFormAvailable(physicianFormAvailable: PhysicianFormAvailable | null): boolean {
  return Boolean(physicianFormAvailable?.available);
}

export function isPhysicianFormSubmitted(physicianForm: GetPhysicianFormBiometricResponse | null): boolean {
  return Boolean(physicianForm?.status && [
    PhysicianFormStatus.pending,
    PhysicianFormStatus.approved,
    PhysicianFormStatus.denied,
  ].includes(physicianForm.status));
}

export function isPhysicianFormDenied(physicianForm: GetPhysicianFormBiometricResponse | null): boolean {
  return physicianForm?.status === PhysicianFormStatus.denied;
}

export function isPhysicianFormPendingOrApproved(physicianForm: GetPhysicianFormBiometricResponse | null): boolean {
  return Boolean(physicianForm?.status && [
    PhysicianFormStatus.pending,
    PhysicianFormStatus.approved,
  ].includes(physicianForm.status));
}

export function isPhysicianFormUnavailableBecauseSubmitted({
  physicianFormAvailable,
  physicianForm,
}: {
  physicianFormAvailable: PhysicianFormAvailable | null;
  physicianForm: GetPhysicianFormBiometricResponse | null;
}): boolean {
  return !isPhysicianFormAvailable(physicianFormAvailable) && isPhysicianFormSubmitted(physicianForm);
}

export function isPhysicianFormUnavailableWithoutStatus({
  physicianFormAvailable,
  physicianForm,
}: {
  physicianFormAvailable: PhysicianFormAvailable | null;
  physicianForm: GetPhysicianFormBiometricResponse | null;
}): boolean {
  return !isPhysicianFormAvailable(physicianFormAvailable) && !physicianForm?.status;
}

export function isPhysicianFormAvailableWithoutStatus({
  physicianFormAvailable,
  physicianForm,
}: {
  physicianFormAvailable: PhysicianFormAvailable | null;
  physicianForm: GetPhysicianFormBiometricResponse | null;
}): boolean {
  return isPhysicianFormAvailable(physicianFormAvailable) && !physicianForm?.status;
}

export function isPhysicianFormDownloadedOrDenied(physicianForm: GetPhysicianFormBiometricResponse | null): boolean {
  return Boolean(physicianForm?.status && [
    PhysicianFormStatus.downloaded,
    PhysicianFormStatus.denied,
  ].includes(physicianForm.status));
}
