<template>
  <input-text
    id="email"
    :model-value="modelValue"
    :validity="validity"
    :error-line="validity"
    autocomplete="email"
    name="email"
    type="email"
    placeholder="Email"
    :required="true"
    @update:model-value="onUpdateValue"
  />
</template>

<script lang="ts" setup>
import InputText from "@/vue/atoms/input-text.vue";
import debounce from "lodash.debounce";
import {inject, ref, watch} from "vue";
import type {Api} from "@/ts/classes/api";
import {OnboardingStore} from "@/ts/store/onboarding/onboarding-store";
import {getModule} from "vuex-module-decorators";
import {vueStore} from "@/ts/store/vue-store";

const props = defineProps<{
  modelValue: string;
  existingValueForThisUser?: string;
}>();

const emit = defineEmits(["update:modelValue"]);

const $api: Api = inject("$api")! as Api;

const onUpdateValue = (value: string): void => {
  emit("update:modelValue", value);
  void debouncedValidateMailAddress();
};

const validity = ref("");

const debouncedValidateMailAddress = debounce(async() => {
  const email = props.modelValue;

  try {
    if (email.indexOf("@") > 0) {
      const {isAvailable} = await $api.emailAddressAvailable({email});
      // make sure requested username is not outdated after await
      if (email === props.modelValue) {
        const hasNoChanges = props.existingValueForThisUser === email;
        validity.value = (hasNoChanges || isAvailable) ? "" : "This Email address is already in use";
      }
    }
  } catch (err) {
    /*
     * probably server-side validation error, we validate client side
     * for that reason: reset validity
     */
    validity.value = "";
  }
}, 500); // eslint-disable-line @typescript-eslint/no-magic-numbers

</script>
