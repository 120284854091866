<template>
  <div
    class="action-card"
  >
    <div class="info">
      <div class="header">
        <h4>{{ title }}</h4>
        <tooltip v-if="tooltipText" :text="tooltipText"/>
      </div>
      <p>
        {{ description }}
      </p>
    </div>
    <div class="cards">
      <icon-card
        v-for="action in actions"
        :key="action.name"
        :title="action.name"
        :image="action.icon"
        :text="text(action)"
        :handle-click="action.handleClick"
        is-shown="true"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";
import IconCard from "@/vue/atoms/icon-card.vue";
import type {Action} from "@/ts/types/component/action-card";
import {formatDateShortMonthName, formatDate, formatDateLongMonthName} from "@/ts/utils/date-pure-functions";
import Tooltip from "@/vue/molecules/tooltip.vue";

@Component({
  name: "ActionCard",
  components: {
    IconCard,
    Tooltip,
  },
})
export default class ActionCard extends Vue {
  @Prop()
  title!: string;

  @Prop()
  description!: string;

  @Prop()
  actions!: Action[];

  @Prop()
  tooltipText!: string | null;

  text(action: Action): string {
    const {startDate, endDate} = action;

    if (startDate && endDate) {
      return `${formatDate(startDate)} - ${formatDate(endDate)}`;
    }
    if (startDate) {
      return `Start date: ${formatDateShortMonthName(startDate)}`;
    }
    if (endDate) {
      return `End date: ${formatDateShortMonthName(endDate)}`;
    }

    return "";
  }
}
</script>

<style lang="sass" scoped>
.action-card
  background-color: $color-primary-hc-blue-10
  display: flex
  flex-direction: column
  flex: 1

.info
  color: $color-primary-hc-blue-100

.header
  display: flex
  justify-content: space-between

h4
  @include Asap700
  font-size: 16px
  text-transform: capitalize
  margin: 0

p
  @include Roboto400
  font-size: 14px
  letter-spacing: 0.2px
  line-height: 22px
  margin-top: 8px

.cards
  display: flex
  flex-direction: column
  flex: 1
  gap: 8px
</style>
