<template>
  <input-text
    id="phone-number"
    :model-value="modelValue"
    autocomplete="tel"
    title="Tel number should have +1 prefix and between 10 and 14 digits"
    pattern="^\+1\d{10,14}$"
    placeholder="+16175551212"
    @update:model-value="updateValue"
  />
</template>

<script lang="ts">
import {
  Component,
  Emit,
  Prop,
  Vue,
} from "vue-property-decorator";
import FieldSet from "@/vue/molecules/field-set.vue";
import InputText from "@/vue/atoms/input-text.vue";

@Component({
  components: {FieldSet, InputText}
})
export default class EditPhoneNumberInput extends Vue {
  @Prop() modelValue!: number;

  @Emit('update:modelValue')
  updateValue(value: number): number {
    return value;
  }
}
</script>
