<template>
  <box-row>
    <template #left>
      <icon-title title="Reward" icon="reward"/>
    </template>
    <template #right>
      <badged-rounded v-if="reward && reward.amount" :value="rewardFormatted"/>
      <p v-if="reward && !reward.amount" class="no-reward">
        no reward
      </p>
    </template>
  </box-row>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";
import BoxRow from "@/vue/atoms/box-row.vue";
import IconTitle from "@/vue/molecules/activities/icon-title.vue";
import BadgedRounded from "@/vue/atoms/badge-rounded.vue";
import type {Reward, RewardValidation} from "@/ts/types/dto/reward.dto";
import {getRewardAmountFormatted} from "@/ts/utils/pure-functions";

@Component({
  name: "ActivityRewardRow",
  components: {
    BoxRow,
    IconTitle,
    BadgedRounded,
  },
})
export default class ActivityRewardRow extends Vue {
  @Prop() reward!: Reward | RewardValidation;

  get rewardFormatted(): string {
    return getRewardAmountFormatted(this.reward ? {
      amount: this.reward.amount,
      type: this.reward.type,
    } : null);
  }
}
</script>

<style lang="sass" scoped>

.no-reward
  color: $color-primary-hc-blue-50
  @include Roboto400
  font-size: 14px
  line-height: 22px
  margin: 0
</style>
