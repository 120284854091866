<template>
  <navbar-page
    title="Settings"
    class="body"
    :display-right-nav="false"
    :back-link="$pagesPath.profile.main"
  >
    <div class="content">
      <h2>General Settings</h2>
      <settings-item
        text="Change Password"
        :link="$pagesPath.main.changePassword"
        :img="lockIcon"
        class="item"
      />
      <settings-item
        text="Terms of Service"
        :link="$pagesPath.auth.termsOfService"
        :img="legalDocIcon"
        class="item"
      />
      <settings-item
        text="Privacy Policy"
        :link="$pagesPath.auth.privacyPolicy"
        :img="shieldIcon"
        class="item-last"
      />
      <div class="version">
        VERSION {{ version }}
      </div>
    </div>
  </navbar-page>
</template>
<script lang="ts">
import {Component} from "vue-property-decorator";
import NavbarPage from "@/vue/templates/navbar-page.vue";
import {mixins} from "vue-class-component";
import {mixinClassBodyFactory} from "@/ts/mixins/bg-mixin";
import {DefaultStoreMixin} from "@/ts/store/default/default-store-instance";
import SettingsItem from "@/vue/molecules/settings-item.vue";
import lockIcon from "@/assets/img/lock.svg";
import shieldIcon from "@/assets/img/shield.svg";
import legalDocIcon from "@/assets/img/legal-doc.svg";
import {GIT_HASH} from "@/ts/utils/consts";

@Component({
  name: "SettingsPage",
  components: {
    SettingsItem,
    NavbarPage,
  },
})
export default class SettingsPage extends mixins(mixinClassBodyFactory("global-onboarding-bg-color"), DefaultStoreMixin) {
  get shieldIcon(): string {
    return shieldIcon;
  }

  get legalDocIcon(): string {
    return legalDocIcon;
  }

  get lockIcon(): string {
    return lockIcon;
  }

  get version(): string {
    return GIT_HASH;
  }
}
</script>
<style lang="sass" scoped>
h2
  @include Asap700
  font-size: 20px
  color: $color-primary-hc-blue-100
  margin-top: 0
  margin-bottom: 24px

.content
  display: flex
  flex-direction: column

  margin: auto
  align-items: center

  > *
    max-width: 448px
    width: 100%

.item
  margin-bottom: 16px

.item-last
  margin-bottom: 24px

.version
  @include Roboto400
  color: $color-primary-hc-blue-100
  font-size: 10px
  letter-spacing: 0.4px
  padding-left: 16px
  text-transform: uppercase
</style>
