<template>
  <navbar-page title="My Connected Devices" :back-link="$pagesPath.profile.main" class="body">
    <PlaceholderApp/>
  </navbar-page>
</template>

<script lang="ts">
import {
  Component,
  Vue,
} from "vue-property-decorator";
import NavbarPage from "@/vue/templates/navbar-page.vue";
import PlaceholderApp from "@/vue/molecules/placeholder-app.vue";

@Component({
  name: "ProfileDevicesPage",
  components: {
    PlaceholderApp,
    NavbarPage,
  },
})
export default class ProfileDevicesPage extends Vue {
}
</script>
<style lang="sass" scoped>
.body
  background-color: $color-neutral-platinum-40
  background-size: contain
  color: $color-primary-hc-blue-100
  @include Asap700
  width: 100%
  height: 100%
  min-height: 100vh
</style>
