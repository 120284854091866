<template>
  <popup-modal :model-value="modelValue" @update:model-value="close">
    <div class="container">
      <p class="title">
        Participants Joined <b class="number">{{ participants.length }}</b>
      </p>
      <div class="friends">
        <p class="subtitle">
          Your Friends
        </p>
        <div class="cards">
          <colleague-display
            v-for="friend in friends"
            :key="friend.id"
            :avatar="friend.avatarPath"
            :first-name="friend.firstName"
            :last-name="friend.lastName"
            :joined-by-you="friend.id === profile.id"
            :user-status="friend.status"
          />
        </div>
      </div>
      <div class="participants">
        <p class="subtitle">
          Your Coworkers
        </p>
        <div class="cards">
          <colleague-display
            v-for="participant in participants"
            :key="participant.id"
            :avatar="participant.avatarPath"
            :first-name="participant.firstName"
            :last-name="participant.lastName"
            :joined-by-you="participant.id === profile.id"
            :user-status="participant.status"
          />
        </div>
      </div>
    </div>
  </popup-modal>
</template>

<script lang="ts">
import {Component, Vue, Prop, Emit} from "vue-property-decorator";
import PopupModal from "@/vue/atoms/popup-modal.vue";
import type {FinishedFriend} from "@/ts/types/dto/pathway.dto";
import ColleagueDisplay from "@/vue/atoms/colleague-display.vue";
import type {Profile} from "@/ts/types/store/default-store-types";
import {DefaultState} from "@/ts/store/default/default-store-instance";
import type {ChallengeParticipant} from "@/ts/types/dto/leaderboard-challenge.dto";

@Component({
  name: "ChallengePaticipantsModal",
  components: {
    PopupModal,
    ColleagueDisplay,
  },
})
export default class ChallengePaticipantsModal extends Vue {
  @Prop() modelValue!: boolean;

  @Prop()
  friends!: FinishedFriend[];

  @Prop()
  participants!: ChallengeParticipant[];

  @DefaultState
  public profile!: Profile;

  @Emit("update:modelValue")
  close(): boolean {
    return false;
  }
}
</script>

<style lang="sass" scoped>
.title
  @include Asap700
  color: $color-primary-hc-blue-100
  text-align: start
  font-size: 32px
  line-height: 40px

.container
  max-width: 700px
  min-width: 400px
  display: flex
  flex-direction: column
  gap: 16px
  max-height: 650px
  overflow: auto

.number
  color: $color-primary-hc-blue-50
  margin-left: 8px

.friends
  background-color: $color-tertiary-orange-10
  padding: 1rem
  border-radius: 16px

.participants
  padding: 1rem

.cards
  display: flex
  flex-wrap: wrap

.subtitle
  @include Asap700
  font-size: 20px
  line-height: 28px
  color: $color-primary-hc-blue-100
  margin-top: 0
</style>
