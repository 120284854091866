<template>
  <div v-if="hraSurvey">
    <div class="header">
      <div>Health Risk Assessment</div>
      <router-link :to="$pagesPath.myHealthPath.myhealth" class="button-go-start">
        <img src="@/assets/img/x-mark.svg" alt="close"/>
      </router-link>
    </div>
    <loading-bar :steps="hraSurvey.questions.length" :current="lastStep"/>
    <p> Review your Health Risk Assessement form before submitting</p>
    <loading-suspense :loading="loading" :error="error">
      <div class="wrapper">
        <hra-answers
          :questions="orderedQuestions"
          class="hra-answers"
        />
      </div>
      <div class="buttons">
        <button-primary
          class="button-back"
          :loading="loading"
          theme="white"
          @click="goBack"
        >
          Back
        </button-primary>
        <button-primary
          class="button-next"
          :loading="loading"
          @click="submit"
        >
          Submit HRA
        </button-primary>
      </div>
    </loading-suspense>
  </div>
</template>

<script lang="ts">

import {Component, Prop} from "vue-property-decorator";
import {orderQuestionsToDisplay} from "@/ts/utils/hra/hra-quiz";
import {DefaultGrowlError, LoadingMixin} from "@/ts/mixins/loading-mixin";
import {mixins} from "vue-class-component";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import ErrorText from "@/vue/atoms/error-text.vue";
import type {HRASurvey, HRASurveyQuestionDetail} from "@/ts/types/dto/health-risk-assessment.dto";
import HraAnswers from "@/vue/molecules/hra-answers.vue";
import LoadingBar from "@/vue/atoms/loading-bar.vue";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";

@Component({
  name: "HraQuizPage",
  components: {
    ErrorText,
    ButtonPrimary,
    HraAnswers,
    LoadingBar,
    LoadingSuspense,
  },
})
export default class HraQuizPage extends mixins(LoadingMixin) {
  hraSurvey: HRASurvey | null = null;

  get lastStep(): number {
    return this.hraSurvey?.questions.length ?? 0;
  }

  get orderedQuestions(): HRASurveyQuestionDetail[] {
    return orderQuestionsToDisplay({hraResponse: this.hraSurvey})
      .map((index) => this.hraSurvey!.questions[index]);
  }

  goBack(): void {
    this.$router.go(-1);
  }

  @DefaultGrowlError
  async submit(): Promise<void> {
    await this.$api.putHRASurveyComplete(this.hraSurvey!.id);
    void this.$router.push(this.$pagesPath.healthRiskAssessment.finishHra);
  }

  async created(): Promise<void> {
    const {id} = await this.$api.getHRASummary();
    this.hraSurvey = await this.$api.getHRASurvey(id);
  }
}

</script>

<style lang="sass" scoped>
.header
  @include Asap700
  font-size: 20px
  line-height: 28px
  text-align: center
  color: $color-primary-hc-blue-100

  display: grid
  grid-template-columns: 1fr repeat(1, auto) 1fr
  grid-column-gap: 5px
  justify-items: center
  align-items: center
  padding: 60px 70px 27px

  > div
    grid-column-start: 2

p
  @include Asap700
  font-size: 28px
  text-align: center
  color: $color-primary-hc-blue-100

.buttons
  display: flex
  justify-content: space-evenly
  margin-top: 40px
  margin-bottom: 20px

.button-back
  height: 45px
  width: 130px
</style>
