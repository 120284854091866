<template>
  <register-page
    :prev-page="$pagesPath.auth.verification"
    :current-step="2"
    title="Registration"
    @next="submit"
  >
    <div class="container">
      <div class="username-email-wrapper">
        <!-- Conditionally render the username input or static display  todo figure out why username sometimes gets auto populated and therefore becomes a label-->
        <!--        <field-set v-if="!username" id="username" label="Username">
          <input-text
            id="username"
            v-model="username"
            :validity="usernameValidity"
            :error-line="usernameValidity"
            minlength="5"
            placeholder="Username"
            autocomplete="username"
            required
          />
        </field-set>
        <div v-else>
          <label class="username-label">Username</label>
          <p class="username-value">
            {{ username }}
          </p>
        </div>-->

        <field-set id="username" label="Username">
          <input-text
            id="username"
            v-model="username"
            :validity="usernameValidity"
            :error-line="usernameValidity"
            minlength="5"
            placeholder="Username"
            autocomplete="username"
            required
          />
        </field-set>

        <field-set id="email" label="Email">
          <edit-email-input v-model="email"/>
        </field-set>
      </div>
      <div class="password-rules-wrapper">
        <password-rules/>
        <div class="empty"/>
      </div>

      <password-change
        class="password-wrapper-custom"
        password-label="Password"
        :password-prop="password"
        :confirm-password-prop="confirmPassword"
        @password-changed="onPasswordChanged"
        @confirm-password-changed="onConfirmPasswordChanged"
      />
    </div>
  </register-page>
</template>

<script lang="ts">
import {
  Component,
  Watch,
} from "vue-property-decorator";
import InputPassword from "@/vue/atoms/input-password.vue";
import FieldSet from "@/vue/molecules/field-set.vue";
import InputText from "@/vue/atoms/input-text.vue";
import RegisterPage from "@/vue/templates/register-page.vue";
import debounce from "lodash.debounce";
import EditEmailInput from "@/vue/molecules/edit-field/edit-email-input.vue";
import {mixins} from "vue-class-component";
import {OnboardingStoreMixin, OnboardingState} from "@/ts/store/onboarding/onboarding-store-instance";
import type {RegistrationData} from "@/ts/types/store/onboarding-store-types";
import PasswordRules from "@/vue/molecules/password-rules.vue";
import PasswordChange from "@/vue/molecules/password-change.vue";

const USERNAME_MIN_LENGTH = 5;

@Component({
  name: "UserRegistration",
  components: {
    EditEmailInput,
    RegisterPage,
    InputText,
    FieldSet,
    InputPassword,
    PasswordRules,
    PasswordChange,
  },
})
export default class UserRegistrationPage extends mixins(OnboardingStoreMixin) {
  username: string = "";

  password: string = "";

  email: string = "";

  confirmPassword: string = "";

  debouncedValidateUserName!: Function; // eslint-disable-line @typescript-eslint/ban-types

  usernameValidity: string = "";

  @OnboardingState
  userRegistrationData!: RegistrationData | null;

  @OnboardingState
  checkToken!: string | null;

  created(): void {
    // Debounce setup for username validation
    this.debouncedValidateUserName = debounce(this.checkUserName, 500);

    // Redirect if no checkToken is present
    if (!this.checkToken) {
      void this.$router.push(this.$pagesPath.auth.verification);
    }

    // Retrieve username from local storage
    const savedUsername = localStorage.getItem("v3-username") || "";
    if (savedUsername) {
      this.username = savedUsername;
    }

    // Populate fields from userRegistrationData if available
    if (this.userRegistrationData) {
      this.username = this.userRegistrationData.username;
      this.password = this.userRegistrationData.password;
      this.confirmPassword = this.userRegistrationData.password;
      this.email = this.userRegistrationData.email;
    }
  }

  async checkUserName(username: string): Promise<void> {
    const {isAvailable} = await this.$api.usernameAvailable({username}, this.checkToken!);
    if (username === this.username) {
      // make sure requested username is not outdated after await
      this.usernameValidity = isAvailable ? "" : "This username is not available";
    }
  }

  @Watch("username")
  onUsernameChanged(username: string): void {
    if (username.length >= USERNAME_MIN_LENGTH) {
      this.debouncedValidateUserName(username);
    }
  }

  onPasswordChanged(password: string): void {
    this.password = password;
  }

  onConfirmPasswordChanged(confirmPassword: string): void {
    this.confirmPassword = confirmPassword;
  }

  submit(): void {
    this.onboardingStore.setUserRegistrationData({
      email: this.email,
      password: this.password,
      username: this.username,
    });
    void this.$router.push(this.$pagesPath.auth.additional);
  }
}
</script>

<style lang="sass" scoped>
.container
  width: 100%

.username-email-wrapper,
.password-wrapper-custom,
.password-rules-wrapper
  max-width: max-container-width(24px, $container-width, 2)
  @include flex-container(24px, $container-width, 2)
  justify-content: center
  margin: auto

.username-email-wrapper
  margin-bottom: 40px

.password-wrapper-custom
  align-items: flex-start
  margin-top: 40px

.password-rules-wrapper
  gap: 0
  column-gap: 24px

.username-label
  @include Asap700
  color: $color-primary-hc-blue-100

.username-value
  @include Asap400
  color: $color-primary-hc-blue-100


</style>
