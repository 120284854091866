<!--eslint-disable max-len-->
<template>
  <img
    v-if="imageObjectUrl"
    :src="imageObjectUrl"
    :alt="alt"
    :aria-hidden="ariaHidden"
  />
</template>

<script lang="ts" setup>
import {inject, onMounted, onUnmounted, ref, watch} from "vue";
import type {Api} from "@/ts/classes/api";

const DEFAULT_SIZE = 500;

const $api: Api = inject("$api")!;

const imageObjectUrl = ref("");

const props = defineProps<{
  src: string;
  ariaHidden: boolean;
  alt: string;
  sizeWidth?: number;
  resizeToSquare?: boolean;
  loadRawImage?: boolean;
}>();

const revokeImageData = (): void => {
  if (imageObjectUrl.value) {
    URL.revokeObjectURL(imageObjectUrl.value);
    imageObjectUrl.value = "";
  }
};

const getImageProcessingOptions = (): string => {
  if (props.loadRawImage) {
    return "raw:true";
  }

  if (props.resizeToSquare) {
    return `resize:true/rt:fill/width:${props.sizeWidth ?? DEFAULT_SIZE}/height:${props.sizeWidth ?? DEFAULT_SIZE}/enlarge:true`;
  }

  return `size:true/width:${props.sizeWidth ?? DEFAULT_SIZE}/enlarge:true`;
};

const fetchImage = async(): Promise<void> => {
  if (props.src) {
    try {
      const imageProcessingOptions = getImageProcessingOptions();
      const image = await $api.getProtectedImage(props.src, imageProcessingOptions);
      imageObjectUrl.value = image.imageObjectUrl;
    } catch (err) {
      // don't display image
    }
  }
};

watch(() => props.src, () => {
  void fetchImage();
});

onMounted(() => {
  void fetchImage();
});

onUnmounted(() => {
  revokeImageData();
});

</script>
