import {
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";

import type {SchedulingCoachingAppointment} from "@/ts/types/store/scheduling-coaching-store-types";
import type {CoachingPhoneNumber} from "@/ts/types/dto/coaching.dto";

@Module({
  name: 'schedulingCoaching'
  })
export class SchedulingCoachingStore extends VuexModule {
  public appointment: SchedulingCoachingAppointment | null = null;

  public phone: CoachingPhoneNumber | null = null;

  @Mutation
  setAppointment(data: SchedulingCoachingAppointment | null): void {
    this.appointment = data;
  }

  @Mutation
  setPhone(data: CoachingPhoneNumber | null): void {
    this.phone = data;
  }

  @Mutation
  clearAll(): void {
    this.appointment = null;
    this.phone = null;
  }
}
