<template>
  <div :class="className">
    <img :src="image" :alt="theme"/>
    <div class="wrapper">
      <p v-if="title" class="title">
        {{ title }}
      </p>
      <div v-if="text" class="text">
        {{ text }}
      </div>
      <slot/>
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";
import successIcon from "@/assets/img/icons/my-health/success.svg";
import infoIcon from "@/assets/img/icons/my-health/info.svg";
import errorIcon from "@/assets/img/icons/my-health/error.svg";
import warningIcon from "@/assets/img/icons/my-health/warning.svg";
import {Theme} from "@/ts/types/component/alert-icon-card";

@Component({
  name: "AlertIconCard",
  components: {},
})
export default class AlertIconCard extends Vue {
  @Prop({default: Theme.info})
  theme!: Theme;

  @Prop()
  title: string | undefined;

  @Prop()
  text: string | undefined;

  get className(): string[] {
    return ["alert-icon-card", this.theme];
  }

  get image(): string {
    switch (this.theme) {
      case Theme.success: {
        return successIcon;
      }
      case Theme.error: {
        return errorIcon;
      }
      case Theme.warning: {
        return warningIcon;
      }
      default: {
        return infoIcon;
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.alert-icon-card
  align-items: flex-start
  border-radius: 8px
  display: flex
  gap: 12px
  padding: 8px 12px

  &.info-white
    background-color: $color-white

  &.info
    background-color: $color-status-info-blue-10

  &.success
    background-color: $color-status-success-green-10

  &.error
    background-color: $color-status-error-red-5

  &.warning
    background-color: $color-tertiary-yellow-10

.title,
.text
  font-size: 14px
  line-height: 22px
  letter-spacing: 0.2px
  margin: 0 0 4px

.title
  @include Asap700
  color: $color-primary-hc-blue-100

.text
  @include Roboto400
  color: $color-primary-hc-blue-100

</style>
