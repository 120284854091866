<template>
  <div class="wrapper">
    <div class="image-container">
      <img
        :src="icon"
        :class="{'icon': true, 'icon--money': iconType === 'money'}"
        alt="transaction icon"
      />
    </div>
    <div class="background-icon">
      <svg
        width="100%"
        height="100%"
        :viewBox="`0 0 350 350`"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M175 0C35 0 0 34.9986 0 175C0 315.001 34.9986 350 175 350C315.001 350 350 315.001 350 175C350 34.9986 315.001 0 175 0Z"
          fill="currentColor"
        />
      </svg>
    </div>
  </div>
</template>
<script lang="ts" setup>
import biking from "@/assets/img/icons/biking.svg";
import dancing from "@/assets/img/icons/dancing.svg";
import gardening from "@/assets/img/icons/gardening.svg";
import hiking from "@/assets/img/icons/hiking.svg";
import jogging from "@/assets/img/icons/jogging.svg";
import ergometer from "@/assets/img/icons/ergometer.svg";
import fitness from "@/assets/img/icons/fitness.svg";
import generic from "@/assets/img/icons/generic.svg";
import golf from "@/assets/img/icons/golf.svg";
import gymnastics from "@/assets/img/icons/gymnastics.svg";
import handbiking from "@/assets/img/icons/hand-biking.svg";
import housework from "@/assets/img/icons/housework.svg";
import meditation from "@/assets/img/icons/meditation.svg";
import pilates from "@/assets/img/icons/pilates.svg";
import rowing from "@/assets/img/icons/rowing.svg";
import sleep from "@/assets/img/icons/sleep.svg";
import steps from "@/assets/img/icons/steps.svg";
import swimming from "@/assets/img/icons/swimming.svg";
import teamSport from "@/assets/img/icons/team-sport.svg";
import waterdrinking from "@/assets/img/icons/water-drinking.svg";
import wheelchair from "@/assets/img/icons/wheel-chair.svg";
import yoga from "@/assets/img/icons/yoga.svg";
import weight from "@/assets/img/icons/weight.svg";
import nutrition from "@/assets/img/icons/nutrition.svg";
import heartRate from "@/assets/img/icons/heart-rate.svg";
import weightLifting from "@/assets/img/icons/weight-lifting.svg";
import smoking from "@/assets/img/icons/smoking.svg";
import platform from "@/assets/img/icons/platform.svg";
import book from "@/assets/img/icons/book.svg";
import warning from "@/assets/img/icons/warning.svg";
import gatekeeper from "@/assets/img/icons/gatekeeper.svg";
import {TaskType} from "@/ts/types/dto/activity.dto";
import company from "@/assets/img/icons/company-building-avatar.svg";
import coaching from "@/assets/img/icons/coaching-icon-frameless.svg";
import hra from "@/assets/img/icons/hra-icon-frameless.svg";
import bio from "@/assets/img/icons/bio-screen-icon-frameless.svg";
import money from "@/assets/img/icons/money.svg";
import comboActivity from "@/assets/img/icons/combo-activity.svg";
import {computed} from "vue";
import type {IconType} from "@/ts/types/component/transaction-history";

const props = defineProps<{
  iconType: IconType;
}>();

const icon = computed((): string => {
  const TaskIconMap: Record<IconType, string> = {
    [TaskType.biking_time]: biking,
    [TaskType.biking_distance]: biking,
    [TaskType.dancing_time]: dancing,
    [TaskType.ergometer_time]: ergometer,
    [TaskType.gardening_time]: gardening,
    [TaskType.golf_time]: golf,
    [TaskType.hiking_time]: hiking,
    [TaskType.hiking_distance]: hiking,
    [TaskType.housework_time]: housework,
    [TaskType.meditation_time]: meditation,
    [TaskType.fitness_time]: fitness,
    [TaskType.pilates_time]: pilates,
    [TaskType.rowing_time]: rowing,
    [TaskType.jogging_time]: jogging,
    [TaskType.jogging_distance]: jogging,
    [TaskType.steps]: steps,
    [TaskType.swimming_time]: swimming,
    [TaskType.general_physical_activity_time]: generic,
    [TaskType.team_sports_time]: teamSport,
    [TaskType.weightlifting_time]: weightLifting,
    [TaskType.wheelchair_time]: wheelchair,
    [TaskType.water_drinking_volume]: waterdrinking,
    [TaskType.yoga_time]: yoga,
    [TaskType.nicotine_use]: smoking,
    [TaskType.heart_rate]: heartRate,
    [TaskType.calorie_intake]: nutrition,
    [TaskType.weight]: weight,
    [TaskType.mood]: generic,
    [TaskType.handbiking_time]: handbiking,
    [TaskType.gymnastics_time]: gymnastics,
    [TaskType.sleep_time]: sleep,
    [TaskType.general_physical_activity_time_or_steps]: comboActivity,
    generic,
    platform,
    book,
    warning,
    gatekeeper,
    company,
    coaching,
    hra,
    bio,
    money,
  };
  return TaskIconMap[props.iconType] || generic;
});
</script>

<style lang="sass" scoped>
.wrapper
  position: relative

.background-icon
  width: 100%
  height: 100%

.image-container
  width: inherit
  height: inherit
  position: absolute
  display: flex
  justify-content: center

.icon
  max-width: 60%

.icon--money
  max-width: 100%
</style>
