<template>
  <loading-suspense :error="error" :loading="loading">
    <router-view :hra-response="hraResponse"/>
  </loading-suspense>
</template>

<script lang="ts">
import {Component} from "vue-property-decorator";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import {ApplyGrowlErr} from "@/ts/utils/decorators";
import type {HRASurvey} from "@/ts/types/dto/health-risk-assessment.dto";
import {mixins} from "vue-class-component";
import ErrorText from "@/vue/atoms/error-text.vue";
import {LoadingMixin} from "@/ts/mixins/loading-mixin";

@Component({
  name: 'HraParentPage',
  components: {LoadingSuspense, ErrorText},
})
export default class HraParentPage extends mixins(LoadingMixin) {
  hraResponse!: HRASurvey;

  @ApplyGrowlErr({
    loadingProperty: 'loading',
    errorProperty: 'error',
    allowSpam: true,
  })
  async created(): Promise<void> {
    const {id} = await this.$api.getHRASummary();
    this.hraResponse = await this.$api.getHRASurvey(id);
  }
}
</script>

<style lang="sass" scoped>

</style>
