<template>
  <!-- eslint-disable -->
  <div class="page-wrapper">
    <router-link :to="$pagesPath.main.home">
      <hc-logo/>
    </router-link>

    <h1 class="headline">
      Privacy Policy
    </h1>

    <p>myHealthCheck360.com is committed to protecting your privacy and developing technology that gives you the most powerful and safe online experience. This Privacy Policy applies to the myHealthCheck360.com web site and any software, tools, applications, features, or functionality available on or though the site (collectively, the “Service”). The myHealthCheck360.com Service is paid for by your employer, your spouse’s employer or other sponsoring organization (the ‘Program Sponsor’) and is operated by HealthCheck360. By using the myHealthCheck360.com website, you consent to the data practices described in this statement.</p>

    <h2>Collection of your Personal Information</h2>
    <p>myHealthCheck360.com collects personally identifiable information. The personal information myHealthCheck360.com collects includes, but is not limited to:</p>
    <ul>
      <li>Your contact information, including your first name and last name, home or work address or telephone number;</li>
      <li>Your sex, date of birth and age;</li>
      <li>Biometric information such as your blood pressure, height or weight;</li>
      <li>Information about your health;</li>
      <li>Information about your fitness and related wellness activities offered with the service;</li>
      <li>Information about your participant and performance in the Service and related challenges;</li>
      <li>Information you voluntarily share about yourself during any calls you participant with Our health coaches;</li>
      <li>Information you voluntary share with our customer services team</li>
      <li>The comments and contributions you make on the web-based platform or mobile application; and</li>
      <li>Additional information you may provide as you submit queries and request to us.</li>
    </ul>

    <p>myHealthCheck360.com also collects anonymous demographic information, which is not unique to you, such as your zip code, age, sex, preferences, interests and favorites. There is also information about your computer hardware and software that is automatically collected by myHealthCheck360.com. This information can include: your IP address, browser type, domain names, access times and referring web site addresses. This information is used by myHealthCheck360.com for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the myHealthCheck360.com web site. myHealthCheck360.com encourages you to review the privacy statements of web sites you choose to link to from myHealthCheck360.com so that you can understand how those web sites collect, use and share your information. myHealthCheck360.com is not responsible for the privacy statements or other content on web sites outside of the myHealthCheck360.com and myHealthCheck360.com family of Web sites.</p>

    <h2>Use of your Personal Information</h2>
    <p>myHealthCheck360.com collects and uses your personal information to operate the myHealthCheck360.com web site and deliver the services you have requested. myHealthCheck360.com also uses your personally identifiable information to inform you of other products or services available from HealthCheck360 and its affiliates. myHealthCheck360.com may also contact you via surveys to conduct research about your opinion of current services or of potential new services that may be offered.</p>

    <p>myHealthCheck360.com does not sell, rent or lease its customer lists to third parties. myHealthCheck360.com may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. In those cases, your unique personally identifiable information (e-mail, name, address, telephone number) is not transferred to the third party. In addition, myHealthCheck360.com may share data with trusted partners to help us perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to myHealthCheck360.com, and they are required to maintain the confidentiality of your information. myHealthCheck360.com does not use or disclose sensitive personal information, such as race, religion, or political affiliations, without your explicit consent. myHealthCheck360.com keeps track of the web sites and pages our customers visit within myHealthCheck360.com, in order to determine what myHealthCheck360.com services are the most popular. This data is used to deliver customized content and advertising within myHealthCheck360.com to customers whose behavior indicates that they are interested in a particular subject area.</p>

    <p>myHealthCheck360.com may share anonymized and aggregated data with your program sponsor. Your program sponsor will not be able to use such anonymized or aggregated data to directly identify you. Your Program Sponsor may use the anonymized information in its discretion, including to evaluate the overall myHealthCheck360.com services, as well as to provide additional benefits, programs and services.</p>

    <p>In specific circumstances and for limited purposes, such as to ensure proper program administration or to support tax compliance, myHealthCheck360.com may share reports containing identifiable information with your Program Sponsors privacy officer. In these circumstances, myHealthCheck360.com limits the Personal Information to the least amount necessary to support the specific, necessary purpose.</p>

    <p>myHealthCheck360.com web sites will disclose your personal information, without notice, only if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on myHealthCheck360.com or the site; (b) protect and defend the rights or property of myHealthCheck360.com; (c) act under exigent circumstances to protect the personal safety of users of myHealthCheck360.com, or the public.</p>

    <p>It is our policy to terminate in appropriate circumstances any Account or user for repeated infringement of intellectual property rights, including copyrights, and we also reserve the right to terminate an Account or user for even one instance of infringement. Accounts may also be terminated should the company sponsoring the wellness program discontinue services with myHealthCheck360.com.</p>

    <p>myHealthCheck360.com may access, preserve and disclose to third parties any of your information or data (including personally identifiable information and private communications) related to a written complaint of copyright infringement if we believe in our sole discretion that such access, preservation, or disclosure is necessary or useful to respond or otherwise address such complaint.</p>

    <p>myHealthCheck360.com users may also connect their account to their Apple or Google Health profiles. To do this, users must consent to the connection and verify which data they would like to be automatically synced to their myhealthcheck360.com account. Data collected through this connection will follow all of the above rules.</p>

    <p>HealthCheck360 complies with the California Consumer Privacy Act (CCPA) and similar state laws. For users that would like more information about our use of Customer Account Data or Customer Usage Data, you have the ability to request:</p>

    <ul>
      <li>That we provide details about the categories of personal information that we collect about you, including how we collect and share it;</li>
      <li>That we provide you access to the personal information we collect about you; and</li>
      <li>That we delete the personal information we have about you.</li>
    </ul>

    <p>Please be aware we will take steps to verify that you are authorized to make the request. You are not required to be a California resident to make a request.</p>

    <p>The following lists what personal information we have shared lately for our business purposes:</p>
    <ul>
      <li>Identifiers</li>
      <li>Internet or other electronic activity information</li>
      <li>Geolocation information</li>
      <li>Professional or employment information</li>
      <li>Biometric data</li>
    </ul>

    <h2>Security of your Personal Information</h2>
    <p>myHealthCheck360.com secures your personal information from unauthorized access, use or disclosure. myHealthCheck360.com secures the personally identifiable information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use or disclosure. When personal information (such as a credit card number) is transmitted to other Web sites, it is protected through the use of encryption, such as the Secure Socket Layer (SSL) protocol.</p>

    <h2>Changes to this Policy</h2>
    <p>myHealthCheck360.com will occasionally update this Privacy Policy to reflect company and customer feedback. myHealthCheck360.com encourages you to periodically review this Privacy Policy to be informed of how myHealthCheck360.com is protecting your information.</p>

    <h2>Contact Information</h2>
    <p>myHealthCheck360.com welcomes your comments regarding this Statement of Privacy. If you believe that myHealthCheck360.com has not adhered to this Policy, please contact HealthCheck360 at: support@healthcheck360.com.</p>

    <address>
      HealthCheck360<br />
      800 Main Street<br />
      P.O. Box 28<br />
      Dubuque, IA 52004-0028<br />
      Attn: Director
    </address>

  </div>
</template>

<script lang="ts">
import {Component} from "vue-property-decorator";
import HcLogo from "@/vue/molecules/hc-logo.vue";
import {mixins} from "vue-class-component";
import {mixinClassBodyFactory} from "@/ts/mixins/bg-mixin";

@Component({
  name: "PrivacyPolicyPage",
  components: {
    HcLogo,
  },
})
export default class PrivacyPolicyPage extends mixins(mixinClassBodyFactory("global-onboarding-bg-color")) {
}
</script>

<style lang="sass" scoped>
.page-wrapper
  margin: 50px auto
  width: 100%
  max-width: 750px
  padding: 0 20px 0 40px
  color: $color-primary-hc-blue-100
  @include Roboto400

.headline
  text-align: center
  margin-bottom: 50px

h2
  @include Asap700
  size: 24px
  margin: 0

</style>
