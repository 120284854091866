import {getModule} from "vuex-module-decorators";
import {stateDecoratorFactory} from "@/ts/utils/decorators";
import {vueStore} from "@/ts/store/vue-store";
import {Vue} from "vue-property-decorator";
import {SchedulingBiometricStore} from "@/ts/store/scheduling-biometric/scheduling-biometric-store";

export const schedulingBiometricStore: SchedulingBiometricStore = getModule(SchedulingBiometricStore, vueStore);

export const SchedulingBiometricState = stateDecoratorFactory(schedulingBiometricStore);

export class SchedulingBiometricStoreMixin extends Vue {
  schedulingBiometricStore!: SchedulingBiometricStore;

  created(): void {
    this.schedulingBiometricStore = schedulingBiometricStore;
  }
}
