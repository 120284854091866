<template>
  <profile-field
    name="Company Code"
    :content="profile.companyCode"
    :error="error"
    :loading="loading"
    :show-success="showSuccess"
    :is-enabled="false"
    @save="save"
  >
    <edit-company-code v-model="companyCode"/>
  </profile-field>
</template>

<script lang="ts">
// NON EDITABLE COMPONENT
import {Component} from "vue-property-decorator";
import ProfileField from "@/vue/organisms/profile/profile-field.vue";
import {DefaultState, DefaultStoreMixin} from "@/ts/store/default/default-store-instance";
import type {Profile} from "@/ts/types/store/default-store-types";
import EditCompanyCode from "@/vue/molecules/edit-field/edit-company-code-input.vue";
import type {PutProfileRequestDTO} from "@/ts/types/dto/profile.dto";
import {profileToPutProfileRequest} from "@/ts/utils/pure-functions";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import {mixins} from "vue-class-component";

@Component({
  name: 'ProfileFieldCompanyCode',
  components: {EditCompanyCode, ProfileField}
})
export default class ProfileFieldCompanyCode extends mixins(LoadingMixin, DefaultStoreMixin) {
  @DefaultState
  profile!: Profile;

  showSuccess: boolean = false;

  // this is required to populate vue3 proxy
  companyCode: string = "";

  created(): void {
    this.companyCode = this.profile.companyCode;
  }

  @DefaultGrowlError
  async save(): Promise<void> {
    this.showSuccess = false;
    const value: PutProfileRequestDTO = {
      ...profileToPutProfileRequest(this.profile),
      // companyCode: this.companyCode,
    };
    const getProfileResponseDTO = await this.$api.updateProfile(value);
    const profileData: Profile = {
      ...getProfileResponseDTO,
      dateOfBirth: this.profile.dateOfBirth,
      location: this.profile.location,
      department: this.profile.department,
    };
    this.defaultStore.setProfile(profileData);
    this.showSuccess = true;
  }
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>

</style>
