<template>
  <button-primary
    :theme="buttonTheme"
    class="large"
    @click="openModal"
  >
    <img v-if="buttonTheme === 'dark'" src="@/assets/img/icons/cancel-white.svg"/>
    <img v-if="buttonTheme === 'white'" src="@/assets/img/icons/cancel.svg"/>
    Cancel Appointment
  </button-primary>
  <popup-modal
    :model-value="isModalDisplayed"
    @update:modelValue="closeModal"
  >
    <div class="modal-content">
      <h3 class="popup-title">
        Are you sure you want to cancel your currently scheduled appointment?
      </h3>
      <error-text :error="error"/>
      <div class="buttons">
        <button-primary
          theme="white"
          class="cancel-btn"
          :flexible-width="true"
          @click="closeModal"
        >
          Keep Current Appointment
        </button-primary>
        <button-primary
          theme="dark"
          :loading="loading"
          class="confirm-btn"
          :flexible-width="true"
          @click="onClickDeleteAppointment"
        >
          Cancel Current Appointment
        </button-primary>
      </div>
    </div>
  </popup-modal>
</template>
<script lang="ts">
import {Component, Prop} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import {GrowlsStoreMixin} from "@/ts/store/growl/growl-store-instance";
import ErrorText from "@/vue/atoms/error-text.vue";
import {SchedulingBiometricStoreMixin} from "@/ts/store/scheduling-biometric/scheduling-biometric-store-instance";
import PopupModal from "@/vue/atoms/popup-modal.vue";
import {SchedulingCoachingStoreMixin} from "@/ts/store/scheduling-coaching/scheduling-coaching-store-instance";

@Component({
  name: "CancelAppointment",
  components: {ButtonPrimary, ErrorText, PopupModal}
})
export default class CancelAppointment
    extends mixins(LoadingMixin, SchedulingBiometricStoreMixin, SchedulingCoachingStoreMixin, GrowlsStoreMixin) {
  @Prop()
  appointmentType!: "coaching" | "screening";

  @Prop({default: 'white'})
  buttonTheme!: "dark" | "white";

  isModalDisplayed: boolean = false;

  closeModal(): void {
    this.isModalDisplayed = false;
  }

  openModal(): void {
    this.isModalDisplayed = true;
  }

  onClickDeleteAppointment(): void {
    if (this.appointmentType === "screening") {
      void this.deleteScreeningAppointment();
    } else if (this.appointmentType === "coaching") {
      void this.deleteCoachingAppointment();
    }
  }

  @DefaultGrowlError
  async deleteCoachingAppointment(): Promise<void> {
    await this.$api.deleteCoachingScheduleAppointment();
    this.schedulingCoachingStore.setAppointment(null);
    this.dropMessageAndGoHome();
  }

  @DefaultGrowlError
  async deleteScreeningAppointment(): Promise<void> {
    await this.$api.deleteBiometricScheduleAppointment();
    this.schedulingBiometricStore.setAppointment(null);
    this.dropMessageAndGoHome();
  }

  dropMessageAndGoHome(): void {
    void this.growlsStore.growlInfo("Appointment has been deleted");
    void this.$router.push(this.$pagesPath.main.home);
  }
}
</script>
<style lang="sass" scoped>
.large
  font-size: 18px

.modal-content
  align-items: center
  display: flex
  flex-direction: column
  max-width: 600px

.popup-title
  @include Asap700
  color: $color-primary-hc-blue-100
  font-size: 24px
  letter-spacing: 0.15px
  line-height: 25px
  margin-top: 0
  text-align: center

.buttons
  display: flex
  gap: 16px

</style>
