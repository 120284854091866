<template>
  <div class="physician-form">
    <title-icon
      :icon="physicianFormIcon"
      text="Physician Form"
    />

    <div class="status">
      <physician-form-not-available-card v-if="!physicianFormAvailable"/>

      <physician-form-available-card
        v-else-if="physicianForm?.status === PhysicianFormStatus.available"
        :physician-form="physicianForm"
      />

      <physician-form-approved-card
        v-else-if="physicianForm?.status === PhysicianFormStatus.approved"
        :physician-form="physicianForm"
      />

      <physician-form-pending-card
        v-else-if="physicianForm?.status === PhysicianFormStatus.pending"
        :physician-form="physicianForm"
      />

      <physician-form-denied-card
        v-else-if="physicianForm?.status === PhysicianFormStatus.denied"
        :physician-form="physicianForm"
      />

      <physician-form-overdue-card
        v-else-if="physicianForm?.status === PhysicianFormStatus.overdue"
        :physician-form="physicianForm"
      />
    </div>

    <button-link
      theme="white"
      class="large"
      :disabled="isDisabled"
      :to="$pagesPath.physicianForm.downloadForm"
    >
      Physician form
    </button-link>
  </div>
</template>

<script lang="ts" setup>
import physicianFormIcon from "@/assets/img/icons/my-health/physician_form.svg";
import type {PhysicianFormAvailable} from "@/ts/types/dto/biometric-screening.dto";
import type {GetPhysicianFormBiometricResponse} from "@/ts/types/dto/physician-form.dto";
import {PhysicianFormStatus} from "@/ts/types/dto/physician-form.dto";
import type {MyProgramResponse} from "@/ts/types/dto/program.dto";
import TitleIcon from "@/vue/atoms/title-icon.vue";
import PhysicianFormDeniedCard from "@/vue/molecules/my-health/physician-form/physician-form-denied-card.vue";
import ButtonLink from "@/vue/atoms/button-link.vue";
import {computed} from "vue";
import PhysicianFormPendingCard from "@/vue/molecules/my-health/physician-form/physician-form-pending-card.vue";
import PhysicianFormApprovedCard from "@/vue/molecules/my-health/physician-form/physician-form-approved-card.vue";
import PhysicianFormNotAvailableCard
  from "@/vue/molecules/my-health/physician-form/physician-form-not-available-card.vue";
import PhysicianFormAvailableCard from "@/vue/molecules/my-health/physician-form/physician-form-available-card.vue";
import PhysicianFormOverdueCard from "@/vue/molecules/my-health/physician-form/physician-form-overdue-card.vue";

const props = defineProps<{
  physicianForm: GetPhysicianFormBiometricResponse | null;
  program: MyProgramResponse;
  physicianFormAvailable: PhysicianFormAvailable | null;
}>();

const isDisabled = computed(() => !(props.physicianFormAvailable && props.physicianFormAvailable.available));
</script>

<style lang="sass" scoped>
.physician-form
  background-color: $color-white
  border-radius: 16px
  color: $color-primary-hc-blue-100
  padding: 24px
  grid-column: 2

.title
  margin-bottom: 0

.status
  margin: 24px 0

.large
  font-size: 18px

.bold
  @include Roboto700

.text
  @include Roboto400
  color: $color-primary-hc-blue-100
  font-size: 14px
  letter-spacing: 0.2px
  line-height: 22px
  margin: 0 0 4px
</style>
