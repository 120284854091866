import {pagesPath} from "@/ts/router/pages-path";
import type {RouteRecordRaw} from "vue-router";
import HraStartPage from "@/vue/pages/hra/hra-start.page.vue";
import HraParentPage from "@/vue/pages/hra/hra-parent.page.vue";
import HraQuizPage from "@/vue/pages/hra/hra-quiz.page.vue";
import HraQuizOverviewPage from "@/vue/pages/hra/hra-quiz-overview.page.vue";
import HraQuizFinishPage from "@/vue/pages/hra/hra-finish.page.vue";

export const hraPages: RouteRecordRaw = {
  path: pagesPath.healthRiskAssessment.parent,
  component: HraParentPage,
  children: [
    {
      path: pagesPath.healthRiskAssessment.startHra,
      component: HraStartPage,
    },
    {
      path: pagesPath.healthRiskAssessment.quizHra,
      component: HraQuizPage,
    },
    {
      path: pagesPath.healthRiskAssessment.quizOverviewHra,
      component: HraQuizOverviewPage,
    },
    {
      path: pagesPath.healthRiskAssessment.finishHra,
      component: HraQuizFinishPage,
    },
  ],
};
