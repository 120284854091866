<template>
  <navbar-page title="My To-Do's" :profile-section="true">
    <calendar-list v-model="currentDay"/>
    <todo-list :day="currentDay"/>
  </navbar-page>
  <add-tracking-button/>
</template>

<script lang="ts">
import {Component} from "vue-property-decorator";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import TodoList from "@/vue/organisms/todo-list.vue";
import CalendarList from "@/vue/organisms/calendar-list.vue";
import NavbarPage from "@/vue/templates/navbar-page.vue";
import {mixins} from "vue-class-component";
import {mixinClassBodyFactory} from "@/ts/mixins/bg-mixin";
import {today} from "@/ts/utils/date-pure-functions";
import AddTrackingButton from "@/vue/atoms/add-tracking-button.vue";

@Component({
  name: "TodoPage",
  components: {
    AddTrackingButton,
    NavbarPage,
    ButtonPrimary,
    TodoList,
    CalendarList,
  },
})
export default class TodoPage extends mixins(mixinClassBodyFactory("global-onboarding-bg-color")) {
  currentDay: Date = today();
}

</script>
