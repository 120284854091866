<template>
  <loading-suspense :error="error" :loading="loading">
    <router-view/>
  </loading-suspense>
</template>

<script lang="ts">
import {Component} from "vue-property-decorator";
import type {Profile} from "@/ts/types/store/default-store-types";
import type {GetProfileResponseDTO} from "@/ts/types/dto/profile.dto";
import {parseDate} from "@/ts/utils/date-pure-functions";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import {mixins} from "vue-class-component";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import {DefaultStoreMixin, DefaultState} from "@/ts/store/default/default-store-instance";

@Component({
  name: 'ParentSignInPage',
  components: {LoadingSuspense},
})
export default class ParentSignInPage extends mixins(LoadingMixin, DefaultStoreMixin) {
  @DefaultState
  public profile!: Profile;

  @DefaultGrowlError
  async created(): Promise<void> {
    try {
      const profile: GetProfileResponseDTO = await this.$api.getProfile();
      this.defaultStore.setProfile({
        ...profile,
        dateOfBirth: parseDate(profile.dateOfBirth),
      });
    } catch (error) {
      this.$logger.error("Unable to get signin profile because of {}", error)();
      await this.$router.push(this.$pagesPath.auth.signIn);
    }
  }
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>
</style>
