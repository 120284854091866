import {DefaultStore} from "@/ts/store/default/default-store";
import {getModule} from "vuex-module-decorators";
import {vueStore} from "@/ts/store/vue-store";
import {BiometricPhysicianFormStore} from "@/ts/store/biometric-physician-form/biometric-physician-form-store";
import {OnboardingStore} from "@/ts/store/onboarding/onboarding-store";
import {SchedulingBiometricStore} from "@/ts/store/scheduling-biometric/scheduling-biometric-store";
import {SchedulingCoachingStore} from "@/ts/store/scheduling-coaching/scheduling-coaching-store";

export function logout(): void {
  const defaultStore: DefaultStore = getModule(DefaultStore, vueStore);
  defaultStore.clearAll(false);

  getModule(BiometricPhysicianFormStore, vueStore).clearAll();
  getModule(OnboardingStore, vueStore).clearAll();
  getModule(SchedulingBiometricStore, vueStore).clearAll();
  getModule(SchedulingCoachingStore, vueStore).clearAll();
}

export function deleteAccount(): void {
  const defaultStore: DefaultStore = getModule(DefaultStore, vueStore);
  defaultStore.clearAll(true);
  defaultStore.markAccountAsDeleted();

  getModule(BiometricPhysicianFormStore, vueStore).clearAll();
  getModule(OnboardingStore, vueStore).clearAll();
  getModule(SchedulingBiometricStore, vueStore).clearAll();
  getModule(SchedulingCoachingStore, vueStore).clearAll();
}
