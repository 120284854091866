<template>
  <div>
    redirecting!
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";

@Component({
  name: "Redirect",
})
export default class Redirect extends Vue {
  async mounted() {
    const response = await this.$api.getRedirectToKeycloakUrl();
    window.location.href = response.authUrl;
  }
}
</script>
