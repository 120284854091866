<template>
  <div class="reward">
    <p>
      {{ text }}
    </p>
    <position-medal :position="position"/>
    <badge-rounded theme="white" size="large" :value="`${points} PTS`"/>
  </div>
</template>

<script lang="ts">
import BadgeRounded from "@/vue/atoms/badge-rounded.vue";
import PositionMedal from "@/vue/atoms/position-medal.vue";
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
  name: "ChallengeReward",
  components: {
    BadgeRounded,
    PositionMedal,
  },
})
export default class ChallengeReward extends Vue {
  @Prop()
  position!: number;

  @Prop()
  points!: number;

  @Prop()
  text!: string;
}
</script>

<style lang="sass" scoped>
.reward
  display: flex
  flex-direction: column
  gap: 8px
  align-items: center

  p
    @include Asap700
    font-size: 16px
    color: $color-primary-hc-blue-100
    margin: 0
</style>
