import type {MultipleCheckboxItem} from "@/ts/types/component/input.select";
import type {HRAQuestionChoiceDetail,
  HRASurveyQuestionDetail,
  HRATakingMedicationsQuestion,
  HRATakingMedicationsQuestionChoiceDetail,
  HRASurvey,
  HRAFollowUpQuestion} from "@/ts/types/dto/health-risk-assessment.dto";
import {
  HRASurveyAnswerType,
} from "@/ts/types/dto/health-risk-assessment.dto";

function getFollowUpQuestionFromCurrentQuestion({
  selections,
  currentQuestionSelected,
}: {
  selections: HRAQuestionChoiceDetail[];
  currentQuestionSelected: MultipleCheckboxItem<HRAQuestionChoiceDetail>[];
}): HRAFollowUpQuestion | null {
  const selectionWithFollowUpQuestion = selections.find(
    (selection) => Boolean(currentQuestionSelected.some((option) => option.id === selection.value) && selection.followUpQuestion),
);

  return selectionWithFollowUpQuestion?.followUpQuestion ?? null;
}

function getFollowUpQuestionFromQuestion(selections: HRAQuestionChoiceDetail[]): HRAFollowUpQuestion | null {
  const selectionWithFollowUpQuestion = selections.find((selection) => selection.selected && selection.followUpQuestion);
  return selectionWithFollowUpQuestion?.followUpQuestion ?? null;
}

function getQuestionIndexOfFollowUpQuestion({
  questions,
  followUpQuestion,
}: {
  questions: HRASurveyQuestionDetail[];
  followUpQuestion: HRAFollowUpQuestion | null;
}): number | null {
  if (!followUpQuestion) {
    return null;
  }
  const index = questions
    .findIndex((question) => question.id === followUpQuestion.questionId && question.type === followUpQuestion.questionType);
  if (index === -1) {
    return null;
  }
  return index;
}

function getFirstFollowUpQuestionIndex({
  questions,
  questionIndex,
  isCurrentQuestion,
  currentQuestionSelected,
}: {
  questions: HRASurveyQuestionDetail[];
  questionIndex: number;
  isCurrentQuestion?: boolean;
  currentQuestionSelected?: MultipleCheckboxItem<HRAQuestionChoiceDetail>[];
}): number | null {
  const question = questions[questionIndex];
  const {selections} = question;
  if (!selections?.length) {
    return null;
  }

  let followUpQuestion = null;

  if (isCurrentQuestion) {
    followUpQuestion = getFollowUpQuestionFromCurrentQuestion({
      selections,
      currentQuestionSelected: currentQuestionSelected!,
    });
  } else {
    followUpQuestion = getFollowUpQuestionFromQuestion(selections);
  }

  return getQuestionIndexOfFollowUpQuestion({
    questions,
    followUpQuestion,
  });
}

function shouldAddDependent({
  questions,
  questionIndex,
  currentQuestionIndex,
  currentQuestionSelected,
}: {
  questions: HRASurveyQuestionDetail[];
  questionIndex: number;
  currentQuestionIndex?: number;
  currentQuestionSelected?: MultipleCheckboxItem<HRAQuestionChoiceDetail>[];
}): boolean {
  const question = questions[questionIndex];
  const {dependsOnQuestionSelections, isDependentQuestion} = question;

  if (!isDependentQuestion || !dependsOnQuestionSelections) {
    return false;
  }

  const dependableQuestionIndex = questions.findIndex((qu) => qu.id === dependsOnQuestionSelections.questionId &&
    qu.type === dependsOnQuestionSelections.questionType);

  const dependableQuestion = questions[dependableQuestionIndex];

  let isAnswerMatch = false;

  if (currentQuestionSelected && currentQuestionIndex && dependableQuestionIndex === currentQuestionIndex) {
    /**
     * in case the dependable question is current question being displayed
     * we will use the current selected options by the user
     * not those saved
     */

    isAnswerMatch = dependsOnQuestionSelections.selectionValues.some((sev) => currentQuestionSelected.map((sel) => sel.id).includes(sev));
  } else {
    isAnswerMatch = dependsOnQuestionSelections.selectionValues.some((sev) => dependableQuestion!.answers.includes(sev));
  }

  return Boolean(isAnswerMatch);
}

function addFollowUpQuestionIndexes({
  orderedIndexes,
  questionIndex,
  questions,
  currentQuestionIndex,
  currentQuestionSelectedOptions,
}: {
  orderedIndexes: number[];
  questionIndex: number;
  questions: HRASurveyQuestionDetail[];
  currentQuestionIndex?: number;
  currentQuestionSelectedOptions?: MultipleCheckboxItem<HRAQuestionChoiceDetail>[];
}): number[] {
  let hasFollowUpQuestion = false;
  let newQuestionIndex = questionIndex;
  do {
    const followUpQuestionIndex = getFirstFollowUpQuestionIndex({
      questions,
      questionIndex: newQuestionIndex,
      isCurrentQuestion: newQuestionIndex === currentQuestionIndex,
      currentQuestionSelected: currentQuestionSelectedOptions,
    });

    if (followUpQuestionIndex) {
      const addedAlready = orderedIndexes.includes(followUpQuestionIndex);

      if (!addedAlready) {
        orderedIndexes.push(followUpQuestionIndex);
      }

      newQuestionIndex = followUpQuestionIndex;
      hasFollowUpQuestion = true;
    }

    hasFollowUpQuestion = false;
  } while (hasFollowUpQuestion);

  return orderedIndexes;
}

function addQuestionIndexWhenIsDependent({
  orderedIndexes,
  questionIndex,
  questions,
  currentQuestionIndex,
  currentQuestionSelectedOptions,
}: {
  orderedIndexes: number[];
  questionIndex: number;
  questions: HRASurveyQuestionDetail[];
  currentQuestionIndex?: number;
  currentQuestionSelectedOptions?: MultipleCheckboxItem<HRAQuestionChoiceDetail>[];
}): number[] {
  const addedAlready = orderedIndexes.includes(questionIndex);

  if (!addedAlready &&
    questions[questionIndex].isDependentQuestion &&
    shouldAddDependent({
      questions,
      questionIndex,
      currentQuestionIndex,
      currentQuestionSelected: currentQuestionSelectedOptions,
    })) {
    orderedIndexes.push(questionIndex);
  }

  return orderedIndexes;
}

function addQuestionIndexWhenIsNotDependent({
  orderedIndexes,
  questionIndex,
  questions,
}: {
  orderedIndexes: number[];
  questionIndex: number;
  questions: HRASurveyQuestionDetail[];
}): number[] {
  const addedAlready = orderedIndexes.includes(questionIndex);

  if (!addedAlready && !questions[questionIndex].isDependentQuestion) {
    orderedIndexes.push(questionIndex);
  }

  return orderedIndexes;
}

function orderQuestionsToDisplay({
  hraResponse,
  currentQuestionIndex,
  currentQuestionSelectedOptions,
}: {
  hraResponse: HRASurvey | null;
  currentQuestionIndex?: number;
  currentQuestionSelectedOptions?: MultipleCheckboxItem<HRAQuestionChoiceDetail>[];
}): number[] {
  if (!hraResponse) {
    return [];
  }

  const orderedIndexes: number[] = [0];
  const {questions} = hraResponse;

  for (let questionIndex = 0; questionIndex < questions.length; questionIndex++) {
    addQuestionIndexWhenIsDependent({
      questionIndex,
      questions,
      currentQuestionIndex,
      currentQuestionSelectedOptions,
      orderedIndexes,
    });

    addQuestionIndexWhenIsNotDependent({
      questionIndex,
      questions,
      orderedIndexes,
    });

    addFollowUpQuestionIndexes({
      questionIndex,
      questions,
      currentQuestionIndex,
      currentQuestionSelectedOptions,
      orderedIndexes,
    });
  }

  return orderedIndexes;
}

function isValid({
  question,
  selected,
  textAnswer,
  medicationQuestion,
  medicationQuestionAnswerSelected,
}: {
  question: HRASurveyQuestionDetail;
  selected: MultipleCheckboxItem<HRAQuestionChoiceDetail>[];
  textAnswer: string | null;
  medicationQuestion: HRATakingMedicationsQuestion | null;
  medicationQuestionAnswerSelected: MultipleCheckboxItem<HRATakingMedicationsQuestionChoiceDetail>[];
}): boolean {
  const isAnswerNotGivenWhenTypeSelection = Boolean(question.answerType === HRASurveyAnswerType.selection && !selected?.length);

  const isAnswerNotGivenWhenTypeText = Boolean(question.answerType === HRASurveyAnswerType.text && !textAnswer);

  const isMedicationQuestionNotAnsweredWhenRequired = Boolean(medicationQuestion?.isRequired && !medicationQuestionAnswerSelected.length);

  if (question.isRequired) {
    if (isAnswerNotGivenWhenTypeSelection || isAnswerNotGivenWhenTypeText) {
      return false;
    }

    if (isMedicationQuestionNotAnsweredWhenRequired) {
      return false;
    }
  }

  return true;
}

export {
  orderQuestionsToDisplay,
  isValid,
};


