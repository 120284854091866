<template>
  <card-item>
    <router-link :to="to">
      <avatar-frame
        :image="pathway.coverPath"
        :size="60"
        :alt="'Pathway Cover Image'"
        border="none"
      />
      <div class="content">
        <h1>{{ pathway.title }}</h1>
        <p v-if="statusLogic" class="legend">
          <pathway-label-with-icon :value="statusLogic.value" :icon-type="statusLogic.iconType"/>
        </p>
        <p>
          <badge-rounded
            v-if="reward"
            :theme="!isCompleted ? 'light' : 'dark'"
            size="small"
            :value="reward"
          />
        </p>
      </div>
    </router-link>
  </card-item>
</template>

<script lang="ts" setup>
import CardItem from "@/vue/atoms/card-item.vue";
import type {PathwayFullDto} from "@/ts/types/dto/pathway.dto";
import {TodoStatus} from "@/ts/types/dto/pathway.dto";
import BadgeRounded from "@/vue/atoms/badge-rounded.vue";
import PathwayLabelWithIcon from "@/vue/atoms/pathway-label-with-icon.vue";
import {getRewardAmountFormatted} from "@/ts/utils/pure-functions";
import {formatDateShortMonthName, formatDuration} from "@/ts/utils/date-pure-functions";
import {computed, inject} from "vue";
import type {PagesPath} from "@/ts/router/pages-path";
import AvatarFrame from "@/vue/atoms/avatar-frame.vue";

type IconType = "completed" | "pending" | "viewed" | "waiting";

interface IStatus {
  value: string;
  iconType?: IconType;
}

const props = defineProps<{
  pathway: PathwayFullDto;
}>();

const isCompleted = computed((): boolean => {
  return props.pathway.status === TodoStatus.completed;
});

const reward = computed((): string | null => {
  if (props.pathway.reward) {
    return getRewardAmountFormatted(props.pathway.reward);
  }
  return null;
});

const statusLogic = computed((): IStatus | null => {
  if (props.pathway.status === TodoStatus.available) {
    const duration = formatDuration(props.pathway.duration);
    return {
      value: duration,
      iconType: "waiting",
    };
  }
  if (props.pathway.status === TodoStatus.completed) {
    const formattedDate = formatDateShortMonthName(props.pathway.lastUpdateTimestamp.toString());
    return {
      value: `Completed on ${formattedDate}`,
      iconType: "completed",
    };
  }
  if (props.pathway.status === TodoStatus.active) {
    if (props.pathway.lastSectionRead <= props.pathway.numberOfSections && props.pathway.lastSectionRead > 0) {
      return {
        value: `${props.pathway.lastSectionRead}/${props.pathway.numberOfSections} viewed`,
        iconType: "viewed",
      };
    }
    return {
      value: "Test pending",
      iconType: "pending",
    };
  }
  return null;
});

const $pagesPath = inject<PagesPath>("$pagesPath")!;
const to = computed((): string => {
  return $pagesPath.pathway.pathwayStart.replace(":id", props.pathway.id);
});

</script>

<style lang="sass" scoped>
.content
  display: flex
  flex-direction: column

  p
    @include Roboto400
    font-size: 0.75rem
    margin: 0.5rem 0

  h1
    @include Asap700
    color: $color-primary-hc-blue-100
    font-size: 1rem
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    margin-bottom: 0

  .legend
    color: $color-neutral-platinum-100

</style>
