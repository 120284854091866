<template>
  <router-link :to="to">
    <card-wrapper size="large" :border="hostBorderClass">
      <div class="image-frame">
        <avatar-frame :image="cover" :fallback-image="coverFallback" :size="60"/>
      </div>
      <div class="content">
        <challenge-type :type="challenge.type"/>
        <h1>{{ challenge.name }}</h1>
        <div class="duration">
          <img :src="iconTime" alt="time remaining"/>
          <p>
            {{ duration }}
          </p>
        </div>
      </div>
      <div class="footer">
        <badge-rounded
          v-if="!creatorTypeAuthor && challengePoints"
          theme="light"
          size="small"
          :value="challengePoints"
        />
      </div>
    </card-wrapper>
  </router-link>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import type {ChallengeDTO} from "@/ts/types/dto/challenges.dto";
import {formatDateShortMonthName, getRemainNumberOfDays} from "@/ts/utils/date-pure-functions";
import BadgeRounded from "@/vue/atoms/badge-rounded.vue";
import AvatarFrame from "@/vue/atoms/avatar-frame.vue";
import AuthorImageLabel from "@/vue/atoms/author-image-label.vue";
import CardWrapper from "@/vue/atoms/card-wrapper.vue";
import clockIcon from "@/assets/img/challenge-card-icons/clock-icon.svg";
import calendarIcon from "@/assets/img/challenge-card-icons/calendar-clean-icon.svg";
import ChallengeType from "@/vue/molecules/challenge/challenge-type.vue";
import {DefaultState} from "@/ts/store/default/default-store-instance";
import type {Profile} from "@/ts/types/store/default-store-types";
import type {RouteLocationRaw} from "vue-router";
import challengeDefaultImage from "@/assets/img/challenge-card-icons/challenge-default-image.svg";
import {ChallengeStatus} from "@/ts/types/component/challenges";
import {determineChallengeStatus} from "@/ts/utils/pure-functions";

@Component({
  name: "ChallengeCard",
  components: {
    BadgeRounded,
    AvatarFrame,
    AuthorImageLabel,
    CardWrapper,
    ChallengeType,
  },
})
export default class ChallengeCard extends Vue {
  @Prop() challenge!: ChallengeDTO;

  @DefaultState
  public profile!: Profile;

  get challengeStatus(): ChallengeStatus {
    return determineChallengeStatus(this.challenge);
  }

  get duration(): string {
    const daysUntilStart = Math.max(getRemainNumberOfDays(
          this.challenge.startDate,
      ), 0);
    const duration = Math.max(getRemainNumberOfDays(this.challenge.endDate), 0);
    switch (this.challengeStatus) {
      case ChallengeStatus.Active:
        return duration === 0
            ? "Ends Today"
            : duration > 1
                ? `Ends in ${duration} days`
                : `Ends in ${duration} day`;

      case ChallengeStatus.Available:
        if (daysUntilStart > 1) {
          return `Starts in ${daysUntilStart} days`;
        } else if (daysUntilStart === 1) {
          return `Starts in ${daysUntilStart} day`;
        } else {
          return duration === 0
              ? "Ends Today"
              : duration > 1
                  ? `Ends in ${duration} days`
                  : `Ends in ${duration} day`;
        }
      case ChallengeStatus.Joined:
        return daysUntilStart > 1 ? `Starts in ${daysUntilStart} days` : `Starts in ${daysUntilStart} day`;
      case ChallengeStatus.Completed:
        return `Ended on ${formatDateShortMonthName(this.challenge.endDate)}`;
      default:
        return "";
    }

  }

  get iconTime(): string {
    return this.challengeStatus === ChallengeStatus.Active ? clockIcon : calendarIcon;
  }

  get cover(): string {
    return this.challenge.coverPath;
  }

  get coverFallback(): string {
    return challengeDefaultImage;
  }

  get challengePoints(): string | null {
    if (
      !this.challenge.pointsThirdPositionReward &&
      !this.challenge.pointsFirstPositionReward
    ) {
      return null;
    }
    return `${this.challenge.pointsThirdPositionReward}-${this.challenge.pointsFirstPositionReward} PTS`;
  }

  get creatorTypeAuthor(): boolean {
    return this.challenge.author?.id === this.profile.id;
  }

  get to(): RouteLocationRaw {
    return {
      path: this.$pagesPath.challenge.challenge.replace(
        ":id",
        this.challenge.id,
      ),
      query: {type: this.challenge.type},
    };
  }

  get hostBorderClass(): string {
    if (this.challenge.author?.id === this.profile.id) {
      return "orange";
    }

    return "none";
  }
}
</script>

<style lang="sass" scoped>

h1
  @include Asap700
  color: $color-primary-hc-blue-100
  font-size: 1rem
  margin: 0
  line-height: 22px
  /* stylelint-disable */
  display: -webkit-box
  -webkit-line-clamp: 2
  -webkit-box-orient: vertical
  overflow: hidden

.sub-text
  @include Roboto400
  font-size: 10px
  line-height: 15px
  letter-spacing: 0.4px
  text-transform: uppercase
  color: $color-primary-hc-blue-100


.duration
  display: flex
  align-items: center
  gap: 4px

  p
    @include Roboto400
    font-size: 12px
    line-height: 16px
    color: $color-primary-hc-blue-50
    margin: 8px 0

</style>
