import type {AuthLoginResponseDTO} from "@/ts/types/dto/auth.dto";
import type {GetProfileResponseDTO} from "@/ts/types/dto/profile.dto";
import type {GetRewardTrackerDTO} from "@/ts/types/dto/reward-tracker";
import type {GetProgramResponseDTO} from "@/ts/types/dto/program.dto";

export enum GrowlType {
  SUCCESS = "growl-success", INFO = "growl-info", ERROR = "growl-error",
}

export interface GrowlModel {
  id: number;
  text: string;
  type: GrowlType;
}

export type AuthTokenInfo = AuthLoginResponseDTO;

export type RewardsTracker = GetRewardTrackerDTO;

export type Program = GetProgramResponseDTO;

export interface Profile extends Omit<GetProfileResponseDTO, "dateOfBirth"> {
  dateOfBirth: Date;
}

export interface SkippedTodos {
  userId: string;
  skippedTodosIds: string[];
}
