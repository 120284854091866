<template>
  <div class="onboarding-card">
    <img :src="image" alt="onboarding image"/>
    <div>
      <h2>{{ title }}</h2>
      <p>{{ text }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";

@Component({
  name: "OnboardingCard",
})
export default class OnboardingCard extends Vue {
  @Prop() text!: string;

  @Prop() title!: string;

  @Prop() image!: string;
}
</script>

<style lang="sass" scoped>
.onboarding-card
  background: $color-white
  display: flex
  width: 100%
  position: relative
  padding: 12px 40px 12px 352px

img
  position: absolute
  bottom: -10px
  left: 57px
  max-height: 176px

h2
  color: $color-primary-hc-blue-100
  font-size: 32px
  font-style: normal
  @include Asap700
  margin: 0

p
  color: $color-primary-hc-blue-100
  @include Roboto400
  font-size: 16px
  font-style: normal
  line-height: 24px
  margin: 0
  margin-top: 8px
</style>
