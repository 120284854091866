<template>
  <field-set id="first-name" label="First Name">
    <input-text
      id="first-name"
      :model-value="modelValue"
      required
      autocomplete="given-name"
      placeholder="First Name"
      @update:model-value="updateValue"
    />
  </field-set>
</template>

<script lang="ts">
import {
  Component,
  Emit,
  Prop,
  Vue,
} from "vue-property-decorator";
import FieldSet from "@/vue/molecules/field-set.vue";
import InputText from "@/vue/atoms/input-text.vue";

@Component({
  components: {FieldSet, InputText}
})
export default class EditFirstName extends Vue {
  @Prop() modelValue!: string;

  @Emit('update:modelValue')
  updateValue(value: string): string {
    return value;
  }
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>

</style>
