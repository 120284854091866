<template>
  <div>
    <label class="switch">
      <input
        type="checkbox"
        :checked="modelValue"
        @click="toggle"
      />
      <span class="slider round"/>
    </label>
  </div>
</template>

<script lang="ts">
import {Component, Vue, Prop, Emit} from "vue-property-decorator";

@Component({
  name: "ToggleSwitch",
})
export default class ToggleSwitch extends Vue {
  @Prop() modelValue!: boolean;

  @Emit("update:modelValue")
  toggle(): boolean {
    return !this.modelValue;
  }
}
</script>

<style lang="sass" scoped>
.switch
  position: relative
  display: inline-block
  width: 50px
  height: 26px

.switch input
  opacity: 0
  width: 0
  height: 0

.slider
  position: absolute
  cursor: pointer
  top: 0
  left: 0
  right: 0
  bottom: 0
  background-color: $color-secondary-state-blue-10
  transition: 0.4s

.slider::before
  position: absolute
  content: ""
  height: 22px
  width: 22px
  left: 2px
  bottom: 2px
  background-color: $color-white
  transition: 0.4s

input:checked + .slider
  background-color: $color-secondary-state-blue-100

input:focus + .slider
  box-shadow: 0 0 1px $color-secondary-state-blue-100

input:checked + .slider::before
  transform: translateX(24px)

.slider.round
  border-radius: 13.5px

.slider.round::before
  border-radius: 50%
</style>
