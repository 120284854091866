<template>
  <label :for="id" class="radio-label">
    <input
      :id="id"
      v-validity="validity"
      :value="value"
      type="radio"
      :name="name"
      :checked="checked"
      :required="required"
      class="radio-input"
      :disabled="disabled"
      @input="updateValue"
    />
    <p>{{ label }} </p></label>
</template>

<script lang="ts">
import {Component, Emit, Prop, Vue} from "vue-property-decorator";

@Component({
  name: "InputRadio",
})
export default class InputRadio extends Vue {
  @Prop() name!: string;

  @Prop() checked!: boolean;

  @Prop() label!: string;

  @Prop() readonly value!: string;

  @Prop({default: false}) required!: boolean;

  @Prop({default: undefined}) id!: string | undefined;

  @Prop({default: ""}) validity!: string;

  @Prop({default: false}) disabled!: boolean;

  @Emit("update:modelValue")
  updateValue(event: any): string {
    return event.target.value as string; // eslint-disable-line @typescript-eslint/no-unsafe-member-access
  }
}
</script>

<style lang="sass" scoped>
.radio-label
  display: flex
  align-items: center
  gap: 8px

  p
    @include Roboto400
    font-size: 14px
    color: $color-primary-hc-blue-100

.radio-input:not(:checked)
  background-color: $color-white
  border: 1.5px solid $color-neutral-platinum-100

.radio-input
  /* stylelint-disable */
  -webkit-appearance: none
  appearance: none
  background-color: $color-secondary-state-blue-100
  margin: 0
  font: inherit
  color: $color-secondary-state-blue-100
  width: 20px
  height: 20px
  border-radius: 50%
  transform: translateY(-0.075em)
  display: flex
  align-items: center
  place-content: center

.radio-input::before
  content: ""
  width: 8px
  height: 8px
  border-radius: 50%
  transform: scale(0)
  transition: 120ms transform ease-in-out
  box-shadow: inset 1em 1em $color-white
  background-color: $color-secondary-state-blue-100

.radio-input:checked::before
  transform: scale(1)

.radio-input:focus
  outline: max(2px, 0.15em) solid $color-secondary-state-blue-100
  outline-offset: max(2px, 0.15em)
</style>
