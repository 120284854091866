import type {TaskType} from "@/ts/types/dto/activity.dto";

export interface CompletionValidation {
  type: RewardValidationType;
  status: RewardCompletionStatus;
}

export enum RewardValidationType {
  Default = "default",
  AdminApproval = "admin_approval",
  AutoApproval = "auto_approval",
  AdminUpload = "admin_upload",
}

export enum RewardCompletionStatus {
  not_submitted = "not_submitted",
  submitted = "submitted",
  accepted = "accepted",
  declined = "declined",
}

export enum RewardType {
  cash = "cash",
  point = "point",
}

export interface Reward {
  type: RewardType;
  amount: number;
}

export interface RewardValidation {
  type: RewardType;
  amount: number;
  completionValidation: CompletionValidation;
}

export enum RewardHistoryContentType {
  reward_history = "reward_history",
  activity = "activity",
  platform_activity = "platform_activity",
  pathway = "pathway",
  challenge_leaderboard = "challenge_leaderboard",
  challenge_target = "challenge_target",
  challenge_map_race = "challenge_map_race",
  challenge_relay_race = "challenge_relay_race",
  bioscreening = "bioscreening",
  hra = "hra",
  coaching = "coaching",
  redeem_reward = "redeem_reward",
}

export interface RewardHistoryItem {
  id: string;
  amount: number;
  contentType: RewardHistoryContentType;
  date: string;
  displayText: string;
  isGatekeeper: boolean;
  rewardType: RewardType;
  taskType: TaskType;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}
