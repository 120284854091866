<template>
  <navbar-page
    title="My Profile"
    class="body"
    :display-right-nav="false"
    :back-link="$pagesPath.main.home"
  >
    <profile-info/>
    <profile-page-profile-link/>
    <profile-page-company-link/>
    <div class="logout">
      <button-primary theme="white" text="Log Out" @click="logout"/>
    </div>
    <div class="delete">
      <button-primary theme="white" text="Delete Account" @click="isDeleteAccountPopupOpen = true"/>
    </div>

    <delete-account-popup v-model="isDeleteAccountPopupOpen"/>
  </navbar-page>
</template>
<script lang="ts">
import {Component} from "vue-property-decorator";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import ProfileInfo from "@/vue/molecules/profile-info.vue";
import ProfilePageProfileLink from "@/vue/organisms/profile/block/profile-page-profile-link.vue";
import ProfilePageCompanyLink from "@/vue/organisms/profile/block/profile-page-company-link.vue";
import NavbarPage from "@/vue/templates/navbar-page.vue";
import {mixins} from "vue-class-component";
import {mixinClassBodyFactory} from "@/ts/mixins/bg-mixin";
import {DefaultStoreMixin} from "@/ts/store/default/default-store-instance";
import ButtonLink from "@/vue/atoms/button-link.vue";
import DeleteAccountPopup from "@/vue/organisms/profile/delete-account-popup.vue";
import {logout} from "@/ts/utils/exit-flows";

@Component({
  name: "ProfilePage",
  components: {
    ButtonLink,
    NavbarPage,
    ButtonPrimary,
    ProfileInfo,
    ProfilePageProfileLink,
    ProfilePageCompanyLink,
    DeleteAccountPopup,
  },
})
export default class ProfilePage extends mixins(mixinClassBodyFactory("global-onboarding-bg-color"), DefaultStoreMixin) {
  isDeleteAccountPopupOpen: boolean = false;

  async logout(): Promise<void> {
    logout();
    await this.$api.logout();
    await this.$router.push(this.$pagesPath.auth.logOut);
  }
}
</script>
<style lang="sass" scoped>
.body
  background-image: url("/assets/img/background-profile.svg")
  background-position-y: -10rem
  background-repeat: no-repeat
  background-size: contain
  color: $color-primary-hc-blue-100
  @include Asap700
  position: absolute
  height: 100%
  width: 100%

.option-title
  display: flex
  justify-content: center
  align-content: center
  @include Asap700

.profile-options
  display: flex
  flex-direction: row
  justify-content: center
  @include Asap700

.logout
  display: flex
  justify-content: center
  align-content: center
  margin: 30px 30px 0 30px
  padding: 2rem

  :deep(button)
    background-color: transparent

.delete
  display: flex
  justify-content: center
  align-content: center
  padding-bottom: 100px

  :deep(button)
    background-color: transparent
    border: none

    &:hover
      border: 2px solid #9eabba

</style>
