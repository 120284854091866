import type {SessionHolder} from "@/ts/types";

export class Storage implements SessionHolder {
  public get sessionToken(): string | null {
    return localStorage.getItem("sessionToken");
  }

  public set sessionToken(value: string | null) {
    if (value) {
      localStorage.setItem("sessionToken", value);
    } else {
      localStorage.removeItem("sessionToken");
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get refreshToken(): string | null {
    return localStorage.getItem("refreshToken");
  }

  set refreshToken(value: string | null) {
    if (value) {
      localStorage.setItem("refreshToken", value);
    } else {
      localStorage.removeItem("refreshToken");
    }
  }

  set mfaToken(value: string | null) {
    if (value === null) {
      localStorage.removeItem("mfaToken");
    } else {
      localStorage.setItem("mfaToken", value);
    }
  }
}
