import {pagesPath} from "@/ts/router/pages-path";
import type {MultipleCheckboxItem} from "@/ts/types/component/input.select";
import type {HRAQuestionChoiceDetail, HRASurvey, HRATakingMedicationsQuestionChoiceDetail} from "@/ts/types/dto/health-risk-assessment.dto";

export function getQuestionLink({
  questionId,
  questionType,
  hraSurvey,
}: {
  questionId: number;
  questionType: string;
  hraSurvey: HRASurvey;
}): string {
  return `${pagesPath.healthRiskAssessment.quizHra
    .replace(":id", hraSurvey.id)}?id=${questionId}&type=${questionType}`;
}

export function getQuestionPath({
  orderedQuestionIndex,
  orderedQuestionsToDisplay,
  hraSurvey,
}: {
  orderedQuestionIndex: number;
  orderedQuestionsToDisplay: number[];
  hraSurvey: HRASurvey;
}): string | null {
  const questionIndex = orderedQuestionsToDisplay[orderedQuestionIndex] ?? null;
  if (questionIndex !== null) {
    const nextQuestion = hraSurvey.questions[questionIndex];
    return getQuestionLink({
      hraSurvey,
      questionId: nextQuestion.id,
      questionType: nextQuestion.type,
    });
  }
  return null;
}

export function buildQuestionOption(selection: HRAQuestionChoiceDetail): MultipleCheckboxItem<HRAQuestionChoiceDetail> {
  return {
    text: selection.selection,
    id: selection.value,
    data: selection,
  };
}

export function convertFromBooleanToString(bool: boolean): string {
  return bool.toString();
}

export function convertFromStringToBoolean(str: string): boolean {
  return str === "true";
}

export function buildMedicationQuestionOption(
  selection: HRATakingMedicationsQuestionChoiceDetail,
): MultipleCheckboxItem<HRATakingMedicationsQuestionChoiceDetail> {
  return {
    text: selection.selection,
    id: convertFromBooleanToString(selection.value),
    data: selection,
  };
}


