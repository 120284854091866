<template>
  <router-link :to="link" class="settings-items">
    <img :src="img"/>
    <div>{{ text }}</div>
    <img src="@/assets/img/right-arrow.svg" class="go-right"/>
  </router-link>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";

@Component({
  name: "SettingsItem",
})
export default class SettingsItem extends Vue {
  @Prop()
  text!: string;

  @Prop()
  link!: string;

  @Prop()
  img!: string;
}
</script>

<style lang="sass" scoped>
.settings-items
  display: flex
  flex-direction: row
  height: 56px
  align-items: center
  background-color: $color-white
  border-radius: 12px
  gap: 12px
  padding: 0 20px
  @include Roboto700
  color: $color-primary-hc-blue-100
  width: 100%

.go-right
  margin-left: auto

</style>
