<template>
  <div class="headline">
    <component :is="headlineTag" class="title text-h5">
      {{ title }}
    </component>
    <span class="text-h5 text-primary-blue-50">{{ amount }}</span>
  </div>
</template>

<script lang="ts" setup>

import {computed} from "vue";

const props = defineProps<{
  title: string;
  amount: number;
  headlineTag?: string;
}>();

const headlineTag = computed((): string => {
  return props.headlineTag ?? "h2";
});

</script>

<style lang="sass" scoped>
.headline
  margin-bottom: 8px

.title
  display: inline-block
  margin: 0 8px 0 0
</style>
