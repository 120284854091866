<template>
  <div class="page-login">
    <hc-logo/>
    <div class="image-box">
      <img src="@/assets/img/login-img.svg" alt="Image Box"/>
    </div>
    <form class="login-group" @submit.prevent="login">
      <div v-if="!showMfaInput" class="container">
        <field-set id="username" label="Username">
          <input-text
            id="username"
            v-model="username"
            placeholder="Enter username"
            required
          />
          <template #right>
            <span class="forgot" @click="showForgotUsername">Forgot username?</span>
          </template>
        </field-set>
        <field-set id="password" label="Password">
          <input-password
            id="password"
            v-model="password"
            placeholder="Enter password"
            required
          />
          <template #right>
            <span class="forgot" @click="showForgotPassword">Forgot password?</span>
          </template>
        </field-set>
      </div>
      <div v-if="showMfaInput" class="mfa-section">
        <div class="field-set">
          <label for="mfaToken">MFA Token Verification</label>
          <input-text
            id="mfaToken"
            v-model="mfaToken"
            placeholder="Enter MFA token"
            required
            @keyup.enter="verifyMfaTokenOnEnter"
          />
          <div class="timer">
            Time remaining: {{ countdown }}
          </div>
          <button-primary
            class="verify-mfa-btn"
            type="button"
            text="Verify MFA"
            @click="verifyMfaToken"
          />
        </div>
      </div>
      <error-text :error="error"/>
      <button-primary
        v-if="!showMfaInput"
        class="submit"
        type="submit"
        text="Login"
      />
      <div v-if="!showMfaInput" class="go-to-register">
        <span class="no-account">Don't have an account?</span>
        <span class="register-btn" @click="showCompanyCodePopup">Register</span>
      </div>
      <div class="privacy-policy">
        <span class="no-account">Learn more about our </span>
        <router-link :to="$pagesPath.auth.privacyPolicy" class="privacy-policy-btn">
          Privacy Policy
        </router-link>
      </div>
    </form>

    <forgot-password-popup
      :displayed="isForgotPassword"
      @close-popup="hideForgotPassword"
    />

    <forgot-username-popup
      :displayed="isForgotUsername"
      @close-popup="hideForgotUsername"
    />

    <company-code-popup
      :displayed="isCompanyCodePopup"
      @close-popup="hideCompanyCodePopup"
    />
  </div>
</template>

<script lang="ts">
import {Component} from "vue-property-decorator";
import HcLogo from "@/vue/molecules/hc-logo.vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import ErrorText from "@/vue/atoms/error-text.vue";
import InputText from "@/vue/atoms/input-text.vue";
import FieldSet from "@/vue/molecules/field-set.vue";
import InputPassword from "@/vue/atoms/input-password.vue";
import type {AuthLoginResponseDTO, MfaVerificationRequestDTO} from "@/ts/types/dto/auth.dto";
import {mixins} from "vue-class-component";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import {DefaultStoreMixin} from "@/ts/store/default/default-store-instance";
import ForgotPasswordPopup from "@/vue/organisms/forgot-password-popup.vue";
import ForgotUsernamePopup from "@/vue/organisms/forgot-username-popup.vue";
import CompanyCodePopup from "@/vue/organisms/company-code-popup.vue";
import {sessionStore} from "@/ts/instances/localstorage";

@Component({
  name: "LoginPage",
  components: {
    HcLogo,
    ButtonPrimary,
    ErrorText,
    InputText,
    FieldSet,
    InputPassword,
    ForgotPasswordPopup,
    ForgotUsernamePopup,
    CompanyCodePopup
  },
})
export default class LoginPage extends mixins(LoadingMixin, DefaultStoreMixin) {
  username: string = "";

  password: string = "";

  isForgotPassword: boolean = false;

  isForgotUsername: boolean = false;

  isCompanyCodePopup: boolean = false;

  mfaToken: string = "";

  showMfaInput: boolean = false;

  timer: number | null = null;

  countdown: string | null = null;

  mounted(): void {
    void this.checkForToken();
  }

  @DefaultGrowlError
  async login(): Promise<void> {
    if (this.showMfaInput) {
      return;
    }

    this.error = "";
    const data: AuthLoginResponseDTO = await this.$api.login({
      password: this.password,
      username: this.username,
    });
    this.defaultStore.setAuthTokens(data);
    if (data.mfaToken) {
      this.showMfaInput = true;
      this.startTimer();
    } else {
      void this.$router.push("/");
    }
  }

  showForgotPassword(): void {
    this.isForgotPassword = true;
  }

  hideForgotPassword(): void {
    this.isForgotPassword = false;
  }

  showForgotUsername(): void {
    this.isForgotUsername = true;
  }

  hideForgotUsername(): void {
    this.isForgotUsername = false;
  }

  showCompanyCodePopup(): void {
  this.isCompanyCodePopup = true;
}

  hideCompanyCodePopup(): void {
    this.isCompanyCodePopup = false;
  }

  async checkForToken(): Promise<void> {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    const redirectUri = urlParams.get("redirect_uri"); // Extract the redirect_uri parameter

    if (token) {
      try {
        const data = await this.$api.loginToken(token);
        this.defaultStore.setAuthTokens(data);

        // Redirect only if redirectUri is a non-null string
        if (redirectUri && this.isValidRedirectUri(redirectUri)) {
          window.location.href = redirectUri;
        } else {
          // Default redirection or handle the absence of a valid redirectUri
          void this.$router.push("/");
        }
      } catch (error) {
        this.error = "Error validating token";
      }
    }
  }

  // Utility method to validate the redirect URI
  isValidRedirectUri(uri: string): boolean {
    // Basic validation: check if the URI is a valid URL
    try {
      new URL(uri);
      return true;
    } catch (e) {
      return false;
    }

    // todo: Add more sophisticated checks as necessary, such as ensuring the URI belongs to your domain
  }

  async verifyMfaToken(): Promise<void> {
    const authToken = localStorage.getItem("mfaToken"); // Retrieve the authToken stored locally
    if (!authToken) {
      this.error = "No auth token available.";
      return;
    }

    try {
      // Construct the request object with both tokens
      const request: MfaVerificationRequestDTO = {
        AuthCode: authToken,
        MfaToken: this.mfaToken,
      };

      // Make the API call with the constructed request
      const verificationResult = await this.$api.verifyMfaToken(request);
      if (verificationResult.sessionToken && verificationResult.refreshToken) {
        this.defaultStore.setAuthTokens(verificationResult); // Update the auth tokens in your store
        void this.$router.push("/"); // Navigate to the home page or another appropriate route
      } else {
        // If the verificationResult doesn't include the expected tokens, handle it as a failure
        this.error = "MFA verification failed. Please try again.";
      }
    } catch (error) {
      console.error(error);
      this.error = "Failed to verify MFA token.";
    }
  }

  startTimer() {
    const duration = 10 * 60; // 10 minutes
    let time = duration;
    this.timer = window.setInterval(() => { // Use window.setInterval to clarify the browser context
      const minutes = Math.floor(time / 60);
      const seconds = time % 60;

      this.countdown = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
      time--;

      if (time < 0) {
        clearInterval(this.timer!); // Type assertion to number
        this.showMfaInput = false;
        this.error = "MFA token verification timed out. Please start the login process again.";
      }
    }, 1000) as unknown as number; // Type assertion if necessary
  }


  beforeDestroy() {
    if (this.timer !== null) {
      clearInterval(this.timer);
    }
  }

  verifyMfaTokenOnEnter(event: KeyboardEvent): void {
    event.preventDefault(); // Prevent the default form submission
    this.verifyMfaToken();
  }
}
</script>

<style lang="sass" scoped>

.container
  max-width: max-container-width(24px, $container-width, 2)
  @include flex-container(24px, $container-width, 2)
  justify-content: center

.page-login
  padding: 30px 20px

.image-box
  align-items: center
  display: flex
  flex-direction: column
  margin-top: 30px
  margin-bottom: 10vh

.forgot
  @include Roboto600
  color: $color-secondary-state-blue-100

  &:hover
    text-decoration: underline
    cursor: pointer

.no-account
  @include Roboto400
  font-size: 14px
  letter-spacing: 0.2px
  color: $color-primary-hc-blue-100

.register-btn
  color: $color-secondary-state-blue-100
  @include Roboto600
  text-decoration: underline
  cursor: pointer

.privacy-policy-btn
  color: $color-secondary-state-blue-100
  @include Roboto600

.login-group
  align-items: center
  display: flex
  flex-direction: column
  justify-content: center

.submit
  margin-top: calc(10vh - 20px)
  width: $container-width

.go-to-register
  display: flex
  padding: 16px 0

  span
    margin-right: 10px

.privacy-policy
  display: flex
  padding: 16px 0

  span
    margin-right: 10px

.mfa-section
  margin-top: 20px
  display: flex
  flex-direction: column
  align-items: center

.timer
  margin-top: 10px
  font-size: 16px

.verify-mfa-btn
  margin-top: 20px

</style>
