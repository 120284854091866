import {
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import type {
  AdditionalData,
  CompanyInformation, Interests,
  RegistrationData,
  VerificationData,
} from "@/ts/types/store/onboarding-store-types";

@Module({
  name: 'onboarding'
})
export class OnboardingStore extends VuexModule {
  public checkToken: string | null = null;

  public motivation: string | null = null;

  public userRegistrationData: RegistrationData | null = null;

  public userVerificationData: VerificationData | null = null;

  public userAdditionalData: AdditionalData | null = null;

  public companyInformation: CompanyInformation | null = null;

  public interests: Interests = [];

  @Mutation
  setUserRegistrationData(data: RegistrationData | null): void {
    this.userRegistrationData = data;
  }

  @Mutation
  setUserVerificationData(data: VerificationData | null): void {
    this.userVerificationData = data;
  }

  @Mutation
  setAdditionalData(data: AdditionalData | null): void {
    this.userAdditionalData = data;
  }

  @Mutation
  setCompanyInformation(data: CompanyInformation | null): void {
    this.companyInformation = data;
  }

  @Mutation
  setMotivation(data: string | null): void {
    this.motivation = data;
  }

  @Mutation
  setCheckToken(token: string | null): void {
    this.checkToken = token;
  }

  @Mutation
  clearAll(): void {
    this.checkToken = null;
    this.motivation = null;
    this.userAdditionalData = null;
    this.userRegistrationData = null;
    this.userVerificationData = null;
    this.companyInformation = null;
    this.interests = [];
  }
}
