<template>
  <input-text
    id="company-code"
    :model-value="modelValue"
    minlength="5"
    maxlength="5"
    required
    placeholder="Enter your company code"
    @input="handleInput"
    @update:model-value="updateValue"
  />
</template>

<script lang="ts">
import {
  Component,
  Emit,
  Prop,
  Vue,
} from "vue-property-decorator";
import FieldSet from "@/vue/molecules/field-set.vue";
import InputText from "@/vue/atoms/input-text.vue";

@Component({
  name: 'EditCompanyCodeInput',
  components: {FieldSet, InputText}
})
export default class EditCompanyCodeInput extends Vue {
  @Prop() modelValue!: string;

  handleInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.toUpperCase();
  }

  @Emit('update:modelValue')
  updateValue(value: string): string {
    return value.toUpperCase();
  }
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>

</style>
