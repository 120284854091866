<template>
  <div>
    <empty-state :image="emptyStateImage" title="Account deleted" description="Thank you, your request was successful."/>
    <div class="button-container">
      <button-link class="button-link" :to="$pagesPath.main.home">
        Homepage
      </button-link>
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Vue,
} from "vue-property-decorator";
import NavbarPage from "@/vue/templates/navbar-page.vue";
import EmptyState from "@/vue/atoms/empty-state.vue";
import programOverviewEmptyState from "@/assets/img/program-overview-empty-state.svg";
import ButtonLink from "@/vue/atoms/button-link.vue";

@Component({
  name: "NotFoundPage",
  components: {
    ButtonLink,
    NavbarPage,
    EmptyState,
  },
})
export default class NotFoundPage extends Vue {
  get emptyStateImage(): string {
    return programOverviewEmptyState;
  }
}
</script>
<style lang="sass" scoped>
.button-container
  display: flex
  justify-content: center
  margin-top: 2rem
  margin-bottom: 2rem
</style>

