<template>
  <div class="select">
    <tabs-navigation :items="tabs"/>
  </div>
  <router-view/>
</template>

<script lang="ts" setup>
import TabsNavigation from "@/vue/atoms/tabs-navigation.vue";
import type {Tab} from "@/ts/types/component/tabs-navigation";
import {pagesPath} from "@/ts/router/pages-path";

const tabs: Tab[] = [
  {
    link: pagesPath.myHealthPath.myhealthCheckScore,
    title: "My healthcheck score",
  },
  {
    link: pagesPath.myHealthPath.myhealthCare360,
    title: "MYCARE360",
  },
  {
    link: pagesPath.myHealthPath.myhealthPastResults,
    title: "past results",
  },
];
</script>

<style lang="sass" scoped></style>
