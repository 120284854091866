<template>
  <div class="section-list">
    <p class="title">
      Rewards
    </p>
    <div class="sections">
      <challenge-reward position="1" :points="firstPlacePoints" text="1st Place"/>
      <challenge-reward position="2" :points="secondPlacePoints" text="2nd Place"/>
      <challenge-reward position="3" :points="thirdPlacePoints" text="3rd Place"/>
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Vue,
} from "vue-property-decorator";
import ChallengeReward from "@/vue/molecules/challenge/challenge-reward.vue";
import type {LeaderboardChallengeDTO} from "@/ts/types/dto/leaderboard-challenge.dto";
import {DefaultState} from "@/ts/store/default/default-store-instance";

@Component({
  name: 'ChallengeRewardsSection',
  components: {
    ChallengeReward
  }
})
export default class ChallengeRewardsSection extends Vue {
  @DefaultState
  leaderboardChallenge!: LeaderboardChallengeDTO;

  get thirdPlacePoints(): number {
    return this.leaderboardChallenge.pointsThirdPositionReward;
  }

  get secondPlacePoints(): number {
    return this.leaderboardChallenge.pointsSecondPositionReward;
  }

  get firstPlacePoints(): number {
    return this.leaderboardChallenge.pointsFirstPositionReward;
  }
}
</script>

<style lang="sass" scoped>
.title
  @include Asap700
  font-size: 24px
  color: $color-primary-hc-blue-100
  margin-top: 4px

.section-list
  display: flex
  flex-direction: column
  align-items: center
  background-color: $color-neutral-platinum-40
  padding: 1rem

.sections
  display: flex
  gap: 5rem
</style>
