<template>
  <div class="wrapper">
    <h2 :class="`day ${displayBreaks ? 'day-centered' : ''}`">
      {{ selectedDayStr }}
    </h2>
    <welcome-box v-if="!changedDate" class="welcome"/>
    <div v-if="displayBreaks" class="activity-empty">
      <img src="@/assets/img/home-page-lazy.svg"/>
      <p>We all need a break sometimes, and that's OK!</p>
    </div>
    <loading-suspense
      :loading="loading"
      :error="error"
      class="activity-list"
    >
      <div class="todo-list-container">
        <todo-item
          v-for="todo in todos"
          :key="todo.id"
          :todo="todo"
          :day="day"
          @delete="deleteTodo(todo)"
          @skip="skipTodo(todo.id)"
          @change-item="fetchTodos"
          @proof-sent="fetchTodos"
        />
        <todo-item
          v-for="historyTodo in completedTodos"
          :key="historyTodo.id"
          :todo="historyTodo"
          :day="day"
        />
      </div>
    </loading-suspense>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Watch} from "vue-property-decorator";
import TodoItem from "@/vue/molecules/todo-item.vue";
import type {TodoItemDto} from "@/ts/types/dto/todo.dto";
import {TodoItemStatus} from "@/ts/types/dto/todo.dto";
import {
  convertDateToServerFormat,
  getUserStrDateWeekMonthDay,
  isSameDay,
  today,
} from "@/ts/utils/date-pure-functions";
import ErrorText from "@/vue/atoms/error-text.vue";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import WelcomeBox from "@/vue/organisms/welcome-box.vue";
import {mixins} from "vue-class-component";
import {DefaultGrowlError, LoadingMixin} from "@/ts/mixins/loading-mixin";
import {ContentReferenceType} from "@/ts/types/dto/content-reference.dto";
import {DefaultStoreMixin} from "@/ts/store/default/default-store-instance";
import {GrowlsStoreMixin} from "@/ts/store/growl/growl-store-instance";

@Component({
  name: 'TodoList',
  components: {
    TodoItem,
    WelcomeBox,
    LoadingSuspense,
    ErrorText,
  },
})

export default class TodoList extends mixins(LoadingMixin, DefaultStoreMixin, GrowlsStoreMixin) {
  @Prop()
  day!: Date;

  todos: TodoItemDto[] = [];

  completedTodos: TodoItemDto[] = [];

  changedDate: boolean = false;

  get selectedDayStr(): string {
    if (this.isToday) {
      return "Today";
    }
    return getUserStrDateWeekMonthDay(this.day);
  }

  get displayBreaks(): boolean {
    return this.changedDate && !this.loading && !this.error && !this.todos.length && !this.completedTodos.length;
  }

  get isToday(): boolean {
    return isSameDay(this.day, today());
  }

  @Watch('day')
  onCurrentDayChange(): void {
    this.changedDate = true;
  }

  @DefaultGrowlError
  async deleteTodo(item: TodoItemDto): Promise<void> {
    const isActivity = (item.contentReference.type === ContentReferenceType.Activity);
    const isPlatformActivity = (item.contentReference.type === ContentReferenceType.PlatformActivity);

    if (isActivity || isPlatformActivity) {
      if (isActivity) {
        await this.$api.deleteActivity(item.id);
      }
      if (isPlatformActivity) {
        await this.$api.deletePlatformActivity(item.id);
      }
      this.todos.splice(this.todos.indexOf(item), 1);
      void this.growlsStore.growlInfo(`Todo ${item.name} has been deleted`);
    } else {
      throw Error("Unsupported");
    }
  }

  skipTodo(id: string): void {
    const localStorageId = this.createSkipTodoLocalStorageId(id);
    this.defaultStore.skipTodo(localStorageId);
    this.todos = this.todos.filter((ac) => this.createSkipTodoLocalStorageId(ac.id) !== localStorageId);
    this.growlsStore.growlInfo("Your To-Do has been skipped successfully.");
  }

  async created(): Promise<void> {
    await this.fetchTodos();
  }

  @Watch('day')
  async onDayChange(): Promise<void> {
    await this.fetchTodos();
  }

  @DefaultGrowlError
  async fetchTodos(): Promise<void> {
    const dayStr = convertDateToServerFormat(this.day);
    const todoItems = await this.$api.getTodo({
      startDate: dayStr,
      endDate: dayStr,
    });

    const skippedTodos = [...this.defaultStore.skippedTodos?.skippedTodosIds ?? []];
    const notSkippedTodoItems = todoItems
      .filter((td) => !skippedTodos.includes(this.createSkipTodoLocalStorageId(td.id)));

    this.todos = [];
    this.completedTodos = [];

    notSkippedTodoItems.forEach((item) => {
      if (item.status === TodoItemStatus.completed) {
        this.completedTodos.push(item);
      } else if (isSameDay(item.date, today())) {
        // do not display uncompleted items on days in the past
        this.todos.push(item);
      }
    });
  }

  createSkipTodoLocalStorageId(todoId: string): string {
    // be aware: if you changed this id-syntax, you should migrate the local-storage of your users.
    return `${todoId}___${convertDateToServerFormat(this.day)}`;
  }
}

</script>

<style lang="sass" scoped>
$max-width: 684px

.activity-empty
  align-content: center
  align-items: center
  color: $color-primary-hc-blue-100
  display: flex
  flex-direction: column
  @include Asap700
  font-size: 28px
  font-style: normal
  justify-content: center
  line-height: 36px

.activity-list
  align-content: stretch
  display: flex
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25))
  flex-direction: column
  flex-wrap: nowrap
  justify-content: center
  width: $max-width

.welcome
  margin-top: 50px

.wrapper
  display: flex
  flex-direction: column
  align-items: center
  max-width: $max-width
  margin: auto

.todo-list-container
  width: 100%
  overflow: auto
  margin-bottom: 100px

.day
  color: $color-primary-hc-blue-100
  @include Asap700
  font-size: 32px
  text-align: left
  margin-bottom: 20px
  margin-top: 70px

  &.day-centered
    text-align: center

h2
  width: $max-width
</style>
