import type {TaskType, Task} from "@/ts/types/dto/activity.dto";
import type {Pagination} from "@/ts/types/dto/pagination.dto";

export enum GoalSubmissionFrequency {
    once = "once",
    week_7_times = "week_7_times",
    week_3_times = "week_3_times",
    week_2_times = "week_2_times",
}

export interface Goal {
    id: string;
    createDate: string; // Assuming RFC 3339 formatted date-time string
    endDate: string; // Assuming RFC 3339 formatted date-time string
    task: Task;
    submissionFrequency: GoalSubmissionFrequency;
    status: GoalStatus;
    progress: number;
}

export interface GetGoalsResponseType {
    pagination: Pagination;
    items: Goal[];
}

export interface GoalCreateType {
    endTimestamp: string;
    submissionFrequency: GoalSubmissionFrequency;
    type: TaskType;
    goal: number;
    status: GoalStatus;
}

  // Assuming the Goal interface defined above matches the response for creating a goal
export type CreateGoalResponse = Goal;

export enum GoalStatus {
    active = "active",
    completed = "completed",
}
