<template>
  <section class="row-section">
    <div class="header">
      <headline-with-counter :title="title" :amount="amount"/>
      <button v-if="showFilter" class="icon" @click="openFilter">
        <icon-chameleon
          color="primary50"
          hover-color="primary100"
          :icon="SvgFilter"
        />
      </button>
      <slot name="extraButton"/>
      <slot name="header"/>
    </div>
    <div class="content">
      <slot/>
    </div>
  </section>
</template>

<script lang="ts" setup>
import HeadlineWithCounter from "@/vue/atoms/headline-with-counter.vue";
import SvgFilter from "@/assets/img/filter.svg";
import IconChameleon from "@/vue/atoms/icon-chameleon.vue";
import {ref} from "vue";

const props = defineProps<{
  title: string;
  amount: number;
  showFilter?: boolean;
}>();

const emit = defineEmits(["openFilter", "expandDropdown"]);

const openFilter = () => {
  emit("openFilter");
};

const showPanel = ref<boolean>(false);
const togglePanel = () => {
      showPanel.value = !showPanel.value;
      emit("expandDropdown", showPanel);
};

</script>
<!-- eslint-disable -->
<style lang="sass" scoped>
.row-section
  display: flex
  flex-direction: column
  margin-left: 5rem
  margin-top: 16px
  margin-bottom: 24px

.header
  display: flex
  gap: 16px
  align-items: center
  @include Asap700
  font-size: 20px

.icon
  margin-bottom: 4px
  cursor: pointer
  border: none
  background: none
  /* stylelint-disable */
.content
  display: flex
  flex-wrap: wrap
  gap: 4px
</style>
