<template>
  <profile-field
    name="Height"
    :content="profile.height"
    :error="error"
    :loading="loading"
    :show-success="showSuccess"
    :is-enabled="true"
    @save="save"
  >
    <edit-height v-model="height"/>
  </profile-field>
</template>

<script lang="ts">
import {Component} from "vue-property-decorator";
import ProfileField from "@/vue/organisms/profile/profile-field.vue";
import type {Profile} from "@/ts/types/store/default-store-types";
import type {PutProfileRequestDTO} from "@/ts/types/dto/profile.dto";
import {profileToPutProfileRequest} from "@/ts/utils/pure-functions";
import EditHeight from "@/vue/molecules/edit-field/edit-height.vue";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import {mixins} from "vue-class-component";
import {DefaultStoreMixin, DefaultState} from "@/ts/store/default/default-store-instance";

/* TODO rename other components by this type*/
@Component({
  name: 'ProfileFieldHeight',
  components: {EditHeight, ProfileField}
})
export default class ProfileFieldHeight extends mixins(LoadingMixin, DefaultStoreMixin) {
  @DefaultState
  profile!: Profile;

  showSuccess: boolean = false;

  // this is required to populate vue3 proxy
  height: number = 0;

  created(): void {
    this.height = this.profile.height;
  }

  @DefaultGrowlError
  async save(): Promise<void> {
    this.showSuccess = false;
    const value: PutProfileRequestDTO = {
      ...profileToPutProfileRequest(this.profile),
      height: this.height,
    };
    const getProfileResponseDTO = await this.$api.updateProfile(value);
    const profileData: Profile = {
      ...getProfileResponseDTO,
      dateOfBirth: this.profile.dateOfBirth,
      location: this.profile.location,
      department: this.profile.department,
    };
    this.defaultStore.setProfile(profileData);
    this.showSuccess = true;
  }
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>

</style>
