<template>
  <input-date
    id="birthday"
    :model-value="modelValue"
    required
    autocomplete="bday"
    :min="'1900-01-01'"
    :max="maxDate"
    @update:model-value="updateValue"
  />
</template>

<script lang="ts" setup>
import {ref} from "vue";
import {convertDateToServerFormat, today} from "@/ts/utils/date-pure-functions";
import InputDate from "@/vue/atoms/input-date.vue";

const props = defineProps<{
  modelValue: Date | null;
}>();

const emit = defineEmits(["update:modelValue"]);

const maxDate = ref<string>(convertDateToServerFormat(today()));

const updateValue = (value: Date): void => {
  emit("update:modelValue", value);
};
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>

</style>
