<template>
  <div class="page-email-confirmation">
    <div class="image-box">
      <img src="@/assets/img/my-health-check360-logo.svg" alt="My Health Check 360 Logo"/>
    </div>
    <loading-suspense :loading="loading">
      <div class="message-wrapper">
        <div v-if="is400">
          <h3 class="headline">
            Your email-address could not be confirmed.
          </h3>
          <p>It is likely your link has expired. If you would like to resend the confirmation email, you can login to your myHC360+ account, navigate to your profile (upper right hand corner), select ‘Edit Profile’, scroll to email address item at the bottom. Click the email address field and confirm and resend your registration link.</p>
        </div>
        <div v-else-if="is409">
          <h3 class="headline">
            Email has already been confirmed.
          </h3>
        </div>
        <div v-else-if="error">
          <h3 class="headline">
            Your email-address could not be confirmed.
          </h3>
          <p>It is likely your link has expired. If you would like to resend the confirmation email, you can login to your myHC360+ account, navigate to your profile (upper right hand corner), select ‘Edit Profile’, scroll to email address item at the bottom. Click the email address field and confirm and resend your registration link.</p>
        </div>
        <div v-else>
          <h3 class="headline">
            Your email address has been successfully confirmed.
          </h3>
        </div>
      </div>
    </loading-suspense>
  </div>
</template>

<script lang="ts">
import {Component} from "vue-property-decorator";
import HcLogo from "@/vue/molecules/hc-logo.vue";
import {mixins} from "vue-class-component";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import {DefaultStoreMixin} from "@/ts/store/default/default-store-instance";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import ButtonLink from "@/vue/atoms/button-link.vue";

@Component({
  name: "LoginPEmailConfirmationPage",
  components: {
    ButtonLink,
    HcLogo,
    LoadingSuspense,
  },
})
export default class EmailConfirmationPage extends mixins(LoadingMixin, DefaultStoreMixin) {
  get is400(): boolean {
    return Boolean(this.error?.includes("400"));
  }

  get is409(): boolean {
    return Boolean(this.error?.includes("409"));
  }

  @DefaultGrowlError
  async created(): Promise<void> {
    const {token} = this.$route.query;
    await this.$api.confirmMail(token as string);
  }
}
</script>

<style lang="sass" scoped>

.page-email-confirmation
  max-width: 800px
  margin: 0 auto
  padding: 30px 20px

.image-box
  align-items: center
  display: flex
  flex-direction: column
  margin-top: 70px
  margin-bottom: 100px

.message-wrapper
  align-items: center
  display: flex
  flex-direction: column

.headline
  @include Asap700
  color: $color-primary-hc-blue-100
  font-size: 28px
  line-height: 36px
  margin-bottom: 33px
  text-align: center

</style>
