<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="html-rich-text" v-html="sanitizedText"/>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";
import DOMPurify from "dompurify";

@Component({
  name: "HtmlRichText",
})
export default class HtmlRichText extends Vue {
  @Prop()
  text!: string;

  get sanitizedText(): string {
    return DOMPurify.sanitize(this.text, {
      ALLOWED_TAGS: [
        "a",
        "b",
        "i",
        "em",
        "strong",
        "span",
        "p",
        "br",
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "ul",
        "ol",
        "li",
        "s",
        "img",
      ],
      ALLOWED_ATTR: ["href", "src", "alt", "title"],
    });
  }
}
</script>
