<template>
  <div class="tooltip-container">
    <img class="tooltip-trigger" :src="infoIcon"/>
    <div ref="tooltip" class="tooltip">
      <div class="icon">
        <img :src="infoIcon"/>
      </div>
      <span>{{ text }}</span>
      <img class="angle" :src="angleImg"/>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import infoIcon from "@/assets/img/icons/my-health/info.svg";
import angleImg from "@/assets/img/angle.svg";

@Component({
  name: "Tooltip"
})
export default class Tooltip extends Vue {
  @Prop()
  text!: string;

  get infoIcon(): string {
    return infoIcon;
  }

  get angleImg(): string {
    return angleImg;
  }
}

</script>

<style lang="sass" scoped>
.tooltip-container
  position: relative

.tooltip-container:hover .tooltip
  visibility: visible

.tooltip
  position: absolute
  width: 277px
  background-color: $color-white
  bottom: 53px
  right: 0
  padding: 8px 16px
  border-radius: 16px

  @include Roboto400
  font-size: 14px
  line-height: 22px
  letter-spacing: 0.2px
  color: $color-primary-hc-blue-100

  display: flex
  visibility: hidden

  gap: 8px

  span
    z-index: 1


.angle
  position: absolute
  top: 44px
  transform: scaleX(-1)
  right: 0

</style>
