<template>
  <field-set id="zip-code" label="Zip">
    <input-text
      id="zip-code"
      :model-value="modelValue"
      autocomplete="postal-code"
      placeholder="Zip Code"
      required
      @update:model-value="updateValue"
    />
  </field-set>
</template>

<script lang="ts">
import {
  Component,
  Emit,
  Prop,
  Vue,
} from "vue-property-decorator";
import FieldSet from "@/vue/molecules/field-set.vue";
import InputText from "@/vue/atoms/input-text.vue";

@Component({
  components: {FieldSet, InputText}
})
export default class EditPostcode extends Vue {
  @Prop() modelValue!: number;

  @Emit('update:modelValue')
  updateValue(value: number): number {
    return value;
  }
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>

</style>
