<template>
  <input-select
    id="sex"
    :model-value="modelValue"
    autocomplete="sex"
    :options="sexes"
    @update:model-value="updateValue"
  />
  <p>We will use this information to provide clinical feedback based on your biological sex.</p>
</template>

<script lang="ts">
import {
  Component,
  Emit,
  Prop,
  Vue,
} from "vue-property-decorator";
import type {InputSelectOption} from "@/ts/types/component/input.select";
import InputSelect from "@/vue/atoms/input-select.vue";
import {SexType} from "@/ts/types/dto/auth.dto";

@Component({
  name: "EditSexSelect",
  components: {InputSelect}
})
export default class EditSexSelect extends Vue {
  @Prop() modelValue!: string;

  get sexes(): InputSelectOption[] {
    return [
      {
        label: "Male",
        value: SexType.MALE,
      },
      {
        label: "Female",
        value: SexType.FEMALE,
      },
    ];
  }

  @Emit('update:modelValue')
  updateValue(value: string): string {
    return value;
  }
}
</script>

<style lang="sass" scoped>
p
  color: $color-primary-hc-blue-100
  @include Roboto400
  font-size: 14px
</style>
