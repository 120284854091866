<template>
  <div class="calendar-list">
    <img
      src="@/assets/img/left-arrow-cal.svg"
      alt="left"
      class="navigation"
      @click="backwards"
    />
    <div v-for="day in days" :key="day.toDateString()" class="calendar-list-items">
      <calendar-day :date="day" :is-active="isActive(day)" @click="onModelValueClick(day)"/>
    </div>
    <img
      src="@/assets/img/right-arrow-cal.svg"
      alt="right"
      class="navigation"
      @click="forward"
    />
  </div>
</template>

<script lang="ts">

import {
  Component,
  Emit,
  Prop,
  Vue,
} from "vue-property-decorator";
import CalendarDay from "@/vue/molecules/calendar-day.vue";
import {
  addToDate,
  convertDateToServerFormat, isDayInFuture,
  isSameDay,
  subtractFromDate,
  today,
} from "@/ts/utils/date-pure-functions";

@Component({
  name: "CalendarList",
  components: {
    CalendarDay,
  }
})
export default class CalendarList extends Vue {
  @Prop()
  modelValue!: Date;

  @Prop({default: 13})
  length!: number;

  @Prop({default: 7})
  scrollFor!: number;

  endDay: Date = today();

  get days(): Date[] {
    const result: Date[] = [];
    for (let daysIndent = this.length; daysIndent >= 0; daysIndent--) {
      result.push(subtractFromDate(this.endDay, {days: daysIndent}));
    }
    return result;
  }

  onModelValueClick(day: Date): void {
    if (convertDateToServerFormat(this.modelValue) !== convertDateToServerFormat(day)) {
      this.updateModelValue(day);
    }
  }

  @Emit("update:modelValue")
  updateModelValue(day: Date): Date {
    return day;
  }

  isActive(day: Date): boolean {
    return isSameDay(day, this.modelValue);
  }

  forward(): void {
    const endDay = addToDate(this.endDay, {days: this.scrollFor});
    if (isDayInFuture(endDay)) {
      this.endDay = today();
    } else {
      this.endDay = endDay;
    }
  }

  backwards(): void {
    this.endDay = subtractFromDate(this.endDay, {days: this.scrollFor});
  }
}
</script>

<style lang="sass" scoped>
.navigation
  cursor: pointer

.calendar-list
  display: flex
  flex-direction: row
  justify-content: center
  @include Roboto600

.calendar-list-items
  align-items: center
  display: flex
  flex-direction: column
  justify-content: center
  margin: 0 1rem
</style>
