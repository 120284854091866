<template>
  <select
    :id="id"
    :value="modelValue"
    :name="name"
    :required="required"
    :autocomplete="autocomplete"
    @change="updateValue"
  >
    <option v-for="option in options" :key="option.id" :value="option.id">
      {{ option.label }}
    </option>
  </select>
</template>

<script lang="ts">
import {
  Component,
  Emit,
  Prop,
  Vue,
} from "vue-property-decorator";
import type {InputDropDownObject} from "@/ts/types/component/input-drop-down-object";

@Component({
  name: "InputObjectDropDown",
})
export default class InputObjectDropDown extends Vue {
  @Prop()
  public readonly modelValue!: number | string;

  @Prop() options!: InputDropDownObject[];

  @Prop() name!: string;

  @Prop({default: undefined}) id!: string | undefined;

  @Prop({default: false}) required!: boolean;

  @Prop({default: ""}) autocomplete!: string;

  @Emit('update:modelValue')
  updateValue(event: Event): number | string {
    const {value} = (event.target as HTMLInputElement)!;
    // find the origin property, casting ignoring type, since target.value casts to string
    return this.options.find((el) => el.id as string == value)!.id; // eslint-disable-line eqeqeq
  }
}
</script>

<style lang="sass" scoped>
select
  @include input-field

</style>
