<template>
  <navbar-page title="My Community" :profile-section="true" :reward-points="true">
    <community-page-select/>
  </navbar-page>
</template>

<script lang="ts">
import {Component} from "vue-property-decorator";
import CommunityPageSelect from "@/vue/molecules/community-page-select.vue";
import NavbarPage from "@/vue/templates/navbar-page.vue";

import {mixins} from "vue-class-component";
import {mixinClassBodyFactory} from "@/ts/mixins/bg-mixin";

@Component({
  name: "MyCommunityPage",
  components: {
    CommunityPageSelect,
    NavbarPage,
  },
})
export default class MyProgramPage extends mixins(mixinClassBodyFactory("global-onboarding-bg-color")) {
}
</script>
