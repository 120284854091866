<!-- This was based on VDate component by AustinGil, structures and implementations has been changed to better fit the project needs
https://github.com/AustinGil/vuetensils/blob/production/src/components/VDate/VDate.vue
-->
<template>
  <div id="datepicker" class="date">
    <div
      ref="calendar"
      role="dialog"
      aria-modal="true"
      aria-labelledby="datepicker-dialog-label"
      class="wrapper"
    >
      <div class="navigation">
        <div class="month-year">
          <h4 id="datepicker-dialog-label" aria-live="polite">
            {{ monthYear }}
          </h4>
          <h4 id="datepicker-dialog-label" aria-live="polite">
            {{ year }}
          </h4>
        </div>
        <div class="buttons">
          <button
            class="button"
            :aria-label="buttonLabels.previousMonth"
            type="button"
            @click="incrementMonthBy(-1)"
          >
            <div name="prevMonthLabel">
              <img
                src="@/assets/img/left-arrow-cal.svg"
                alt="prev"
                class="arrows"
              />
            </div>
          </button>
          <button
            class="button"
            :aria-label="buttonLabels.nextMonth"
            type="button"
            @click="incrementMonthBy(1)"
          >
            <div name="nextMonthLabel">
              <img
                src="@/assets/img/right-arrow-cal.svg"
                alt="prev"
                class="arrows"
              />
            </div>
          </button>
        </div>
      </div>
      <div
        class="calendar"
        role="grid"
        aria-labelledby="datepicker-dialog-label"
      >
        <div>
          <div class="week">
            <div
              v-for="(val, key) in daysOfWeek"
              :key="key"
              :abbr="val"
              scope="col"
              class="th"
            >
              {{ key.toString().charAt(0) }}
            </div>
          </div>
        </div>
        <div>
          <div v-for="week in 6" :key="week" class="row">
            <div
              v-for="day in daysByWeeks[week - 1]"
              :key="day.date.toString()"
            >
              <button
                :class="[
                  'day',
                  {'day--focused': day.isFocused},
                  {'day--selected': day.isSelected},
                  {'day--different-month': day.date.getMonth() !== focusedDate.getMonth()},
                  {available: day.available && !day.isSelected},
                ]"
                tabindex="0"
                :aria-selected="day.isFocused"
                :value="day.date.toString()"
                :disabled="day.disabled"
                type="button"
                @click="onClick(day)"
              >
                {{ day.date.getDate() }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
import {
  applyFocusTrap,
  daysOfWeek,
  monthLabels,
  buttonLabels,
  incrementMonthBy,
  daysByWeeks,
} from "@/ts/utils/datepicker-helpers";
import type {Weeks} from "@/ts/types/component/datepicker";
import {today} from "@/ts/utils/date-pure-functions";

@Component({
  name: "DatepickerCalendar",
  components: {},
})
export default class DatepickerCalendar extends Vue {
  @Prop({default: today()}) modelValue!: Date;

  // min date for selection. Min and Max define a range of dates
  @Prop({default: ""}) min!: Date | string;

  // max date for selection. Min and Max define a range of dates
  @Prop({default: ""}) max!: Date | string;

  // Array of available dates returned from API
  @Prop() availableDates!: string[];

  focusedDate: Date = today();

  get daysOfWeek(): any {
    return daysOfWeek;
  }

  get buttonLabels(): any {
    return buttonLabels;
  }

  get monthYear(): string {
    return `${monthLabels[this.focusedDate!.getMonth()]}`;
  }

  get year(): number {
    return this.focusedDate!.getFullYear();
  }

  get daysByWeeks(): Weeks[][] {
    return daysByWeeks(
      this.focusedDate!,
      this.modelValue!,
      this.min,
      this.max,
      this.availableDates,
    );
  }

  created(): void {
    this.focusedDate = this.modelValue;
  }

  incrementMonthBy(inc: number): void {
    this.focusedDate = incrementMonthBy(inc, this.focusedDate!);
  }

  @Emit("update:modelValue")
  updateValue(date: Date): Date {
    return date;
  }

  onClick(day: Weeks): void {
    if (day.available) {
      this.updateValue(day.date);
      if (this.focusedDate.getMonth() < day.date.getMonth()) {
        this.incrementMonthBy(1);
      }
      if (this.focusedDate.getMonth() > day.date.getMonth()) {
        this.incrementMonthBy(-1);
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.wrapper
  width: 400px

.navigation
  display: flex
  justify-content: space-between
  align-items: center
  padding: 0 12px 24px
  @include Roboto700
  font-size: 20px
  color: $color-primary-hc-blue-100

.month-year
  display: flex
  gap: 10px

.arrows
  width: 7px

.row
  display: flex
  justify-content: space-between
  margin: 8px 0

.buttons
  display: flex
  gap: 12px

.button
  border: none
  background-color: transparent
  padding: 8px

.day
  border: none
  text-decoration: none
  background-color: transparent
  display: flex
  align-items: center
  text-align: center
  justify-content: center
  border-radius: 10px
  height: 35px
  padding: 5px
  width: 35px
  box-sizing: border-box
  position: relative
  color: $color-primary-hc-blue-100
  @include Roboto400
  font-size: 14px
  cursor: not-allowed

  &.available
    background-color: $color-primary-hc-blue-10
    border-radius: 10px
    cursor: pointer !important

  &--selected
    background-color: $color-secondary-state-blue-100
    border-radius: 10px
    color: $color-white

  &--different-month
    color: $color-primary-hc-blue-50

  &:hover
    border: 1.5px solid $color-secondary-state-blue-100
    border-radius: 10px

.week
  display: flex
  justify-content: space-between
  padding: 8px 12px
  @include Roboto400
  font-size: 12px
  color: $color-primary-hc-blue-50
  margin: 0 0 12px

h4
  margin: 0
</style>
