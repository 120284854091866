<template>
  <div v-if="participants.length" class="wrap">
    <p class="title" @click="isOpen = true">
      {{ participants.length }} Participants Joined
    </p>
    <div class="friends-list" @click="isOpen = true">
      <user-avatar
        v-for="friend in participantsList"
        :key="friend.id"
        :avatar="friend.avatarPath"
        :is-me="friend.id === profile.id"
        :firstname="friend.firstName"
        :lastname="friend.lastName"
        size="mid"
        :user-status="friend.status"
      />

      <div v-if="participants.length > listLength">
        <avatar-frame
          image=""
          :letters="`+${participants.length - listLength}`"
          letters-theme="orange"
          :size="48"
        />
      </div>
    </div>
    <challenge-participants-modal
      v-model="isOpen"
      :friends="leaderboardChallenge.joinedFriends"
      :participants="participants"
    />
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import ChallengeParticipantsModal from "@/vue/molecules/challenge/challenge-participants-modal.vue";
import {mixins} from "vue-class-component";
import {LoadingMixin} from "@/ts/mixins/loading-mixin";
import UserAvatar from "@/vue/atoms/user-avatar.vue";
import type {Profile} from "@/ts/types/store/default-store-types";
import {DefaultState} from "@/ts/store/default/default-store-instance";
import type {ChallengeParticipant, LeaderboardChallengeDTO} from "@/ts/types/dto/leaderboard-challenge.dto";
import AvatarFrame from "@/vue/atoms/avatar-frame.vue";

@Component({
  name: "ChallengePaticipants",
  components: {
    AvatarFrame,
    ChallengeParticipantsModal,
    UserAvatar,
  },
})
export default class ChallengePaticipants extends mixins(LoadingMixin) {
  @DefaultState
  leaderboardChallenge!: LeaderboardChallengeDTO;

  @Prop()
  participants!: ChallengeParticipant[];

  @DefaultState
  public profile!: Profile;

  isOpen: boolean = false;

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  listLength = 11;


  get participantsList(): ChallengeParticipant[] {
    // if your has joined display at first index position
    if (this.leaderboardChallenge.hasUserJoined) {
      const userIndex = this.participants.findIndex(
        (you) => you.id === this.profile.id,
      );
      const [user] = this.participants.splice(userIndex, 1);

      this.participants.splice(0, 0, user);
    }

    return this.participants.length > this.listLength
      ? this.participants.slice(0, this.listLength)
      : this.participants;
  }
}
</script>

<style lang="sass" scoped>
.wrap
  max-width: 686px
  margin: 0 auto 2rem
  padding: 1rem

.friends-list
  cursor: pointer
  display: flex
  align-items: center
  gap: 10px

.title
  color: $color-primary-hc-blue-100
  @include Asap700

</style>
