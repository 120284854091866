import {getModule} from "vuex-module-decorators";
import {stateDecoratorFactory} from "@/ts/utils/decorators";
import {vueStore} from "@/ts/store/vue-store";
import {Vue} from "vue-property-decorator";
import {SchedulingCoachingStore} from "@/ts/store/scheduling-coaching/scheduling-coaching-store";

export const schedulingCoachingStore: SchedulingCoachingStore = getModule(SchedulingCoachingStore, vueStore);

export const SchedulingCoachingState = stateDecoratorFactory(schedulingCoachingStore);

export class SchedulingCoachingStoreMixin extends Vue {
  schedulingCoachingStore!: SchedulingCoachingStore;

  created(): void {
    this.schedulingCoachingStore = schedulingCoachingStore;
  }
}
