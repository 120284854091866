<template>
  <div class="drop-down">
    <div class="dropdown-stub"/>
    <div v-show="!isOpen" class="dropped-down-closed">
      <img src="@/assets/img/bars.svg" class="bars" @click="revert"/>
    </div>
    <div v-show="isOpen" class="dropped-down-opened">
      <img src="@/assets/img/x-mark.svg" class="bars bars-close" @click="revert"/>
      <div class="links">
        <drop-down-item
          title="My To-Do's"
          :link="$pagesPath.main.todo"
          :image="todoSvg"
        />
        <drop-down-item
          title="My Program"
          :link="$pagesPath.program.programOverview"
          :image="myProgramSvg"
        />
        <drop-down-item
          title="My Community"
          :link="$pagesPath.community.communityFeed"
          :image="unionSvg"
        />
        <drop-down-item
          title="My Health"
          :link="$pagesPath.myHealthPath.myhealth"
          :image="myHealthSvg"
        />
        <drop-down-item
          title="My Rewards"
          :link="$pagesPath.main.rewards"
          :image="myRewardsSvg"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Vue,
} from "vue-property-decorator";
import DropDownItem from "@/vue/molecules/drop-down-item.vue";
import myProgramSvg from "@/assets/img/my-program.svg";
import todoSvg from "@/assets/img/to-do.svg";
import unionSvg from "@/assets/img/union.svg";
import myPathSvg from "@/assets/img/my-path.svg";
import myHealthSvg from "@/assets/img/my-health.svg";
import myRewardsSvg from "@/assets/img/my-rewards.svg";

@Component({
  name: "DropDownMenu",
  components: {DropDownItem},
})
export default class DropDownMenu extends Vue {
  isOpen = false;

  get myProgramSvg(): string {
    return myProgramSvg;
  }

  get todoSvg(): string {
    return todoSvg;
  }

  get unionSvg(): string {
    return unionSvg;
  }

  get myPathSvg(): string {
    return myPathSvg;
  }

  get myHealthSvg(): string {
    return myHealthSvg;
  }

  get myRewardsSvg(): string {
    return myRewardsSvg;
  }

  revert(): void {
    this.isOpen = !this.isOpen;
  }
}
</script>

<style lang="sass" scoped>
.dropdown-stub
  height: 169px

.bars
  margin-top: 45px
  margin-left: 45px
  cursor: pointer

.bars-close
  margin-top: 51px
  margin-left: 51px

%dropped-down
  background-size: cover
  position: absolute
  left: 0
  top: 0

.dropped-down-closed
  @extend %dropped-down
  background-image: url("@/assets/img/drop-shape.svg")
  width: 189px
  height: 169px

.dropped-down-opened
  @extend %dropped-down
  background-image: url("@/assets/img/menu-extend.svg")
  height: 520px
  width: 342px
  z-index: 1

.links
  padding-top: 40px
  padding-left: 48px

.links > *
  display: flex
</style>
