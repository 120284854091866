import { Vue } from 'vue-property-decorator';
import { ApplyGrowlErr } from '@/ts/utils/decorators';

export class LoadingMixin extends Vue {
  loading: boolean = false;
  error: string | null = null;

  async withLoading<T>(asyncFunction: () => Promise<T>): Promise<T> {
    this.loading = true;
    this.error = null;
    try {
      return await asyncFunction();
    } catch (error: any) {
      this.error = error.message || 'An error occurred';
      throw error;
    } finally {
      this.loading = false;
    }
  }
}

// eslint-disable-next-line babel/new-cap
export const DefaultGrowlError = ApplyGrowlErr({
  loadingProperty: 'loading',
  errorProperty: 'error',
});
