<template>
  <popup-modal :model-value="modelValue" @update:model-value="close">
    <div class="wrap">
      <h2>Confirmation</h2>
      <div class="redeeming">
        You are redeeming <b>{{ redeemAmountFormatted }}</b>
      </div>
      <div class="button-holder">
        <button-primary theme="white" text="Cancel" @click="close"/>
        <button-primary
          theme="dark"
          :loading="loading"
          data-cy="confirm-button"
          text="Confirm"
          @click="redeemCash"
        />
        <error-text :error="error"/>
      </div>
    </div>
  </popup-modal>
</template>
<script lang="ts">
import {
  Component,
  Emit,
  Prop,
} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import PopupModal from "@/vue/atoms/popup-modal.vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import ErrorText from "@/vue/atoms/error-text.vue";
import {GrowlsStoreMixin} from "@/ts/store/growl/growl-store-instance";
import {formatCash} from "@/ts/utils/pure-functions";

@Component({
  components: {
    ErrorText,
    ButtonPrimary,
    PopupModal
  },
})
export default class RewardsRedeemModal extends mixins(LoadingMixin, GrowlsStoreMixin) {
  @Prop() modelValue!: boolean;

  @Prop() redeemAmount!: number;

  get redeemAmountFormatted(): string {
    return formatCash(this.redeemAmount);
  }

  @DefaultGrowlError
  async redeemCash(): Promise<void> {
    // redeemedDate is returned only if it's redeemed right now
    const {redeemedDate} = await this.$api.redeemCash();
    if (redeemedDate) {
      void this.growlsStore.growlInfo(`Redeemed ${this.redeemAmount} cash`);
      this.onRedeemCash(redeemedDate);
    } else {
      void this.growlsStore.growlError("You don't have any cash to redeem atm");
    }
    this.close();
  }

  @Emit("update:modelValue")
  close(): boolean {
    return false;
  }

  @Emit("redeemCash")
  onRedeemCash(date: string): string {
    return date;
  }
}
</script>

<style lang="sass" scoped>

.button-holder
  display: flex
  gap: 24px

  > *
    width: 130px

.wrap
  display: flex
  flex-direction: column

h2
  margin: 0
  margin-bottom: 18px
  @include Asap700
  color: $color-primary-hc-blue-100

.redeeming
  margin-bottom: 48px
  font-size: 22px
  color: $color-primary-hc-blue-100
  @include Roboto400

  b
    @include Roboto700
    color: $color-secondary-state-blue-100
</style>
