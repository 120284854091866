<template>
  <profile-field
    name="Name"
    :content="`${profile.firstName} ${profile.lastName}`"
    :error="error"
    :loading="loading"
    :show-success="showSuccess"
    :is-enabled="true"
    @save="save"
  >
    <edit-first-name v-model="firstName" class="edit-first-name"/>
    <edit-last-name v-model="lastName" class="edit-last-name"/>
  </profile-field>
</template>

<script lang="ts">
import {Component} from "vue-property-decorator";
import ProfileField from "@/vue/organisms/profile/profile-field.vue";
import {DefaultState, DefaultStoreMixin} from "@/ts/store/default/default-store-instance";
import type {Profile} from "@/ts/types/store/default-store-types";
import EditFirstName from "@/vue/molecules/edit-field/edit-first-name.vue";
import EditLastName from "@/vue/molecules/edit-field/edit-last-name.vue";
import type {PutProfileRequestDTO} from "@/ts/types/dto/profile.dto";
import {profileToPutProfileRequest} from "@/ts/utils/pure-functions";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import {mixins} from "vue-class-component";

@Component({
  name: 'ProfileFieldName',
  components: {EditLastName, EditFirstName, ProfileField}
})
export default class ProfileFieldName extends mixins(LoadingMixin, DefaultStoreMixin) {
  @DefaultState
  profile!: Profile;

  showSuccess: boolean = false;

  firstName: string = "";

  lastName: string = "";

  created(): void {
    this.firstName = this.profile.firstName;
    this.lastName = this.profile.lastName;
  }

  @DefaultGrowlError
  async save(): Promise<void> {
    this.showSuccess = false;
    const value: PutProfileRequestDTO = {
      ...profileToPutProfileRequest(this.profile),
      firstName: this.firstName,
      lastName: this.lastName,
    };
    const getProfileResponseDTO = await this.$api.updateProfile(value);
    const profileData: Profile = {
      ...getProfileResponseDTO,
      dateOfBirth: this.profile.dateOfBirth,
      location: this.profile.location,
      department: this.profile.department,
    };
    this.defaultStore.setProfile(profileData);
    this.showSuccess = true;
  }
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>
  .edit-first-name,
  .edit-last-name
    flex: 1
    min-width: 200px
</style>
