import {pagesPath} from "@/ts/router/pages-path";
import MyHealthPage from "@/vue/templates/my-health-page.vue";
import MyHealthcheckScorePage from "@/vue/pages/my-health/my-healthcheck-score.vue";
import MyHealthCare360Page from "@/vue/pages/my-health/my-health-care-360.vue";
import MyHealthPastResultsPage from "@/vue/pages/my-health/my-health-past-results.vue";
import type {RouteRecordRaw} from "vue-router";

export const myHealthPages: RouteRecordRaw = {
  path: pagesPath.myHealthPath.myhealth,
  component: MyHealthPage,
  children: [
    {
      path: "",
      redirect: pagesPath.myHealthPath.myhealthCheckScore,
    },
    {
      path: pagesPath.myHealthPath.myhealthCheckScore,
      component: MyHealthcheckScorePage,
    },
    {
      path: pagesPath.myHealthPath.myhealthCare360,
      component: MyHealthCare360Page,
    },
    {
      path: pagesPath.myHealthPath.myhealthPastResults,
      component: MyHealthPastResultsPage,
    },
  ],
};
