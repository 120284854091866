<template>
  <navbar-page
    title="My Health"
    :profile-section="true"
    class="custom"
    slot-custom="custom"
  >
    <my-health-page-select/>
  </navbar-page>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import MyHealthPageSelect from "@/vue/molecules/my-health-page-select.vue";
import NavbarPage from "@/vue/templates/navbar-page.vue";

@Component({
  name: "MyHealthPage",
  components: {
    MyHealthPageSelect,
    NavbarPage,
  },
})
export default class MyHealthPage extends Vue {
}
</script>

<style lang="sass" scoped>
.custom
  min-height: 100vh
  display: flex
  flex-direction: column
</style>
