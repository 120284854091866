<template>
  <scheduling-page :step="1" title="Personal Information">
    <div class="row">
      <personal-information-item name="First Name" :value="profile.firstName"/>
      <personal-information-item name="Last Name" :value="profile.lastName"/>
    </div>
    <div class="row">
      <personal-information-item name="Birthday" :value="dateOfBirthStr"/>
      <personal-information-item name="Gender" :value="gender"/>
    </div>
    <div class="row">
      <personal-information-item name="Email Address" :value="profile.email"/>
    </div>
    <div class="row">
      <personal-information-item name="Street Address" :value="profile.address1"/>
    </div>
    <div class="row">
      <personal-information-item name="Address Line 2" :value="profile.address2"/>
    </div>
    <div class="row">
      <personal-information-item name="City" :value="profile.city"/>
      <personal-information-item name="State" :value="profile.state"/>
      <personal-information-item name="Zip" :value="profile.postcode"/>
    </div>
    <div class="row">
      <personal-information-item name="Cell Phone Number" :value="profile.phoneNumber"/>
    </div>
    <div class="buttons">
      <button-link :to="$pagesPath.profile.edit" theme="white">
        <img src="@/assets/img/profile-icons/edit-profile.svg"/>Edit My Personal Data
      </button-link>
      <button-link :to="$pagesPath.biometricScheduling.location">
        Next <img class="next-img" src="@/assets/img/right-arrow-white.svg"/>
      </button-link>
    </div>
  </scheduling-page>
</template>

<script lang="ts">
import {
  Component,
  Prop,
} from "vue-property-decorator";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import {mixins} from "vue-class-component";
import {LoadingMixin} from "@/ts/mixins/loading-mixin";
import {DefaultState} from "@/ts/store/default/default-store-instance";
import LoadingBar from "@/vue/atoms/loading-bar.vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import SchedulingPage from "@/vue/templates/scheduling-page.vue";
import ButtonLink from "@/vue/atoms/button-link.vue";
import PersonalInformationItem from "@/vue/molecules/personal-information-item.vue";
import type {Profile} from "@/ts/types/store/default-store-types";
import {formatDateShortMonthName} from "@/ts/utils/date-pure-functions";

@Component({
  name: 'SchedulingPersonalInformationPage',
  components: {
    PersonalInformationItem, ButtonLink, SchedulingPage, ButtonPrimary, LoadingBar, LoadingSuspense
  },
})
export default class SchedulingPersonalInformationPage extends mixins(LoadingMixin) {
  @DefaultState
  profile!: Profile;

  get dateOfBirthStr(): string {
    return formatDateShortMonthName(this.profile.dateOfBirth);
  }

  get gender(): string {
    const {gender} = this.profile;
    return gender.charAt(0).toUpperCase() + gender.slice(1).replace("_", "-");
  }
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>
.next-img
  margin-left: 8px

.buttons
  display: flex
  gap: 32px
  margin-top: 56px

.row
  display: flex
  padding-top: 8px
  max-width: 447px
  width: 100%
  flex-direction: row

  + .row
    border-top: 1.5px solid $color-primary-hc-blue-10
</style>
