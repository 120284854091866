<template>
  <div class="nav-buttons">
    <router-link :to="$pagesPath.profile.main">
      <img src="@/assets/img/user.svg"/>
    </router-link>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Vue,
} from "vue-property-decorator";

@Component({})
export default class ProfileAndNotifications extends Vue {
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>
.nav-buttons
  display: flex
  margin: 1rem
  gap: 13px

  > *
    margin-right: 0.5rem
</style>
