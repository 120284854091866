<template>
  <box-row>
    <template #left>
      <loading-suspense :error="error" :loading="loading">
        <div class="left">
          <p> {{ related.name }} </p> {{ rewardFormatted }}
        </div>
      </loading-suspense>
    </template>
    <template #right>
      <toggle-switch
        v-model="setActive"
        @click="addOrRemoveActivity"
      />
    </template>
  </box-row>
</template>

<script lang="ts">
import {Component, Prop, Emit, Watch} from "vue-property-decorator";
import type {ActivityDTO} from "@/ts/types/dto/activity.dto";
import ToggleSwitch from "@/vue/atoms/toggle-switch.vue";
import BoxRow from "@/vue/atoms/box-row.vue";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import {mixins} from "vue-class-component";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import {getRewardAmountFormatted} from "@/ts/utils/pure-functions";

@Component({
  name: "RelatedActivity",
  components: {
    ToggleSwitch,
    BoxRow,
    LoadingSuspense,
  },
})

export default class RelatedActivity extends mixins(LoadingMixin) {
  @Prop() related!: ActivityDTO;

  setActive: boolean = false;

  get rewardFormatted(): string {
    return getRewardAmountFormatted(this.related.reward ? {
      amount: this.related.reward.amount,
      type: this.related.reward.type,
    } : null);
  }

  @Watch("setActive")
  @DefaultGrowlError
  async addOrRemoveActivity(): Promise<void> {
    if (this.setActive) {
      await this.$api.deleteActivity(this.related.id);
    } else {
      await this.$api.addActivityToTodo(this.related.id);
    }
  }

  @Emit("changeItem")
  changeItem(item: ActivityDTO): ActivityDTO {
    return item;
  }
}
</script>

<style lang="sass" scoped>

p
  @include Roboto400
  font-size: 14px
  color: $color-primary-hc-blue-100
  margin-right: 1rem

.left
  @include Roboto700
  font-size: 14px
  color: $color-secondary-state-blue-100
  display: flex
  flex-direction: row
  align-items: center
</style>
