<!-- eslint-disable @typescript-eslint/no-magic-numbers -->
<template>
  <div class="container">
    <img :src="medalIconType" alt="medal"/>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";
import BronzeMedal from "@/assets/img/medals/bronze-medal.svg";
import GoldMedal from "@/assets/img/medals/gold-medal.svg";
import SilverMedal from "@/assets/img/medals/silver-medal.svg";
import NoMedal from "@/assets/img/medals/finished-no-medal.svg";

@Component({
  name: "PositionMedal",
})
export default class PositionMedal extends Vue {
  @Prop() position!: number;

  get medalIconType(): string {
    switch (Number(this.position)) {
      case 1:
        return GoldMedal;
      case 2:
        return SilverMedal;
      case 3:
        return BronzeMedal;

      default:
        return NoMedal;
    }
  }
}
</script>

<style lang="sass" scoped></style>
