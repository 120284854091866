<template>
  <box-row>
    <template #left>
      <p class="title">
        {{ typeString }}
      </p>
      <p class="text">
        {{ frequency }}
      </p>
    </template>
    <template v-if="props.goal.targetValue !== undefined" #right>
      <p class="title align-right">
        Goal
      </p>
      <p class="text align-right">
        <span class="progress-value">{{ formattedProgressValue }}&nbsp;</span>
        <span class="goal-value">/&nbsp;</span>
        <span class="goal-value">{{ formattedTargetValue }}</span>
      </p>
    </template>
  </box-row>
</template>

<script lang="ts" setup>
import BoxRow from "@/vue/atoms/box-row.vue";
import {TaskTypeNames} from "@/ts/types/component/tracking-list";
import {
  formatTaskAmountByTypeUnit,
} from "@/ts/utils/pure-functions";
import {computed} from "vue";
import type {ActivityTaskGoal} from "@/ts/types/dto/activity.dto";
import {TaskTargetValueStorageUnit, TaskType} from "@/ts/types/dto/activity.dto";

const props = defineProps<{
  taskType: TaskType;
  goal: ActivityTaskGoal;
  frequency: string;
}>();

const typeString = computed((): string => {
  if (props.taskType === TaskType.general_physical_activity_time_or_steps) {
    if (props.goal.unit === TaskTargetValueStorageUnit.Seconds) {
      return "Minutes of Activity";
    }
    if (props.goal.unit === TaskTargetValueStorageUnit.Steps) {
      return "Steps";
    }
  }

  return TaskTypeNames[props.taskType];
});

const formattedProgressValue = computed((): string => {
  const val = formatTaskAmountByTypeUnit(props.goal.progress, props.goal.unit);
  return val
      /*
       * remove units, but not for time!
       * it's because time is displayed like '1h 15m'
       */
      .replace(" mi.", "")
      .replace(" steps", "")
      .replace(" fl oz", "")
      .replace(" lbs.", "");
});

const formattedTargetValue = computed((): string => {
  return formatTaskAmountByTypeUnit(props.goal.targetValue, props.goal.unit);
});

</script>

<style lang="sass" scoped>
.title
  @include Roboto700
  font-size: 16px
  line-height: 24px
  color: $color-primary-hc-blue-100
  margin: 0

.text
  @include Roboto400
  font-size: 14px
  line-height: 22px
  color: $color-primary-hc-blue-50
  margin: 0

.progress-value
  @include Roboto700
  font-size: 14px
  line-height: 22px
  color: $color-primary-hc-blue-100

.goal-value
  @include Roboto400
  font-size: 14px
  line-height: 22px
  color: $color-primary-hc-blue-100


.align-right
  text-align: right
</style>
