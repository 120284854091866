<template>
  <alert-icon-card
    v-if="showThankYou"
    theme="success"
    title="Thank you for sending your results!"
  >
    <div class="text">
      Data submitted on <span class="bold"> {{ submittedDate }} </span>
    </div>
  </alert-icon-card>
</template>

<script lang="ts">
import {Component, Vue, Prop} from "vue-property-decorator";
import AlertIconCard from "@/vue/atoms/alert-icon-card.vue";
import {formatDateShortMonthName} from "@/ts/utils/date-pure-functions";
import {isBioscreeningSubmitted} from "@/ts/utils/my-health/bioscreening";
import type {BiometricScreeningStatus} from "@/ts/types/dto/biometric-screening.dto";

@Component({
  name: "ThankYouCard",
  components: {AlertIconCard}
})
export default class ThankYouCard extends Vue {
  @Prop({default: null})
  biometricStatus!: BiometricScreeningStatus | null;

  get isBioscreeningSubmitted(): boolean {
    return isBioscreeningSubmitted(this.biometricStatus);
  }

  get showThankYou(): boolean {
    return this.isBioscreeningSubmitted;
  }

  get submittedDate(): string {
    return formatDateShortMonthName(this.biometricStatus!.submittedDate!);
  }
}
</script>

<style lang="sass" scoped>
.bold
  @include Roboto700

.text
  @include Roboto400
  color: $color-primary-hc-blue-100
  font-size: 14px
  letter-spacing: 0.2px
  line-height: 22px
  margin: 0 0 4px
</style>
