<template>
  <div class="title">
    <img :src="icon" :alt="text"/>
    <p>{{ text }}</p>
  </div>
</template>

<script lang="ts">
import {Component, Vue, Prop} from "vue-property-decorator";

@Component({
  name: "TitleIcon",
  components: {},
})
export default class TitleIcon extends Vue {
  @Prop()
  icon!: string;

  @Prop()
  text!: string;
}

</script>

<style lang="sass" scoped>
.title
  display: flex
  gap: 10px

p
  @include Asap700
  font-size: 20px
  line-height: 28px
  color: $color-primary-hc-blue-100
  text-transform: capitalize
  margin: 0

</style>

