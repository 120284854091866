<template>
  <div class="error" :style="style">
    <img src="@/assets/img/warning.svg"/>
    {{ error }}
  </div>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";

@Component({
  name: "ErrorText",
})
export default class ErrorText extends Vue {
  @Prop() error!: string;

  get style(): Record<string, string> {
    return {
      display: this.error ? "block" : "none",
    };
  }
}
</script>

<style lang="sass" scoped>
.error
  color: $color-status-error-red
  @include Roboto400
  letter-spacing: 0.4px
  font-size: 14px

img
  position: relative
  top: 3px
  width: 20px
</style>
