<template>
  <leaderboard-challenge v-if="challengeTypeLeaderboard" :id="id"/>
  <div v-if="!challengeTypeLeaderboard">
    other challenge type
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import LeaderboardChallenge from "@/vue/pages/challenge/leaderboard-challenge.page.vue";

@Component({
  name: "ChallengePage",
  components: {
    LeaderboardChallenge,
  },
})
export default class ChallengePage extends Vue {
  /*
   *  Challenge ID and ChallengeType comes in the route query
   *  Currently only ChallengeType leaderboard is supported
   */

  get id(): string {
    return this.$route.params.id as string;
  }

  get challengeTypeLeaderboard(): boolean {
    return this.$route.query.type ? this.$route.query.type.includes("leaderboard") : false;
  }
}
</script>

<style></style>
