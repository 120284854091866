<template>
  <div v-if="expanded" class="content">
    <slot name="body"/>
  </div>
</template>
  <script lang="ts">
import {
  Component,
  Prop,
  Vue,
  Watch,
} from "vue-property-decorator";


  @Component({
  name: "ExpansionPanel",
  components: {},
})
  // PARENT MUST IMPLEMENT TOGGLE AND BUTTON
  export default class ExpansionPanel extends Vue {
    @Prop()
     title!: string | undefined;


    @Prop()
    expanded!: boolean;
  }
  </script>

<style lang="sass" scoped>

.content
  display: flex
  flex-wrap: wrap
  gap: 4px
</style>

