import type {MyProgramResponse} from "@/ts/types/dto/program.dto";
import {isTimestampInFuture} from "@/ts/utils/date-pure-functions";

export function hasProgramHra(program: MyProgramResponse | null): boolean {
  return Boolean(program?.includeHra);
}

export function hasProgramHraStartDate(program: MyProgramResponse | null): boolean {
  return Boolean(program?.hraStartDate);
}

export function hasProgramHraEndDate(program: MyProgramResponse | null): boolean {
  return Boolean(program?.hraEndDate);
}

export function hasProgramHraStartDateInFuture(program: MyProgramResponse | null): boolean {
  return Boolean(
    hasProgramHraStartDate(program) &&
    isTimestampInFuture(program!.hraStartDate!),
  );
}

export function hasProgramHraStartDateInPast(program: MyProgramResponse | null): boolean {
  return Boolean(
    hasProgramHraStartDate(program) &&
    !isTimestampInFuture(program!.hraStartDate!),
  );
}

export function hasProgramHraEndDateInFuture(program: MyProgramResponse | null): boolean {
  return Boolean(
    hasProgramHraEndDate(program) &&
    isTimestampInFuture(program!.hraEndDate!),
  );
}

export function hasProgramHraEndDateInPast(program: MyProgramResponse | null): boolean {
  return Boolean(
    hasProgramHraEndDate(program) &&
    !isTimestampInFuture(program!.hraEndDate!),
  );
}

export function hasProgramOnlyHraStartDate(program: MyProgramResponse | null): boolean {
  return Boolean(
    hasProgramHraStartDate(program) &&
    !hasProgramHraEndDate(program),
  );
}

export function hasProgramOnlyHraEndDate(program: MyProgramResponse | null): boolean {
  return Boolean(
    !hasProgramHraStartDate(program) &&
    hasProgramHraEndDate(program),
  );
}

export function hasProgramNoHraDates(program: MyProgramResponse | null): boolean {
  return Boolean(
    !hasProgramHraStartDate(program) &&
    !hasProgramHraEndDate(program),
  );
}

export function hasProgramHraAvailableWithoutDates(program: MyProgramResponse | null): boolean {
  return Boolean(
    hasProgramHra(program) &&
    hasProgramNoHraDates(program),
  );
}

export function hasProgramHraAvailableWithOnlyStartDate(program: MyProgramResponse | null): boolean {
  return Boolean(
    hasProgramHra(program) &&
    hasProgramOnlyHraStartDate(program) &&
    hasProgramHraStartDateInPast(program),
  );
}

export function hasProgramHraAvailableWithOnlyEndDate(program: MyProgramResponse | null): boolean {
  return Boolean(
    hasProgramHra(program) &&
    hasProgramOnlyHraEndDate(program) &&
    hasProgramHraEndDateInFuture(program),
  );
}

export function hasProgramHraAvailableWithDates(program: MyProgramResponse | null): boolean {
  return Boolean(
    hasProgramHra(program) &&
    hasProgramHraStartDateInPast(program) &&
    hasProgramHraEndDateInFuture(program),
  );
}

export function hasProgramHraAvailable(program: MyProgramResponse | null): boolean {
  return Boolean(
    hasProgramHraAvailableWithoutDates(program) ||
    hasProgramHraAvailableWithDates(program) ||
    hasProgramHraAvailableWithOnlyStartDate(program) ||
    hasProgramHraAvailableWithOnlyEndDate(program),
  );
}

export function hasProgramHraAvailableWithoutEndDate(program: MyProgramResponse | null): boolean {
  return hasProgramHraAvailable(program) && !hasProgramHraEndDate(program);
}
