<template>
  <physician-form-page step="2" title="Upload Physician’s form">
    <physician-form-information/>
  </physician-form-page>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import PhysicianFormPage from "@/vue/templates/physician-form-page.vue";
import PhysicianFormInformation from "@/vue/molecules/physician-form-information.vue";

@Component({
  name: "PhysicianUploadFormPage",
  components: {
    PhysicianFormPage,
    PhysicianFormInformation,
  },
})
export default class PhysicianUploadFormPage extends Vue {

}
</script>

<style lang="sass" scoped></style>
