<template>
  <alert-icon-card theme="error">
    <p class="text">
      The deadline for submitting the form ended on <span class="bold">{{ deadline }}</span>. You can not submit the form anymore.
    </p>
  </alert-icon-card>
</template>

<script lang="ts" setup>
import AlertIconCard from "@/vue/atoms/alert-icon-card.vue";
import type {GetPhysicianFormBiometricResponse} from "@/ts/types/dto/physician-form.dto";
import {computed} from "vue";
import {formatDateShortMonthName} from "@/ts/utils/date-pure-functions";

const props = defineProps<{
  physicianForm: GetPhysicianFormBiometricResponse | null;
}>();

const deadline = computed(() => {
  if (props.physicianForm?.denialDetails && props.physicianForm.denialDetails.cutoffDate) {
    return formatDateShortMonthName(props.physicianForm.denialDetails.cutoffDate);
  }
  if (props.physicianForm?.deadline) {
    return formatDateShortMonthName(props.physicianForm.deadline);
  }
  return null;
});
</script>

<style lang="sass" scoped>
.text
  @include Roboto400
  color: $color-primary-hc-blue-100
  font-size: 14px
  letter-spacing: 0.2px
  line-height: 22px
  margin: 0 0 4px
</style>
