<template>
  <challenge-time-label/>
  <div class="main">
    <leaderboard-challenge-cover-image/>
    <challenge-description/>
    <challenge-participants
      :participants="participants"
    />
    <challenge-rewards-section/>
    <challenge-friends-joined
      :friends="leaderboardChallenge.joinedFriends"
      :participants="participants"
    />
    <div v-if="!leaderboardChallenge.hasUserJoined" class="buttons">
      <button-primary
        text="join this challenge"
        :loading="loading"
        class="button-add"
        @click="handleJoinChallenge"
      >
        Join This Challenge
      </button-primary>
      <error-text :error="error"/>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import ErrorText from "@/vue/atoms/error-text.vue";
import NavbarPage from "@/vue/templates/navbar-page.vue";
import {LoadingMixin} from "@/ts/mixins/loading-mixin";
import {mixins} from "vue-class-component";
import ChallengeDescription from "@/vue/molecules/challenge/challenge-description.vue";
import ChallengeRewardsSection from "@/vue/molecules/challenge/challenge-rewards-section.vue";
import type {ChallengeParticipant, LeaderboardChallengeDTO} from "@/ts/types/dto/leaderboard-challenge.dto";
import ChallengeCreatedByLabel from "@/vue/molecules/challenge/challenge-created-by.vue";
import challengeParticipants from "@/vue/molecules/challenge/challenge-participants.vue";
import ChallengeFriendsJoined from "@/vue/molecules/challenge/challenge-friends-joined.vue";
import ChallengeTimeLabel from "@/vue/molecules/challenge/challenge-time-label.vue";
import LeaderboardChallengeCoverImage from "@/vue/molecules/challenge/leaderboard-challenge-cover-image.vue";
import {DefaultState} from "@/ts/store/default/default-store-instance";

@Component({
  components: {
    LeaderboardChallengeCoverImage,
    NavbarPage,
    ErrorText,
    ButtonPrimary,
    ChallengeRewardsSection,
    ChallengeDescription,
    ChallengeCreatedByLabel,
    challengeParticipants,
    ChallengeFriendsJoined,
    ChallengeTimeLabel,
  },
})
export default class LeaderboardChallengeAvailable extends mixins(LoadingMixin) {
  @Prop()
  participants!: ChallengeParticipant[];

  @DefaultState
  leaderboardChallenge!: LeaderboardChallengeDTO;

  @Emit("join-challenge")
  handleJoinChallenge(): void {
  }
}
</script>

<style lang="sass" scoped>
.buttons
  display: flex
  justify-content: center
  margin: 5rem
  flex-direction: row
  align-items: center

.button-add
  margin-left: 2rem
</style>
