<template>
  <loading-suspense :loading="loading" :error="error">
    <router-view :pathway-response="pathwayResponse"/>
  </loading-suspense>
</template>

<script lang="ts">
import {Component} from "vue-property-decorator";
import {ApplyGrowlErr} from "@/ts/utils/decorators";
import type {GetPathwayResponseDTO} from "@/ts/types/dto/pathway.dto";
import ErrorText from "@/vue/atoms/error-text.vue";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import {mixins} from "vue-class-component";
import {LoadingMixin} from "@/ts/mixins/loading-mixin";

@Component({
  name: 'PathwayParentWrapper',
  components: {LoadingSuspense, ErrorText}
})
export default class PathwayParentWrapperPage extends mixins(LoadingMixin) {
  pathwayResponse: GetPathwayResponseDTO | null = null;

  get id(): string {
    return this.$route.params.id as string;
  }

  @ApplyGrowlErr({
    loadingProperty: 'loading',
    errorProperty: 'error',
    allowSpam: true,
  })
  async created(): Promise<void> {
    this.pathwayResponse = await this.$api.getPathway({id: this.id});
  }
}
</script>
