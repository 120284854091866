<template>
  <div class="health-risk-assessment">
    <title-icon
      :icon="hraIcon"
      text="Health Risk Assessment"
    />
    <div class="text">
      This <span class="bold">Health Risk Assessment (HRA)</span> survey
      will ask you about your health and lifestyle habits.
    </div>
    <health-risk-assessment-alert
      :hra-summary="hraSummary"
      :program="program"
    />
    <button-link
      v-if="showButton"
      theme="dark"
      class="button"
      :to="$pagesPath.healthRiskAssessment.startHra"
      :disabled="disableButton"
    >
      Take assessment
    </button-link>
  </div>
</template>

<script lang="ts">
import {Component, Vue, Prop} from "vue-property-decorator";
import TitleIcon from "@/vue/atoms/title-icon.vue";
import hraIcon from "@/assets/img/icons/my-health/hra.svg";
import type {HRASummary} from "@/ts/types/dto/health-risk-assessment.dto";
import HealthRiskAssessmentAlert from "@/vue/molecules/my-health/health-risk-assessment-alert.vue";
import ButtonLink from "@/vue/atoms/button-link.vue";
import type {MyProgramResponse} from "@/ts/types/dto/program.dto";
import {isHraCompleted, isHraUnavailableOrNotAvailableInProgram} from "@/ts/utils/my-health/hra";

@Component({
  name: "HealthRiskAssessment",
  components: {
    ButtonLink,
    HealthRiskAssessmentAlert,
    TitleIcon,
  }
})
export default class HealthRiskAssessment extends Vue {
  @Prop({default: null})
  hraSummary!: HRASummary | null;

  @Prop({default: null})
  program!: MyProgramResponse | null;

  get isHraUnavailableOrNotAvailableInProgram(): boolean {
    return isHraUnavailableOrNotAvailableInProgram({
      program: this.program,
      hraSummary: this.hraSummary,
    });
  }

  get isHraNotCompleted(): boolean {
    return !isHraCompleted(this.hraSummary);
  }

  get showButton(): boolean {
    return this.isHraNotCompleted;
  }

  get disableButton(): boolean {
    return this.isHraUnavailableOrNotAvailableInProgram;
  }

  get hraIcon(): string {
    return hraIcon;
  }
}
</script>

<style lang="sass" scoped>
.health-risk-assessment
  background-color: $color-white
  border-radius: 16px
  color: $color-primary-hc-blue-100
  padding: 24px

.title
  margin-bottom: 0

.text
  @include Roboto400
  color: $color-primary-hc-blue-100
  font-size: 16px
  letter-spacing: 0.1px
  line-height: 24px
  margin: 24px 0

.button
  font-size: 18px
  margin-top: 24px

.bold
  font-weight: 700

</style>
