<template>
  <div class="welcome-box">
    <img src="@/assets/img/welcome.svg"/>
    <div class="welcome-text">
      <h2>Welcome, {{ profile.firstName }}!</h2>
      <p class="welcome-description">
        These are your activities for today!
      </p>
      <button-link :to="to">
        Discover My Program
      </button-link>
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Vue,
} from "vue-property-decorator";
import ButtonLink from "@/vue/atoms/button-link.vue";
import {DefaultState} from "@/ts/store/default/default-store-instance";
import type {Profile} from "@/ts/types/store/default-store-types";


@Component({
  name: "WelcomeBox",
  components: {ButtonLink},
})
export default class WelcomeBox extends Vue {
  @DefaultState
  public profile!: Profile;

  get to(): string {
    return this.$pagesPath.program.programOverview;
  }
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>
.welcome-box
  align-items: center
  display: flex
  flex-direction: row
  flex-wrap: nowrap
  justify-content: center
  color: $color-primary-hc-blue-100

.welcome-text
  margin-left: 60px

h2
  @include Asap700
  size: 24px
  margin: 0

.welcome-description
  @include Roboto400
  font-size: 14px
  margin-top: 8px
  margin-bottom: 40px
</style>
