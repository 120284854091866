<template>
  <div class="personal-info-item">
    <div class="name">
      {{ name }}
    </div>
    <div class="value">
      {{ value }}
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Prop,
} from "vue-property-decorator";
import {Vue} from "vue-class-component";

@Component({
  name: 'PersonalInformationItem',
})
export default class PersonalInformationItem extends Vue {
  @Prop()
  public name!: string;

  @Prop()
  public value!: string;
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>
.personal-info-item
  display: flex
  flex-direction: column
  gap: 10px
  flex: 1

.name
  color: $color-primary-hc-blue-100
  @include Asap700
  font-size: 16px
  line-height: 22px
  letter-spacing: 0.15px

.value
  @include Roboto400
  line-height: 24px
  letter-spacing: 0.1px
  font-size: 16px
  padding-bottom: 14px
  color: $color-primary-hc-blue-100
</style>
