<template>
  <div :class="`status ${status}`">
    <p>{{ value }}</p>
  </div>
</template>
<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";

@Component({
  name: "StatusBadge",
  components: {},
})
export default class StatusBadge extends Vue {
  @Prop()
  value!: string;

  @Prop({default: "active"})
  status!: "active" | "onhold";
}
</script>

<style lang="sass" scoped>
.status
  border-radius: 4px
  padding: 4px 12px

  p
    @include Roboto400
    font-size: 14px
    line-height: 22px
    margin: 0
    text-align: center
    letter-spacing: 0.2px

.active
  background-color: $color-secondary-state-blue-100

  p
    color: $color-white


.onhold
  background-color: $color-primary-hc-blue-10
  padding: 2px 8px

  p
    color: $color-primary-hc-blue-100
</style>
