<template>
  <div class="friend-card">
    <div>
      <user-avatar
        :firstname="friendData.firstName"
        :lastname="friendData.lastName"
        :avatar="friendData.avatarPath ?? ''"
        :user-status="friendData.status"
      />
      <div class="username text-h6">
        {{ displayedName }}
      </div>
      <div
        v-if="displayDepartment"
        class="department text-body2 text-secondary-blue-100"
      >
        {{ friendData.department?.name }}
      </div>
      <div
        v-if="displayLocation"
        class="location text-body2 text-primary-blue-50"
      >
        <icon-chameleon
          :icon="SvgLocation"
          class="location__icon"
          aria-hidden="true"
          color="primary50"
        />
        {{ friendData.location?.name }}
      </div>
    </div>

    <div v-if="mode === 'best-buddy'">
      <button-round
        class="btn"
        :label="`Break up with ${friendData.firstName} ${friendData.lastName}`"
        :icon="SvgDeleteTrashIcon"
        :loading="isInProgress.breakUp"
        @click="onClickBreakUp"
      />
    </div>

    <div v-if="mode === 'requested'">
      <button-round
        class="btn"
        :label="`Cancel friendship request for ${friendData.firstName} ${friendData.lastName}`"
        :icon="SvgCancel"
        :loading="isInProgress.cancelInvitation"
        @click="onClickCancelInvitation"
      />
    </div>

    <div v-if="mode === 'request-received'">
      <button-round
        class="btn"
        :label="`Reject friendship request from ${friendData.firstName} ${friendData.lastName}`"
        :icon="SvgCancel"
        :loading="isInProgress.rejectInvitation"
        :disabled="isInProgress.acceptInvitation"
        @click="onClickRejectInvitation"
      />
      <button-round
        class="btn"
        :label="`Accept friendship request from ${friendData.firstName} ${friendData.lastName}`"
        :icon="SvgCheckmark"
        :loading="isInProgress.acceptInvitation"
        :disabled="isInProgress.rejectInvitation"
        @click="onClickAcceptInvitation"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type {Ref} from "vue";
import {computed, inject, ref} from "vue";
import UserAvatar from "@/vue/atoms/user-avatar.vue";
import type {FriendDto} from "@/ts/types/dto/friend.dto";
import {UserStatus} from "@/ts/types/dto/profile.dto";
import SvgLocation from "@/assets/img/location.svg";
import SvgDeleteTrashIcon from "@/assets/img/delete-trash-icon.svg";
import SvgCheckmark from "@/assets/img/checkmark.svg";
import SvgCancel from "@/assets/img/cancel.svg";
import type {Api} from "@/ts/classes/api";
import ButtonRound from "@/vue/atoms/button-round.vue";
import IconChameleon from "@/vue/atoms/icon-chameleon.vue";

const props = defineProps<{
  friendData: FriendDto;
  mode: "best-buddy" | "request-received" | "requested";
}>();

const emit = defineEmits([
  "friendshipRequestAccepted",
  "friendshipRequestRejected",
  "myFriendshipRequestRemoved",
  "friendshipBrokeUp",
]);

const $api = inject("$api")! as Api;

interface InProgress {
  breakUp: boolean;
  acceptInvitation: boolean;
  rejectInvitation: boolean;
  cancelInvitation: boolean;
}

const isInProgress: Ref<InProgress> = ref({
  breakUp: false,
  acceptInvitation: false,
  rejectInvitation: false,
  cancelInvitation: false,
});

const displayedName = computed((): string => {
  return props.friendData.status === UserStatus.anonymized
    ? "Deleted Account" : `${props.friendData.firstName} ${props.friendData.lastName}`;
});

const displayDepartment = computed((): boolean => {
  return Boolean(props.mode === "best-buddy" && props.friendData.department);
});

const displayLocation = computed((): boolean => {
  return Boolean(props.mode === "best-buddy" && props.friendData.location);
});

const onClickBreakUp = async(): Promise<void> => {
  isInProgress.value.breakUp = true;
  try {
    await $api.deleteFriendship(`${props.friendData.id}`);
    emit("friendshipBrokeUp", props.friendData.id);
  } catch (err) {
    // eslint-disable-line
  }
  isInProgress.value.breakUp = false;
};

const onClickAcceptInvitation = async(): Promise<void> => {
  isInProgress.value.acceptInvitation = true;
  try {
    const myNewBestBuddy = await $api.acceptFriendship(props.friendData.id);
    emit("friendshipRequestAccepted", props.friendData.id, myNewBestBuddy);
  } catch (err) {
    // eslint-disable-line
  }
  isInProgress.value.acceptInvitation = false;
};

const onClickRejectInvitation = async(): Promise<void> => {
  isInProgress.value.rejectInvitation = true;
  try {
    await $api.rejectFriendship(props.friendData.id);
    emit("friendshipRequestRejected", props.friendData.id);
  } catch (err) {
    // eslint-disable-line
  }
  isInProgress.value.rejectInvitation = false;
};

const onClickCancelInvitation = async(): Promise<void> => {
  isInProgress.value.cancelInvitation = true;
  try {
    await $api.deleteMyFriendshipRequest(props.friendData.id);
    emit("myFriendshipRequestRemoved", props.friendData.id);
  } catch (err) {
    // eslint-disable-line
  }
  isInProgress.value.cancelInvitation = false;
};

</script>

<style lang="sass" scoped>

.friend-card
  background-color: $color-white
  border-radius: 8px
  width: 176px
  padding: 12px
  display: flex
  flex-direction: column
  gap: 16px
  justify-content: space-between

.username
  margin: 16px 0 8px 0

.department
  margin-bottom: 8px

.location
  position: relative
  padding-left: 20px
  margin-bottom: 8px

  &__icon
    position: absolute
    top: 0
    left: 0

.btn
  margin-right: 8px

</style>
