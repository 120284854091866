<template>
  <router-link :to="$pagesPath.profile.edit">
    <profile-button>
      <template #icon>
        <img src="@/assets/img/profile-icons/edit-profile.svg" alt="profile"/>
      </template>
      <span>Edit Profile</span>
      <template #description>
        <span class="description">Change your personal information</span>
      </template>
    </profile-button>
  </router-link>
</template>

<script lang="ts">
import {
  Component,
  Vue,
} from "vue-property-decorator";
import ProfileButton from "@/vue/organisms/profile/profile-button.vue";

@Component({
  name: "ProfileLinkEdit",
  components: {
    ProfileButton,
  },
})

export default class ProfileLinkEdit extends Vue {
}
</script>

<style lang="sass" scoped>

</style>
