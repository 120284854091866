<template>
  <template v-if="isAppointmentScheduledInFuture">
    <export-to-cal-btn :appointment="appointment"/>
    <cancel-appointment appointment-type="screening"/>
  </template>
</template>

<script lang="ts">
import {Component, Vue, Prop} from "vue-property-decorator";
import type {BiometricScreeningAppointment} from "@/ts/types/dto/biometric-screening.dto";
import ExportToCalBtn from "@/vue/molecules/export-to-cal-btn.vue";
import CancelAppointment from "@/vue/molecules/cancel-appointment.vue";
import {isAppointmentScheduledInFuture} from "@/ts/utils/my-health/bioscreening";

@Component({
  name: "ExportCancelAppointmentBtns",
  components: {
    ExportToCalBtn,
    CancelAppointment,
  }
})
export default class ExportCancelAppointmentBtns extends Vue {
  @Prop({default: null})
  appointment!: BiometricScreeningAppointment | null;

  get isAppointmentScheduledInFuture(): boolean {
    return isAppointmentScheduledInFuture(this.appointment);
  }
}
</script>

<style lang="sass" scoped>
.buttons
  display: flex
  flex-direction: column
  row-gap: 16px
</style>
