<template>
  <loading-suspense :error="error" :loading="isLoading">
    <empty-state
      v-if="!program"
      :image="noProgramImage"
      title="There’s no program selected for you yet!"
      description="Please contact your admin."
    />
    <template v-else>
      <section class="main">
        <protected-image-curved-frame :src="coverPath" class="cover-url"/>
        <h2 class="title">
          {{ program.name }}
        </h2>
        <program-details :program="program"/>
      </section>
      <section class="progress">
        <reward-card :reward-response="rewardTracker" class="reward"/>
        <div v-if="showActionsContainer" class="actions-container">
          <gatekeeper-items
            v-if="gatekeeperItems.length"
            default-description="In order to earn the incentive tied to your wellness program, you must complete the required activities."
            :items="gatekeeperItems"
          />
          <primary-action-card :gatekeeper-items="gatekeeperItems"/>
        </div>
      </section>
      <section v-if="program?.levels?.length" class="level-section">
        <div class="heading">
          <img src="@/assets/img/levels-icon.svg" alt="levels"/>
          <h3>Levels</h3>
        </div>
        <div class="levels">
          <level-card
            v-for="level in levels"
            :key="level.id"
            :level="level"
          />
        </div>
        <div class="row">
          <img alt="trophy" src="@/assets/img/trophy-icon.svg"/><span>Total rewards required in order to level up</span>
        </div>
      </section>
    </template>
  </loading-suspense>
  <add-tracking-button/>
</template>
<script lang="ts" setup>
import type {MyProgramResponse, GatekeeperItem} from "@/ts/types/dto/program.dto";
import EmptyState from "@/vue/atoms/empty-state.vue";
import ProgramDetails from "@/vue/molecules/program-details.vue";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import RewardCard from "@/vue/molecules/reward-card.vue";
import PrimaryActionCard from "@/vue/molecules/primary-action-card.vue";
import ButtonLink from "@/vue/atoms/button-link.vue";
import LevelCard from "@/vue/molecules/level-card.vue";
import type {RewardTrackerResponse} from "@/ts/types/dto/reward-tracker";
import ProtectedImageCurvedFrame from "@/vue/atoms/protected-image-curved-frame.vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import noProgramImage from "@/assets/img/program-overview-empty-state.svg";
import type {ProgramLevelInfo} from "@/ts/types/component/levels";
import AddTrackingButton from "@/vue/atoms/add-tracking-button.vue";
import GatekeeperItems from "@/vue/molecules/gatekeeper-items.vue";
import {computed, inject, onMounted, ref} from "vue";
import type {Api} from "@/ts/classes/api";
import type {CachedApi} from "@/ts/classes/cached-api";
import {HealthContentActivityResponseType} from "@/ts/types/dto/health.dto";

const $api = inject("$api")! as Api;
const $cachedApi = inject("$cachedApi")! as CachedApi;
const isLoading = ref<boolean>(true);
const error = ref<string>("");
const program = ref<MyProgramResponse | null>(null);
const rewardTracker = ref<RewardTrackerResponse | null>(null);
const gatekeeperItems = ref<GatekeeperItem[]>([]);
const healthContent = ref<GatekeeperItem[]>();

const showActionsContainer = computed(() => Boolean(
    program.value?.includeBiometricScreening ||
    program.value?.includeCoachingSessions ||
    program.value?.includeHra,
  ));

const emptyStateImage = () => noProgramImage;
const coverPath = computed(() => program.value?.coverPath!);

const levels = computed<ProgramLevelInfo[]>(() => {
    return program.value?.levels
      ?.map((level) => ({...level,
isActive: level.id === program.value?.currentLevel?.id}))
      .sort((l1, l2) => l1.rewardAmountThreshold - l2.rewardAmountThreshold) ?? [];
  });

onMounted(async() => {
  try {
    program.value = await $cachedApi.getProgram();
    rewardTracker.value = await $cachedApi.getRewardTracker();
    gatekeeperItems.value = await $api.getGatekeeperItems(program.value.id, HealthContentActivityResponseType.HealthActivityProgramOverview);
  } catch (err) {
    error.value = err as string;
  } finally {
    isLoading.value = false;
  }
});
</script>

<style lang="sass" scoped>
.main
  max-width: 920px
  margin: 0 auto

.cover-url
  position: absolute
  top: 255px
  right: 0
  z-index: -1

  @include max-device(md)
    display: none

.title
  @include Asap700
  font-size: 28px
  line-height: 36px
  color: $color-primary-hc-blue-100
  text-align: center
  margin: 3rem auto

.progress
  max-width: 920px
  margin: 0 auto
  align-items: flex-start
  display: grid
  grid-template-columns: 1fr 1fr
  gap: 26px
  padding: 3rem 0

  @include max-device(md)
    flex-direction: column
    padding: 0 16px

.row
  @include Roboto400
  align-items: center
  border-top: 1px solid $color-white
  color: $color-primary-hc-blue-100
  display: flex
  font-size: 14px
  line-height: 22px
  letter-spacing: 0.2px
  justify-content: center
  padding-top: 16px
  max-width: 920px
  margin: 0 auto
  margin-top: 40px

.actions-container
  background-color: $color-primary-hc-blue-10
  display: flex
  gap: 26px
  flex-wrap: wrap
  padding: 24px
  border-radius: 16px

.coach
  display: flex
  flex-direction: column
  margin: 0 auto
  align-items: center
  margin-bottom: 40px

  p
    color: $color-primary-hc-blue-100
    @include Roboto400
    font-size: 14px
    line-height: 22px
    margin-bottom: 0

  button
    width: 339px

.level-section
  background-color: $color-neutral-platinum-100
  padding: 2rem

.levels
  margin: 0 auto
  display: flex
  gap: 16px
  justify-content: center

  @include max-device(md)
    flex-direction: column
    padding: 0 16px

.heading
  font-size: 20px
  @include Asap700
  color: $color-primary-hc-blue-100
  margin: 1rem auto
  padding: 0 16px
  display: flex
  align-items: center
  gap: 8px
  max-width: 920px

  h3
    margin: 0
</style>
