<template>
  <avatar-frame
    :image="image"
    :fallback-image="fallbackImage"
    :size="sizeNum"
    :title="title"
    :letters="initials"
    :is-me="isMe"
    :alt="`Avatar image of ${title}`"
  />
</template>
<script lang="ts" setup>
import AvatarFrame from "@/vue/atoms/avatar-frame.vue";
import {computed} from "vue";
import {UserStatus} from "@/ts/types/dto/profile.dto";
import DeleteAccount from "@/assets/img/deleted-account.svg";

const props = defineProps<{
  isMe?: boolean;
  avatar: string;
  firstname: string;
  lastname: string;
  size?: "large" | "mid" | "small" | "xlarge" | "xsmall";
  userStatus?: UserStatus;
}>();

const sizeName = computed((): string => {
  return props.size ?? "mid";
});

const sizeNum = computed((): number => {
  let size;
  switch (sizeName.value) {
    case "xlarge":
      size = 110; // eslint-disable-line @typescript-eslint/no-magic-numbers
      break;
    case "large":
      size = 73; // eslint-disable-line @typescript-eslint/no-magic-numbers
      break;
    case "mid":
      size = 48; // eslint-disable-line @typescript-eslint/no-magic-numbers
      break;
    case "small":
      size = 40; // eslint-disable-line @typescript-eslint/no-magic-numbers
      break;
    case "xsmall":
      size = 32; // eslint-disable-line @typescript-eslint/no-magic-numbers
      break;
    default:
      size = 48; // eslint-disable-line @typescript-eslint/no-magic-numbers
  }

  return size;
});

const initials = computed((): string => {
  return `${props.firstname.charAt(0).toUpperCase()}${props.lastname.charAt(0).toUpperCase()}`;
});

const isDeletedUser = computed(() => {
  return props.userStatus === UserStatus.anonymized;
});

const title = computed(() => {
  return isDeletedUser.value ? "Deleted Account" : `${props.firstname} ${props.lastname}`;
});

const image = computed(() => {
  return isDeletedUser.value ? undefined : props.avatar; // eslint-disable-line no-undefined
});

const fallbackImage = computed(() => {
  return isDeletedUser.value ? DeleteAccount : undefined; // eslint-disable-line no-undefined
});

</script>
