<template>
  <div class="time-selector-row">
    <div class="title">
      {{ title }}
    </div>
    <div class="row-items">
      <div
        v-for="value in values"
        :key="value.toString()"
        :class="{selected: modelValue === value}"
        @click="updateValue(value)"
      >
        {{ getTime(value) }}
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {
  Component,
  Emit,
  Prop,
  Vue,
} from "vue-property-decorator";
import {get12HourTime} from "@/ts/utils/date-pure-functions";

@Component({
  name: "TimeSelectorRow",
})
export default class TimeSelectorRow extends Vue {
  @Prop()
  title!: string;

  @Prop()
  values!: Date[];

  @Prop() modelValue!: Date | null;

  @Emit('update:modelValue')
  updateValue(event: Date): Date {
    return event;
  }

  getTime(time: Date): string {
    return get12HourTime(time);
  }
}
</script>

<style lang="sass" scoped>
.time-selector-row
  display: flex
  flex-direction: column
  gap: 8px

.title
  @include Asap700
  color: $color-primary-hc-blue-100
  letter-spacing: 0.15px
  font-size: 16px

.row-items
  display: flex
  flex-direction: row
  gap: 12px
  max-width: 460px
  flex-wrap: wrap

.row-items > *
  background-color: $color-primary-hc-blue-10
  border-radius: 12px
  width: 76px
  height: 36px
  display: flex
  align-items: center
  justify-content: center
  color: $color-primary-hc-blue-100
  @include Roboto400
  font-size: 14px
  cursor: pointer

  &.selected
    background-color: $color-secondary-state-blue-100
    color: $color-white
</style>
