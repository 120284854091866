<template>
  <input-text
    id="unique-id"
    :model-value="modelValue"
    required
    placeholder="ID"
    @update:model-value="updateValue"
  />
</template>

<script lang="ts">
import {
  Component,
  Emit,
  Prop,
  Vue,
} from "vue-property-decorator";
import FieldSet from "@/vue/molecules/field-set.vue";
import InputText from "@/vue/atoms/input-text.vue";

@Component({
  name: 'EditUniqueIdInput',
  components: {FieldSet, InputText}
})
export default class EditUniqueIdInput extends Vue {
  @Prop() modelValue!: string;

  @Emit('update:modelValue')
  updateValue(value: string): string {
    return value;
  }
}
</script>
