import {pagesPath} from "@/ts/router/pages-path";
import PathwayParentWrapperPage from "@/vue/pages/pathways/pathway-parent-wrapper.page.vue";
import PathwayStartPage from "@/vue/pages/pathways/pathway-start.page.vue";
import PathwayQuizPage from "@/vue/pages/pathways/pathway-quiz.page.vue";
import PathwayFinishPage from "@/vue/pages/pathways/pathway-finish.page.vue";
import PathwayCongratulationsPage from "@/vue/pages/pathways/pathway-congratulations.page.vue";
import PathwayQuizFailPage from "@/vue/pages/pathways/pathway-quiz-fail.page.vue";
import PathwayTaskPage from "@/vue/pages/pathways/pathway-task.page.vue";
import type {RouteRecordRaw} from "vue-router";

export const pathwayPages: RouteRecordRaw = {
  path: pagesPath.pathway.pathway,
  component: PathwayParentWrapperPage,
  children: [
    {
      path: pagesPath.pathway.pathwayStart,
      component: PathwayStartPage,
    },
    {
      path: pagesPath.pathway.pathwayQuiz,
      component: PathwayQuizPage,
    },
    {
      path: pagesPath.pathway.pathwayTaskFinish,
      component: PathwayFinishPage,
    },
    {
      path: pagesPath.pathway.pathWayCongratulations,
      component: PathwayCongratulationsPage,
    },
    {
      path: pagesPath.pathway.pathwayFailed,
      component: PathwayQuizFailPage,
    },
    {
      path: pagesPath.pathway.pathwayTask,
      component: PathwayTaskPage,
    },
  ],
};
