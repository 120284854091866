<template>
  <proof-item :status="props.proof.status">
    <div v-if="status === 'pending'">
      <proof-pending-item
        :date="formattedSubmitDate"
      />
    </div>
    <div v-else-if="status === 'approved'">
      <proof-approved-item
        :date="formattedResolvedDate"
      />
    </div>
    <div v-else-if="status === 'declined'">
      <proof-declined-item
        :date="formattedResolvedDate"
      />
    </div>
  </proof-item>
</template>

<script lang="ts" setup>
import type {ActivityApprovalRequest} from "@/ts/types/dto/activity.dto";
import ProofItem from "@/vue/atoms/proof-item.vue";
import ProofPendingItem from "@/vue/atoms/proof/proof-pending-item.vue";
import ProofApprovedItem from "@/vue/atoms/proof/proof-approved-item.vue";
import ProofDeclinedItem from "@/vue/atoms/proof/proof-declined-item.vue";
import dayjs from "dayjs";
import {computed} from "vue";

const props = defineProps<{
  proof: ActivityApprovalRequest;
}>();

const status = computed((): string => {
    return props.proof.status;
});

const formattedSubmitDate = computed(() => {
  return dayjs(props.proof.submitDate).format("MMM D");
});

const formattedResolvedDate = computed(() => {
  return dayjs(props.proof.resolveDate).format("MMM D");
});

</script>
