import type {ActivityApprovalRequest,
  ActivityDTO,
  ActivityTask,
  PlatformActivityDTO,
  TaskType} from "@/ts/types/dto/activity.dto";
import {ActivityStatus, SubmissionFrequency} from "@/ts/types/dto/activity.dto";
import type {CompletionValidation, Reward} from "@/ts/types/dto/reward.dto";

export enum KindOfActivity {
  standard = "standard_activity",
  platform = "platform_activity",
  unknown = "unknown",
}

export type ActivityIconType = TaskType | "generic" | "platform";

export class ActivityCardModel {
  private readonly activity: ActivityDTO | null;

  private readonly platformActivity: PlatformActivityDTO | null;

  constructor(data: {activity?: ActivityDTO; platformActivity?: PlatformActivityDTO}) {
    this.activity = data.activity ?? null;
    this.platformActivity = data.platformActivity ?? null;
  }

  public get instanceClone(): ActivityCardModel {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const data = JSON.parse(
      JSON.stringify({
        activity: this.activity,
        platformActivity: this.platformActivity,
      }),
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return new ActivityCardModel(data);
  }

  public get id(): string {
    const status = this.activity?.id ?? this.platformActivity?.id;
    return status ?? "";
  }

  public get name(): string {
    const name = this.activity?.name ?? this.platformActivity?.name;
    return name ?? "";
  }

  public get description(): string {
    const description = this.activity?.description ?? this.platformActivity?.description;
    return description ?? "";
  }

  public get reward(): Reward | null {
    if (this.activity?.reward) {
      return {
        amount: this.activity.reward.amount,
        type: this.activity.reward.type,
      };
    }

    if (this.platformActivity) {
      return {
        amount: this.platformActivity.rewardAmount,
        type: this.platformActivity.rewardType,
      };
    }

    return null;
  }

  public get task(): ActivityTask | null {
    return this.activity?.task ?? null;
  }

  public get submissionFrequency(): SubmissionFrequency | null {
    return this.activity?.submissionFrequency ?? null;
  }

  public get endDate(): string | null {
    return this.activity?.endDate ?? null;
  }

  public get canDo(): boolean {
    // value for platform activities is always true
    return this.activity?.canDo ?? true;
  }

  public get hasMaxPending(): boolean {
    return this.activity?.hasMaxPending ?? false;
  }

  public get isGateKeeper(): boolean {
    return this.activity?.isGateKeeper ?? false;
  }

  public get rewardCompletionValidation(): CompletionValidation | null {
    return this.activity?.reward?.completionValidation ?? null;
  }

  public get kindOf(): KindOfActivity {
    if (this.activity) {
      return KindOfActivity.standard;
    }

    if (this.platformActivity) {
      return KindOfActivity.platform;
    }

    return KindOfActivity.unknown;
  }

  public get status(): ActivityStatus {
    const status = this.activity?.status ?? this.platformActivity?.status;
    return status ?? ActivityStatus.unknown;
  }

  public set status(val: ActivityStatus) {
    if (this.activity) {
      this.activity.status = val;
    }

    if (this.platformActivity) {
      this.platformActivity.status = val;
    }
  }

  public get proof(): ActivityApprovalRequest[] | null {
    return this.activity?.proof ?? null;
  }

  public get frequency(): string {
    switch (this.activity?.submissionFrequency) {
      case SubmissionFrequency.once:
        return "One time";
      case SubmissionFrequency.week_7_times:
        return "Daily";
      case SubmissionFrequency.week_3_times:
        return "3x per week";
      case SubmissionFrequency.week_2_times:
        return "2x per week";
      case SubmissionFrequency.unlimited:
        return "Unlimited";
      default:
        return "";
    }
  }

  public static createActivity(activity: ActivityDTO): ActivityCardModel {
    return new ActivityCardModel({activity});
  }

  public static createPlatformActivity(platformActivity: PlatformActivityDTO): ActivityCardModel {
    return new ActivityCardModel({platformActivity});
  }
}
