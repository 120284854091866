<template>
  <div>
    <article>
      <div class="logo">
        <img src="https://my.hc360.app/img/my-health-check360-logo-12588a28.svg" alt="My Health Check 360 Logo"/>
      </div>
      <div>
        <p style="text-align:center;">
          You can close this page.
        </p>
      </div>
    </article>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {LoadingMixin} from "@/ts/mixins/loading-mixin";
import {DefaultStoreMixin} from "@/ts/store/default/default-store-instance";

@Component({
  name: 'ConnectionCompleted',
})
export default class ConnectionCompleted extends mixins(LoadingMixin, DefaultStoreMixin) {
  mounted() {
    window.close();
  }
}
</script>

<style scoped>
body {
  font: 20px Helvetica, sans-serif; /* Keep the font styling */
  margin: 0; /* Remove default margin */
  height: 100vh; /* Full height of the viewport */
  display: flex; /* Establish flex container */
  justify-content: center; /* Center children horizontally */
  align-items: center; /* Center children vertically */
  background-color: #fff; /* Optional: Set background color if needed */
}

article {
  display: block;
  text-align: center; /* Ensures text inside article is centered */
  width: 650px; /* Set or adjust the width of the article as necessary */
  /* Removed margin: 0 auto; since flexbox takes care of centering */
}

.logo {
  width: 300px;
  margin: 0 auto; /* Center the logo image within its container */
}

a {
  color: #fff; /* Set the color of links */
  text-decoration: underline; /* Underline links by default */
}

a:hover {
  color: #3a88fd; /* Change link color on hover */
}
</style>


