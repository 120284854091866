<!-- eslint-disable max-len -->
<template>
  <div :class="{['big-icon']: big}" :style="{display: 'flex', justifyContent: 'center', alignItems: 'center'}">
    <svg
      width="60%"
      height="60%"
      :viewBox="`0 0 350 350`"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <path
        d="M111.044 240.187C117.424 246.584 128.271 246.584 134.651 240.187L322.229 52.1434C328.61 45.7472 328.61 34.8739 322.229 28.4779L299.261 5.4521C292.881 -0.943994 282.672 -0.943994 276.292 5.4521L123.167 158.958L51.0703 87.3217C44.6901 80.9255 34.4818 80.9255 28.1015 87.3217L5.13279 110.347C-1.24739 116.743 -1.24739 127.617 5.13279 134.013L111.044 240.187Z"
        fill="currentColor"
      />
    </svg>
  </div>
</template>
<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";


@Component({
  name: "CompletedIcon",
  components: {},
})

export default class CompletedIcon extends Vue {
  @Prop({default: false})
  big?: boolean;
}
</script>

<style lang="sass" scoped>

div
  color: $color-secondary-state-blue-100
  width: 48px
  height: 48px
  display: flex
  justify-content: center
  align-items: center

  .big-icon
    width: 60px
    height: 60px
</style>
