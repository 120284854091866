import {Api} from "@/ts/classes/api";
import {sessionStore} from "@/ts/instances/localstorage";
import {CachedApi} from "@/ts/classes/cached-api";
import {defaultStore} from "@/ts/store/default/default-store-instance";
import {routerFactory} from "@/ts/router/router-factory";
import {Auth} from "@/ts/classes/auth";
import {growlStore} from "@/ts/store/growl/growl-store-instance";
import {pagesPath} from "@/ts/router/pages-path";

const router = routerFactory(sessionStore);

// eslint-disable-next-line @typescript-eslint/no-use-before-define
const api: Api = new Api("", sessionStore, async() => auth.refreshToken());
const auth = new Auth(router, defaultStore, growlStore, api, pagesPath);

export {api, router};
export const cachedApi: CachedApi = new CachedApi(api, defaultStore);
