import type {TaskType} from "@/ts/types/dto/activity.dto";
import type {RewardType} from "@/ts/types/dto/reward.dto";
import type {ContentReference} from "@/ts/types/dto/content-reference.dto";


export interface ContentCategory {
  id: string;
  name: string;
}

export interface GetContentCategoryRequestDTO {
  onlyActive?: boolean;
  onlyAvailable?: boolean;
  onlyCompleted?: boolean;
}

export interface GetContentCategoryResponseDTO {
  id: string;
  name: string;
  count: number;
}

export interface ProgramLevel {
  id: string;
  name: string;
  description: string;
  rewardAmountThreshold: number;
}

export interface GetProgramResponseDTO {
  id: string;
  name: string;
  description: string;
  coverPath?: string;
  maxRewardAmount: number;
  includeCoachingSessions: boolean;
  includeBiometricScreening: boolean;
  includeHra: boolean;
  biometricScreeningStartDate?: string;
  biometricScreeningEndDate?: string;
  hraStartDate?: string;
  hraEndDate?: string;
  hraDate: string;
  hraVersion?: string;
  categories: ContentCategory[];
  currentLevel?: ProgramLevel;
  levels?: ProgramLevel[];
  startDate: string;
  endDate: string;
  biometricScreeningIsGatekeeper: boolean;
  coachingIsGatekeeper: boolean;
  hraIsGatekeeper: boolean;
}

export interface GatekeeperItem {
  id: string;
  name: string;
  description: string;
  rewardAmount: number;
  rewardType: RewardType;
  status: GatekeeperStatus;
  contentReference: ContentReference;
  endDate?: string;
  taskType?: TaskType;
  isRequired: boolean;
}

export enum GatekeeperStatus {
  pending = "pending",
  completed = "completed",
}

export type MyProgramResponse = GetProgramResponseDTO;
