import UserVerificationPage from "@/vue/pages/registration/user-veritifcation.page.vue";
import UserRegistrationPage from "@/vue/pages/registration/user-registration.page.vue";
import UserAdditionalPage from "@/vue/pages/registration/user-additional.page.vue";
import MotivationPage from "@/vue/pages/registration/motivation.page.vue";
import InterestsPage from "@/vue/pages/registration/user-interests.page.vue";
import OnboardingPage from "@/vue/pages/onboarding.page.vue";
import LoginPage from "@/vue/pages/login.page.vue";
import ResetPasswordPage from "@/vue/pages/reset-password.page.vue";
import {pagesPath} from "@/ts/router/pages-path";
import type {RouteRecordRaw} from "vue-router";
import TermsOfServicePage from "@/vue/pages/registration/terms-of-service.page.vue";
import EmailConfirmationPage from "@/vue/pages/email-confirmation.page.vue";
import PrivacyPolicyPage from "@/vue/pages/registration/privacy-policy.page.vue";
import AccountDeletedPage from "@/vue/pages/account-deleted.page.vue";
import Redirect from "@/vue/pages/sso/redirect.vue";
import Callback from "@/vue/pages/sso/callback.vue";
import ProviderAuthorization from "@/vue/pages/sso/provider-authorization.vue";
import ConnectionCompleted from "@/vue/pages/static/connection-completed.vue";

export const authorizationPages: RouteRecordRaw[] = [
  {
    path: pagesPath.auth.onboarding,
    component: OnboardingPage,
  },
  {
    path: pagesPath.auth.signIn,
    component: LoginPage,
  },
  {
    path: pagesPath.auth.logOut,
    redirect: pagesPath.auth.signIn,
  },
  {
    path: pagesPath.auth.redirect,
    component: Redirect,
  },
  {
    path: pagesPath.auth.callback,
    component: Callback,
  },
  {
    path: pagesPath.auth.providerAuthorization,
    component: ProviderAuthorization,
  },
  {
    path: pagesPath.auth.termsOfService,
    component: TermsOfServicePage,
  },
  {
    path: pagesPath.auth.privacyPolicy,
    component: PrivacyPolicyPage,
  },
  {
    path: pagesPath.auth.authBase,
    redirect: pagesPath.auth.signIn,
  },
  {
    path: pagesPath.auth.authSignUpBase,
    redirect: pagesPath.auth.verification,
  },
  {
    path: pagesPath.auth.verification,
    component: UserVerificationPage,
  },
  {
    path: pagesPath.auth.registration,
    component: UserRegistrationPage,
  },
  {
    path: pagesPath.auth.additional,
    component: UserAdditionalPage,
  },
  /*
   * {
   *   path: pagesPath.auth.companyInfo,
   *   component: CompanyInformationPage,
   * },
   */
  {
    path: pagesPath.auth.interests,
    component: InterestsPage,
  },
  {
    path: pagesPath.auth.motivation,
    component: MotivationPage,
  },
  {
    path: pagesPath.auth.resetPassword,
    component: ResetPasswordPage,
  },
  {
    path: pagesPath.auth.eMailConfirmation,
    component: EmailConfirmationPage,
  },
  {
    path: pagesPath.auth.accountSuccessfullyDeleted,
    component: AccountDeletedPage,
  },
  {
    path: pagesPath.auth.connectionCompleted,
    component: ConnectionCompleted,
  },
];
