<template>
  <protected-image-curved-frame :src="challengeCoverImage" class="cover-url"/>
</template>

<script lang="ts">
import ProtectedImageCurvedFrame from "@/vue/atoms/protected-image-curved-frame.vue";
import {Component, Vue} from "vue-property-decorator";
import challengeDefaultImage from "@/assets/img/challenge-card-icons/challenge-default-image.svg";
import type {LeaderboardChallengeDTO} from "@/ts/types/dto/leaderboard-challenge.dto";
import {DefaultState} from "@/ts/store/default/default-store-instance";

@Component({
  name: "LeaderboardChallengeCoverImage",
  components: {ProtectedImageCurvedFrame},
})
export default class LeaderboardChallengeCoverImage extends Vue {
  @DefaultState
  leaderboardChallenge!: LeaderboardChallengeDTO;

  get challengeCoverImage(): string {
    return this.leaderboardChallenge.coverPath || "";
  }

  get fallbackImage(): string {
    // TODO display fallback image
    return challengeDefaultImage;
  }
}
</script>

<style lang="sass" scoped>
.cover-url
  position: absolute
  top: 0
  right: 0
</style>
