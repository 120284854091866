<template>
  <select
    :id="id"
    :value="modelValue"
    :name="name"
    :required="required"
    :autocomplete="autocomplete"
    :disabled="disabled"
    @change="updateValue"
  >
    <option
      v-if="placeholder"
      key="placeholder"
      value=""
      disabled
      selected
      hidden
    >
      {{ placeholder }}
    </option>
    <option v-for="option in options" :key="option.name" :value="option.value">
      {{ option.name }}
    </option>
  </select>
</template>

<script lang="ts" setup>

const props = defineProps<{
  modelValue: string;
  options: {name: string; value: string}[];
  id?: string;
  required?: boolean;
  autocomplete?: string;
  placeholder?: string;
  name?: string;
  disabled?: boolean;
}>();

const emit = defineEmits(["update:modelValue"]);

const updateValue = (event: Event): void => {
  emit("update:modelValue", (event.target as HTMLInputElement)!.value);
};

</script>

<style lang="sass" scoped>
select
  @include input-field
</style>
