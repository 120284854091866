<template>
  <button-primary
    v-if="isProofActivity"
    class="history-btn"
    theme="white"
    @click="openProofHistory"
  >
    <div class="svg-icon"/>
    View Proof History
  </button-primary>
</template>

<script lang="ts" setup>
import ButtonPrimary from "@/vue/atoms/button-primary.vue";

const props = defineProps<{
    isProofActivity: boolean;
}>();

const emit = defineEmits(["open"]);

const openProofHistory = (): void => {
    emit("open");
};

</script>

<style lang="sass" scoped>
.history-btn
  display: flex
  align-self: center

.svg-icon
  width: 26px
  height: 26px
  background-color: $color-primary-hc-blue-100
  mask: url('@/assets/img/icons/external-link.svg') no-repeat center / contain
  margin-right: 10px
</style>
