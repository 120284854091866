<template>
  <div>
    <div v-if="appointment" title="Appointment confirmed">
      <navbar-page title="My Coaching Health" :back-link="$pagesPath.program.programOverview">
        <div class="description">
          <img src="@/assets/img/coaching-scheduled.svg"/>
          <h1>Scheduled Call</h1>
          <h2>You have a coaching call scheduled for <span> {{ fullDate }} </span></h2>
          <h2>Your coach will call you at your number: {{ appointment.phoneNumber }}</h2>
        </div>
        <div class="button">
          <cancel-appointment appointment-type="coaching" button-theme="dark"/>
        </div>
      </navbar-page>
    </div>
  </div>
</template>

<script lang="ts">
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {
  SchedulingCoachingState,
  SchedulingCoachingStoreMixin,
} from "@/ts/store/scheduling-coaching/scheduling-coaching-store-instance";
import type {SchedulingCoachingAppointment} from "@/ts/types/store/scheduling-coaching-store-types";
import SchedulingPage from "@/vue/templates/scheduling-page.vue";
import {
  dateToFullFormat,
  parseDate,
} from "@/ts/utils/date-pure-functions";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import {
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import {GrowlsStoreMixin} from "@/ts/store/growl/growl-store-instance";
import NavbarPage from "@/vue/templates/navbar-page.vue";
import CancelAppointment from "@/vue/molecules/cancel-appointment.vue";

@Component({
  name: 'CoachingAppointment',
  components: {
    SchedulingPage,
    NavbarPage,
    ButtonPrimary,
    CancelAppointment,
  },
})
export default class CoachingAppointment extends mixins(LoadingMixin, SchedulingCoachingStoreMixin, GrowlsStoreMixin) {
  @SchedulingCoachingState
  appointment!: SchedulingCoachingAppointment;

  get fullDate(): string | null {
    return dateToFullFormat(parseDate(this.appointment!.timestamp!));
  }
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>
.button
  display: flex
  justify-content: center

.description
  display: flex
  flex-direction: column
  align-items: center
  margin: 5rem 0 15rem

  h1
    @include Asap700
    font-size: 24px
    color: $color-primary-hc-blue-100
    margin-top: 3rem

  h2
    @include Asap400
    font-size: 16px
    color: $color-primary-hc-blue-100
    margin-top: 1rem

    span
      @include Asap700
      font-size: 16px
      color: $color-primary-hc-blue-100
      margin-top: 1rem
</style>
