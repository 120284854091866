import {getModule} from "vuex-module-decorators";
import {stateDecoratorFactory} from "@/ts/utils/decorators";
import {vueStore} from "@/ts/store/vue-store";
import {Vue} from "vue-property-decorator";
import {BiometricPhysicianFormStore} from "@/ts/store/biometric-physician-form/biometric-physician-form-store";

export const biometricPhysicianFormStore: BiometricPhysicianFormStore = getModule(
  BiometricPhysicianFormStore,
  vueStore,
);

export const BiometricPhysicianFormState = stateDecoratorFactory(
  biometricPhysicianFormStore,
);

export class BiometricPhysicianFormStoreMixin extends Vue {
  biometricPhysicianFormStore!: BiometricPhysicianFormStore;

  created(): void {
    this.biometricPhysicianFormStore = biometricPhysicianFormStore;
  }
}
