<template>
  <input-text
    id="height"
    :model-value="modelValue"
    type="number"
    :min="0"
    :max="999"
    required
    placeholder="Height"
    @update:model-value="updateValue"
  />
</template>

<script lang="ts">
import {
  Component,
  Emit,
  Prop,
  Vue,
} from "vue-property-decorator";
import InputText from "@/vue/atoms/input-text.vue";

@Component({
  components: {InputText}
})
export default class EditHeight extends Vue {
  @Prop() modelValue!: number;

  @Emit('update:modelValue')
  updateValue(value: string): number {
    return parseInt(value, 10);
  }
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>

</style>
