<template>
  <div v-if="errorMessage">
    {{ errorMessage }}
  </div>
  <div v-else>
    Authorizing with Provider...
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {LoadingMixin} from "@/ts/mixins/loading-mixin";
import {DefaultStoreMixin} from "@/ts/store/default/default-store-instance";

@Component({
  name: "provider-authorization",
})
export default class ProviderAuthorization extends mixins(LoadingMixin, DefaultStoreMixin) {
  errorMessage: string = "";

  async mounted() {
    const queryParams = new URLSearchParams(window.location.search);
    const state = queryParams.get("state");
    const redirectUri = queryParams.get("redirect_uri");

    const response = await this.$api.authorizeWithProvider();

    // Check if the response contains an authorization code
    if (response.code && state && redirectUri) {
      // Construct the redirect URL with the authorization code and state
      const redirectUrl = `${redirectUri}?code=${encodeURIComponent(response.code)}&state=${encodeURIComponent(state)}`;
      window.location.href = redirectUrl;
    } else {
      // Set an error message if no authorization code, state, or redirect URI is received
      this.errorMessage = "Authorization failed: Missing required parameters.";
    }
  }
}
</script>
