<template>
  <loading-suspense :error="error" :loading="loading">
    <navbar-page
      :title="leaderboardChallenge.name"
      :display-right-nav="false"
      :back-link="$pagesPath.program.programChallenges"
      subtitle="leaderboard challenge"
      theme="orange"
    >
      <leaderboard-challenge-available
        v-if="status === ChallengeStatus.Available"
        :participants="participants"
        @join-challenge="handleJoinChallenge"
      />
      <leaderboard-challenge-active
        v-if="status === ChallengeStatus.Active || status === ChallengeStatus.Joined"
        :ranking="ranking"
      />
      <leaderboard-challenge-completed
        v-if="status === ChallengeStatus.Completed || status === ChallengeStatus.GracePeriod"
        :ranking="ranking"
      />
    </navbar-page>
  </loading-suspense>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import NavbarPage from "@/vue/templates/navbar-page.vue";
import {DefaultGrowlError, LoadingMixin} from "@/ts/mixins/loading-mixin";
import {mixins} from "vue-class-component";
import type {
  ChallengeParticipant,
  LeaderboardChallengeDTO,
  RankingPosition,
} from "@/ts/types/dto/leaderboard-challenge.dto";
import LeaderboardChallengeAvailable from "@/vue/organisms/leaderboard-challenge/leaderboard-challenge-available.vue";
import LeaderboardChallengeActive from "@/vue/organisms/leaderboard-challenge/leaderboard-challenge-active.vue";
import LeaderboardChallengeCompleted from "@/vue/organisms/leaderboard-challenge/leaderboard-challenge-completed.vue";
import {DefaultState, DefaultStoreMixin} from "@/ts/store/default/default-store-instance";
import {GrowlsStoreMixin} from "@/ts/store/growl/growl-store-instance";
import {ChallengeStatus} from "@/ts/types/component/challenges";
import {determineChallengeStatus} from "@/ts/utils/pure-functions";

@Component({
  components: {
    NavbarPage,
    LeaderboardChallengeAvailable,
    LeaderboardChallengeActive,
    LoadingSuspense,
    LeaderboardChallengeCompleted,
  },
})
export default class LeaderboardChallenge extends mixins(
  LoadingMixin,
  DefaultStoreMixin,
  GrowlsStoreMixin,
) {
  participants!: ChallengeParticipant[];

  ranking!: RankingPosition[];

  ChallengeStatus = ChallengeStatus;

  @Prop() id!: string;

  @DefaultState
  leaderboardChallenge!: LeaderboardChallengeDTO;

  get status(): ChallengeStatus {
    return this.leaderboardChallenge.challengeStatus;
  }

  @DefaultGrowlError
  async handleJoinChallenge(): Promise<void> {
    const joinedChallenge = await this.$api.postLeaderboardChallenge(this.id);
    this.defaultStore.setLeaderboardChallenge(joinedChallenge);
    // After user joins get participants list updated
    this.participants = (
      await this.$api.getLeaderboardChallengeParticipants(this.id)
    ).items;

    this.ranking = (
      await this.$api.getLeaderboardChallengeRanking(this.id)
    ).items;
  }

  @DefaultGrowlError
  async created(): Promise<void> {
    const challenge = await this.$api.getLeaderboardChallenge(this.id);
    this.defaultStore.setLeaderboardChallenge(challenge);

    if (this.status === ChallengeStatus.Available) {
      this.participants = (
        await this.$api.getLeaderboardChallengeParticipants(this.id)
      ).items;
    }

    if (this.status !== ChallengeStatus.Available) {
      const {items} = await this.$api.getLeaderboardChallengeRanking(this.id);
      this.ranking = items;
    }
  }
}
</script>

<style lang="sass" scoped></style>
