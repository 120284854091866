import {
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import type {
  AuthTokenInfo,
  Profile,
  Program,
  RewardsTracker,
  SkippedTodos,
} from "@/ts/types/store/default-store-types";
import {sessionStore} from "@/ts/instances/localstorage";
import type {LeaderboardChallengeDTO} from "@/ts/types/dto/leaderboard-challenge.dto";
import {vueStore} from "@/ts/store/vue-store";
import type {UserInterestDTO} from "@/ts/types/dto/profile.dto";
import {Interest} from "@/ts/types/store/onboarding-store-types";

const SKIPPED_TODOS_STORAGE_NAME = "skipped-todos-unknown";

function getSkipTodoStorageName(): string {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const defaultStore: DefaultStore = getModule(DefaultStore, vueStore);
  return defaultStore.profile?.id ? SKIPPED_TODOS_STORAGE_NAME.replace("unknown", defaultStore.profile.id) : SKIPPED_TODOS_STORAGE_NAME;
}

@Module({
  name: 'default'
})
export class DefaultStore extends VuexModule {
  public profile: Profile | null = null;

  public skippedTodos: SkippedTodos | null = null;

  public rewardsTracker: RewardsTracker | null = null;

  public leaderboardChallenge: LeaderboardChallengeDTO | null = null;

  public program: Program | null = null;

  public userInterestOptions: UserInterestDTO[] | null = null;

  public accountJustDeleted: boolean = false;

  @Mutation
  initSkippedTodos(): void {
    const skippedTodos = localStorage.getItem(getSkipTodoStorageName());
    if (skippedTodos) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this.skippedTodos = JSON.parse(skippedTodos);
    }
  }

  @Mutation
  setAuthTokens(data: AuthTokenInfo | null): void {
    sessionStore.sessionToken = data?.sessionToken ?? null;
    sessionStore.refreshToken = data?.refreshToken ?? null;
    sessionStore.mfaToken = data?.mfaToken ?? null;
  }

  @Mutation
  setRewardsTracker(data: RewardsTracker): void {
    this.rewardsTracker = data;
  }

  @Mutation
  setProgram(data: Program): void {
    this.program = data;
  }

  @Mutation
  setInterestOptions(data: UserInterestDTO[]): void {
    this.userInterestOptions = data;
  }

  @Mutation
  setProfile(data: Profile): void {
    this.profile = {
      ...data,
    };
    if (this.skippedTodos?.userId !== data.id) {
      const defaultStore: DefaultStore = getModule(DefaultStore, vueStore);
      defaultStore.initSkippedTodos();
    }
    this.rewardsTracker = null;
    this.program = null;
    this.accountJustDeleted = false;
  }

  @Mutation
  skipTodo(id: string): void {
    if (this.skippedTodos && this.skippedTodos?.userId === this.profile?.id) {
      this.skippedTodos = {
        ...this.skippedTodos,
        skippedTodosIds: [...new Set([...this.skippedTodos.skippedTodosIds, id])],
      };
    } else if (this.profile) {
      this.skippedTodos = {
        userId: this.profile.id,
        skippedTodosIds: [id],
      };
    }
    localStorage.setItem(getSkipTodoStorageName(), JSON.stringify(this.skippedTodos));
  }

  @Mutation
  removeSkipTodos(): void {
    this.skippedTodos = null;
    localStorage.removeItem(getSkipTodoStorageName());
  }

  @Mutation
  setLeaderboardChallenge(data: LeaderboardChallengeDTO): void {
    this.leaderboardChallenge = data;
  }

  @Mutation
  markAccountAsDeleted(): void {
    this.accountJustDeleted = true;
  }

  @Mutation
  clearAll(clearLocalStorage = false): void {
    const defaultStore: DefaultStore = getModule(DefaultStore, vueStore);

    // logout
    defaultStore.setAuthTokens(null);

    if (clearLocalStorage) {
      // clear skipTodos in localStorage, make sure that it occurs before clearing the profile data (username needed!)
      defaultStore.removeSkipTodos();
    } else {
      this.skippedTodos = null;
    }

    this.profile = null;
    this.program = null;
    this.rewardsTracker = null;
    this.leaderboardChallenge = null;
    this.program = null;
    this.userInterestOptions = null;
  }
}
