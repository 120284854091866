<template>
  <div class="goal-container">
    <div class="goal-header">
      <completed-icon v-if="completedGoal"/>
      <nicotine-icon v-if="nicotineUse" :type="iconType"/>
      <activity-icon v-else :type="iconType"/>
      <h3>{{ displayType }}</h3>
    </div>
    <p v-if="nicotineUse">
      You set a Nicotine Quit Date!
    </p>
    <p v-else>
      You have successfully completed {{ displayProgress }}% of the days within the timeframe of your goal.
    </p>
    <div v-if="completedGoal" class="goal-details">
      <div class="detail-item">
        <div class="completed-label">
          <icon-chameleon
            :icon="CheckIcon"
            color="secondary100"
            alt="check icon"
            aria-hidden="true"
          />
          Completed on
        </div>
      </div>
      <div class="detail-item">
        <span>{{ formatDateShortMonthName(goal.endDate) }}</span>
      </div>
    </div>
    <div v-else class="goal-details">
      <div class="detail-item">
        <span class="label">Created on</span>
        <span>{{ formatDateShortMonthName(goal.createDate) }}</span>
      </div>
      <div class="detail-item">
        <span class="label">Ends on</span>
        <span>{{ formatDateShortMonthName(goal.endDate) }}</span>
      </div>
    </div>
    <div v-if="nicotineUse" class="goal-footer">
      <div class="footer-detail">
        <span>Nicotine Quit Date</span>
      </div>
      <div class="footer-detail">
        <span>Set date</span>
        <span class="detail-amount">{{ formatDateShortMonthName(goal.createDate) }}</span>
      </div>
    </div>
    <div v-else class="goal-footer">
      <div class="footer-detail">
        <span>{{ displayType }}</span>
        <span class="detail-frequency">{{ displayFrequency }}</span>
      </div>
      <div class="footer-detail">
        <span>Goal per Session</span>
        <span class="detail-amount">{{ goal.task.goal }} {{ unit }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {defineProps, computed} from "vue";
import {GoalSubmissionFrequency, GoalStatus, type Goal} from "@/ts/types/dto/goal.dto";
import ActivityIcon from "@/vue/atoms/activity-icon.vue";
import CompletedIcon from "@/vue/atoms/completed-icon.vue";
import NicotineIcon from "@/vue/atoms/nicotine-icon.vue";
import {TaskTypeNames} from "@/ts/types/component/tracking-list";
import {TaskType} from "@/ts/types/dto/activity.dto";
import IconChameleon from "@/vue/atoms/icon-chameleon.vue";
import CheckIcon from "@/assets/img/checkmark.svg";
import {formatDateShortMonthName} from "@/ts/utils/date-pure-functions";

const props = defineProps({
    goal: {
        type: Object as () => Goal,
        required: true,
    },
});

const frequencyTypes = {
    [GoalSubmissionFrequency.once]: "Once",
    [GoalSubmissionFrequency.week_7_times]: "Daily",
    [GoalSubmissionFrequency.week_3_times]: "3x per week",
    [GoalSubmissionFrequency.week_2_times]: "2x per week",
};

const unit = computed(() => {
  switch (props.goal.task.type) {
    case "biking_distance":
    case "hiking_distance":
    case "jogging_distance":
      return "miles";
    case "steps":
      return "steps";
    case "sleep_time":
      return "hours";
    case "nicotine_use":
      return "units";
    case "heart_rate":
      return "beats per minute";
    case "calorie_intake":
      return "calories";
    case "weight":
      return "lbs.";
    case "water_drinking_volume":
      return "fl.oz.";
    default:
      return "minutes";
  }
});

const displayType = computed(() => TaskTypeNames[props.goal.task.type] || "Unknown Activity");
const displayFrequency = computed(() => frequencyTypes[props.goal.submissionFrequency] || "Unknown Frequency");
const displayProgress = computed(() => `${props.goal.progress.toFixed()}`);


const iconType = computed(() => {
    return props.goal.task?.type ?? "generic";
});

const nicotineUse = computed(() => {
    return props.goal.task?.type == TaskType.nicotine_use;
});

const completedGoal = computed(() => {
    return props.goal.status == GoalStatus.completed;
});

// redundant
const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
};
</script>

<style lang="sass" scoped>
.goal-container
  background-color: white
  font-size: 16px
  line-height: 24px
  color: $color-primary-hc-blue-100
  @include Roboto400
  padding: 1rem
  border-radius: 10px
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)
  width: 100%
  margin-bottom: 1rem

.goal-header
  display: flex
  align-items: center

  h3
    margin-left: 8px

.goal-details
  display: flex
  justify-content: space-between
  background-color: $color-secondary-state-blue-10
  border-radius: 8px

.detail-item
  display: flex
  flex-direction: column
  margin: 8px

.completed-label
  display: flex
  font-size: 0.8rem

.label
  font-size: 0.8rem

.goal-footer
  display: flex
  justify-content: space-between
  margin-top: 1rem
  border: 2px solid #eee
  border-radius: 8px

.footer-detail
  display: flex
  flex-direction: column
  margin: 8px
  font-weight: bold

.detail-frequency
  font-weight: normal
  color: #888

.detail-amount
  align-self: flex-end
  font-size: 0.8rem

</style>

