export interface QuestionOption {
  id: string;
  text: string;
  isCorrectAnswer: boolean;
}

export interface QuizQuestion {
  id: string;
  stem: string;
  minOptions: number;
  maxOptions: number;
  options: QuestionOption[];
}

export interface HRASurveyConsent {
  consentText: string;
  consentGivenText: string;
  isConsentGiven: boolean;
}

export enum HRASurveyQuestionType {
  demographic = "Demographic",
  standard = "Standard",
  custom = "Custom",
}

export interface HRAQuestionDependsOnQuestionSelections {
  questionType: HRASurveyQuestionType;
  questionId: number;
  selectionValues: string[];
}

export enum HRASurveyAnswerType {
  selection = "Selection",
  text = "Text",
}

export enum HRASurveyAnswerSelectionType {
  single = "Single",
  multiple = "Multiple",
}

export interface HRAFollowUpQuestion {
  questionType: HRASurveyQuestionType;
  questionId: number;
}

export interface HRATakingMedicationsQuestionChoiceDetail {
  selection: string;
  value: boolean;
  selected: boolean;
}

export interface HRATakingMedicationsQuestion {
  question: string;
  isRequired: boolean;
  answerType: HRASurveyAnswerType;
  selectionType: HRASurveyAnswerSelectionType;
  selections: HRATakingMedicationsQuestionChoiceDetail[];
  answer: boolean;
}

export interface HRAQuestionChoiceDetail {
  selection: string;
  value: string;
  selected: boolean;
  followUpQuestion: HRAFollowUpQuestion;
  takingMedicationsQuestion: HRATakingMedicationsQuestion;
}

export interface HRASurveyQuestionDetail {
  selected: unknown;
  type: HRASurveyQuestionType;
  id: number;
  question: string;
  isRequired: boolean;
  isDependentQuestion: boolean;
  dependsOnQuestionSelections: HRAQuestionDependsOnQuestionSelections;
  answerType: HRASurveyAnswerType;
  selectionType: HRASurveyAnswerSelectionType;
  selections: HRAQuestionChoiceDetail[];
  textValue: string;
  answers: string[];
}

export interface HRASurveyCompletionUrl {
  prompt: string;
  url: string;
}

export interface HRASurvey {
  id: string;
  isAnswersCompleted: boolean;
  dateCompleted: string;
  surveyConsent: HRASurveyConsent;
  questions: HRASurveyQuestionDetail[];
  surveyCompletionUrls: HRASurveyCompletionUrl[];
}

export interface HRASummary {
  firstName: string;
  id: string;
  dateCompleted?: string | null;
  available?: boolean;
  screeningResultId?: number | null;
  reportsAvailable?: boolean;
  faxingAvailable?: boolean;
  surveyDeadline?: string | null;
}

export interface HRASurveyAnswer {
  surveySessionId: string;
  questionType: HRASurveyQuestionType;
  questionId: number;
  answers: string[];
  isTakingMedications: boolean | null;
}
