<template>
  <div class="main">
    <div class="description">
      <img src="@/assets/img/pathway-icons/pathway-quiz-done.svg" alt="celebration-image"/>
      <h2 class="description-title">
        Congratulations!
      </h2>
      <p class="description-body">
        You passed the quiz of the <b>"{{ pathwayResponse.title }}"</b> pathway module
      </p>
      <p v-if="pathwayResponse.reward" class="reward-type">
        + {{ rewardType }}
      </p>
      <button-link :to="$pagesPath.main.home" class="button" theme="white">
        {{ buttonText }}
      </button-link>
    </div>
  </div>
</template>
<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import type {GetPathwayResponseDTO} from "@/ts/types/dto/pathway.dto";
import {getRewardAmountFormatted} from "@/ts/utils/pure-functions";
import ButtonLink from "@/vue/atoms/button-link.vue";

@Component({
  components: {
    ButtonLink,
    ButtonPrimary
  }
})
export default class PathwayCongratulationsPage extends Vue {
  @Prop()
  pathwayResponse!: GetPathwayResponseDTO;

  get buttonText(): string {
    return this.pathwayResponse.reward ? "Collect reward" : "Got it";
  }

  get rewardType(): string {
    return getRewardAmountFormatted(this.pathwayResponse.reward);
  }
}
</script>

<style lang="sass" scoped>
.main
  background-color: $color-secondary-state-blue-100

img
  margin-top: 154px

.description
  color: $color-white
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

.description-body
  @include Roboto400
  font-size: 16px
  margin-top: 7px
  margin-bottom: 0

  b
    @include Roboto700

.description-title
  @include Asap700
  color: $color-white
  font-size: 28px
  margin-top: 54px
  margin-bottom: 0

.button
  margin-top: 108px
  margin-bottom: 125px

.reward-type
  margin-top: 72px
  margin-bottom: 0
  @include Asap700
  font-size: 40px
  color: $color-white
</style>
