<template>
  <alert-icon-card
    v-if="showCurrentlyNotAvailable"
    theme="error"
  >
    <div class="text">
      Currently not available
    </div>
  </alert-icon-card>
</template>

<script lang="ts">
import {Component, Vue, Prop} from "vue-property-decorator";
import AlertIconCard from "@/vue/atoms/alert-icon-card.vue";
import type {MyProgramResponse} from "@/ts/types/dto/program.dto";
import {hasProgramBioscreeningAvailable, hasProgramBioscreeningUnavailableWithoutDates} from "@/ts/utils/my-health/program-bioscreening";
import {isBioscreeningUnavailable} from "@/ts/utils/my-health/bioscreening";
import type {
  BiometricScreeningAppointment,
  BiometricScreeningAvailable,
  BiometricScreeningStatus,
} from "@/ts/types/dto/biometric-screening.dto";

@Component({
  name: "CurrentlyNotAvailableCard",
  components: {AlertIconCard}
})
export default class CurrentlyNotAvailableCard extends Vue {
  @Prop({default: null})
  biometricStatus!: BiometricScreeningStatus | null;

  @Prop({default: null})
  appointment!: BiometricScreeningAppointment | null;

  @Prop()
  program!: MyProgramResponse;

  @Prop({default: null})
  biometricAvailable!: BiometricScreeningAvailable | null;

  get hasProgramBioscreeningAvailable(): boolean {
    return hasProgramBioscreeningAvailable(this.program);
  }

  get hasProgramBioscreeningUnavailableWithoutDates(): boolean {
    return hasProgramBioscreeningUnavailableWithoutDates(this.program);
  }

  get isBioscreeningUnavailable(): boolean {
    return isBioscreeningUnavailable({
      biometricAvailable: this.biometricAvailable,
      biometricStatus: this.biometricStatus,
      appointment: this.appointment,
    });
  }

  get showCurrentlyNotAvailable(): boolean {
    return (this.hasProgramBioscreeningAvailable || this.hasProgramBioscreeningUnavailableWithoutDates) && this.isBioscreeningUnavailable;
  }
}
</script>

<style lang="sass" scoped>
.text
  @include Roboto400
  color: $color-primary-hc-blue-100
  font-size: 14px
  letter-spacing: 0.2px
  line-height: 22px
  margin: 0 0 4px
</style>
