<template>
  <div class="page-onboarding">
    <hc-logo/>
    <onboarding-card-list class="container"/>
    <button-link
      :to="$pagesPath.auth.signIn"
      class="button-primary"
    >
      Start your journey!
    </button-link>
    <div class="privacy">
      <span class="privacy-text">Learn more about our </span>
      <router-link :to="$pagesPath.auth.privacyPolicy" class="privacy-text">
        Privacy Policy
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import {Component} from "vue-property-decorator";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import OnboardingCardList from "@/vue/organisms/onboarding-card-list.vue";
import HcLogo from "@/vue/molecules/hc-logo.vue";
import {mixins} from "vue-class-component";
import {mixinClassBodyFactory} from "@/ts/mixins/bg-mixin";
import ButtonLink from "@/vue/atoms/button-link.vue";

@Component({
  name: "OnboardingPage",
  components: {
    ButtonLink,
    ButtonPrimary,
    OnboardingCardList,
    HcLogo,
  },
})
export default class OnboardingPage extends mixins(mixinClassBodyFactory("global-onboarding-bg-color")) {
}
</script>

<style lang="sass" scoped>
.page-onboarding
  display: flex
  flex-direction: column
  justify-content: center
  padding-top: 80px
  height: 100%
  width: 100%

.button-primary
  width: 260px
  // design
  margin: 0 auto
  margin-top: 97px
  margin-bottom: 40px

.privacy
  display: flex
  justify-content: center
  align-items: center
  margin-bottom: 25px

  span
    margin-right: 10px

.privacy-text
  align-items: center
</style>
