<template>
  <profile-link name="Profile">
    <profile-link-edit/>

    <profile-button :hide-arrow="true" :grow-to-available-space="true">
      <template #icon>
        <img src="@/assets/img/my-interests.svg" alt="interests" aria-hidden="true"/>
      </template>
      My Interests
      <template #description>
        <div v-if="profile.interests?.length" class="interest-list-wrapper">
          <interest-list
            :edit-mode="false"
            :interests="profile.interests"
            :float-left="true"
          />
        </div>
        <span v-else>Change your personal information</span>
      </template>
    </profile-button>
  </profile-link>
</template>

<script lang="ts" setup>
import ProfileLinkEdit from "@/vue/organisms/profile/link/profile-link-edit.vue";
import ProfileLink from "@/vue/templates/profile-link.vue";
import InterestList from "@/vue/molecules/interest-list.vue";
import {DefaultStore} from "@/ts/store/default/default-store";
import {getModule} from "vuex-module-decorators";
import {vueStore} from "@/ts/store/vue-store";
import {computed} from "vue";
import type {Profile} from "@/ts/types/store/default-store-types";
import ProfileButton from "@/vue/organisms/profile/profile-button.vue";

const defaultStore: DefaultStore = getModule(DefaultStore, vueStore);
const profile = computed((): Profile => {
  return defaultStore.profile!;
});

</script>

<style lang="sass" scoped>
.interest-list-wrapper
  padding-top: 5px
</style>
