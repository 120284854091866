<template>
  <div class="page-reset-password">
    <div class="image-box">
      <img src="@/assets/img/my-health-check360-logo.svg" alt="My Health Check 360 Logo"/>
    </div>
    <loading-suspense :loading="loading" :error="error">
      <!-- TODO: Handle invalid token page with a proper design -->
      <div class="valid-token-wrapper">
        <h3 class="headline">
          Change your password
        </h3>
        <password-rules class="password-rules-custom"/>
        <form class="fields-group" @submit.prevent="resetPassword">
          <password-change
            class="password-change-custom"
            password-label="New Password"
            confirm-password-label="Confirm Password"
            password-placeholder="Enter your new password"
            confirm-password-placeholder="Confirm your new password"
            @password-changed="onPasswordChanged"
            @confirm-password-changed="onConfirmPasswordChanged"
          />
          <error-text :error="error"/>
          <button-primary
            class="submit"
            type="submit"
            text="Submit new password"
          />
        </form>
      </div>
    </loading-suspense>
  </div>
</template>

<script lang="ts">
import {Component} from "vue-property-decorator";
import HcLogo from "@/vue/molecules/hc-logo.vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import ErrorText from "@/vue/atoms/error-text.vue";
import InputText from "@/vue/atoms/input-text.vue";
import FieldSet from "@/vue/molecules/field-set.vue";
import InputPassword from "@/vue/atoms/input-password.vue";
import {mixins} from "vue-class-component";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import {DefaultStoreMixin} from "@/ts/store/default/default-store-instance";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import PasswordChange from "@/vue/molecules/password-change.vue";
import PasswordRules from "@/vue/molecules/password-rules.vue";

@Component({
  name: "LoginPage",
  components: {
    HcLogo,
    ButtonPrimary,
    ErrorText,
    InputText,
    FieldSet,
    InputPassword,
    LoadingSuspense,
    PasswordChange,
    PasswordRules,
  },
})
export default class LoginPage extends mixins(LoadingMixin, DefaultStoreMixin) {
  password: string = "";

  passwordConfirmation: string = "";

  onPasswordChanged(password: string): void {
    this.password = password;
  }

  onConfirmPasswordChanged(confirmPassword: string): void {
    this.passwordConfirmation = confirmPassword;
  }

  @DefaultGrowlError
  async created(): Promise<void> {
    const recoveryToken = this.$route.query.token;

    await this.$api.checkPasswordRecoveryToken({
      token: recoveryToken as string,
    });
  }

  @DefaultGrowlError
  async resetPassword(): Promise<void> {
    const res = await this.$api.resetPassword({
      token: this.$route.query.token as string,
      new: this.password,
      newConfirmation: this.passwordConfirmation,
    });

    void this.$router.push(this.$pagesPath.auth.signIn);
  }
}
</script>

<style lang="sass" scoped>

.page-reset-password
  max-width: 800px
  margin: 0 auto
  padding: 30px 20px

.image-box
  align-items: center
  display: flex
  flex-direction: column
  margin-top: 70px
  margin-bottom: 100px

.valid-token-wrapper
  align-items: center
  display: flex
  flex-direction: column

.headline
  @include Asap700
  color: $color-primary-hc-blue-100
  font-size: 28px
  line-height: 36px
  margin-bottom: 33px
  text-align: center

.password-rules-custom
  margin-bottom: 100px
  text-align: center
  max-width: 500px

.fields-group
  align-items: center
  display: flex
  flex-direction: column
  justify-content: center
  margin-bottom: 40px

.password-change-custom
  max-width: max-container-width(24px, $container-width, 2)
  @include flex-container(24px, $container-width, 2)
  align-items: flex-start
  justify-content: center

.submit
  margin-top: 60px
  width: $container-width

</style>
