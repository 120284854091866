<template>
  <div class="author">
    <div>
      <user-avatar
        :avatar="image"
        :is-me="true"
        size="xsmall"
        :firstname="firstname"
        :lastname="lastname"
      />
    </div>
    <div>
      <p class="label">
        {{ label }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed} from "vue";
import UserAvatar from "@/vue/atoms/user-avatar.vue";

const props = defineProps<{
  image: string;
  firstname: string;
  lastname: string;
  isMe?: boolean;
}>();

const label = computed(() => {
  return props.isMe ? "by you" : `${props.firstname} ${props.lastname}`;
});

</script>
<style lang="sass" scoped>
.author
  display: flex
  gap: 8px
  align-items: center

.label
  @include Roboto600
  font-size: 10px
  line-height: 16px
  color: $color-tertiary-orange-100
  text-transform: uppercase

</style>
