enum SignUpPath {
  verification = "/auth/sign-up/verification",
  registration = "/auth/sign-up/registration",
  additional = "/auth/sign-up/additional",
  motivation = "/auth/sign-up/motivation",
  interests = "/auth/sign-up/interests",
  authSignUpBase = "/auth/sign-up",
  authBase = "/auth",
  signIn = "/auth/sign-in",
  logOut = "/auth/logout",
  redirect = "/auth/redirect",
  callback = "/auth/callback",
  providerAuthorization = "/auth/provider-authorization",
  onboarding = "/onboarding",
  resetPassword = "/reset-password",
  termsOfService = "/tos",
  privacyPolicy = "/privacy",
  eMailConfirmation = "/email-confirm",
  accountSuccessfullyDeleted = "/auth/account-deleted",
  connectionCompleted = "/connection-completed",
}

enum ProfilePath {
  main = "/profile",
  edit = "/profile/edit",
  settings = "/profile/settings",
  companyBenefits = "/profile/company-benefits",
  myFriends = "/profile/my-friends",
  myConnectedDevices = "/profile/my-connected-devices",
  myPath = "/profile/my-path",
  goals = "/profile/my-path/goals",
  myProgress = "/profile/my-path/my-progress",
  myStepsProgress = "/profile/my-path/my-progress/steps",
  myMinutesOfActivityProgress = "/profile/my-path/my-progress/minutes-activity",
  myBadges = "/profile/my-badges",
}

enum MainPath {
  changePassword = "/settings/change-password",
  rewards = "/rewards",
  transactionsHistory = "/transaction-history",
  home = "/",
  todo = "/todo",
}

enum ProgramPath {
  program = "/program/",
  programPathway = "/program/pathway",
  programOverview = "/program/overview",
  programChallenges = "/program/challenges",
  programActivities = "/program/activities",
}

enum CommunityPath {
  community = "/community/",
  communityFeed = "/community/feed",
  communityFriends = "/community/friends",
  communityGroups = "/community/groups",
}

enum PathwayPath {
  pathwayStart = "/pathway/:id/start",
  pathway = "/pathway/:id",
  pathwayQuiz = "/pathway/:id/quiz/:number",
  pathWayCongratulations = "/pathway/:id/congratulations",
  pathwayTask = "/pathway/:id/task/:number",
  pathwayTaskFinish = "/pathway/:id/finish",
  pathwayFailed = "/pathway/:id/failed",
}

enum ChallengePath {
  challenge = "/challenge/:id",
}

enum CoachingPath {
  parent = "/coaching/",
  info = "/coaching/info",
  coachingSchedule = "/coaching/schedule",
  appointmentConfirmed = "/coaching/appointment-confirmed",
}

enum MyHealthPath {
  myhealth = "/my-health/",
  myhealthCheckScore = "/my-health/healthcheck",
  myhealthCare360 = "/my-health/care-360",
  myhealthPastResults = "/my-health/past-results",
}

enum BiometricScheduling {
  parent = "/biometric-scheduling/",
  personalInformation = "/biometric-scheduling/personal-information",
  location = "/biometric-scheduling/location",
  time = "/biometric-scheduling/time",
  appointmentConfirmed = "/biometric-scheduling/appointment-confirmed",
}

enum BiometricScreening {
  faxResults = "/biometric-screening/:id/fax-results",
}

enum HealthRiskAssessment {
  parent = "/health-risk-assessment/",
  startHra = "/health-risk-assessment/start",
  quizHra = "/health-risk-assessment/:id/quiz",
  quizOverviewHra = "/health-risk-assessment/quiz-overview",
  finishHra = "/health-risk-assessment/congratulations",
}
enum PhysicianForm {
  physicianForm = "/physician-form/",
  downloadForm = "/physician-form/download-form",
  uploadForm = "/physician-form/upload-form",
  examinationInformation = "/physician-form/examination-information",
}

export interface PagesPath {
  auth: typeof SignUpPath;
  main: typeof MainPath;
  pathway: typeof PathwayPath;
  program: typeof ProgramPath;
  coaching: typeof CoachingPath;
  challenge: typeof ChallengePath;
  myHealthPath: typeof MyHealthPath;
  biometricScheduling: typeof BiometricScheduling;
  healthRiskAssessment: typeof HealthRiskAssessment;
  physicianForm: typeof PhysicianForm;
  community: typeof CommunityPath;
  profile: typeof ProfilePath;
  biometricScreening: typeof BiometricScreening;
}

export const pagesPath: PagesPath = {
  auth: SignUpPath,
  main: MainPath,
  pathway: PathwayPath,
  program: ProgramPath,
  coaching: CoachingPath,
  challenge: ChallengePath,
  myHealthPath: MyHealthPath,
  biometricScheduling: BiometricScheduling,
  healthRiskAssessment: HealthRiskAssessment,
  physicianForm: PhysicianForm,
  community: CommunityPath,
  profile: ProfilePath,
  biometricScreening: BiometricScreening,
};
