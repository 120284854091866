<template>
  <label :class="className"><img v-if="icon" :src="icon" alt="Label Icon"/>{{ value }}</label>
</template>
<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import eye from "@/assets/img/eye.svg";
import task from "@/assets/img/task.svg";
import clock from "@/assets/img/challenge-card-icons/clock-icon.svg";
import {PathwayStatus} from "@/ts/types/dto/pathway.dto";

@Component({
  name: "PathwayLabelWithIcon",
})
export default class PathwayLabelWithIcon extends Vue {
  @Prop()
  value!: string;

  @Prop()
  iconType!: PathwayStatus;

  get className(): string {
    return `label label--${this.iconType}`;
  }

  get icon(): string | null {
    const TaskIconMap: Record<PathwayStatus, string | null> = {
      [PathwayStatus.pending]: task,
      [PathwayStatus.viewed]: eye,
      [PathwayStatus.waiting]: clock,
      [PathwayStatus.completed]: null,
    };

    return TaskIconMap[this.iconType] ?? null;
  }
}
</script>

<style lang="sass" scoped>
.label
  display: flex
  align-items: center
  color: $color-primary-hc-blue-50
  @include Roboto400

  &--completed
    color: $color-secondary-state-blue-100

  img
    margin-right: 0.5rem
</style>
