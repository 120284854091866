<template>
  <alert-icon-card
    v-if="showSuccess"
    theme="success"
    title="Thank you for sending your results!"
  >
    <div class="text">
      Data submitted on <span class="bold">{{ dateCompleted }}</span>
    </div>
  </alert-icon-card>

  <alert-icon-card
    v-if="showAvailableInFuture"
    theme="info"
  >
    <div class="text">
      The survey will be available <span class="bold">{{ hraStartDate }}</span>
    </div>
  </alert-icon-card>

  <alert-icon-card
    v-if="showDeadline"
    theme="info"
  >
    <div class="text">
      The deadline to take the survey is <span class="bold">{{ hraEndDate }}</span>
    </div>
  </alert-icon-card>

  <alert-icon-card
    v-if="showAvailable"
    theme="info"
  >
    <div class="text">
      The process will take about <span class="bold">3-5 minutes</span>.
      Progress will <span class="bold">not be saved</span> if you abandon it midway.
    </div>
  </alert-icon-card>

  <alert-icon-card
    v-if="showUnavailable"
    theme="error"
  >
    <div class="text">
      Currently not available
    </div>
  </alert-icon-card>

  <alert-icon-card
    v-if="showDeadlineReached"
    theme="info"
  >
    <div class="text">
      The time for completing the survey is over since the {{ hraEndDate }}.
      You can not take the survey anymore. Please reach out to your contact person for more information.
    </div>
  </alert-icon-card>
</template>

<script lang="ts">
import {Component, Vue, Prop} from "vue-property-decorator";
import AlertIconCard from "@/vue/atoms/alert-icon-card.vue";
import {formatDate, formatDateShortMonthName} from "@/ts/utils/date-pure-functions";
import type {HRASummary} from "@/ts/types/dto/health-risk-assessment.dto";
import type {MyProgramResponse} from "@/ts/types/dto/program.dto";
import {isHraCompleted, isHraAvailableAndNotCompleted, isHraUnavailable} from "@/ts/utils/my-health/hra";
import {
  hasProgramHraStartDateInFuture,
  hasProgramHraEndDateInFuture,
  hasProgramHraAvailable,
  hasProgramHraEndDateInPast,
  hasProgramHraAvailableWithoutDates,
  hasProgramHraAvailableWithoutEndDate,
} from "@/ts/utils/my-health/program-hra";

@Component({
  name: "HealthRiskAssessmentAlert",
  components: {
    AlertIconCard
  }
})
export default class HealthRiskAssessmentAlert extends Vue {
  @Prop({default: null})
  hraSummary!: HRASummary | null;

  @Prop({default: null})
  program!: MyProgramResponse | null;

  get isHraCompleted(): boolean {
    return isHraCompleted(this.hraSummary);
  }

  get hasProgramHraStartDateInFuture(): boolean {
    return hasProgramHraStartDateInFuture(this.program);
  }

  get hasProgramHraEndDateInFuture(): boolean {
    return hasProgramHraEndDateInFuture(this.program);
  }

  get hasProgramHraEndDateInPast(): boolean {
    return hasProgramHraEndDateInPast(this.program);
  }

  get isHraAvailableAndNotCompleted(): boolean {
    return isHraAvailableAndNotCompleted(this.hraSummary);
  }

  get hasProgramHraAvailable(): boolean {
    return hasProgramHraAvailable(this.program);
  }

  get hasProgramHraAvailableWithoutDates(): boolean {
    return hasProgramHraAvailableWithoutDates(this.program);
  }

  get hasProgramHraAvailableWithoutEndDate(): boolean {
    return hasProgramHraAvailableWithoutEndDate(this.program);
  }

  get isHraUnavailable(): boolean {
    return isHraUnavailable(this.hraSummary);
  }

  get isHraNotCompleted(): boolean {
    return !this.isHraCompleted;
  }

  get dateCompleted(): string | null {
    if (this.hraSummary?.dateCompleted) {
      return formatDateShortMonthName(this.hraSummary.dateCompleted);
    }
    return null;
  }

  get hraStartDate(): string | null {
    if (this.program?.hraStartDate) {
      return formatDateShortMonthName(this.program.hraStartDate);
    }
    return null;
  }

  get hraEndDate(): string | null {
    if (this.hraSummary?.surveyDeadline) {
      return formatDateShortMonthName(this.hraSummary?.surveyDeadline);
    }
    return null;
  }

  get showSuccess(): boolean {
    return this.isHraCompleted;
  }

  get showAvailableInFuture(): boolean {
    return this.hasProgramHraStartDateInFuture;
  }

  get showDeadline(): boolean {
    return this.hasProgramHraAvailable &&
      this.hasProgramHraEndDateInFuture &&
      this.isHraAvailableAndNotCompleted;
  }

  get showDeadlineReached(): boolean {
    return this.hasProgramHraEndDateInPast && this.isHraNotCompleted;
  }

  get showAvailable(): boolean {
    return this.hasProgramHraAvailableWithoutEndDate && this.isHraAvailableAndNotCompleted;
  }

  get showUnavailable(): boolean {
    return this.hasProgramHraAvailableWithoutDates || this.isHraUnavailable;
  }
}
</script>
<style lang="sass" scoped>
.text
  @include Roboto400
  color: $color-primary-hc-blue-100
  font-size: 14px
  letter-spacing: 0.2px
  line-height: 22px
  margin: 0 0 4px

.bold
  font-weight: 700
</style>

