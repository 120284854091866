<template>
  <div class="item">
    <img :src="image" :alt="title"/>
    <div>
      <p class="title">
        {{ title }}
      </p>
      <p class="text">
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";

@Component({})
export default class ProgramInfo extends Vue {
  @Prop()
  title!: string;

  @Prop()
  image!: string;

  @Prop()
  text!: string;
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>
.item
  display: flex
  gap: 8px

.title
  @include Roboto400
  color: $color-primary-hc-blue-100
  margin: 0
  font-size: 14px
  line-height: 22px
  text-align: left

.text
  @include Roboto600
  color: $color-secondary-state-blue-100
  margin: 0
  font-size: 14px
  line-height: 22px
  text-align: left
</style>
