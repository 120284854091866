<template>
  <div class="like">
    <button
      class="btn"
      :aria-label="ariaLabel"
      :aria-pressed="modelValue"
      @click="onClickLike"
    >
      <img :src="modelValue ? likeFilled : like" aria-hidden="true"/>
    </button>
    <span
      class="text-body2"
      aria-hidden="true"
      :class="{'text-secondary-blue-100': modelValue, 'text-primary-blue-100': !modelValue}"
    >
      {{ numberOfLikesStr }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import {computed, ref} from "vue";
import likeFilled from "@/assets/img/like-filled.svg";
import like from "@/assets/img/like.svg";

const props = defineProps<{
  modelValue: boolean;
  numberOfLikes: number;
  ariaLabel?: string;
}>();

const emit = defineEmits(["update:modelValue"]);

const numberOfLikesStr = computed((): string => {
  const count = props.numberOfLikes ?? 0;
  return count < 1 ? "Like" : count.toString();
});

const onClickLike = (): void => {
  emit("update:modelValue", !props.modelValue);
};

const ariaLabel = computed((): string => {
  return `${props.ariaLabel || "I like it"}. This post has currently ${props.numberOfLikes ?? 0} likes.`;
});

</script>

<style lang="sass" scoped>
.like
  display: flex
  align-items: center

.btn
  background: transparent
  border: none
  padding: 0
  margin: 0 4px 0 0

</style>
