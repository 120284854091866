<template>
  <loading-suspense :error="error" :loading="loading">
    <router-view/>
  </loading-suspense>
</template>

<script lang="ts">
import {Component} from "vue-property-decorator";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import {mixins} from "vue-class-component";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import LoadingBar from "@/vue/atoms/loading-bar.vue";
import {
  SchedulingBiometricState,
  SchedulingBiometricStoreMixin,
} from "@/ts/store/scheduling-biometric/scheduling-biometric-store-instance";
import type {SchedulingBiometricAppointment} from "@/ts/types/store/scheduling-biometric-store-types";

@Component({
  name: 'BiometricSchedulingParentPage',
  components: {LoadingBar, LoadingSuspense},
})
export default class BiometricSchedulingParentPage extends mixins(LoadingMixin, SchedulingBiometricStoreMixin) {
  @SchedulingBiometricState
  appointment!: SchedulingBiometricAppointment;

  @DefaultGrowlError
  async created(): Promise<void> {
    this.schedulingBiometricStore.setAppointment(null);
    const appointment = await this.$api.getBiometricScreeningScheduleAppointment();
    this.schedulingBiometricStore.setAppointment(appointment);
    if (appointment) {
      void this.$router.push(this.$pagesPath.biometricScheduling.appointmentConfirmed);
    }
  }
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>
</style>
