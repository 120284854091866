<template>
  <div :class="className">
    <div class="top">
      <label v-if="label" :for="id">{{ label }}</label>
      <slot name="right"/>
    </div>
    <slot :id="id"/>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";

@Component({
  name: "FieldSet",
})
export default class FieldSet extends Vue {
  @Prop({default: ""})
  label!: string;

  @Prop()
  id!: string;

  @Prop()
  full!: boolean;

  get className(): string {
    return this.full ? "wrapper full" : "wrapper";
  }
}
</script>

<style lang="sass" scoped>
.wrapper
  display: flex
  // this is required on mobile apps for every form it's used in
  flex-direction: column

  :deep(>:nth-child(2))
    margin-top: 4px

label
  @include Asap700
  color: $color-primary-hc-blue-100

.slot
  margin-top: 4px

.top
  display: flex
  flex-direction: row
  justify-content: space-between

.full
  width: 100%
  @include max-device(md)
    width: 330px
</style>
