import type {MyProgramResponse} from "@/ts/types/dto/program.dto";
import {isTimestampInFuture} from "@/ts/utils/date-pure-functions";

export function hasProgramBioscreening(program: MyProgramResponse | null): boolean {
  return Boolean(program?.includeBiometricScreening);
}

export function hasProgramBioscreeningStartDate(program: MyProgramResponse | null): boolean {
  return Boolean(program?.biometricScreeningStartDate);
}

export function hasProgramBioscreeningEndDate(program: MyProgramResponse | null): boolean {
  return Boolean(program?.biometricScreeningEndDate);
}

export function hasProgramBioscreeningStartDateInFuture(program: MyProgramResponse | null): boolean {
  return Boolean(
    hasProgramBioscreeningStartDate(program) &&
    isTimestampInFuture(program!.biometricScreeningStartDate!),
  );
}

export function hasProgramBioscreeningStartDateInPast(program: MyProgramResponse | null): boolean {
  return Boolean(
    hasProgramBioscreeningStartDate(program) &&
    !isTimestampInFuture(program!.biometricScreeningStartDate!),
  );
}

export function hasProgramBioscreeningEndDateInFuture(program: MyProgramResponse | null): boolean {
  return Boolean(
    hasProgramBioscreeningEndDate(program) &&
    isTimestampInFuture(program!.biometricScreeningEndDate!),
  );
}

export function hasProgramBioscreeningEndDateInPast(program: MyProgramResponse | null): boolean {
  return Boolean(
    hasProgramBioscreeningEndDate(program) &&
    !isTimestampInFuture(program!.biometricScreeningEndDate!),
  );
}

export function hasProgramOnlyBioscreeningStartDate(program: MyProgramResponse | null): boolean {
  return Boolean(
    hasProgramBioscreeningStartDate(program) &&
    !hasProgramBioscreeningEndDate(program),
  );
}

export function hasProgramOnlyBioscreeningEndDate(program: MyProgramResponse | null): boolean {
  return Boolean(
    !hasProgramBioscreeningStartDate(program) &&
    hasProgramBioscreeningEndDate(program),
  );
}

export function hasProgramNoBioscreeningDates(program: MyProgramResponse | null): boolean {
  return Boolean(
    !hasProgramBioscreeningStartDate(program) &&
    !hasProgramBioscreeningEndDate(program),
  );
}

export function hasProgramBioscreeningAvailableWithoutDates(program: MyProgramResponse | null): boolean {
  return Boolean(
    hasProgramBioscreening(program) &&
    hasProgramNoBioscreeningDates(program),
  );
}

export function hasProgramBioscreeningAvailableWithOnlyStartDate(program: MyProgramResponse | null): boolean {
  return Boolean(
    hasProgramBioscreening(program) &&
    hasProgramOnlyBioscreeningStartDate(program) &&
    hasProgramBioscreeningStartDateInPast(program),
  );
}

export function hasProgramBioscreeningAvailableWithOnlyEndDate(program: MyProgramResponse | null): boolean {
  return Boolean(
    hasProgramBioscreening(program) &&
    hasProgramOnlyBioscreeningEndDate(program) &&
    hasProgramBioscreeningEndDateInFuture(program),
  );
}

export function hasProgramBioscreeningAvailableWithDates(program: MyProgramResponse | null): boolean {
  return Boolean(
    hasProgramBioscreening(program) &&
    hasProgramBioscreeningStartDateInPast(program) &&
    hasProgramBioscreeningEndDateInFuture(program),
  );
}

export function hasProgramBioscreeningAvailable(program: MyProgramResponse | null): boolean {
  return Boolean(
    hasProgramBioscreeningAvailableWithoutDates(program) ||
    hasProgramBioscreeningAvailableWithDates(program) ||
    hasProgramBioscreeningAvailableWithOnlyStartDate(program) ||
    hasProgramBioscreeningAvailableWithOnlyEndDate(program),
  );
}

export function hasProgramBioscreeningAvailableWithEndDate(program: MyProgramResponse | null): boolean {
  return hasProgramBioscreeningAvailable(program) && hasProgramBioscreeningEndDate(program);
}

export function hasProgramBioscreeningUnavailableWithoutDates(program: MyProgramResponse | null): boolean {
  return !hasProgramBioscreeningAvailable(program) && hasProgramNoBioscreeningDates(program);
}
