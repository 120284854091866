import {pagesPath} from "@/ts/router/pages-path";
import type {RouteRecordRaw} from "vue-router";
import ProfilePage from "@/vue/pages/profile.page.vue";
import ProfileGoalsPage from "@/vue/pages/profile/profile-goals.page.vue";
import ProfileMyProgressPage from "@/vue/pages/profile/profile-my-progress.page.vue";
import EditProfilePage from "@/vue/pages/edit-profile.page.vue";
import SettingsPage from "@/vue/pages/settings.page.vue";
import ProfileBenefitsPage from "@/vue/pages/profile/profile-benefits.page.vue";
import ProfileBadgesPage from "@/vue/pages/profile/profile-badges.page.vue";
import ProfilePathPage from "@/vue/pages/profile/profile-path.page.vue";
import ProfileDevicesPage from "@/vue/pages/profile/profile-devices.page.vue";
import StepsPage from "@/vue/pages/profile/my-progress/steps.page.vue";
import MinutesOfActivityPage from "@/vue/pages/profile/my-progress/minutes-activity.page.vue";

export const profilePages: RouteRecordRaw[] = [
  {
    path: pagesPath.profile.main,
    component: ProfilePage,
  },
  {
    path: pagesPath.profile.edit,
    component: EditProfilePage,
  },
  {
    path: pagesPath.profile.settings,
    component: SettingsPage,
  },
  {
    path: pagesPath.profile.companyBenefits,
    component: ProfileBenefitsPage,
  },
  {
    path: pagesPath.profile.myBadges,
    component: ProfileBadgesPage,
  },
  {
    path: pagesPath.profile.myConnectedDevices,
    component: ProfileDevicesPage,
  },
  {
    path: pagesPath.profile.myPath,
    component: ProfilePathPage,
    children: [
      {
        path: "",
        redirect: pagesPath.profile.goals,
      },
      {
        path: pagesPath.profile.goals,
        component: ProfileGoalsPage,
      },
      {
        path: pagesPath.profile.myProgress,
        component: ProfileMyProgressPage,
      },
    ],
  },
  {
    path: pagesPath.profile.myStepsProgress,
    component: StepsPage,
  },
  {
    path: pagesPath.profile.myMinutesOfActivityProgress,
    component: MinutesOfActivityPage,
  },
];
