<template>
  <div v-if="categories && categories.length > 0">
    <program-content-filter
      :categories="categories"
      :model-value="isFilterModalOpen"
      @toggle-modal="toggleModal"
      @apply-filter="applyFilter"
    />
  </div>
  <card-row
    :title="title"
    :amount="pathways.length"
    :show-filter="categories && categories.length > 0"
    @open-filter="toggleModal"
  >
  <template #extraButton>
      <button class="icon" @click="togglePanel">
        <icon-chameleon
          color="primary100"
          hover-color="primary100"
          :icon="(showPanel ? DownChevron : RightChevron)"
        />
      </button>
    </template>
    <expansion-panel
      :expanded="showPanel"> 
    <template #body>
    <pathway-card
      v-for="pathway in pathways"
      :key="pathway.id"
      :pathway="pathway"
    />
    </template>
    </expansion-panel>
  </card-row>
</template>
<script lang="ts" setup>
import {ref} from "vue";
import CardRow from "@/vue/molecules/card-row.vue";
import ProgramContentFilter from "@/vue/molecules/program-content-filter.vue";
import PathwayCard from "@/vue/molecules/pathway-card.vue";
import type {PathwayFullDto} from "@/ts/types/dto/pathway.dto";
import type {ContentCategory} from "@/ts/types/dto/program.dto";
import ExpansionPanel from "@/vue/atoms/expansion-panel.vue";
import RightChevron from "@/assets/img/right-chevron.svg";
import DownChevron from "@/assets/img/down-chevron.svg";
import IconChameleon from "@/vue/atoms/icon-chameleon.vue";
const props = defineProps<{
  title: string;
  pathways: PathwayFullDto[];
  categories?: ContentCategory[];
}>();

const isFilterModalOpen = ref(false);

const emit = defineEmits(["applyFilter"]);

const toggleModal = () => {
  isFilterModalOpen.value = !isFilterModalOpen.value;
};
const showPanel = ref<boolean>(false);
const togglePanel = () => {
      showPanel.value = !showPanel.value;
};
const applyFilter = (categoryIds: string[]): void => {
  emit("applyFilter", categoryIds);
  isFilterModalOpen.value = false;
};

</script>
