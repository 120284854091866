<template>
  <div class="chart">
    <header>
      <icon-chameleon
        :icon="WeightIcon"
        color="secondary100"
        alt="weight icon"
        aria-hidden="true"
      />
      <h4 class="title">
        Weight
      </h4>
    </header>
    <bar v-if="hasData" :data="chartData" :options="options"/>
    <no-data-placeholder v-else/>
  </div>
</template>

<script setup lang="ts">
import {Chart as ChartJS, BarElement, CategoryScale, LinearScale, Title} from "chart.js";
import {Bar} from "vue-chartjs";
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";
import {computed, inject, onMounted, ref} from "vue";
import NoDataPlaceholder from "@/vue/molecules/my-progress/no-data-placeholder.vue";
import IconChameleon from "@/vue/atoms/icon-chameleon.vue";
import WeightIcon from "@/assets/img/icons/weight_black.svg";
import type {Api} from "@/ts/classes/api";
import {
  addToDate,
  convertDateToServerFormat, getEndOfTheMonth,
  getMonth, getStartOfTheMonth, parseDate,
  subtractFromDate,
} from "@/ts/utils/date-pure-functions";
import {TaskType} from "@/ts/types/dto/activity.dto";
import type {GetTrackingListResponseDTO} from "@/ts/types/dto/tracking.dto";
import {calculateGramToPound} from "@/ts/utils/pure-functions";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, ChartJsPluginDataLabels);

const $api = inject("$api")! as Api;

const numberOfMonths = 7;
const startDate: Date = getStartOfTheMonth(subtractFromDate(new Date(), {months: numberOfMonths - 1}));
const firstDaysOfMonths: Date[] = new Array(numberOfMonths)
  .fill("")
  .map((day, index) => addToDate(startDate, {months: index}));
const labels: string[] = firstDaysOfMonths.map((day) => getMonth(day).substring(0, 3)
.toUpperCase());
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const chartData = ref<any>({labels,
datasets: []});

/* eslint-disable */
const options: any = {
  responsive: true,
  maintainAspectRatio: true,
  elements: {
    point: {radius: 0},
    bar: {
      borderRadius: 5,
      borderSkipped: false,
    },
  },
  layout: {
    padding: {
      top: 20
    }
  },
  events: [],
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false
    },
    datalabels: {
      anchor: 'end',
      align: 'start',
      offset: -20,
      display: (ctx: any) => (ctx.dataset.data[ctx.dataIndex] > 0),
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false,
      },
      border: {
        display: false,
      },
      ticks: {
        color: '#9EABBA',
        padding: 10,
      },
    },
    y: {display: false},
  },
};

onMounted(async () => {
  try {
    const trackingItems = await $api.getTrackingList({
      startDate: convertDateToServerFormat(firstDaysOfMonths[0]),
      endDate: convertDateToServerFormat(getEndOfTheMonth(firstDaysOfMonths[firstDaysOfMonths.length-1])),
      taskType: TaskType.weight}
    );
    // Instantiate maps
    const monthToTrackedWeights: { [key: string]: GetTrackingListResponseDTO[]; } = firstDaysOfMonths.reduce((acc, d) => ({...acc,[convertDateToServerFormat(d)]:[]}),{})
    const dateToLastTrackedWeight: { [key: string]: number; } = firstDaysOfMonths.reduce((acc, d) => ({...acc,[convertDateToServerFormat(d)]:0}),{})

    // Sort tracking items per date and per createdAt timestamp
    // Add sorted tracking items to corresponding month
    trackingItems
      .sort((i1, i2) => parseDate(i1.date).getTime() - parseDate(i2.date).getTime() || parseDate(i1.createdAt).getTime() - parseDate(i2.createdAt).getTime())
      .forEach((item) => {
        const firstOfMonth = convertDateToServerFormat(getStartOfTheMonth(parseDate(item.date)))
        monthToTrackedWeights[firstOfMonth] = [...monthToTrackedWeights[firstOfMonth], item]
      })

    // Take last tracked item for each month
    Object.keys(monthToTrackedWeights).forEach((date) => {
      const weightInLbs = Math.ceil(calculateGramToPound(monthToTrackedWeights[date][monthToTrackedWeights[date].length -1]?.trackedAmount))
      dateToLastTrackedWeight[date] = weightInLbs || 0
    })

    const data: number[] = Object.values(dateToLastTrackedWeight)
    const max = data.reduce((m,d) => d > m ? d : m, 0)
    const backgroundColors: string[] = data.map(d => d > max*0.6 ? '#8FD8C6' : d > max*0.3 ? '#D2EFE8' : '#F4FBF9')
    chartData.value.datasets = [{
      backgroundColor: backgroundColors,
      categoryPercentage: 0.6,
      data
    }]
  } catch (err) {
    // TODO handle error
    console.error(err)
  }
})

const hasData = computed((): boolean => {
  let sum = 0;
  if (chartData.value) {
    sum = chartData.value.datasets
      .map((dataset: {data: number[]}) => dataset.data.reduce((previousValue, currentValue) => previousValue + currentValue, 0))
      .reduce((previousValue: number, currentValue: any) => previousValue + currentValue, 0);
  }

  return (Boolean(chartData.value) && sum > 0);
});
</script>

<style lang="sass" scoped>
.chart
  padding: 16px
  box-shadow: 0 0 10px rgba(15, 38, 78, 0.15)
  border-radius: 10px
  background-color: $color-white

header
  display: flex
  margin: 10px 0

.title
  font-size: 16px
  text-transform: uppercase
  margin: 0 0 0 10px
  display: flex
  justify-content: center
  align-items: center
</style>
