<template>
  <register-page
    :prev-page="$pagesPath.auth.registration"
    :current-step="3"
    title="Additional Data"
    subtitle="Tell us a little more about yourself."
    @next="submit"
  >
    <field-set id="phone-number" label="Cell Phone Number">
      <edit-phone-number-input v-model="phoneNumber"/>
    </field-set>
    <edit-address1 v-model="address1"/>
    <edit-address2 v-model="address2"/>
    <edit-city v-model="city"/>
    <edit-state v-model="state"/>
    <edit-postcode v-model="postcode"/>
  </register-page>
</template>

<script lang="ts" setup>
import RegisterPage from "@/vue/templates/register-page.vue";
import EditAddress1 from "@/vue/molecules/edit-field/edit-address-1.vue";
import EditAddress2 from "@/vue/molecules/edit-field/edit-address-2.vue";
import EditCity from "@/vue/molecules/edit-field/edit-city.vue";
import EditState from "@/vue/molecules/edit-field/edit-state.vue";
import EditPostcode from "@/vue/molecules/edit-field/edit-postcode.vue";
import EditPhoneNumberInput from "@/vue/molecules/edit-field/edit-phone-number-input.vue";
import FieldSet from "@/vue/molecules/field-set.vue";
import {inject, ref} from "vue";
import {OnboardingStore} from "@/ts/store/onboarding/onboarding-store";
import {getModule} from "vuex-module-decorators";
import {vueStore} from "@/ts/store/vue-store";
import {useRouter} from "vue-router";
import type {PagesPath} from "@/ts/router/pages-path";
import type {CachedApi} from "@/ts/classes/cached-api";

const phoneNumber = ref<string>("+1");
const address1 = ref<string>("");
const address2 = ref<string>("");
const city = ref<string>("");
const state = ref<string>("");
const postcode = ref<string>("");

const onboardingStore: OnboardingStore = getModule(OnboardingStore, vueStore);
const router = useRouter();
const $pagesPath = inject<PagesPath>("$pagesPath")!;
const $cachedApi = inject<CachedApi>("$cachedApi")!;

const submit = (): void => {
  onboardingStore.setAdditionalData({
    address1: address1.value,
    city: city.value,
    address2: address2.value,
    phoneNumber: phoneNumber.value,
    postcode: postcode.value,
    state: state.value,
  });
  void router.push($pagesPath.auth.interests);
};

if (!onboardingStore.userRegistrationData) {
  void router.push($pagesPath.auth.registration);
}

if (onboardingStore.userAdditionalData) {
  address1.value = onboardingStore.userAdditionalData!.address1;
  city.value = onboardingStore.userAdditionalData!.city;
  address2.value = onboardingStore.userAdditionalData!.address2;
  phoneNumber.value = onboardingStore.userAdditionalData!.phoneNumber;
  postcode.value = onboardingStore.userAdditionalData!.postcode;
  state.value = onboardingStore.userAdditionalData!.state;
}

// data-preload for next page
void $cachedApi.getInterestOptions();

</script>

<style lang="sass" scoped>
.title
  color: $color-primary-hc-blue-100
  margin-bottom: 5rem
  text-align: center
</style>
