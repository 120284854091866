<template>
  <popup-modal :model-value="modelValue" @update:model-value="toggleModal">
    <p class="title">
      Filter Content
    </p>
    <p class="subtitle">
      Categories
    </p>
    <aria-list-box
      class="listbox"
      option-class-name="listbox__option"
      option-active-class-name="listbox__option--active"
      orientation="horizontal"
      aria-label="Select your interests"
      :options="categories"
      :selection="selectedIds"
      @removed-from-selection="onSelectCategory"
      @added-to-selection="onSelectCategory"
    >
      <template #item="templateProps">
        <badge-rounded
          :value="templateProps.option.name"
          :theme="selectedIds.has(templateProps.option.id) ? 'dark' : 'grey'"
          :icon="selectedIds.has(templateProps.option.id) ? 'check' : 'plus'"
          size="large-thin"
        />
      </template>
    </aria-list-box>
    <div class="buttons">
      <button-primary theme="white" @click="toggleModal">
        Cancel
      </button-primary>
      <button-primary @click="applyFilter">
        Apply
      </button-primary>
    </div>
  </popup-modal>
</template>

<script lang="ts" setup>

import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import AriaListBox from "@/vue/molecules/aria-list-box.vue";
import PopupModal from "@/vue/atoms/popup-modal.vue";
import {type ContentCategory} from "@/ts/types/dto/program.dto";
import {ref, computed} from "vue";
import BadgeRounded from "@/vue/atoms/badge-rounded.vue";

const props = defineProps<{
  categories: ContentCategory[];
  modelValue: boolean;
}>();

const selectedCategories = ref<ContentCategory[]>([]);
const previouslySelectedCategories = ref<ContentCategory[]>([]);

const emit = defineEmits(["applyFilter", "toggleModal"]);

const toggleModal = (): void => {
  selectedCategories.value = previouslySelectedCategories.value.slice();
  emit("toggleModal");
};

const applyFilter = (): void => {
  previouslySelectedCategories.value = selectedCategories.value.slice();
  emit("applyFilter", Array.from(selectedIds.value.keys()));
};

const selectedIds = computed((): Map<string, ContentCategory> => {
  const idMap = new Map<string, ContentCategory>();
    selectedCategories.value.forEach((interest: ContentCategory) => {
    idMap.set(interest.id, interest);
  });
  return idMap;
});

const onSelectCategory = (selectedCategory: ContentCategory): void => {
  const alreadySelectedIndex = selectedCategories.value.findIndex((item) => item.id === selectedCategory.id);

  if (alreadySelectedIndex === -1) {
    selectedCategories.value.push(selectedCategory);
  } else {
    selectedCategories.value.splice(alreadySelectedIndex, 1);
  }
};
</script>

<style lang="sass" scoped>

.available
  font-size: 16px
  line-height: 24px
  color: $color-primary-hc-blue-100
  @include Roboto400
  max-width: 652px

.title
  @include Asap700
  margin: 0
  font-size: 32px
  line-height: 40px
  text-align: left

.subtitle
  @include Asap700
  font-size: 16px
  line-height: 24px
  max-width: 652px

.buttons
  display: flex
  gap: 16px
  justify-content: center

.listbox
  width: auto
  display: flex
  align-items: center
  justify-content: flex-start
  flex-wrap: wrap
  gap: 12px
  outline: none
  margin-bottom: 24px
  max-width: 600px

  &--float-left
    justify-content: flex-start

  &:deep(.listbox__option)
    user-select: none
    background: none
    border: none
    padding: 0

  &:focus-visible:deep(.listbox__option--active)
    outline: 2px solid $color-secondary-state-blue-100
    outline-offset: 2px
    border-radius: 4px

</style>
