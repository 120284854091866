<template>
  <input-select
    id="gender"
    :model-value="modelValue"
    autocomplete="sex"
    :options="genders"
    @update:model-value="updateValue"
  />
</template>

<script lang="ts">
import {
  Component,
  Emit,
  Prop,
  Vue,
} from "vue-property-decorator";
import type {InputSelectOption} from "@/ts/types/component/input.select";
import {GenderType} from "@/ts/types/component/input.select";
import InputSelect from "@/vue/atoms/input-select.vue";

@Component({
  name: "EditGenderSelect",
  components: {InputSelect}
})
export default class EditGenderSelect extends Vue {
  @Prop() modelValue!: string;


  get genders(): InputSelectOption[] {
    return [
      {
        label: "Male",
        value: GenderType.MALE,
      },
      {
        label: "Female",
        value: GenderType.FEMALE,
      },
      {
        label: "Non-binary",
        value: GenderType.NON_BINARY,
      },
    ];
  }

  @Emit('update:modelValue')
  updateValue(value: string): string {
    return value;
  }
}
</script>
