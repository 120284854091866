<template>
  <physician-form-page step="3" title="Wellness Examination Results">
    <section class="informations">
      <form class="form" @submit.prevent="submitFormFields">
        <field-set id="examDate" label="Examination Date" class="field">
          <input-date
            id="examDate"
            :model-value="examDate"
            required
            :min="minExamDate"
            :max="maxExamDate"
            autocomplete="examDate"
            placeholder="Insert examination date"
            :disabled="!canEdit"
            @update:model-value="updateExamDate"
          />
        </field-set>
        <div class="row">
          <field-set id="pregnant" label="Pregnant?" class="field">
            <input-radio
              id="pregnant-Yes"
              :model-value="isPregnant"
              :checked="isPregnant !== 'false'"
              :disabled="!canEdit"
              required
              name="pregnant"
              label="Yes"
              value="true"
              @update:model-value="updateIsPregnant"
            />
            <input-radio
              id="pregnant-No"
              :model-value="isPregnant"
              :checked="isPregnant === 'false'"
              :disabled="!canEdit"
              required
              name="pregnant"
              label="No"
              value="false"
              @update:model-value="updateIsPregnant"
            />
          </field-set>

          <field-set id="fasting" label="Fasting?" class="field">
            <input-radio
              id="fasting-Yes"
              :model-value="isFasting"
              :checked="isFasting !== 'false'"
              :disabled="!canEdit"
              required
              name="fasting"
              label="Yes"
              value="true"
              @update:model-value="updateIsFasting"
            />
            <input-radio
              id="fasting-No"
              :model-value="isFasting"
              :checked="isFasting === 'false'"
              :disabled="!canEdit"
              required
              name="fasting"
              label="No"
              value="false"
              @update:model-value="updateIsFasting"
            />
          </field-set>
        </div>
        <physician-form-exam-information @update:modelValue="examFields"/>
        <div class="buttons">
          <button-link theme="white" :to="$pagesPath.physicianForm.uploadForm">
            <img src="@/assets/img/left-arrow-cal.svg" alt="arrow left" class="left-arrow"/> Back
          </button-link>
          <button-primary type="submit" :disabled="!canEdit">
            Submit <img src="@/assets/img/right-arrow-white.svg" alt="arrow right"/>
          </button-primary>
        </div>
      </form>
    </section>
  </physician-form-page>
</template>

<script lang="ts">
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import {
  BiometricPhysicianFormState,
  BiometricPhysicianFormStoreMixin,
} from "@/ts/store/biometric-physician-form/biometric-physician-form-store-instance";
import type {PhysicianFormBiometric} from "@/ts/types/store/biometric-physician-form-store-types";
import LoadingBar from "@/vue/atoms/loading-bar.vue";
import PhysicianFormPage from "@/vue/templates/physician-form-page.vue";
import PhysicianFormExamInformation from "@/vue/molecules/physician-form-exam-information.vue";
import FieldSet from "@/vue/molecules/field-set.vue";
import InputText from "@/vue/atoms/input-text.vue";
import InputDate from "@/vue/atoms/input-date.vue";
import InputRadio from "@/vue/atoms/input-radio.vue";
import type {PhysicianFormBiometric as PhysicianFormBiometricType} from "@/ts/types/dto/physician-form.dto";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import ButtonLink from "@/vue/atoms/button-link.vue";
import {GrowlsStoreMixin} from "@/ts/store/growl/growl-store-instance";
import {
  convertDateToServerFormat,
  parseDate,
  today,
} from "@/ts/utils/date-pure-functions";
import {PhysicianFormStatus} from "@/ts/types/dto/physician-form.dto";

@Component({
  name: "PhysicianExamInformationFormPage",
  components: {
    LoadingBar,
    PhysicianFormPage,
    PhysicianFormExamInformation,
    FieldSet,
    InputText,
    InputDate,
    InputRadio,
    ButtonLink,
    ButtonPrimary,
  },
})
export default class PhysicianExamInformationFormPage extends mixins(
  LoadingMixin,
  BiometricPhysicianFormStoreMixin,
  GrowlsStoreMixin,
) {
  @BiometricPhysicianFormState
  physicianForm!: PhysicianFormBiometric;

  @BiometricPhysicianFormState
  physicianFormFile!: File;

  isPregnant!: string;

  examDateStr!: string;

  isFasting!: string;

  minExamDate: string = "2023-01-01"; // Coming from the physician form API as examFromDate

  maxExamDate: string = convertDateToServerFormat(today());

  get canEdit(): boolean {
    return this.physicianForm.status != PhysicianFormStatus.approved && this.physicianForm.status != PhysicianFormStatus.overdue;
  }

  get examDate(): Date | null {
    return this.examDateStr ? parseDate(this.examDateStr) : null;
  }

  updateExamDate(value: string): string {
    const dateValue = parseDate(value).toISOString();
    this.biometricPhysicianFormStore.setPhysicianForm({
      ...this.physicianForm,
      examDate: dateValue,
    });
    this.examDateStr = dateValue;
    return dateValue;
  }

  updateIsFasting(value: string): string {
    const booleanValue = value !== "false";
    this.isFasting = value;
    this.biometricPhysicianFormStore.setPhysicianForm({
      ...this.physicianForm,
      isFasting: booleanValue,
    });
    return value;
  }

  examFields(value: PhysicianFormBiometricType[]): void {
    this.biometricPhysicianFormStore.setPhysicianForm({
      ...this.physicianForm,
      biometrics: value,
    });
  }

  updateIsPregnant(value: string): string {
    const booleanValue = value !== "false";
    this.isPregnant = value;
    this.biometricPhysicianFormStore.setPhysicianForm({
      ...this.physicianForm,
      isPregnant: booleanValue,
    });
    return value;
  }

  @DefaultGrowlError
  async submitFormFields(): Promise<void> {
    try {
      await this.$api.postPhysicianForm(this.physicianForm, this.physicianFormFile);

      void this.growlsStore.growlInfo("Physician form submitted");
      void this.$router.push(this.$pagesPath.myHealthPath.myhealthCheckScore);
    } catch (error) {
      void this.growlsStore.growlError(error as string);
    }
  }

  created(): void {
    this.examDateStr = this.physicianForm.examDate;
    this.isPregnant = this.physicianForm.isPregnant.toString();
    this.isFasting = this.physicianForm.isFasting.toString();
    this.minExamDate = this.physicianForm.examFromDate;
  }
}
</script>

<style lang="sass" scoped>
.informations
  width: 681px

.form
  display: flex
  flex-direction: column
  gap: 24px

.row
  display: flex
  justify-content: space-between

.field
  width: 328px

.buttons
  width: 681px
  display: flex
  justify-content: space-between
  margin: 48px auto

  button,
  a
    width: 130px

.form
  display: flex
  flex-direction: column
  gap: 24px

.left-arrow
  width: 7px

</style>
