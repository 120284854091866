<template>
  <div>
    Processing authentication callback...
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {LoadingMixin} from "@/ts/mixins/loading-mixin";
import {DefaultStoreMixin} from "@/ts/store/default/default-store-instance";

@Component({
  name: "Callback",
})
export default class Callback extends mixins(LoadingMixin, DefaultStoreMixin) {
  async mounted() {
    try {
      const authLoginResponseDTO = await this.$api.handleOIDCCallback();
      if (authLoginResponseDTO.registerUrl) {
        const url = new URL(authLoginResponseDTO.registerUrl);
        const redirectUrl = url.searchParams.get("redirectUrl");

        if (redirectUrl) {
          localStorage.setItem("redirectUrl", redirectUrl);
        }

        if (authLoginResponseDTO.claims) {
          if (authLoginResponseDTO.claims.company_code) {
            localStorage.setItem("companyCode", String(authLoginResponseDTO.claims.company_code));
          }
          if (authLoginResponseDTO.claims.preferred_username) {
            localStorage.setItem("v3-username", String(authLoginResponseDTO.claims.preferred_username));
          }
          if (authLoginResponseDTO.claims.given_name) {
            localStorage.setItem("firstName", String(authLoginResponseDTO.claims.given_name));
          }
          if (authLoginResponseDTO.claims.family_name) {
            localStorage.setItem("lastName", String(authLoginResponseDTO.claims.family_name));
          }
        }

        window.location.href = authLoginResponseDTO.registerUrl;
        return;
      }
      this.defaultStore.setAuthTokens(authLoginResponseDTO);
      void this.$router.push("/todo");
    } catch (error) {
      throw new Error("sso callback failed");
    }
  }
}
</script>
