<template>
  <action-card
    v-if="actions.length"
    title="Recommended Activity"
    description="These are actions we recommend to help you learn more about your overall well-being."
    :actions="actions"
  />
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";
import ActionCard from "@/vue/molecules/action-card.vue";
import type {Action} from "@/ts/types/component/action-card";
import type {GatekeeperItem} from "@/ts/types/dto/program.dto";
import {GatekeeperStatus} from "@/ts/types/dto/program.dto";
import coachingIcon from "@/assets/img/icons/coaching-icon.svg";
import hraIcon from "@/assets/img/icons/hra-icon.svg";
import bioScreeningIcon from "@/assets/img/icons/bio-screen-icon.svg";
import {ContentReferenceType} from "@/ts/types/dto/content-reference.dto";

@Component({
  name: "PrimaryActionCard",
  components: {ActionCard},
})
export default class PrimaryActionCard extends Vue {
  @Prop({ default: () => [] })
  gatekeeperItems!: GatekeeperItem[];

  get actions(): Action[] {
    return this.gatekeeperItems
        .filter((item) => !item.isRequired)
        .map((item) => {
          let icon = "";
          let handleClick = () => {};

          switch (item.contentReference.type) {
            case ContentReferenceType.BiometricScreening:
              icon = bioScreeningIcon;
              handleClick = () => {
void this.$router.push(this.$pagesPath.myHealthPath.myhealth);
};
              break;
            case ContentReferenceType.HealthRiskAssessment:
              icon = hraIcon;
              handleClick = () => {
void this.$router.push(this.$pagesPath.myHealthPath.myhealth);
};
              break;
            case ContentReferenceType.Coaching:
              icon = coachingIcon;
              handleClick = () => {
void this.$router.push(this.$pagesPath.coaching.info);
};
              break;
          }

          return {
            name: item.name,
            icon,
            endDate: item.endDate,
            handleClick,
          };
        });
  }
}
</script>

<style lang="sass" scoped>
</style>
