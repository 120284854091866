<template>
  <div class="password-rules">
    Password must contain at least <span>12 characters</span>,
    <span>1 upper case letter</span>, <span>1 lower case letter</span>,
    <span>1 digit</span> and <span>1 symbol</span>.
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";

@Component({
  name: "PasswordRules"
})
export default class PasswordRules extends Vue {
}
</script>

<style lang="sass" scoped>
.password-rules
  @include Roboto400
  align-items: center
  color: $color-primary-hc-blue-100
  letter-spacing: 0.1px
  font-size: 16px
  line-height: 24px

  span
    @include Roboto700
</style>
