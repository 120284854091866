<template>
  <profile-block name="Personal Information">
    <profile-field-name/>
    <profile-field-gender/>
    <profile-field-birthdate/>
    <profile-field-height/>
    <profile-field-unique-id/>
  </profile-block>
</template>

<script lang="ts">

import {
  Component,
  Vue,
} from "vue-property-decorator";
import ProfileBlock from "@/vue/templates/profile-block.vue";
import ProfileFieldName from "@/vue/organisms/profile/field/profile-field-name.vue";
import ProfileFieldGender from "@/vue/organisms/profile/field/profile-field-gender.vue";
import ProfileFieldBirthdate from "@/vue/organisms/profile/field/profile-field-birthday.vue";
import ProfileFieldHeight from "@/vue/organisms/profile/field/profile-field-height.vue";
import ProfileFieldUniqueId from "@/vue/organisms/profile/field/profile-field-unique-id.vue";

@Component({
  name: "PersonalInformation",
  components: {
    ProfileFieldName,
    ProfileBlock,
    ProfileFieldGender,
    ProfileFieldBirthdate,
    ProfileFieldHeight,
    ProfileFieldUniqueId,
  },
})
export default class PersonalInformation extends Vue {
}
</script>
