<template>
  <article class="feed-item">
    <div class="header">
      <h3
        class="header__headline text-h5"
        :aria-label="ariaPostTitle"
      >
        {{ displayName }}
      </h3>

      <div v-if="false" class="header__subline text-secondary-blue-100">
        ???
      </div>
    </div>

    <display-time-ago class="time" :date="dateTime"/>

    <p class="msg text-body2">
      <markdown-it :text="feedItemData.message"/>
    </p>

    <div v-if="feedItemData.imagePath" class="image">
      <protected-image
        :src="feedItemData.imagePath"
        :aria-hidden="false"
        alt="Image of this post"
      />
    </div>

    <like-btn
      v-model="isLikedByMe"
      :number-of-likes="numberOfLikes"
      :aria-label="'Flag ' + ariaPostTitle + ' as liked'"
      @update:model-value="onUpdatedIsLikedByMe"
    />

    <avatar-frame
      v-if="isAdmin"
      class="avatar"
      :image="avatarPath"
      :fallback-image="feedItemData.author.role === UserRole.super_admin ? SvgHc360Symbol : SvGCompanyBuilding"
      :size="48"
      :title="feedItemData.author.role === UserRole.super_admin ? displayName : feedItemData.author.company.name"
    />

    <user-avatar
      v-else
      class="avatar"
      :avatar="avatarPath"
      :firstname="feedItemData.author.firstName"
      :lastname="feedItemData.author.lastName"
      size="mid"
    />
  </article>
</template>

<script lang="ts" setup>

import type {FeedItem} from "@/ts/types/dto/feed.dto";
import UserAvatar from "@/vue/atoms/user-avatar.vue";
import DisplayTimeAgo from "@/vue/atoms/display-time-ago.vue";
import {computed, inject, ref} from "vue";
import {parseDate} from "@/ts/utils/date-pure-functions";
import ProtectedImage from "@/vue/atoms/protected-image.vue";
import LikeBtn from "@/vue/atoms/like-btn.vue";
import type {Api} from "@/ts/classes/api";
import {UserRole} from "@/ts/types/dto/user.dto";
import AvatarFrame from "@/vue/atoms/avatar-frame.vue";
import SvgHc360Symbol from "@/assets/img/HC360-symbol.svg";
import SvGCompanyBuilding from "@/assets/img/company-building-avatar.svg";
import MarkdownIt from "@/vue/atoms/markdown-it.vue";

const props = defineProps<{
  feedItemData: FeedItem;
  index: number;
}>();

const $api = inject("$api")! as Api;

const isLikedByMe = ref<boolean>(props.feedItemData?.isLiked ?? false);
const numberOfLikes = ref<number>(props.feedItemData?.numberOfLikes ?? 0);

const dateTime = computed((): Date => {
  return parseDate(props.feedItemData.createdAt);
});

const onUpdatedIsLikedByMe = async(value: boolean): Promise<void> => {
  try {
    const result = await $api.putLikeFeedItem(props.feedItemData.id, value);
    // sync displayed data with server state
    isLikedByMe.value = result.isLiked;
    numberOfLikes.value = result.numberOfLikes;
  } catch (err) {
    // something went wrong: undo flagging as liked-by-me
    isLikedByMe.value = !value;
  }
};

const displayName = computed((): string => {
  let name: string;
  switch (props.feedItemData.author.role) {
    case UserRole.company_admin:
      name = "Company Admin";
      break;
    case UserRole.super_admin:
      name = "HealthCheck360";
      break;
    default:
      name = `${props.feedItemData.author.firstName} ${props.feedItemData.author.lastName}`;
  }
  return name;
});

const isAdmin = computed((): boolean => {
  return props.feedItemData.author.role === UserRole.super_admin ||
    props.feedItemData.author.role === UserRole.company_admin;
});

const avatarPath = computed((): string => {
  let path: string;
  switch (props.feedItemData.author.role) {
    case UserRole.company_admin:
      path = props.feedItemData.author.company.logoPath;
      break;
    case UserRole.super_admin:
      path = "";
      break;
    default:
      path = props.feedItemData.author.avatarPath;
  }

  return path;
});

const ariaPostTitle = computed((): string => {
  return `Post number ${props.index + 1} posted by ${displayName.value}`;
});

</script>

<style lang="sass" scoped>

.feed-item
  background-color: $color-white
  border-radius: 16px
  padding: 32px 16px 16px 80px
  margin-bottom: 8px
  position: relative

.header
  margin: 0 0 16px 0

.header__headline
  margin: 0

.header__subline
  font-size: 14px

.msg
  margin: 0 0 16px 0

.image
  margin: 0 0 16px 0

.time
  position: absolute
  top: 16px
  right: 16px

.avatar
  position: absolute
  left: 16px
  top: 32px

</style>


