<template>
  <loading-suspense :error="error" :loading="loading">
    <main v-if="dataFound">
      <activity-card-row
        title="Active"
        :activities="activeActivities ?? []"
        :categories="activeCategories"
        @change-item="changeActiveItem"
        @proof-sent="activeProofSent"
        @apply-filter="applyActiveFilter"
      />
      <activity-card-row
        title="Available"
        :activities="availableActivities ?? []"
        :categories="availableCategories"
        @change-item="changeAvailableItem"
        @proof-sent="availableProofSent"
        @apply-filter="applyAvailableFilter"
      />
      <activity-card-row
        title="Available Platform Activities"
        :activities="availablePlatformActivities ?? []"
        @change-item="changePlatformItem"
      />
      <activity-card-row
        title="Completed"
        :activities="completedActivities ?? []"
        :categories="completedCategories"
        @apply-filter="applyCompletedFilter"
      />
      <activity-card-row
        title="Completed Platform Activities"
        :activities="completedPlatformActivities ?? []"
        @change-item="changePlatformItemCompleted"
      />
    </main>
    <empty-state
      v-else
      :image="noActivityImage"
      title="No Activities Available"
      description="Please contact your admin."
    />
  </loading-suspense>

  <add-tracking-button/>
</template>

<script lang="ts" setup>
import AddTrackingButton from "@/vue/atoms/add-tracking-button.vue";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import type {ActivityDTO, PlatformActivityDTO} from "@/ts/types/dto/activity.dto";
import ActivityCardRow from "@/vue/molecules/activity-card-row.vue";
import EmptyState from "@/vue/atoms/empty-state.vue";
import noActivityImageSvg from "@/assets/img/challenge-card-icons/person-practicing-yoga.svg";
import {computed, inject, onMounted, ref} from "vue";
import type {Api} from "@/ts/classes/api";
import {ActivityCardModel} from "@/ts/types/component/activity-card";
import type {ContentCategory} from "@/ts/types/dto/program.dto";

const $api: Api = inject("$api")! as Api;

const activeCategories = ref<ContentCategory[]>([]);
const availableCategories = ref<ContentCategory[]>([]);
const completedCategories = ref<ContentCategory[]>([]);

const error = ref("");
const loading = ref(true);
const dataFound = ref(true);

const activeActivities = ref<ActivityCardModel[]>();
const availableActivities = ref<ActivityCardModel[]>();
const availablePlatformActivities = ref<ActivityCardModel[]>();
const completedPlatformActivities = ref<ActivityCardModel[]>();
const completedActivities = ref<ActivityCardModel[]>();

const noActivityImage = computed((): string => {
  return noActivityImageSvg;
});

const changeActiveItem = (item: ActivityCardModel): void => {
  if (!activeActivities.value || item.status === "active") {
    return;
  }
  const index = activeActivities.value.findIndex(
    (activity) => activity.id === item.id,
  );
  activeActivities.value.splice(index, 1);
  if (item.status === "available") {
    if (!availableActivities.value) {
      return;
    }
    availableActivities.value.splice(index, 0, item);
  } else if (item.status === "completed") {
    if (!completedActivities.value) {
      return;
  }
    completedActivities.value.splice(index, 0, item);
  }
};

const changeAvailableItem = (item: ActivityCardModel): void => {
  if (!availableActivities.value || item.status === "available") {
    return;
  }
  const index = availableActivities.value.findIndex(
    (activity) => activity.id === item.id,
  );
  availableActivities.value.splice(index, 1);
  if (item.status === "active") {
    if (!activeActivities.value) {
      return;
    }
    activeActivities.value.splice(index, 0, item);
  } else if (item.status === "completed"){
    if (!completedActivities.value) {
      return;
    }
    completedActivities.value.splice(index, 0, item);
  }
};

const changePlatformItem = (item: ActivityCardModel): void => {
  if (!availablePlatformActivities.value) {
    return;
  }
  const index = availablePlatformActivities.value.findIndex(
    (activity) => activity.id === item.id,
  );
  availablePlatformActivities.value.splice(index, 1, item);
};

const changePlatformItemCompleted = (item: ActivityCardModel): void => {
  if (!completedPlatformActivities.value) {
    return;
  }
  const index = completedPlatformActivities.value.findIndex(
    (activity) => activity.id === item.id,
  );
  completedPlatformActivities.value.splice(index, 1, item);
};

const activeProofSent = async(id: string): Promise<void> => {
  if (!activeActivities.value) {
    return;
  }

  const activityByIdResponse: ActivityDTO = await $api.getActivityById(id);
  const data = activeActivities.value.map((item) => {
    if (item.id === id) {
      return ActivityCardModel.createActivity(activityByIdResponse);
    }
    return item;
  });

  activeActivities.value = data;
};

const availableProofSent = async(id: string): Promise<void> => {
  if (!availableActivities.value) {
    return;
  }

  const activityByIdResponse: ActivityDTO = await $api.getActivityById(id);
  const data = availableActivities.value.map((item) => {
    if (item.id === id) {
      return ActivityCardModel.createActivity(activityByIdResponse);
    }
    return item;
  });

  availableActivities.value = data;
};

const applyActiveFilter = async(categoryIds: string[]): Promise<void> => {
  const filteredActivities = await $api.getActivities({
    onlyActive: true,
    categoryIds: categoryIds.join(","),
    limit: 100,
  });
  activeActivities.value = (filteredActivities?.items ?? [])
  .map((item: ActivityDTO): ActivityCardModel => ActivityCardModel.createActivity(item));
};

const applyAvailableFilter = async(categoryIds: string[]): Promise<void> => {
  const filteredActivities = await $api.getActivities({
    onlyAvailable: true,
    categoryIds: categoryIds.join(","),
    limit: 100,
  });
  availableActivities.value = (filteredActivities?.items ?? [])
  .map((item: ActivityDTO): ActivityCardModel => ActivityCardModel.createActivity(item));
};

const applyCompletedFilter = async(categoryIds: string[]): Promise<void> => {
  const filteredActivities = await $api.getActivities({
    onlyCompleted: true,
    categoryIds: categoryIds.join(","),
    limit: 100,
  });
  completedActivities.value = (filteredActivities?.items ?? [])
  .map((item: ActivityDTO): ActivityCardModel => ActivityCardModel.createActivity(item));
};

onMounted(async() => {
  try {
    await loadCategories();

    const [activeResponse, availableResponse, completedResponse, availPlatformActivitiesResponse, complPlatformActivitiesResponse] = await Promise.allSettled([
      $api.getActivities({
        onlyActive: true,
        limit: 100,
      }),
      $api.getActivities({
        onlyAvailable: true,
        limit: 100,
      }),
      $api.getActivities({
        onlyCompleted: true,
        limit: 100,
      }),
      $api.getPlatformActivities({
        onlyAvailable: true,
        limit: 100,
      }),
      $api.getPlatformActivities({
        onlyCompleted: true,
        limit: 100,
      }),
    ]);

    if (activeResponse.status === "fulfilled") {
      activeActivities.value = (activeResponse?.value?.items ?? [])
      .map((item: ActivityDTO): ActivityCardModel => ActivityCardModel.createActivity(item));
    }

    if (availableResponse.status === "fulfilled") {
      availableActivities.value = (availableResponse?.value?.items ?? [])
      .map((item: ActivityDTO): ActivityCardModel => ActivityCardModel.createActivity(item));
    }

    if (completedResponse.status === "fulfilled") {
      completedActivities.value = (completedResponse?.value?.items ?? [])
      .map((item: ActivityDTO): ActivityCardModel => ActivityCardModel.createActivity(item));
    }

    if (availPlatformActivitiesResponse.status === "fulfilled") {
      availablePlatformActivities.value = (availPlatformActivitiesResponse?.value.items ?? [])
      .map((item: PlatformActivityDTO): ActivityCardModel => ActivityCardModel.createPlatformActivity(item));
    }
    if (complPlatformActivitiesResponse.status === "fulfilled") {
      completedPlatformActivities.value = (complPlatformActivitiesResponse?.value.items ?? [])
      .map((item: PlatformActivityDTO): ActivityCardModel => ActivityCardModel.createPlatformActivity(item));
    }
  } catch (err) {
    error.value = err as string;
  } finally {
    loading.value = false;
    dataFound.value = Boolean(activeActivities.value?.length) ||
    Boolean(availableActivities.value?.length) ||
    Boolean(completedActivities.value?.length) ||
    Boolean(availablePlatformActivities.value?.length);
  }
});

const loadCategories = async(): Promise<void> => {
  const [activeCategoryResponse, availableCategoryResponse, completedCategoryResponse] = await Promise.allSettled([
      $api.getActivityCategories({
        onlyActive: true,
      }),
      $api.getActivityCategories({
        onlyAvailable: true,
      }),
      $api.getActivityCategories({
        onlyCompleted: true,
      }),
    ]);

    if (activeCategoryResponse.status === "fulfilled") {
      activeCategories.value = activeCategoryResponse.value
      .sort((a, b) => b.count - a.count)
      .map((category) => ({id: category.id,
name: `${category.name} (${category.count})`}));
    }
    if (availableCategoryResponse.status === "fulfilled") {
      availableCategories.value = availableCategoryResponse.value
      .sort((a, b) => b.count - a.count)
      .map((category) => ({id: category.id,
name: `${category.name} (${category.count})`}));
    }
    if (completedCategoryResponse.status === "fulfilled") {
      completedCategories.value = completedCategoryResponse.value
      .sort((a, b) => b.count - a.count)
      .map((category) => ({id: category.id,
name: `${category.name} (${category.count})`}));
    }
};

</script>

<style lang="sass" scoped>
main
  margin-top: 3rem
</style>
