<template>
  <select
    :id="id"
    :value="modelValue"
    :name="name"
    :required="required"
    :autocomplete="autocomplete"
    @change="updateValue"
  >
    <option v-for="option in selectOptions" :key="option" :value="option">
      {{ option }}
    </option>
  </select>
</template>

<script lang="ts">
import {
  Component,
  Emit,
  Prop,
  Vue,
} from "vue-property-decorator";

@Component({
  name: "InputDropDown",
})
export default class InputDropDown extends Vue {
  @Prop()
  public readonly modelValue!: string;

  @Prop() options!: string[];

  @Prop() name!: string;

  @Prop({default: undefined}) id!: string | undefined;

  @Prop({default: false}) required!: boolean;

  @Prop({default: ""}) autocomplete!: string;

  get selectOptions(): string[] {
    if (!this.required) {
      return ["", ...this.options];
    }
    return [...this.options];
  }

  @Emit('update:modelValue')
  updateValue(event: Event): string {
    return (event.target as HTMLInputElement)!.value;
  }
}
</script>

<style lang="sass" scoped>
select
  @include input-field

</style>
