import {Store} from "vuex";
import {DefaultStore} from "@/ts/store/default/default-store";
import {OnboardingStore} from "@/ts/store/onboarding/onboarding-store";
import {GrowslStore} from "@/ts/store/growl/growl-store";
import {SchedulingBiometricStore} from "@/ts/store/scheduling-biometric/scheduling-biometric-store";
import {BiometricPhysicianFormStore} from "@/ts/store/biometric-physician-form/biometric-physician-form-store";
import {SchedulingCoachingStore} from "@/ts/store/scheduling-coaching/scheduling-coaching-store";

/*
 * this vueStore module is required to assemble general vue store,
 * the names in this vue store should be same as name parameter in @Store class module decorator
 */
export const vueStore = new Store({
  modules: {
    default: DefaultStore,
    onboarding: OnboardingStore,
    growls: GrowslStore,
    schedulingBiometric: SchedulingBiometricStore,
    biometricPhysicianForm: BiometricPhysicianFormStore,
    schedulingCoaching: SchedulingCoachingStore,
  },
});
