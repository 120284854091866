<template>
  <navbar-page
    title="My Rewards"
    :profile-section="true"
    :reward-points="true"
    class="body"
  >
    <loading-suspense :loading="loading" :error="error">
      <div class="grid">
        <div class="header-image">
          <img src="@/assets/img/rewards-main-image.svg" alt="main-image"/>
        </div>
        <div v-if="gatekeeperItems.length" class="gatekeeper-items">
          <gatekeeper-items
            default-description="These activities must be complete in order to be eligible for the incentive."
            :items="gatekeeperItems"
            show-only-pending-items
          />
        </div>
        <div class="rewards-boxes">
          <rewards-boxes
            v-if="rewardBoxes"
            :rewards="rewardBoxes"
            :rewards-mall-url="rewardsMallUrl"
            :is-hard-coded-url="isHardCodedUrl"
            :is-cash-rewards-available="isCashRewardsAvailable"
            @redeem-cash="redeemCash"
          />
        </div>
        <div v-if="rewardTracker" class="reward-tracker">
          <div>
            <img src="@/assets/img/trophy-icon.svg" alt="trophy"/>
            <div class="points-earned-text">
              Total points earned in Program: <b>{{ rewardTracker.currentProgramPointsInfo.earnedPoints }}</b>
            </div>
          </div>
        </div>
        <div class="history-button-wrapper">
          <button-link
            v-if="rewardTracker"
            class="history-button"
            theme="white"
            :to="$pagesPath.main.transactionsHistory"
          >
            <img src="@/assets/img/new-reward.svg" alt="history"/> See History
          </button-link>
        </div>
      </div>
    </loading-suspense>
  </navbar-page>
</template>

<script lang="ts" setup>
import NavbarPage from "@/vue/templates/navbar-page.vue";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import ButtonLink from "@/vue/atoms/button-link.vue";
import type {GetRewardTrackerDTO} from "@/ts/types/dto/reward-tracker";
import RewardsBoxes from "@/vue/organisms/rewards-boxes.vue";
import type {RedeemIncentiveWebResponseDTO} from "@/ts/types/dto/web.dto";
import {CachedApiMixin} from "@/ts/mixins/cached-api-mixin";
import type {RewardBoxesData} from "@/ts/types/component/reward-boxes";
import {formatCash} from "@/ts/utils/pure-functions";
import {computed, inject, onMounted, ref} from "vue";
import type {Api} from "@/ts/classes/api";
import type {CachedApi} from "@/ts/classes/cached-api";
import GatekeeperItems from "@/vue/molecules/gatekeeper-items.vue";
import type {GatekeeperItem, MyProgramResponse} from "@/ts/types/dto/program.dto";
import {HealthContentActivityResponseType} from "@/ts/types/dto/health.dto";

const $api = inject("$api")! as Api;
const $cachedApi = inject("$cachedApi")! as CachedApi;
const loading = ref<boolean>(true);
const error = ref<string>("");

const rewardTracker = ref<GetRewardTrackerDTO | null>(null);
const rewardBoxes = ref<RewardBoxesData | null>(null);
const rewardsMallUrl = ref<string>("");
const isHardCodedUrl = ref<boolean>(false);
const program = ref<MyProgramResponse | null>(null);
const gatekeeperItems = ref<GatekeeperItem[]>([]);
const healthContent = ref<GatekeeperItem[]>();

const earnedCashFormatted = computed(() => rewardTracker.value?.earnedCash || 0);

const isCashRewardsAvailable = computed(() => rewardTracker.value?.currentProgramPointsInfo.isCashRewardsAvailable || false);

const redeemCash = (date: string): void => {
  if (rewardBoxes.value) {
    rewardBoxes.value.redeemedCashDate = date;
  }
};
onMounted(async() => {
  try {
    program.value = await $cachedApi.getProgram();
    gatekeeperItems.value = await $api.getGatekeeperItems(program.value.id, HealthContentActivityResponseType.HealthActivityGatekeepers);

    const [rewardTrackerResp, incentiveWebResp, redeemCashStatusResp] = await Promise.allSettled([
      $cachedApi.getRewardTracker(),
      $api.getIncentiveWeb(),
      $api.getRedeemCashStatus(),
    ]);

    const rewardBoxesValue: RewardBoxesData = {
      maxPoints: 0,
      earnedPoints: 0,
      availableCash: 0,
      availablePoints: 0,
      pendingPoints: 0,
    };

    if (rewardTrackerResp.status === "fulfilled") {
      rewardTracker.value = rewardTrackerResp.value;
      rewardBoxesValue.maxPoints = rewardTracker.value.currentProgramPointsInfo.maxPoints;
      rewardBoxesValue.earnedPoints = rewardTracker.value.currentProgramPointsInfo.earnedPoints;
      rewardBoxesValue.availableCash = rewardTracker.value.availableCash;
      rewardBoxesValue.availablePoints = rewardTracker.value.currentProgramPointsInfo.availablePoints;
      rewardBoxesValue.pendingPoints = rewardTracker.value.currentProgramPointsInfo.pendingPoints;
    }

    if (incentiveWebResp.status === "fulfilled") {
      rewardsMallUrl.value = incentiveWebResp.value.url;
      isHardCodedUrl.value = incentiveWebResp.value.isHardCoded;
    }

    if (redeemCashStatusResp.status === "fulfilled") {
      rewardBoxesValue.redeemedCashDate = redeemCashStatusResp.value.redeemedDate;
    }

    rewardBoxes.value = rewardBoxesValue;
  } catch (err) {
    error.value = err as string;
  } finally {
    loading.value = false;
  }
});
</script>
<style lang="sass" scoped>
.body
  background-image: url("@/assets/img/background-profile.svg")
  background-position-y: -10rem
  background-repeat: no-repeat
  background-size: contain
  color: $color-primary-hc-blue-100
  @include Asap700
  position: absolute
  height: 100%
  width: 100%

.grid
  margin: 0 auto
  max-width: 904px
  padding-bottom: 45px
  display: grid
  align-items: flex-start
  grid-template-columns: 1fr 1fr
  gap: 26px

.header-image
  grid-column: span 2
  display: flex
  justify-content: center

.gatekeeper-items
  background-color: $color-primary-hc-blue-10
  border-radius: 16px
  padding: 24px

.reward-tracker
  grid-column: span 2
  border-top: 1.5px solid $color-neutral-platinum-100
  margin: 40px 0
  max-width: 920px
  width: 100%
  display: flex
  align-content: center
  @include Roboto400
  color: $color-primary-hc-blue-100
  font-size: 14px

  > div
    margin: auto
    display: flex
    align-items: center
    padding-top: 17px

.main-image
  margin-top: -80px

.points-earned-text
  padding-right: 12px
  padding-left: 10px

.cash-earned-text
  padding-left: 12px

.history-button-wrapper
  grid-column: span 2
  display: flex
  justify-content: center

.history-button
  margin: 25px 0
</style>
