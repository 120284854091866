<template>
  <card-row v-if="challenges?.items?.length > 0" :title="title" :amount="challenges.items.length">
    <template #extraButton>
      <button class="icon" @click="togglePanel">
        <icon-chameleon
          color="primary100"
          hover-color="primary100"
          :icon="(showPanel ? DownChevron : RightChevron)"
        />
      </button>
    </template>
    <expansion-panel
      :expanded="showPanel"
    > 
      <template #body>
        <div v-for="item in challengeList" :key="item.challenge.id">
      <challenge-card
        v-if="!item.isFinished"
        :challenge="item.challenge"
      />
      <challenge-card-completed
        v-if="item.isFinished"
        :challenge="item.challenge"
      />
    </div>
       </template>
    </expansion-panel> 

  </card-row>
</template>
<script lang="ts" setup>
import type {ChallengeDTO, PaginatedChallengeDTO} from "@/ts/types/dto/challenges.dto";
import ChallengeCard from "@/vue/molecules/challenge/challenge-card.vue";
import CardRow from "@/vue/molecules/card-row.vue";
import ChallengeCardCompleted from "@/vue//molecules/challenge/challenge-card-completed.vue";
import {determineChallengeStatus} from "@/ts/utils/pure-functions";
import {ChallengeStatus} from "@/ts/types/component/challenges";
import {computed, ref} from "vue";
import {useRoute} from "vue-router";
import ExpansionPanel from "@/vue/atoms/expansion-panel.vue";
import RightChevron from "@/assets/img/right-chevron.svg";
import DownChevron from "@/assets/img/down-chevron.svg";
import IconChameleon from "@/vue/atoms/icon-chameleon.vue";



const props = defineProps<{
  title: string;
  challenges: PaginatedChallengeDTO;
}>();
const showPanel = ref<boolean>(false);
const togglePanel = () => {
      showPanel.value = !showPanel.value;
};

const challengeList = computed((): {challenge: ChallengeDTO; isFinished: boolean}[] => {
  return props.challenges.items.map((challenge) => ({
    challenge,
    isFinished: challenge.challengeStatus === ChallengeStatus.Completed ||
    challenge.challengeStatus === ChallengeStatus.GracePeriod,
  }));
});

</script>
