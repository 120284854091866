<template>
  <popup-modal :model-value="modelValue" @update:model-value="close">
    <div class="wrap">
      <activity-logging :track-activity="trackActivity" :type="type" @finish="close" @tracking-updated="emitTrackingUpdated"/>
      <div v-if="loading" class="spinner"/>
      <div v-else-if="type !== TaskType.mood" class="scroll-view">
        <div v-for="(date, dayIndex) in sortedTrackingDays" :key="dayIndex">
          <p>{{ getMonthDayYear(parseDate(date)) }}</p>
          <activity-logging-history
            v-if="trackingByDay.get(date)?.has('manual')"
            provider="manual"
            :amount="formatTaskAmountByType(trackingByDay.get(date)?.get('manual')!, type as TaskType)"
          />
          <activity-logging-history
            v-for="(item, index) in Array.from(trackingByDay.get(date)?.keys() ?? []).filter(f => f !== 'manual')"
            :key="index"
            :provider="item"
            :amount="formatTaskAmountByType(trackingByDay.get(date)?.get(item)!, type as TaskType)"
          />
        </div>
      </div>
    </div>
  </popup-modal>
</template>

<script lang="ts" setup>

import PopupModal from "@/vue/atoms/popup-modal.vue";
import ActivityLogging from "@/vue/molecules/tracking/activity-logging.vue";
import ActivityLoggingHistory from "@/vue/molecules/tracking/activity-logging-history.vue";
import type {GetTrackingListResponseDTO} from "@/ts/types/dto/tracking.dto";
import type {Api} from "@/ts/classes/api";
import {TrackingProvider} from "@/ts/types/dto/tracking.dto";
import {formatTaskAmountByType} from "@/ts/utils/pure-functions";
import {TaskType} from "@/ts/types/dto/activity.dto";
import {
  convertDateToServerFormat,
  getEndOfToday,
  getStartOfToday,
  isTimestampBeforeReferenceTimestamp,
  getMonthDayYear,
  parseDate,
  getStartOfTheMonth,
} from "@/ts/utils/date-pure-functions";

import {computed, ref, watch, inject, onMounted} from "vue";
import type {GetProgramResponseDTO} from "@/ts/types/dto/program.dto";
import {Emit} from "vue-property-decorator";

const props = defineProps<{
  modelValue: boolean;
  type: string;
  trackActivity: string;
}>();

const emit = defineEmits(["update:modelValue", "tracking-updated"]);
const $api = inject("$api")! as Api;

const program = ref<GetProgramResponseDTO>();
const loading = ref(false);

// Map of dates with value of mapping from providers to amounts
const trackingByDay = ref(new Map<string, Map<string, number>>());

const sortedTrackingDays = computed(() => {
  return Array.from(trackingByDay.value.keys()).sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
});

const close = (): boolean => {
  emit("update:modelValue");
  return false;
};

const emitTrackingUpdated = (): void => {
  emit("tracking-updated");
};

watch(() => props.modelValue, async(val) => {
  await onModelValueChanged(val);
});

const onModelValueChanged = async(val: boolean): Promise<void> => {
  if (val) {
    loading.value = true;
    program.value = await $api.getProgram();
    trackingByDay.value = new Map<string, Map<string, number>>();
    await getTrackingItems();
    loading.value = false;
  }
};

const getTrackingItems = async(): Promise<void> => {
  const programStartDate = program.value?.startDate ? new Date(program.value.startDate) : getStartOfTheMonth(getStartOfToday());
  const response = await $api.getTrackingList({
    taskType: props.type,
    startDate: convertDateToServerFormat(programStartDate),
    endDate: convertDateToServerFormat(getEndOfToday()),
  });

  // do not sum up weight and heard-rate
  const sumUp = !(props.type === TaskType.weight || props.type === TaskType.heart_rate);

  const trackingMap = new Map<string, GetTrackingListResponseDTO[]>();
  for (const item of response) {
    // append each item to corresponding date mapping
    trackingMap.set(item.date, [...(trackingMap.get(item.date) ?? []), item]);
  }

  for (const date of trackingMap.keys()) {
    const trackingItems = trackingMap.get(date);
    if (!trackingItems || trackingItems.length < 1) {
      return;
    }

    if (!trackingByDay.value.has(date)) {
      trackingByDay.value.set(date, new Map());
    }

    const tracking = trackingByDay.value.get(date);
    if (tracking) {
      populateMap(tracking, trackingItems, sumUp);
    }
  }
};

const populateMap = (map: Map<string, number>, items: GetTrackingListResponseDTO[], sumUp: boolean): void => {
  if (sumUp) {
    items.forEach((item) => {
      map.set(
        item.provider,
        (map.get(item.provider) ?? 0) + item.trackedAmount,
      );
    });
  } else {
    const recordsByProviderDict = new Map<string, GetTrackingListResponseDTO[]>();

    // separate records by provider
    items.forEach((item) => {
      if (!recordsByProviderDict.has(item.provider)) {
        recordsByProviderDict.set(item.provider, []);
      }

      recordsByProviderDict.get(item.provider)!.push(item);
    });

    // reduce to the latest stored amount-value
    recordsByProviderDict.forEach((value, providerKey) => {
      const latestValue = value
        .reduce((recordA: GetTrackingListResponseDTO, recordB: GetTrackingListResponseDTO) => {
          return isTimestampBeforeReferenceTimestamp(recordA.createdAt, recordB.createdAt) ? recordB : recordA;
        }).trackedAmount;

      map.set(providerKey, latestValue);
    });
  }

  // make sure manual is always in the list
  map.set(
    TrackingProvider.manual,
    map.get(TrackingProvider.manual) ?? 0,
  );
};

</script>

<style lang="sass" scoped>
.wrap
  display: flex
  flex-direction: column
  height: 600px
  width: 800px
  overflow: auto

p
  @include Asap700
  font-size: 20px
  color: $color-primary-hc-blue-100
  border-bottom: 1px solid $color-neutral-platinum-40
  margin-left: 10px
  margin-bottom: 0

.scroll-view
  overflow: auto

.spinner
  @include lds-spinner(20px, "Loading", false, $color-secondary-state-blue-100)
  margin-top: 32px
</style>
