<template>
  <div class="tabs">
    <router-link
      v-for="item in items"
      :key="item.link"
      class="tab"
      :to="item.link"
    >
      {{ item.title }}
    </router-link>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";
import type {Tab} from "@/ts/types/component/tabs-navigation";

@Component({})
export default class TabsNavigation extends Vue {
  @Prop()
  items!: Tab[];
}
</script>

<style lang="sass" scoped>
.tabs
  display: flex
  flex-direction: row
  justify-content: center
  margin: 0 auto
  gap: 4rem
  border-bottom: 3px solid $color-neutral-platinum-100

.tab
  padding: 2rem 2.5rem
  width: fit-content
  display: flex
  justify-content: center
  text-transform: uppercase
  @include Roboto600
  color: $color-primary-hc-blue-100
  position: relative

  &.router-link-exact-active
    color: $color-secondary-state-blue-100

    &::after
      content: ""
      background: $color-secondary-state-blue-100
      height: 3px
      position: absolute
      bottom: -3px
      /* stylelint-disable */
      width: -webkit-fill-available
</style>
