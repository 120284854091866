<template>
  <loading-suspense :error="error" :loading="loading">
    <router-view/>
  </loading-suspense>
</template>

<script lang="ts">
import {Component} from "vue-property-decorator";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import {mixins} from "vue-class-component";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import {
  SchedulingCoachingState,
  SchedulingCoachingStoreMixin,
} from "@/ts/store/scheduling-coaching/scheduling-coaching-store-instance";
import type {SchedulingCoachingAppointment} from "@/ts/types/store/scheduling-coaching-store-types";

@Component({
  name: 'CoachingSchedulingParentPage',
  components: {LoadingSuspense},
})
export default class CoachingSchedulingParentPage extends mixins(LoadingMixin, SchedulingCoachingStoreMixin) {
  @SchedulingCoachingState
  appointment!: SchedulingCoachingAppointment;

  @DefaultGrowlError
  async created(): Promise<void> {
    this.schedulingCoachingStore.setAppointment(null);
    const appointment = await this.$api.getCoachingScheduleAppointment();
    this.schedulingCoachingStore.setAppointment(appointment);
    if (appointment) {
      void this.$router.push(this.$pagesPath.coaching.appointmentConfirmed);
    }
  }
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>
</style>
