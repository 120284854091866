import {pagesPath} from "@/ts/router/pages-path";
import type {RouteRecordRaw} from "vue-router";
import PhysicianFormPage from "@/vue/pages/physician-form/physician-form.page.vue";
import PhysicianDownloadFormPage from "@/vue/pages/physician-form/physician-form-download.page.vue";
import PhysicianUploadFormPage from "@/vue/pages/physician-form/physician-form-upload.page.vue";
import PhysicianFormExaminationInformation from "@/vue/pages/physician-form/physician-form-exam-information.page.vue";

export const biometricPhysicianFormPages: RouteRecordRaw = {
  path: pagesPath.physicianForm.physicianForm,
  component: PhysicianFormPage,
  children: [
    {
      path: pagesPath.physicianForm.downloadForm,
      component: PhysicianDownloadFormPage,
    },
    {
      path: pagesPath.physicianForm.uploadForm,
      component: PhysicianUploadFormPage,
    },
    {
      path: pagesPath.physicianForm.examinationInformation,
      component: PhysicianFormExaminationInformation,
    },
  ],
};
