<template>
  <challenge-time-label/>
  <div class="main">
    <leaderboard-challenge-cover-image/>
    <challenge-description/>
    <challenge-rewards-section/>
    <challenge-ranking-list :ranking="ranking"/>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import ErrorText from "@/vue/atoms/error-text.vue";
import NavbarPage from "@/vue/templates/navbar-page.vue";
import ChallengeDescription from "@/vue/molecules/challenge/challenge-description.vue";
import ChallengeRewardsSection from "@/vue/molecules/challenge/challenge-rewards-section.vue";
import type {
  RankingPosition,
} from "@/ts/types/dto/leaderboard-challenge.dto";
import ChallengeCreatedByLabel from "@/vue/molecules/challenge/challenge-created-by.vue";
import challengeParticipants from "@/vue/molecules/challenge/challenge-participants.vue";
import ChallengeFriendsJoined from "@/vue/molecules/challenge/challenge-friends-joined.vue";
import ChallengeTimeLabel from "@/vue/molecules/challenge/challenge-time-label.vue";
import ChallengeRankingList from "@/vue/molecules/challenge/challenge-ranking-list.vue";
import LeaderboardChallengeCoverImage from "@/vue/molecules/challenge/leaderboard-challenge-cover-image.vue";

@Component({
  components: {
    LeaderboardChallengeCoverImage,
    NavbarPage,
    ErrorText,
    ButtonPrimary,
    ChallengeRewardsSection,
    ChallengeDescription,
    ChallengeCreatedByLabel,
    challengeParticipants,
    ChallengeFriendsJoined,
    ChallengeTimeLabel,
    ChallengeRankingList,
  },
})
export default class LeaderboardChallengeActive extends Vue {
  @Prop()
  ranking!: RankingPosition[];
}
</script>

<style lang="sass" scoped>

</style>
