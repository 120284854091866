<template>
  <div class="password-wrapper">
    <field-set id="password" :label="passwordLabel">
      <input-password
        id="password"
        v-model="password"
        name="password"
        autocomplete="new-password"
        :placeholder="passwordPlaceholder"
        :validity="passwordValidity"
        @update-on-blur:model-value="onPasswordChanged"
      />
    </field-set>
    <field-set id="confirm-password" :label="confirmPasswordLabel">
      <input-password
        id="confirm-password"
        v-model="confirmPassword"
        name="confirm-password"
        autocomplete="new-password"
        :placeholder="confirmPasswordPlaceholder"
        :validity="confirmPasswordValidity"
        @update-on-blur:model-value="onConfirmPasswordChanged"
      />
    </field-set>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch, Emit} from "vue-property-decorator";
import FieldSet from "@/vue/molecules/field-set.vue";
import InputPassword from "@/vue/atoms/input-password.vue";
import {validatePassword} from "@/ts/utils/validation";

@Component({
  name: "PasswordChange",
  components: {
    FieldSet,
    InputPassword,
  }
})
export default class PasswordChange extends Vue {
  @Prop({default: "New Password"})
  passwordLabel!: string;

  @Prop({default: "Confirm Password"})
  confirmPasswordLabel!: string;

  @Prop()
  passwordPlaceholder!: string;

  @Prop()
  confirmPasswordPlaceholder!: string;

  @Prop()
  passwordProp!: string;

  @Prop()
  confirmPasswordProp!: string;

  password: string = "";

  confirmPassword: string = "";

  passwordValidity: string = "";

  confirmPasswordValidity: string = "";

  get hasError(): boolean {
    return Boolean(
      !this.password ||
      !this.confirmPassword ||
      this.passwordValidity ||
      this.confirmPasswordValidity,
    );
  }

  created(): void {
    this.password = this.passwordProp ?? "";
    this.confirmPassword = this.confirmPasswordProp ?? "";
  }

  @Watch("password")
  onPasswordChanged(password: string): void {
    if (password === "") {
      this.passwordValidity = "Password cannot be empty";
    } else {
      const isValidPassword = validatePassword(password);
      if (isValidPassword) {
        this.passwordValidity = "";
      } else {
        this.passwordValidity = "Password criterias are not met";
      }
    }
  }

  @Watch("confirmPassword")
  onConfirmPasswordChanged(confirmPassword: string): void {
    if (confirmPassword === "") {
      this.confirmPasswordValidity = "Password confirmation cannot be empty";
    } else if (this.password === confirmPassword) {
      this.confirmPasswordValidity = "";
    } else {
      this.confirmPasswordValidity = "Passwords don't match";
    }
  }

  @Emit('passwordHasError')
  @Watch('hasError')
  updateValue(hasError: boolean): boolean {
    return hasError;
  }

  @Emit("passwordChanged")
  @Watch("password")
  passwordChanged(): string {
    return this.password;
  }

  @Emit("confirmPasswordChanged")
  @Watch("confirmPassword")
  confirmPasswordChanged(): string {
    return this.confirmPassword;
  }
}
</script>

<style lang="sass" scoped>
.password-wrapper
  display: flex
  flex-direction: column
  gap: 24px
</style>
