<template>
  <empty-state
    v-if="!anyChallenges"
    :image="noChallengeImage"
    title="No Challenges Available"
    description="Please contact your admin."
  />
  <loading-suspense :error="error" :loading="loading">
    <main v-if="anyChallenges" class="content">
      <challenge-card-row title="Active" :challenges="activeChallenges"/>
      <challenge-card-row title="Joined & Active Soon" :challenges="joinedChallenges"/>
      <challenge-card-row title="Available" :challenges="availableChallenges"/>
      <challenge-card-row title="Completed" :challenges="completedChallenges"/>
    </main>
  </loading-suspense>
  <add-tracking-button/>
</template>

<script lang="ts">
import {Component} from "vue-property-decorator";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import ChallengeCardRow from "@/vue/molecules/challenge-card-row.vue";
import {mixins} from "vue-class-component";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import type {ChallengeDTO, PaginatedChallengeDTO} from "@/ts/types/dto/challenges.dto";
import EmptyState from "@/vue/atoms/empty-state.vue";
import noChallengeImage from "@/assets/img/challenge-card-icons/person-practicing-yoga.svg";
import {ChallengeStatus} from "@/ts/types/component/challenges";
import {determineChallengeStatus} from "@/ts/utils/pure-functions";
import AddTrackingButton from "@/vue/atoms/add-tracking-button.vue";
import { ActivityCardModel } from "@/ts/types/component/activity-card";
import { ActivityDTO } from "@/ts/types/dto/activity.dto";

@Component({
  name: "ChallengeProgramPage",
  components: {
    AddTrackingButton,
    ChallengeCardRow,
    LoadingSuspense,
    EmptyState,
  },
})
export default class ChallengeProgramPage extends mixins(LoadingMixin) {
  challenges: ChallengeDTO[] = [];
  activeChallenges?: PaginatedChallengeDTO;
  completedChallenges?: PaginatedChallengeDTO;
  availableChallenges?: PaginatedChallengeDTO;
  joinedChallenges?: PaginatedChallengeDTO;
  gracePeriodChallenges?: PaginatedChallengeDTO;
  anyChallenges: boolean = false;

   getAvailableChallenges(): Promise<PaginatedChallengeDTO> {
    return this.$api.getChallenges({
        onlyAvailable: true,
        limit: 100,
      });
  }

     getActiveChallenge()  :  Promise<PaginatedChallengeDTO> {
    return this.$api.getChallenges({
        onlyActive: true,
        limit: 100,
      });
      
    
  }

   getCompletedChallenges(): Promise<PaginatedChallengeDTO> {
    return this.$api.getChallenges({
        onlyCompleted: true,
        limit: 100,
      });
  }

  getjoinedChallenges():  Promise<PaginatedChallengeDTO> {
    return this.$api.getChallenges({
        onlyJoined: true,
        limit: 100,
      });
  }
  getGracePeriodChallenges():  Promise<PaginatedChallengeDTO> {
    return this.$api.getChallenges({
        onlyGrace: true,
        limit: 100,
      });
  }
  get noChallengeImage(): string {
    return noChallengeImage;
  }

  @DefaultGrowlError
  async created(): Promise<void> {

    
    const [activeResponse, availableResponse, completedResponse,joinedResponse,graceResponse] = await Promise.allSettled([
    this.getActiveChallenge(),
    this.getAvailableChallenges(),
    this.getCompletedChallenges(),
    this.getjoinedChallenges(),
    this.getGracePeriodChallenges(),
    ]);
    if (activeResponse.status === "fulfilled") {
      this.activeChallenges = activeResponse?.value
      this.anyChallenges = true;
    }

    if (availableResponse.status === "fulfilled") {
      this.availableChallenges = availableResponse?.value;
      this.anyChallenges = true;

    }

    if (completedResponse.status === "fulfilled") {
      this.completedChallenges = completedResponse?.value;
      this.anyChallenges = true;

    }
    if (joinedResponse.status === "fulfilled") {
      this.joinedChallenges = joinedResponse?.value;
      this.anyChallenges = true;

    }
    if (graceResponse.status === "fulfilled") {
      this.gracePeriodChallenges = graceResponse?.value;
      this.anyChallenges = true;

      if(this.completedChallenges?.items != undefined){
        this.completedChallenges.items = this.completedChallenges?.items.concat(this.gracePeriodChallenges.items);
        this.completedChallenges.pagination.total += this.gracePeriodChallenges.pagination.total
      }
      //TODO: maybe handle this combination in terms of the pagination params

    }
    // TODO: handle pagination and offset with slider/scroll


  }
}
</script>

<style lang="sass" scoped>
.content
  margin-top: 3rem
</style>
