export interface InputSelectOption {
  label: string;
  value: string;
}

export interface MultipleCheckboxItem<T> {
  text: string;
  id: string;
  data: T;
}

export enum GenderType {
  MALE = "male",
  FEMALE = "female",
  NON_BINARY = "non_binary",
}

export enum PeriodType {
  WEEKLY = "weekly",
  MONTHLY = "monthly",
  YEARLY = "yearly",
  ALL_TIME = "all_time"
}
