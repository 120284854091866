<template>
  <popup-modal
    :model-value="modelValue"
    @update:modelValue="updateModelValue"
  >
    <div class="modal-content">
      <action-title-heading
        title="Coaching"
        :subtext="subtext"
        description="Your Program includes Coaching."
        display-coaching-icon="true"
      />
      <error-text :error="error"/>
      <div class="buttons">
        <button-primary
          :flexible-width="true"
          theme="white"
          class="cancel-btn"
          @click="onClickSkipCoaching"
        >
          <img src="@/assets/img/skip-icon.svg"/>
          Skip
        </button-primary>
        <button-link
          :to="to"
          :flexible-width="true"
          theme="dark"
          :loading="loading"
          class="confirm-btn"
        >
          Schedule a Session
          <img src="@/assets/img/right-arrow-white.svg"/>
        </button-link>
      </div>
    </div>
  </popup-modal>
</template>
<script lang="ts">
import {Component, Emit, Prop} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import ButtonLink from "@/vue/atoms/button-link.vue";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import {GrowlsStoreMixin} from "@/ts/store/growl/growl-store-instance";
import ErrorText from "@/vue/atoms/error-text.vue";
import {SchedulingBiometricStoreMixin} from "@/ts/store/scheduling-biometric/scheduling-biometric-store-instance";
import PopupModal from "@/vue/atoms/popup-modal.vue";
import ActionTitleHeading from "@/vue/molecules/action-title-heading.vue";
import type {RouteLocationRaw} from "vue-router";

@Component({
  name: "CoachingPopup",
  components: {ButtonLink, ActionTitleHeading, ButtonPrimary, ErrorText, PopupModal, }
})
export default class CoachingPopup extends mixins(LoadingMixin, SchedulingBiometricStoreMixin, GrowlsStoreMixin) {
  @Prop()
  modelValue!: boolean;

  @Prop()
  isGateKeeper!: boolean;

  @Prop()
  to!: RouteLocationRaw | string;

  get subtext(): string {
    return this.isGateKeeper ? "Required Activity" : "Recommended Activity";
  }

  @Emit("update:modelValue")
  updateModelValue(val: boolean): boolean {
    return val;
  }

  @DefaultGrowlError
  async deleteAppointment(): Promise<void> {
    await this.$api.deleteBiometricScheduleAppointment();
    this.schedulingBiometricStore.setAppointment(null);
    void this.growlsStore.growlInfo("Appointment has been deleted");
    void this.$router.push(this.$pagesPath.main.home);
  }

  @Emit('skip')
  onClickSkipCoaching(): void {
  }
}
</script>
<style lang="sass" scoped>
.large
  font-size: 18px

.modal-content
  //align-items: center
  display: flex
  flex-direction: column
  min-width: 600px

.buttons
  display: flex
  gap: 16px
  justify-content: right
  margin-top: 50px

</style>
