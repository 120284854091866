<template>
  <button-link
    v-if="showButton"
    theme="dark"
    class="large"
    :disabled="buttonDisabled"
    :to="schedulerLink"
  >
    Schedule a Screening
  </button-link>
</template>

<script lang="ts" setup>
import ButtonLink from "@/vue/atoms/button-link.vue";
import type {MyProgramResponse} from "@/ts/types/dto/program.dto";
import type {
  BiometricScreeningAppointment,
  BiometricScreeningAvailable, BiometricScreeningScheduler,
  BiometricScreeningStatus,
} from "@/ts/types/dto/biometric-screening.dto";
import {
  hasProgramBioscreeningAvailable,
} from "@/ts/utils/my-health/program-bioscreening";
import {
  isBioscreeningUnavailable,
  isBioscreeningSubmitted,
  isAppointmentScheduled,
} from "@/ts/utils/my-health/bioscreening";
import {computed, inject, ref, watch, watchEffect} from "vue";
import type {Api} from "@/ts/classes/api";
import type {PagesPath} from "@/ts/router/pages-path";

const $api: Api = inject("$api")! as Api;
const $pagesPath = inject<PagesPath>("$pagesPath")!;

const props = defineProps<{
  biometricStatus: BiometricScreeningStatus | null;
  appointment: BiometricScreeningAppointment | null;
  program: MyProgramResponse;
  biometricAvailable: BiometricScreeningAvailable | null;
}>();

const schedulerLink = ref<string>("");

const buttonDisabled = computed((): boolean => {
  const unavailable = isBioscreeningUnavailable({
    biometricAvailable: props.biometricAvailable,
    biometricStatus: props.biometricStatus,
    appointment: props.appointment,
  });
  const hasProgramBioscreeningUnavailable = !hasProgramBioscreeningAvailable(props.program);
  return hasProgramBioscreeningUnavailable || unavailable || !schedulerLink.value;
});

const showButton = computed((): boolean => {
  const isBioscreeningNotSubmitted = !isBioscreeningSubmitted(props.biometricStatus);
  const isNoAppointmentScheduled = !isAppointmentScheduled(props.appointment);
  return isBioscreeningNotSubmitted && isNoAppointmentScheduled;
});

watchEffect(() => {
  if (showButton.value) {
    void (async() => {
      let schedulerInfo: BiometricScreeningScheduler = {sourceType: "InternalRequest"};
      try {
        schedulerInfo = (await $api.getBiometricScreeningScheduleStart()) ?? schedulerInfo;
      } finally {
        if (schedulerInfo.sourceType === "External" && schedulerInfo.url) {
          schedulerLink.value = schedulerInfo.url;
        } else if (schedulerInfo.sourceType === "SSO") {
          const ssoSchedulerUrl = (await $api.getBiometricSchedulingSSOUrl()) ?? "";
          schedulerLink.value = ssoSchedulerUrl.url;
        } else {
          schedulerLink.value = $pagesPath.biometricScheduling.personalInformation;
        }
      }
    })();
  }
});

</script>

<style lang="sass" scoped>
.large
  font-size: 18px
</style>
