<template>
  <div class="biometric-screening-results">
    <div class="hra-wrapper">
      <title-icon
        :icon="hraIcon"
        text="Health Risk Assessment"
      />
      <button-link
        theme="dark"
        class="buttons"
        :disabled="!hraSummary?.dateCompleted"
        :to="$pagesPath.healthRiskAssessment.quizOverviewHra"
      >
        <img src="@/assets/img/icons/my-health/eye.svg" alt="Eye"/> See Submitted Answers
      </button-link>
    </div>

    <div
      v-if="biometricResult"
      class="biometric-screening-wrapper"
    >
      <title-icon
        :icon="stethoscopeIcon"
        text="Biometric Screening"
      />
      <div class="biometric-screening-download">
        <button-primary :loading="isDownloading" @click.prevent="onClickDownload">
          <img src="@/assets/img/icons/download_file.svg" alt="download results"/> Download Results
        </button-primary>
        <button-link :to="linkToFaxResults" theme="white">
          <img src="@/assets/img/icons/test.svg" alt="fax results"/> Fax Results
        </button-link>
      </div>
      <div class="screening-results">
        <biometric-screening-result-card
          v-for="(result,index) in biometricResult.biometricScreeningResults"
          :key="index"
          :result="result"
          :theme="BiometricScreeningResultCardTheme.white"
          @click="showScoredResult(result)"
        />
        <biometric-screening-result-card
          v-for="(result,index) in biometricResult.biometricScreeningAdditionalResults"
          :key="index"
          :result="result"
          :theme="BiometricScreeningResultCardTheme.gray"
          @click="showAdditionalResult(result)"
        />
      </div>
    </div>

    <biometric-result-popup
      v-model="showResultPopup"
      :result="selectedResult"
      :date="biometricResult?.date"
      :loading="isSelectedResultLoading"
    />
  </div>
</template>

<script lang="ts" setup>
import {BiometricScreeningResultCardTheme} from "@/ts/types/component/biometric-screening";
import stethoscopeIcon from "@/assets/img/icons/my-health/stethoscope.svg";
import hraIcon from "@/assets/img/icons/my-health/hra.svg";
import TitleIcon from "@/vue/atoms/title-icon.vue";
import ButtonLink from "@/vue/atoms/button-link.vue";
import BiometricScreeningResultCard from "@/vue/molecules/my-health/biometric-screening-result-card.vue";
import type {
  BiometricScreeningAdditionalResult,
  BiometricScreeningResult,
  BiometricScreeningWrapperResult,
} from "@/ts/types/dto/biometric-screening.dto";
import type {HRASummary} from "@/ts/types/dto/health-risk-assessment.dto";
import BiometricResultPopup from "@/vue/molecules/my-health/biometric-result-popup.vue";
import type {Ref} from "vue";
import {computed, inject, ref} from "vue";
import type {Api} from "@/ts/classes/api";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import {pagesPath} from "@/ts/router/pages-path";

const props = defineProps<{
  biometricResult: BiometricScreeningWrapperResult;
  hraSummary: HRASummary | null;
}>();

const $api = inject("$api")! as Api;

const showResultPopup: Ref<boolean> = ref(false);
const selectedResult: Ref<BiometricScreeningAdditionalResult | BiometricScreeningResult | null> = ref(null);
const isDownloading: Ref<boolean> = ref(false);
const isSelectedResultLoading: Ref<boolean> = ref(false);

const showScoredResult = async(result: BiometricScreeningResult): Promise<void> => {
  if (result.id === 0) {
    return;
  }
  showResultPopup.value = true;
  isSelectedResultLoading.value = true;
  selectedResult.value = await $api.getBiometricScoredResult(props.biometricResult.screeningResultId, result.id);
  isSelectedResultLoading.value = false;
};

const showAdditionalResult = async(result: BiometricScreeningAdditionalResult): Promise<void> => {
  showResultPopup.value = true;
  isSelectedResultLoading.value = true;
  selectedResult.value = await $api.getBiometricAdditionalResult(props.biometricResult.screeningResultId, result.id);
  isSelectedResultLoading.value = false;
};

const onClickDownload = async(): Promise<void> => {
  isDownloading.value = true;
  // Fetch PDF blob
  const blob = await $api.getBiometricScreeningPDF(props.biometricResult.screeningResultId);
  // Create a temporary URL for the PDF blob
  const url = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement("a");
  link.href = url;
  link.download = `biometric-screening-${props.biometricResult.screeningResultId}.pdf`;
  // Programmatically click the link to trigger the download
  link.click();
  // Clean up the temporary URL
  URL.revokeObjectURL(url);

  isDownloading.value = false;
};

// TODO we should rather use a router-link with the params
const linkToFaxResults = computed(() => pagesPath.biometricScreening.faxResults
    .replace(":id", props.biometricResult.screeningResultId.toString()));
</script>

<style lang="sass" scoped>
.text
  @include Roboto400
  font-size: 16px
  line-height: 24px
  margin-top: 16px
  margin-bottom: 32px

.hra-wrapper
  padding-bottom: 40px

.biometric-screening-wrapper
  border-top: 3px solid $color-neutral-platinum-100
  padding-top: 40px

.hra-wrapper,
.biometric-screening-wrapper
  align-items: flex-start
  display: flex
  flex-direction: column

.biometric-screening-download
  margin: 22px 0
  width: 100%
  display: flex

  > button
    margin-right: 22px

.screening-results
  column-gap: 16px
  display: flex
  flex-wrap: wrap
  margin-top: 22px
  margin-bottom: 32px
  row-gap: 22px

.buttons
  align-self: center
  margin-top: 15px
</style>
