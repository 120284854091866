<template>
  <profile-field
    name="Date of Birth"
    :content="dateOfBirthStr"
    :error="error"
    :loading="loading"
    :show-success="showSuccess"
    :is-enabled="false"
    @save="save"
  />
</template>

<script lang="ts">
import {Component} from "vue-property-decorator";
import ProfileField from "@/vue/organisms/profile/profile-field.vue";
import {DefaultState, DefaultStoreMixin} from "@/ts/store/default/default-store-instance";
import type {Profile} from "@/ts/types/store/default-store-types";
import type {PutProfileRequestDTO} from "@/ts/types/dto/profile.dto";
import {profileToPutProfileRequest} from "@/ts/utils/pure-functions";
import {
  convertDateToServerFormat,
  formatDateShortMonthName,
  parseDate, today,
} from "@/ts/utils/date-pure-functions";
import EditBirthdayInput from "@/vue/molecules/edit-field/edit-birthday-input.vue";
import {mixins} from "vue-class-component";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";

@Component({
  name: 'ProfileFieldBirthdate',
  components: {EditBirthdayInput, ProfileField}
})
export default class ProfileFieldBirthdate extends mixins(LoadingMixin, DefaultStoreMixin) {
  @DefaultState
  profile!: Profile;

  showSuccess: boolean = false;

  // this is required to populate vue3 proxy
  dateOfBirth: Date = today();

  get dateOfBirthStr(): string {
    return formatDateShortMonthName(this.dateOfBirth);
  }

  created(): void {
    this.dateOfBirth = this.profile.dateOfBirth;
  }

  @DefaultGrowlError
  async save(): Promise<void> {
    this.showSuccess = false;
    const value: PutProfileRequestDTO = {
      ...profileToPutProfileRequest(this.profile),
      dateOfBirth: convertDateToServerFormat(this.dateOfBirth),
    };
    const getProfileResponseDTO = await this.$api.updateProfile(value);
    const profileData: Profile = {
      ...getProfileResponseDTO,
      dateOfBirth: parseDate(getProfileResponseDTO.dateOfBirth),
      location: this.profile.location,
      department: this.profile.department,
    };
    this.defaultStore.setProfile(profileData);
    this.showSuccess = true;
  }
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>

</style>
