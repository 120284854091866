<template>
  <div>
    <img
      v-if="type === 'password'"
      class="icon"
      src="@/assets/img/login-eye.svg"
      @click="invertType"
    />
    <img
      v-else
      class="icon"
      src="@/assets/img/eye-crossed.svg"
      @click="invertType"
    />
    <input
      :id="id"
      v-validity="validity"
      :value="modelValue"
      :type="type"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      required
      @change="updateValue"
      @blur="updateValueOnBlur"
    />
    <error-text :error="validity" class="error"/>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Emit,
  Prop,
  Vue,
} from "vue-property-decorator";
import ErrorText from "@/vue/atoms/error-text.vue";

@Component({
  name: "InputPassword",
  components: {
    ErrorText,
  }
})
export default class InputPassword extends Vue {
  @Prop() readonly modelValue!: string;

  @Prop({default: ""}) validity!: string;

  @Prop({default: ""}) autocomplete!: string;

  @Prop({default: "Password"}) placeholder!: string;

  @Prop({default: undefined}) id!: string | undefined;

  type: "password" | "text" = "password";

  @Emit('update:modelValue')
  updateValue(event: any): string {
    return event.target.value as string; // eslint-disable-line @typescript-eslint/no-unsafe-member-access
  }

  invertType(): void {
    if (this.type === "password") {
      this.type = "text";
    } else {
      this.type = "password";
    }
  }

  @Emit('updateOnBlur:modelValue')
  updateValueOnBlur(event: Event): string {
    return (event.target as HTMLInputElement)!.value as string;
  }
}
</script>

<style lang="sass" scoped>

div
  position: relative

.icon
  cursor: pointer
  position: absolute
  right: 12px
  width: 24px
  height: 24px
  top: 12px

  &:hover
    opacity: 0.8

input
  @include input-field

.error
  font-size: 14px
  line-height: 22px
  letter-spacing: 0.2px
  margin-top: 8px
</style>
