export enum PhysicianFormBirthGenderType {
  male = "male",
  female = "female",
}

export enum PhysicianFormUserType {
  dependent = "dependent",
  participant = "participant",
}

export enum PhysicianFormStatus {
  available = "available",
  pending = "pending",
  approved = "approved",
  denied = "denied",
  downloaded = "downloaded",
  overdue = "overdue",
}

export interface PhysicianFormDenial {
  id: number;
  deniedReasonId: string;
  deniedReason: string;
  deniedDate: string;
  cutoffDate: string;
}

export enum PhysicianFormBiometricType {
  number = "number",
  select = "select",
  component = "component",
  component_optional = "component_optional",
}

export interface PhysicianFormBiometricOptionType {
  name: string;
  value: string;
}

export interface PhysicianFormOptionalBiometric {
  id: string;
  name: string;
}

export interface PhysicianFormBiometric {
  id: string;
  name: string;
  type: PhysicianFormBiometricType;
  unitOfMeasure: string;

  isScored: boolean;
  isRequired: boolean;
  isSecondaryMeasurement: boolean;
  // when PhysicianFormBiometricType is "number".
  precision: number;
  scale: number;
  editMask: string;
  minValue: number;
  maxValue: number;
  // when PhysicianFormBiometricType is "Select"
  options: PhysicianFormBiometricOptionType;
  //
  components: any[];
  optionalBiometric: PhysicianFormOptionalBiometric;

  // value enter by user
  value: string;
}

export interface GetPhysicianFormBiometricResponse {
  id: number | null;
  companyName: string;
  locationCode: string;
  firstName: string;
  lastName: string;
  gender: PhysicianFormBirthGenderType;
  birthDate: string;
  last4Ssn: string;
  participantOrDependent: PhysicianFormUserType;
  primaryPhoneNumber: string;
  email: string;
  address1: string;
  address2: string;
  city: string;
  stateId: string;
  zip: string;
  status: PhysicianFormStatus;

  examFromDate: string;
  examToDate: string;
  examDate: string;
  isPregnant: boolean;
  isFasting: boolean;
  physicianClinic: string;
  physicianName: string;
  physicianPhone: string;
  physicianSignatureDate: string;
  physicianSigned: boolean;
  healthInfoRelease: boolean;
  uploadFormFileName: string;
  downloadDate: string;

  approvedDate: string;
  deadline: string;
  updateDate: string;
  submittedOn: string;
  url: string;

  denialDetails: PhysicianFormDenial;

  biometrics?: PhysicianFormBiometric[];
}

export type GetPhysicianFormBiometricDTO = GetPhysicianFormBiometricResponse;

export type PostPhysicianForm = GetPhysicianFormBiometricResponse;

export interface PhysicianFormBodyRequest {
  physicianFormDetails: GetPhysicianFormBiometricResponse;
  physicianFormFile: File | FormData;
}
