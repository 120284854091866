import type {HRASummary} from "@/ts/types/dto/health-risk-assessment.dto";
import type {MyProgramResponse} from "@/ts/types/dto/program.dto";
import {hasProgramHraAvailable} from "@/ts/utils/my-health/program-hra";

export function isHraAvailable(hraSummary: HRASummary | null): boolean {
  return Boolean(hraSummary?.available);
}

export function isHraCompleted(hraSummary: HRASummary | null): boolean {
  return Boolean(hraSummary?.dateCompleted);
}

export function isHraUnavailableBecauseCompleted(hraSummary: HRASummary | null): boolean {
  return !isHraAvailable(hraSummary) && isHraCompleted(hraSummary);
}

export function isHraUnavailable(hraSummary: HRASummary | null): boolean {
  return !isHraAvailable(hraSummary) && !isHraCompleted(hraSummary);
}

export function isHraUnavailableOrNotAvailableInProgram({
  program,
  hraSummary,
}: {
  program: MyProgramResponse | null;
  hraSummary: HRASummary | null;
}): boolean {
  return isHraUnavailable(hraSummary) || !hasProgramHraAvailable(program);
}

export function isHraAvailableInProgramAndCompleted({
  program,
  hraSummary,
}: {
  program: MyProgramResponse | null;
  hraSummary: HRASummary | null;
}): boolean {
  return hasProgramHraAvailable(program) && isHraCompleted(hraSummary);
}

export function isHraAvailableAndNotCompleted(hraSummary: HRASummary | null): boolean {
  return isHraAvailable(hraSummary) && !isHraCompleted(hraSummary);
}
