import {
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import type {SchedulingBiometricAppointment} from "@/ts/types/store/scheduling-biometric-store-types";
import type {BiometricScreeningLocation} from "@/ts/types/dto/biometric-screening.dto";

@Module({
  name: 'schedulingBiometric'
})
export class SchedulingBiometricStore extends VuexModule {
  public appointment: SchedulingBiometricAppointment | null = null;

  public location: BiometricScreeningLocation | null = null;

  @Mutation
  setAppointment(data: SchedulingBiometricAppointment | null): void {
    this.appointment = data;
  }

  @Mutation
  setLocation(data: BiometricScreeningLocation | null): void {
    this.location = data;
  }

  @Mutation
  clearAll(): void {
    this.appointment = null;
    this.location = null;
  }
}
