<template>
  <popup-modal :model-value="modelValue" @update:model-value="close">
    <div class="container">
      <loading-suspense :loading="loading">
        <div v-if="result" class="content">
          <h1>{{ result.name }}</h1>

          <div class="details">
            <div class="wrapper">
              <p class="value">
                {{ result.value }}<span class="unit">{{ result.unitOfMeasure }}</span>
              </p>

              <div v-if="result.riskLevel" :class="riskBarClass">
                <img
                  v-if="arrowPosition"
                  class="arrow"
                  src="@/assets/img/down-arrow.svg"
                  :style="{left: arrowPosition}"
                  alt="arrow"
                />
                <div class="bar"/>
              </div>

              <p v-if="result.riskLevel" class="risk">
                Your risk level is <span class="risk-factor bold">{{ result.riskLevel }}</span>
              </p>
              <alert-icon-card
                v-if="result.riskLevel && result.idealRangeValue"
                :text="`Ideal range is ${result.idealRangeValue}`"
                theme="info-white"
              />
              <p class="taken-date">
                Taken {{ takenDate }}
              </p>
            </div>

            <div v-if="result.pointsPossible" class="score">
              <p>Your score: {{ result.pointsReceived }}/{{ result.pointsPossible }}</p>
            </div>
          </div>

          <div class="about">
            <div v-if="result.description" class="description">
              <h4 class="title">
                {{ result.name }}:
              </h4>
              <html-rich-text :text="result.description"/>
            </div>
            <div v-if="result.takeActionHtml" class="action">
              <h4 class="title">
                Take Action:
              </h4>
              <html-rich-text :text="result.takeActionHtml"/>
            </div>
          </div>
        </div>
      </loading-suspense>
    </div>
  </popup-modal>
</template>

<script lang="ts" setup>
import PopupModal from "@/vue/atoms/popup-modal.vue";
import AlertIconCard from "@/vue/atoms/alert-icon-card.vue";
import {formatDateShortMonthName} from "@/ts/utils/date-pure-functions";
import HtmlRichText from "@/vue/atoms/html-rich-text.vue";
import {computed} from "vue";
import type {
  BiometricScreeningAdditionalResult,
  BiometricScreeningResult,
} from "@/ts/types/dto/biometric-screening.dto";
import {RiskColor} from "@/ts/types/dto/biometric-screening.dto";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";

const props = defineProps<{
  result: BiometricScreeningAdditionalResult | BiometricScreeningResult | null;
  date: string;
  modelValue: boolean;
  loading: boolean;
}>();

const takenDate = computed(() => formatDateShortMonthName(props.date));

const arrowPosition = computed(() => {
  switch (props.result?.riskColor) {
    case RiskColor.green:
      return "10%";
    case RiskColor.light_green:
      return "30%";
    case RiskColor.yellow:
      return "50%";
    case RiskColor.orange:
      return "70%";
    case RiskColor.red:
      return "80%";
    default:
      return null;
  }
});

const riskBarClass = computed(() => ["risk-bar", arrowPosition.value ? "move-upper" : ""]);

const emit = defineEmits(["update:modelValue"]);

const close = (): void => {
  emit("update:modelValue", false);
};
</script>

<!-- eslint-disable -->
<style lang="sass" scoped>
.container
  height: 80vh
  width: 600px
  display: flex
  flex-direction: column
  justify-content: center

.content
  overflow: auto

h1
  @include Asap700
  color: $color-primary-hc-blue-100
  font-size: 28px
  line-height: 36px
  margin: 0
  margin-bottom: 18px

.details
  background-color: $color-status-info-blue-10
  border-radius: 16px
  margin-bottom: 24px

.wrapper
  align-items: center
  display: flex
  flex-direction: column
  gap: 16px
  padding: 16px 24px

  p
    margin: 0

.value
  color: $color-secondary-state-blue-100
  @include Roboto500
  font-size: 50px
  line-height: 59px

.unit
  font-size: 20px
  line-height: 22px
  margin-left: 16px
  text-transform: uppercase

.risk-bar
  width: 60%

.move-upper
  margin-top: -30px

.arrow
  left: 90%
  margin-bottom: -19px
  position: relative

.bar
  background: linear-gradient(270deg, $color-risk-green 0%, $color-risk-light-green 27.08%, $color-risk-yellow 47.4%, $color-risk-orange 73.96%, $color-risk-red 100%)
  border-radius: 16px
  height: 12px
  opacity: 0.5
  transform: rotate(180deg)

.risk
  @include Roboto400
  color: $color-primary-hc-blue-100
  font-size: 16px
  line-height: 24px
  letter-spacing: 0.1px

.risk-factor
  text-transform: capitalize

.taken-date
  color: $color-primary-hc-blue-50
  @include Roboto400
  font-size: 12px
  line-height: 16px
  letter-spacing: 0.2px

.score
  width: 100%
  height: 32px
  display: flex
  justify-content: center
  align-items: center
  background-color: $color-secondary-state-blue-100
  color: $color-white
  font-size: 12px
  line-height: 16px
  text-transform: uppercase
  border-bottom-left-radius: 16px
  border-bottom-right-radius: 16px

.about
  color: $color-primary-hc-blue-100
  @include Roboto400
  letter-spacing: 0.1px
  font-size: 16px
  line-height: 24px
  display: flex
  flex-wrap: wrap
  gap: 32px

  > div
    width: 280px

.description .title
  margin-top: 0
  margin-bottom: 16px

.action .title
  margin: 0

</style>
