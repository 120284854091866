<template>
  <div v-if="isShown" :class="iconCardClasses" @click="handleClick">
    <img :src="image" :alt="title"/>
    <div class="info">
      <p class="title">
        {{ title }}
      </p>
      <p class="text">
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";

@Component({
  name: "IconCard",
  components: {},
})
export default class IconCard extends Vue {
  @Prop()
  image!: string;

  @Prop()
  title!: string;

  @Prop()
  text!: string;

  @Prop()
  isShown!: boolean;

  @Prop()
  handleClick!: (() => void) | undefined;

  get iconCardClasses(): string[] {
    return ["icon-card", this.handleClick ? "clickable" : ""];
  }
}
</script>

<style lang="sass" scoped>
.icon-card
  background-color: $color-white
  border-radius: 16px
  display: flex
  gap: 12px
  padding: 12px

  .info
    text-align: left

    .title
      @include Asap700
      color: $color-primary-hc-blue-100
      font-size: 16px
      line-height: 22px
      margin: 4px 0

    .text
      @include Roboto400
      color: $color-primary-hc-blue-50
      font-size: 12px
      line-height: 16px
      margin: 0 0 4px

.clickable
  cursor: pointer

</style>
