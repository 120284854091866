<template>
  <navbar-page class="body" title="Minutes of activity" :back-link="$pagesPath.profile.myProgress">
    <div class="container">
      <div class="chart">
        <line-chart v-if="hasData" :data="chartData" :options="options"/>
        <no-data-placeholder v-else/>
      </div>
      <div v-if="hasData" class="data-points">
        <div class="current-week">
          <p class="title">
            This week:
          </p>
          <p class="value">
            {{ sum.toLocaleString() }} <span>minutes</span>
          </p>
        </div>
        <ul class="days">
          <li v-for="(day, index) in days" :key="index" class="day">
            <div class="day-label">
              {{ dayLabel(day).value }}:
            </div><div class="day-value">
              {{ chartData.datasets[0].data[index].toLocaleString() }} <span>minutes</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </navbar-page>
</template>

<script lang="ts" setup>
import {Chart as ChartJS, PointElement, LinearScale, LineElement, CategoryScale} from "chart.js";
import {Line as LineChart} from "vue-chartjs";
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";
import {computed, inject, onMounted, ref} from "vue";
import type {Api} from "@/ts/classes/api";
import NavbarPage from "@/vue/templates/navbar-page.vue";
import {
  addToDate,
  convertDateToServerFormat, formatDateShortMonthName,
  getDayOfWeek, getMondayOfTheWeek, isSameDay,
} from "@/ts/utils/date-pure-functions";
import {TaskType} from "@/ts/types/dto/activity.dto";
import NoDataPlaceholder from "@/vue/molecules/my-progress/no-data-placeholder.vue";

const $api = inject("$api")! as Api;

const isLoading = ref<boolean>(true);
const error = ref<string>("");

ChartJS.register(LinearScale, PointElement, LineElement, ChartJsPluginDataLabels, CategoryScale);

const numberOfDays = 7;
const startDate: Date = getMondayOfTheWeek(new Date());
const days: Date[] = new Array(numberOfDays)
    .fill("")
    .map((day, index) => addToDate(startDate, {days: index}));
const labels: string[] = days.map((day) => getDayOfWeek(day).substring(0, 3)
    .toUpperCase());
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const chartData = ref<any>({labels,
datasets: []});
const sum = ref(0);

/* eslint-disable */
const options: any = {
  responsive: true,
  maintainAspectRatio: true,
  elements: {
    point: {
      radius: 5,
      borderWidth: 3,
      borderColor: '#EB61C7',
    },
    line: {
      borderColor: '#9EABBA',
    }
  },
  layout: {
    padding: {
      top: 20
    }
  },
  events: [],
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false
    },
    datalabels: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
      ticks: {
        color: '#9EABBA',
        padding: 10,
      },
    },
    y: {
      display: true,
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
      ticks: {
        color: '#9EABBA',
        padding: 10,
      },
    },
  },
};

onMounted(async () => {
  try {
    const trackingItems = await $api.getTrackingList({
      startDate: convertDateToServerFormat(days[0]),
      endDate: convertDateToServerFormat(days[days.length-1]),
      taskType: Object.values(TaskType)
        .filter(taskType => taskType.includes('time') || taskType == TaskType.general_physical_activity_time)
        .filter(taskType => taskType != TaskType.sleep_time)
        .join(',')}
    );
    const isoDateToMinutesCount: { [key: string]: number; } = days.reduce((acc,d) => ({...acc,[convertDateToServerFormat(d)]:0}),{})
    trackingItems.forEach(item => isoDateToMinutesCount[item.date] += Math.floor(item.trackedAmount/60))
    const data: number[] = Object.values(isoDateToMinutesCount)
    chartData.value.datasets = [{
      backgroundColor: '#FFFFFF',
      data
    }]
    if (chartData.value) {
      sum.value = chartData.value.datasets
        .map((dataset: {data: number[]}) => dataset.data.reduce((previousValue, currentValue) => previousValue + currentValue, 0))
        .reduce((previousValue: number, currentValue: any) => previousValue + currentValue, 0);
    }
  } catch (err) {
    // TODO handle error
    console.error(err)
  }
})

const hasData = computed((): boolean => Boolean(chartData.value) && sum.value > 0);

const dayLabel = (date: Date) => computed(() => isSameDay(date, new Date()) ? "Today" : formatDateShortMonthName(date))
</script>

<style lang="sass" scoped>
.body
  background-color: $color-neutral-platinum-40
  background-size: contain
  color: $color-primary-hc-blue-100
  @include Asap700
  width: 100%
  height: 100%
  min-height: 100vh

.container
  @include container
  display: grid
  grid-template-columns: 1fr 1fr
  grid-gap: 25px
  align-items: start

.chart, .data-points
  padding: 24px 16px
  box-shadow: 0 0 10px rgba(15, 38, 78, 0.15)
  border-radius: 10px
  background-color: $color-white

.current-week
  font-size: 20px

  .title
    color: $color-primary-hc-blue-100
    @include Asap700
    margin: 0

  .value
    @include Asap400
    margin: 0

    span
      color: $color-primary-hc-blue-50

.days
  list-style: none
  padding: 0
  margin-bottom: 0

.day
  @include Asap400
  margin: 16px 0
  display: grid
  grid-template-columns: 95px auto

  &:last-child
    margin-bottom: 0

  span
    color: $color-primary-hc-blue-50
</style>
