<template>
  <div class="onboarding-card-list">
    <onboarding-card
      title="Track Your Progress"
      class="precise-1"
      text="Monitor steps, activity, nutrition, sleep, and more for valuable health insights."
      :image="photo1"
    />
    <onboarding-card
      class="precise-2"
      title="Receive Support"
      text="Get the right support at the right time with our team of health coaches."
      :image="photo2"
    />
    <onboarding-card
      class="precise-3"
      title="Find Inspiration"
      text="Explore learning pathways, engaging activities, and exciting challenges to fuel your health journey."
      :image="photo3"
    />
    <onboarding-card
      class="precise-4"
      title="Build a Community"
      text="Grow with friends as you accomplish your wellness goals."
      :image="photo4"
    />
    <onboarding-card
      class="precise-5"
      title="Earn Rewards"
      text="Get rewarded for completing activities and improving your health."
      :image="photo5"
    />
    <onboarding-card
      class="precise-6"
      title="Stay Connected"
      text="Download the app to access your health information at the tap of a finger."
      :image="photo6"
    />
  </div>
</template>
<script lang="ts">
import {
  Component,
  Vue,
} from "vue-property-decorator";
import OnboardingCard from "@/vue/molecules/onboarding-card.vue";
import photo1 from "@/assets/img/onboarding-photos/onboarding-photo-1.svg";
import photo2 from "@/assets/img/onboarding-photos/onboarding-photo-2.svg";
import photo3 from "@/assets/img/onboarding-photos/onboarding-photo-3.svg";
import photo4 from "@/assets/img/onboarding-photos/onboarding-photo-4.svg";
import photo5 from "@/assets/img/onboarding-photos/onboarding-photo-5.svg";
import photo6 from "@/assets/img/onboarding-photos/onboarding-photo-6.svg";

@Component({
  name: "OnboardingCardList",
  components: {
    OnboardingCard,
  },
})
export default class OnboardingCardList extends Vue {
  get photo1(): string {
    return photo1;
  }

  get photo2(): string {
    return photo2;
  }

  get photo3(): string {
    return photo3;
  }

  get photo4(): string {
    return photo4;
  }

  get photo5(): string {
    return photo5;
  }

  get photo6(): string {
    return photo6;
  }
}
</script>

<style lang="sass" scoped>
.onboarding-card-list
  padding-left: 2rem
  padding-right: 2rem
  display: flex
  flex-direction: column
  max-width: 1156px
  margin: 104px auto auto
  gap: 96px

.precise-1 :deep(img)
  bottom: -25px

.precise-2 :deep(img)
  bottom: -25px

.precise-3 :deep(img)
  bottom: -5px

.precise-4 :deep(img)
  bottom: -5px

.precise-5 :deep(img)
  bottom: -15px

.precise-6 :deep(img)
  bottom: 5px
</style>
