import {
  Component,
  Vue,
} from "vue-property-decorator";
import {cachedApi} from "@/ts/instances/main-instances";
import type {CachedApi} from "@/ts/classes/cached-api";

/**
 * @deprecated use inject in order to get access to cachedApi instance
 */

@Component({})
export class CachedApiMixin extends Vue {
  cachedApi!: CachedApi;

  created(): void {
    this.cachedApi = cachedApi;
  }
}
