<template>
  <register-page
    :prev-page="$pagesPath.auth.signIn"
    :current-step="1"
    title="User Verification"
    subtitle="Please verify your personal information to create your account."
    :loading="loading"
    @next="goNext"
  >
    <edit-first-name v-model="firstName"/>
    <edit-last-name v-model="lastName"/>
    <field-set id="birthday" label="Birthday">
      <edit-birthday-input v-model="dateOfBirth"/>
    </field-set>
    <field-set
      v-if="companyCode !== 'GDINT'"
      id="gender"
      required
      label="Gender"
    >
      <edit-gender-select v-model="gender"/>
    </field-set>
    <field-set
      id="sex"
      required
      label="Sex at Birth"
      :full="true"
    >
      <edit-sex-select v-model="sex"/>
    </field-set>
    <field-set v-if="!companyCode" id="company-code" label="Company Code">
      <edit-company-code-input v-model="companyCode"/>
    </field-set>
    <div v-else>
      <label class="company-code-label">Company Code</label>
      <p class="company-code-value">
        {{ companyCode }}
      </p>
    </div>
    <field-set
      id="unique-id"
      required
      label="Unique ID"
    >
      <edit-unique-id-input v-model="uniqueId"/>
      <p class="forgot">
        Forgot your unique ID?
        <span @click="showForgotPopup">Contact support here.</span>
      </p>
    </field-set>
    <error-text :error="error"/>
  </register-page>

  <static-forgot-popup
    :displayed="isForgotPopupDisplayed"
    forgot-unique-id="true"
    @close-popup="hideForgotPopup"
  />
</template>

<script lang="ts">
import {Component, Watch} from "vue-property-decorator";
import {GenderType} from "@/ts/types/component/input.select";
import RegisterPage from "@/vue/templates/register-page.vue";
import {convertDateToServerFormat, parseDate} from "@/ts/utils/date-pure-functions";
import type {VerificationData} from "@/ts/types/store/onboarding-store-types";
import ErrorText from "@/vue/atoms/error-text.vue";
import EditFirstName from "@/vue/molecules/edit-field/edit-first-name.vue";
import EditLastName from "@/vue/molecules/edit-field/edit-last-name.vue";
import EditCompanyCodeInput from "@/vue/molecules/edit-field/edit-company-code-input.vue";
import FieldSet from "@/vue/molecules/field-set.vue";
import EditGenderSelect from "@/vue/molecules/edit-field/edit-gender-select.vue";
import EditUniqueIdInput from "@/vue/molecules/edit-field/edit-unique-id-input.vue";
import EditBirthdayInput from "@/vue/molecules/edit-field/edit-birthday-input.vue";
import {SexType} from "@/ts/types/dto/auth.dto";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import {mixins} from "vue-class-component";
import {OnboardingStoreMixin, OnboardingState} from "@/ts/store/onboarding/onboarding-store-instance";
import EditSexSelect from "@/vue/molecules/edit-field/edit-sex-select.vue";
import StaticForgotPopup from "@/vue/organisms/static-forgot-popup.vue";

@Component({
  name: "UserVerification",
  components: {
    EditCompanyCodeInput,
    EditBirthdayInput,
    EditUniqueIdInput,
    EditGenderSelect,
    EditSexSelect,
    FieldSet,
    ErrorText,
    EditLastName,
    EditFirstName,
    RegisterPage,
    StaticForgotPopup,
  },
})
export default class UserVerificationPage extends mixins(LoadingMixin, OnboardingStoreMixin) {
  firstName: string = "";

  lastName: string = "";

  dateOfBirth: Date | null = null;

  gender: GenderType = GenderType.MALE;

  sex: SexType = SexType.MALE;

  companyCode: string = "";

  uniqueId: string = "";

  isForgotPopupDisplayed: boolean = false;

  @OnboardingState
  userVerificationData!: VerificationData | null;

  @Watch('sex')
  onSexChange(newSex: SexType) {
    if (this.companyCode.toUpperCase() === "GDINT") {
      this.gender = this.convertSexToGender(newSex);
    }
  }

  convertSexToGender(sex: SexType): GenderType {
    switch (sex) {
      case SexType.MALE:
        return GenderType.MALE;
      case SexType.FEMALE:
        return GenderType.FEMALE;
      default:
        return GenderType.NON_BINARY;
    }
  }

  created(): void {
    // Existing code to retrieve companyCode from localStorage
    const savedCompanyCode = localStorage.getItem("companyCode") || "";
    if (savedCompanyCode) {
      this.companyCode = savedCompanyCode;
    }

    // New code to retrieve firstName and lastName from localStorage
    const savedFirstName = localStorage.getItem("firstName") || "";
    if (savedFirstName) {
      this.firstName = savedFirstName;
    }

    const savedLastName = localStorage.getItem("lastName") || "";
    if (savedLastName) {
      this.lastName = savedLastName;
    }

    // Existing code to populate fields from userVerificationData if available
    if (this.userVerificationData) {
      this.firstName = this.userVerificationData.firstName;
      this.lastName = this.userVerificationData.lastName;
      this.gender = this.userVerificationData.gender;
      this.sex = this.userVerificationData.sex;
      this.dateOfBirth = parseDate(this.userVerificationData.dateOfBirth);
      this.uniqueId = this.userVerificationData.uniqueId;
      this.companyCode = this.userVerificationData.companyCode;
    }
  }


  @DefaultGrowlError
  async goNext(): Promise<void> {
    if (!this.dateOfBirth) {
      throw Error("Birthday is required");
    }
    if (this.dateOfBirth.getTime() > Date.now()) {
      throw Error("Date can't be in the future");
    }
    const redirectUrl = localStorage.getItem("redirectUrl"); // this is only ever set when the user came from an sso process
    if (!redirectUrl && this.companyCode.toUpperCase() === "LUMER") {
      throw new Error("Please use your company's internal system to create your account. For more information, refer to your myHC360+ communications.");
    }
    const verificationData: VerificationData = {
      companyCode: this.companyCode,
      firstName: this.firstName,
      lastName: this.lastName,
      uniqueId: this.uniqueId,
      dateOfBirth: convertDateToServerFormat(this.dateOfBirth),
      sex: this.sex,
      gender: this.gender,
    };

    const {censusToken} = await this.$api.checkCensus(verificationData);
    this.onboardingStore.setCheckToken(censusToken);
    this.onboardingStore.setUserVerificationData(verificationData);

    await this.$router.push(this.$pagesPath.auth.registration);
  }

  showForgotPopup(): void {
    this.isForgotPopupDisplayed = true;
  }

  hideForgotPopup(): void {
    this.isForgotPopupDisplayed = false;
  }
}
</script>

<style lang="sass" scoped>
.forgot
  @include Roboto400
  color: $color-primary-hc-blue-100
  font-size: 14px
  line-height: 22px
  letter-spacing: 0.2px

  span
    @include Roboto600
    color: $color-secondary-state-blue-100

    &:hover
      text-decoration: underline
      cursor: pointer

.company-code-label
  @include Asap700
  color: $color-primary-hc-blue-100

.company-code-value
  @include Asap400
  color: $color-primary-hc-blue-100

</style>
