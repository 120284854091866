<template>
  <popup-modal :model-value="displayed" @update:model-value="closePopup">
    <div class="content">
      <h3 class="title">
        Welcome!
      </h3>
      <p class="text">
        Please enter your <span>company code</span> to begin your registration process.
      </p>
      <form @submit.prevent="checkCompanyCode">
        <field-set id="companyCode" class="label" label="My Company Code">
          <edit-company-code-input
            v-model="companyCode"
          />
        </field-set>
        <error-text :error="error"/>
        <p class="forgot">
          Forgot your company code?
          <span @click="showForgotPopup">Contact support here.</span>
        </p>
        <button-primary
          class="button"
          :loading="loading"
          type="submit"
          text="Begin Registration"
          theme="dark"
        />
      </form>
    </div>
  </popup-modal>

  <static-forgot-popup
    :displayed="isForgotPopupDisplayed"
    forgot-company-code="true"
    @close-popup="hideForgotPopup"
  />
</template>

<script lang="ts" setup>
import {ref, inject} from "vue";
import {useRouter} from "vue-router";
import PopupModal from "@/vue/atoms/popup-modal.vue";
import ErrorText from "@/vue/atoms/error-text.vue";
import FieldSet from "@/vue/molecules/field-set.vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import EditCompanyCodeInput from "@/vue/molecules/edit-field/edit-company-code-input.vue";
import StaticForgotPopup from "@/vue/organisms/static-forgot-popup.vue";
import type {Api} from "@/ts/classes/api";
import type {CompanyCodeDTO} from "@/ts/types/dto/company-code.dto";

const $api = inject("$api")! as Api;

const props = defineProps({
  displayed: Boolean,
});

const emit = defineEmits(["closePopup"]);

const companyCode = ref("");
const loading = ref(false);
const error = ref("");
const isForgotPopupDisplayed = ref(false);

const router = useRouter();

const showForgotPopup = () => {
  isForgotPopupDisplayed.value = true;
};

const hideForgotPopup = () => {
  isForgotPopupDisplayed.value = false;
};

const closePopup = () => {
  emit("closePopup");
};

const checkCompanyCode = async() => {
error.value = "";

// TODO: Make check for SSO dynamic https://software.cottinghambutler.com/healthcheck360/myhc360/-/issues/493
if (companyCode.value.toUpperCase() === "LUMER") {
  error.value = "Please use your company's internal system to create your account. For more information refer to your myHC360+ communications.";
  return;
}

loading.value = true;
try {
    const payload: CompanyCodeDTO = {
      CompanyCode: companyCode.value,
    };

    await $api.checkCompanyCode(payload);
    localStorage.setItem("companyCode", companyCode.value);
    router.push("/auth/sign-up/verification");
  } catch (e) {
    error.value = "The company code you entered is not a valid company code.";
  } finally {
    loading.value = false;
  }
};
</script>

<style lang="sass" scoped>
.forgot
  @include Roboto400
  color: $color-primary-hc-blue-100
  font-size: 14px
  line-height: 22px
  letter-spacing: 0.2px

  span
    @include Roboto600
    color: $color-secondary-state-blue-100

    &:hover
      text-decoration: underline
      cursor: pointer

.content
    display: flex
    flex-direction: column
    max-width: 652px

.title
    @include Asap700
    color: $color-primary-hc-blue-100
    font-size: 28px
    line-height: 36px
    margin-top: 0
    margin-bottom: 16px

.text
    @include Roboto400
    color: $color-primary-hc-blue-100
    font-size: 16px
    line-height: 24px
    letter-spacing: 0.1px

span
    @include Roboto700

form
    display: flex
    flex-direction: column
    margin-top: 40px

.label
    font-size: 16px
    line-height: 22px
    letter-spacing: 0.15px

.button
    margin-top: 48px
    align-self: flex-end

img
    align-self: center
    height: 132px
    width: 183px
</style>
