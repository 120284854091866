import type {GenderType} from "@/ts/types/component/input.select";
import type {Interests} from "@/ts/types/store/onboarding-store-types";

export interface AuthLoginRequestDTO {
  username: string;
  password: string;
}

export interface UserNameAvailableRequestDTO {
  username: string;
}

export interface UserNameAvailableResponseDTO {
  isAvailable: boolean;
}

export interface EmailAddressAvailableRequestDTO {
  email: string;
}

export interface EmailAddressAvailableResponseDTO {
  isAvailable: boolean;
}

export interface AuthLoginResponseDTO {
  sessionToken?: string; // Make optional if MFA may precede session token issuance
  refreshToken?: string; // Make optional for the same reason as above
  registerUrl?: string;
  claims?: Record<string, unknown>;
  mfaToken?: string; // Optional MFA token for cases where further verification is needed
}

export interface MfaVerificationRequestDTO {
  MfaToken: string;
  AuthCode: string;
}

export interface AuthRefreshRequestDTO {
  refreshToken: string;
}

export type AuthRefreshResponseDTO = AuthLoginResponseDTO;

export enum SexType {
  MALE = "M",
  FEMALE = "F",
}

export interface CheckCensusRequestDTO {
  firstName: string;
  lastName: string;
  companyCode: string;
  uniqueId: string;
  dateOfBirth: string;
  sex: SexType;
  gender: GenderType;
}

export interface CheckCensusResponseDTO {
  censusToken: string;
}

export interface AuthRegisterRequestDTO {
  username: string;
  email: string;
  password: string;
  gender: GenderType;
  sex: SexType;
  phoneNumber: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postcode: string;
  motivationQuote: string;
  dateOfBirth: string;
  interests: Interests;
  hasConsentedToTexting: boolean;
}
