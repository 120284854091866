<template>
  <popup-modal :model-value="modelValue" @update:model-value="close">
    <loading-suspense :error="error" :loading="loading">
      <h1>
        Please confirm your appointment
      </h1>
      <div class="date">
        <span>
          {{ fullDate }}
        </span>
        <span>
          Your phone: {{ phoneNumber }}
        </span>
      </div>
      <button-primary
        text="Confirm appointment"
        :loading="loading"
        class="button"
        @click="postAppointment"
      >
        Confirm appointment <img src="@/assets/img/icon-check-w.svg"/>
      </button-primary>
    </loading-suspense>
  </popup-modal>
</template>

<script lang="ts">
import {Component, Prop, Emit} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import ButtonLink from "@/vue/atoms/button-link.vue";
import {SchedulingCoachingStoreMixin} from "@/ts/store/scheduling-coaching/scheduling-coaching-store-instance";
import {dateToFullFormat} from "@/ts/utils/date-pure-functions";
import TimeSelector from "@/vue/molecules/time-selector.vue";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import PopupModal from "@/vue/atoms/popup-modal.vue";
import type {PostCoachingScheduleAppointmentResponse} from "@/ts/types/dto/coaching.dto";

@Component({
  name: 'ScheduleCoachingModal',
  components: {
    PopupModal,
    LoadingSuspense,
    TimeSelector,
    ButtonPrimary,
    ButtonLink,
  },
})
export default class ScheduleCoachingModal extends mixins(LoadingMixin, SchedulingCoachingStoreMixin) {
  @Prop()
  phoneNumber!: string;

  @Prop()
  selectedTime!: Date;

  @Prop() modelValue!: boolean;

  get fullDate(): string | null {
    if (this.selectedTime) {
      return dateToFullFormat(this.selectedTime);
    }
    return null;
  }

  @Emit('update:modelValue')
  close(): boolean {
    return false;
  }

  @DefaultGrowlError
  async postAppointment(): Promise<void> {
    const resp: PostCoachingScheduleAppointmentResponse = await this.$api.postCoachingScheduleAppointment({
      phoneNumber: this.phoneNumber,
      timestamp: this.selectedTime!.toISOString(),
    });
    this.schedulingCoachingStore.setAppointment(resp);
    void this.$router.push(this.$pagesPath.coaching.appointmentConfirmed);
  }
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>
h1
  color: $color-primary-hc-blue-100
  @include Asap700
  font-size: 32px
  margin: -20px 50px 32px 0

.date
  @include Asap700
  color: $color-primary-hc-blue-100
  font-size: 16px
  letter-spacing: 0.15px
  display: flex
  line-height: 22px
  flex-direction: column

.location
  @include Asap400
  font-size: 16px
  color: $color-primary-hc-blue-100
  letter-spacing: 0.15px
  margin-top: 24px
  margin-bottom: 8px

.button
  margin-top: 48px
  margin-left: auto
</style>
