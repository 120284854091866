<!--
  THIS component was extracted as separate page for 2 reasons:
  - when appointment is confirmed if it's a modal and user closes it he should not be able to pick a data,
  and disabling all buttons and calendar seems ridiculous
  - we neeed to show user that appointment is created if it was created before
-->
<template>
  <scheduling-page v-if="appointment" :step="3" title="Appointment confirmed">
    <div class="appointment-wrapper">
      <alert-icon-card
        theme="success"
        text="You have scheduled the following biometric screening."
      />
      <biometric-appointment-details
        :timestamp="appointment.timestamp"
        :location="appointment.location"
      />
      <div class="buttons">
        <cancel-appointment appointment-type="screening"/>
        <export-to-cal-btn :appointment="appointment"/>
      </div>
    </div>
  </scheduling-page>
</template>

<script lang="ts">
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {
  SchedulingBiometricState,
  SchedulingBiometricStoreMixin,
} from "@/ts/store/scheduling-biometric/scheduling-biometric-store-instance";
import type {SchedulingBiometricAppointment} from "@/ts/types/store/scheduling-biometric-store-types";
import SchedulingPage from "@/vue/templates/scheduling-page.vue";
import ExportToCalBtn from "@/vue/molecules/export-to-cal-btn.vue";
import BiometricAppointmentDetails from "@/vue/molecules/biometric-appointment-details.vue";
import AlertIconCard from "@/vue/atoms/alert-icon-card.vue";
import CancelAppointment from "@/vue/molecules/cancel-appointment.vue";

@Component({
  name: 'SchedulingAppointmentConfirmedPage',
  components: {
    SchedulingPage,
    ExportToCalBtn,
    BiometricAppointmentDetails,
    AlertIconCard,
    CancelAppointment,
  },
})
export default class SchedulingAppointmentConfirmedPage extends mixins(SchedulingBiometricStoreMixin) {
  @SchedulingBiometricState
  appointment!: SchedulingBiometricAppointment;
}
</script>
<style lang="sass" scoped>
.appointment-wrapper
  display: flex
  flex-direction: column
  gap: 30px
  max-width: 600px

.buttons
  display: flex
  gap: 15px
  justify-content: space-between
  margin-top: 20px

</style>
