<template>
  <div class="duration">
    <img :src="iconTime" alt="time remaining"/>
    <p>
      {{ dateValue }}
    </p>
  </div>
  <!-- <div v-if="isInGracePeriod" class="grace">
    <div class="info">
      <img src="@/assets/img/info-icon.svg"/>
      <p>
        We are processing the data to announce the winners! Thanks for waiting.
      </p>
    </div>
  </div> -->
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import iconTime from "@/assets/img/challenge-card-icons/stopwatch.svg";
import {formatDateShortMonthName, getRemainNumberOfDays} from "@/ts/utils/date-pure-functions";
import type {LeaderboardChallengeDTO} from "@/ts/types/dto/leaderboard-challenge.dto";
import {DefaultState} from "@/ts/store/default/default-store-instance";
import {determineChallengeStatus} from "@/ts/utils/pure-functions";
import {ChallengeStatus} from "@/ts/types/component/challenges";

@Component({
  name: "ChallengeTimeLabel",
})
export default class ChallengeTimeLabel extends Vue {
  @DefaultState
  leaderboardChallenge!: LeaderboardChallengeDTO;

  get iconTime(): string {
    return iconTime;
  }

  get isInGracePeriod(): boolean {
    return this.leaderboardChallenge.challengeStatus === ChallengeStatus.GracePeriod
  }

  get dateValue(): string {
    const daysUntilStart = getRemainNumberOfDays(this.leaderboardChallenge.startDate);
    const duration = getRemainNumberOfDays(this.leaderboardChallenge.endDate);
    const challengeStatus = determineChallengeStatus(this.leaderboardChallenge);
    switch (challengeStatus) {
      case ChallengeStatus.Active:
        return duration === 0 ? `Ends Today` : duration > 1 ? `Ends in ${duration} days` : `Ends in ${duration} day`;
      case ChallengeStatus.Available:
        if (daysUntilStart > 1) {
          return `Starts in ${daysUntilStart} days`;
        } else if (daysUntilStart === 1) {
          return `Starts in ${daysUntilStart} day`;
        } else {
          return duration === 0 ? `Ends Today` : duration > 1 ? `Ends in ${duration} days` : `Ends in ${duration} day`;
        }
      case ChallengeStatus.Joined:
        return duration === 0 ? `Ends Today` : duration > 1 ? `Ends in ${duration} days` : `Ends in ${duration} day`;
      case ChallengeStatus.Completed:
        return `Ended on ${formatDateShortMonthName(this.leaderboardChallenge.endDate)}`;
      default:
        return "";
    }
  }
}
</script>

<style lang="sass" scoped>
.duration
  display: flex
  justify-content: center
  align-items: center
  color: $color-tertiary-orange-100
  @include Roboto700
  font-size: 14px
  gap: 4px

.grace
  text-align: left
  margin: 1rem auto
  width: fit-content

.info
  padding: 16px
  border: 1px solid $color-neutral-platinum-100
  background-color: $color-status-info-blue-10
  border-radius: 16px
  display: flex
  gap: 8px

  p
    @include Roboto400
    font-size: 14px
    line-height: 22px
    color: $color-primary-hc-blue-100
    margin: 0
</style>
