<template>
  <scheduling-page :step="2" title="Select a Location">
    <loading-suspense :loading="loading" :error="error">
      <form @submit.prevent="goNext">
        <div class="fields-holder">
          <field-set id="state" label="State">
            <input-object-drop-down
              id="state"
              v-model="stateCode"
              autocomplete="zip"
              placeholder="State"
              required
              :options="states"
            />
          </field-set>
          <field-set id="city" label="City">
            <input-drop-down
              id="city"
              v-model="city"
              required
              autocomplete="city"
              placeholder="Select one city"
              :options="cities"
            />
          </field-set>
          <field-set id="city" label="Location">
            <input-object-drop-down
              id="location"
              v-model="locationId"
              required
              placeholder="Select one location"
              :options="locations"
            />
          </field-set>
        </div>
        <div class="buttons">
          <button-link :to="$pagesPath.biometricScheduling.personalInformation" theme="white">
            <img src="@/assets/img/left-arrow-cal.svg"/>Back
          </button-link>
          <button-primary type="submit">
            Next <img class="next-img" src="@/assets/img/right-arrow-white.svg"/>
          </button-primary>
        </div>
      </form>
    </loading-suspense>
  </scheduling-page>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Watch,
} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import {DefaultState} from "@/ts/store/default/default-store-instance";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import SchedulingPage from "@/vue/templates/scheduling-page.vue";
import ButtonLink from "@/vue/atoms/button-link.vue";
import type {Profile} from "@/ts/types/store/default-store-types";
import EditState from "@/vue/molecules/edit-field/edit-state.vue";
import FieldSet from "@/vue/molecules/field-set.vue";
import InputDropDown from "@/vue/atoms/input-drop-down.vue";
import {SchedulingBiometricStoreMixin} from "@/ts/store/scheduling-biometric/scheduling-biometric-store-instance";
import type {BiometricScreeningLocation} from "@/ts/types/dto/biometric-screening.dto";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import {
  formatAddress,
  uniqueOnly,
  uniqueOnlyById,
} from "@/ts/utils/pure-functions";
import InputObjectDropDown from "@/vue/atoms/input-object-drop-down.vue";
import type {InputDropDownObject} from "@/ts/types/component/input-drop-down-object";

@Component({
  name: 'SchedulingSelectLocationPage',
  components: {
    InputObjectDropDown,
    LoadingSuspense,
    InputDropDown,
    FieldSet,
    EditState,
    ButtonLink,
    SchedulingPage,
    ButtonPrimary,
  },
})
export default class SchedulingSelectLocationPage extends mixins(LoadingMixin, SchedulingBiometricStoreMixin) {
  @DefaultState
  profile!: Profile;

  stateCode: string = "";

  city: string = "";

  locationId: number = 0;

  biometricLocations: BiometricScreeningLocation[] = [];

  get states(): InputDropDownObject[] {
    const uniqueLocations = uniqueOnlyById(this.biometricLocations, (item: BiometricScreeningLocation) => item.stateCode);
    return uniqueLocations.map((bl) => ({
      label: bl.stateName,
      id: bl.stateCode,
    }));
  }

  get cities(): string[] {
    return this.stateCode ? uniqueOnly(
      this.biometricLocations
        .filter((bl) => bl.stateCode === this.stateCode)
        .map((location) => location.city),
    ) : [];
  }

  get locations(): InputDropDownObject[] {
    return this.city ? uniqueOnly(
      this.biometricLocations
        .filter((location) => this.city === location.city)
        .map((location) => ({
          label: formatAddress(location),
          id: location.id,
        })),
    ) : [];
  }

  @DefaultGrowlError
  async created(): Promise<void> {
    this.biometricLocations = await this.$api.getBiometricScreeningLocations();
  }

  goNext(): void {
    const location: BiometricScreeningLocation = this.biometricLocations.find((bl) => bl.id === this.locationId)!;
    this.schedulingBiometricStore.setLocation(location);
    void this.$router.push(this.$pagesPath.biometricScheduling.time);
  }
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>
.fields-holder
  width: 448px
  display: flex
  flex-direction: column
  gap: 32px

  > *
    width: 100%

.next-img
  margin-left: 8px

.buttons
  display: flex
  width: 100%
  margin-top: 56px
  justify-content: space-between

  :deep(button)
    width: auto
    padding-left: 29px
    padding-right: 29px
</style>
