<template>
  <field-set id="state" label="State">
    <input-drop-down
      id="state"
      :model-value="modelValue"
      autocomplete="address-level1"
      placeholder="State"
      :options="states"
      required
      @update:model-value="updateValue"
    />
  </field-set>
</template>

<script lang="ts">
import {
  Component,
  Emit,
  Prop,
  Vue,
} from "vue-property-decorator";
import FieldSet from "@/vue/molecules/field-set.vue";
import InputText from "@/vue/atoms/input-text.vue";
import allowedStates from "@/ts/json/allowed-states.json";
import InputDropDown from "@/vue/atoms/input-drop-down.vue";

@Component({
  components: {InputDropDown, FieldSet, InputText}
})
export default class EditState extends Vue {
  @Prop() modelValue!: string;

  get states(): string[] {
    return allowedStates;
  }

  @Emit('update:modelValue')
  updateValue(value: string): string {
    return value;
  }
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>

</style>
