import {Module, Mutation, VuexModule} from "vuex-module-decorators";
import type {PhysicianFormBiometric} from "@/ts/types/store/biometric-physician-form-store-types";

@Module({
  name: "biometricPhysicianForm",
})
export class BiometricPhysicianFormStore extends VuexModule {
  public physicianForm: PhysicianFormBiometric | null = null;

  public physicianFormFile: File | null = null;

  @Mutation
  setPhysicianForm(data: PhysicianFormBiometric | null): void {
    this.physicianForm = data;
  }

  @Mutation
  setPhysicianFormFile(data: File | null): void {
    this.physicianFormFile = data;
  }

  @Mutation
  clearAll(): void {
    this.physicianForm = null;
    this.physicianFormFile = null;
  }
}
