<template>
  <div class="followup-container">
    <div class="question">
      {{ question.question }} <span v-if="question.isRequired">*</span>
    </div>
    <multiple-checkbox
      v-model="selected"
      :values="optionList"
      class="centered"
    />
  </div>
</template>

<script lang="ts">
import {Component, Vue, Prop, Emit, Watch} from "vue-property-decorator";
import PopupModal from "@/vue/atoms/popup-modal.vue";
import MultipleCheckbox from "@/vue/molecules/multiple-checkbox.vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import type {MultipleCheckboxItem} from "@/ts/types/component/input.select";
import type {
  HRATakingMedicationsQuestion,
  HRATakingMedicationsQuestionChoiceDetail,
} from "@/ts/types/dto/health-risk-assessment.dto";
import {buildMedicationQuestionOption} from "@/ts/utils/hra/common";

@Component({
  name: "HraFollowupQuestion",
  components: {
    PopupModal,
    MultipleCheckbox,
    ButtonPrimary,
  },
})
export default class HraFollowupQuestion extends Vue {
  @Prop() error!: string;

  @Prop() question!: HRATakingMedicationsQuestion;

  @Prop() alreadySelected!: MultipleCheckboxItem<HRATakingMedicationsQuestionChoiceDetail>[];

  selected: MultipleCheckboxItem<HRATakingMedicationsQuestionChoiceDetail>[] = [];

  get optionList(): MultipleCheckboxItem<HRATakingMedicationsQuestionChoiceDetail>[] {
    return this.question!.selections.map(buildMedicationQuestionOption);
  }

  @Watch('selected')
  @Emit('selected')
  emitSelected(): MultipleCheckboxItem<HRATakingMedicationsQuestionChoiceDetail>[] {
    return this.selected;
  }

  created(): void {
    this.selected = [...this.alreadySelected];
  }
}
</script>

<style lang="sass" scoped>
.followup-container
  display: flex
  flex-direction: column
  align-items: center
  margin-top: 40px

.question
  @include Asap700
  color: $color-primary-hc-blue-100

.save
  display: flex
  margin-top: 2rem

</style>
