<template>
  <alert-icon-card theme="success" title="Physician Form Submission Approved">
    <div class="text">
      Your Physician form was approved on <span class="bold"> {{ approvedDate }} </span>.
      <br/>
      Thank you, and be well!
    </div>
  </alert-icon-card>
</template>

<script lang="ts" setup>
import AlertIconCard from "@/vue/atoms/alert-icon-card.vue";
import {formatDateShortMonthName} from "@/ts/utils/date-pure-functions";
import type {GetPhysicianFormBiometricResponse} from "@/ts/types/dto/physician-form.dto";
import {computed} from "vue";

const props = defineProps<{
  physicianForm: GetPhysicianFormBiometricResponse | null;
}>();

const approvedDate = computed(() => {
  if (props.physicianForm?.approvedDate) {
    return formatDateShortMonthName(props.physicianForm.approvedDate);
  }
  return null;
});
</script>

<style lang="sass" scoped>
.bold
  @include Roboto700

.text
  @include Roboto400
  color: $color-primary-hc-blue-100
  font-size: 14px
  letter-spacing: 0.2px
  line-height: 28px
  margin: 0 0 4px
</style>
