import type {HRASummary} from "@/ts/types/dto/health-risk-assessment.dto";
import type {MyProgramResponse} from "@/ts/types/dto/program.dto";
import {
  isHraCompleted,
  isHraAvailable,
} from "@/ts/utils/my-health/hra";
import {hasProgramBioscreeningAvailable} from "@/ts/utils/my-health/program-bioscreening";
import {hasProgramHraAvailable} from "@/ts/utils/my-health/program-hra";
import {isPhysicianFormDownloadedOrDenied, isPhysicianFormAvailable} from "@/ts/utils/my-health/physician-form";
import {isBioscreeningAvailable, isAppointmentScheduledInFuture, isBioscreeningSubmitted} from "@/ts/utils/my-health/bioscreening";
import type {BiometricScreeningAvailable, BiometricScreeningStatus, PhysicianFormAvailable} from "@/ts/types/dto/biometric-screening.dto";
import type {SchedulingBiometricAppointment} from "@/ts/types/store/scheduling-biometric-store-types";
import type {GetPhysicianFormBiometricResponse} from "@/ts/types/dto/physician-form.dto";

function isBioscreeningNotSubmitted(biometricStatus: BiometricScreeningStatus | null): boolean {
  return !isBioscreeningSubmitted(biometricStatus);
}

function isBioscreeningAvailableOrPhysicianFormAvailable({
  physicianFormAvailable,
  biometricAvailable,
}: {
  physicianFormAvailable: PhysicianFormAvailable | null;
  biometricAvailable: BiometricScreeningAvailable | null;
}): boolean {
  return isBioscreeningAvailable(biometricAvailable) || isPhysicianFormAvailable(physicianFormAvailable);
}

function isFurtherActionRequired({
  appointment,
  physicianForm,
}: {
  appointment: SchedulingBiometricAppointment | null;
  physicianForm: GetPhysicianFormBiometricResponse | null;
}): boolean {
  return isAppointmentScheduledInFuture(appointment) || isPhysicianFormDownloadedOrDenied(physicianForm);
}

function hasBioscreeningRequiredAction({
  biometricStatus,
  physicianForm,
  appointment,
}: {
  biometricStatus: BiometricScreeningStatus | null;
  appointment: SchedulingBiometricAppointment | null;
  physicianForm: GetPhysicianFormBiometricResponse | null;
}): boolean {
  return isBioscreeningNotSubmitted(biometricStatus) &&
    (!physicianForm?.status || isPhysicianFormDownloadedOrDenied(physicianForm)) &&
    (!appointment || isAppointmentScheduledInFuture(appointment));
}

function isBioscreeningAvailableForAction({
  program,
  physicianFormAvailable,
  biometricAvailable,
  appointment,
  physicianForm,
}: {
  program: MyProgramResponse | null;
  physicianFormAvailable: PhysicianFormAvailable | null;
  biometricAvailable: BiometricScreeningAvailable | null;
  appointment: SchedulingBiometricAppointment | null;
  physicianForm: GetPhysicianFormBiometricResponse | null;
}): boolean {
  return hasProgramBioscreeningAvailable(program) &&
  (
    isBioscreeningAvailableOrPhysicianFormAvailable({
      physicianFormAvailable,
      biometricAvailable,
    }) ||
    isFurtherActionRequired({
      appointment,
      physicianForm,
    })
  );
}

function isHraNotCompleted(hraSummary: HRASummary | null): boolean {
  return !isHraCompleted(hraSummary);
}

function hasHraRequiredAction(hraSummary: HRASummary | null): boolean {
  return isHraNotCompleted(hraSummary);
}

function isHraAvailableForAction({
  program,
  hraSummary,
}: {
  program: MyProgramResponse | null;
  hraSummary: HRASummary | null;
}): boolean {
  return hasProgramHraAvailable(program) && isHraAvailable(hraSummary);
}

export function isBioscreeningRequired({
  program,
  physicianFormAvailable,
  biometricAvailable,
  appointment,
  physicianForm,
  biometricStatus,
}: {
  program: MyProgramResponse | null;
  physicianFormAvailable: PhysicianFormAvailable | null;
  biometricAvailable: BiometricScreeningAvailable | null;
  appointment: SchedulingBiometricAppointment | null;
  physicianForm: GetPhysicianFormBiometricResponse | null;
  biometricStatus: BiometricScreeningStatus | null;
}): boolean {
  return isBioscreeningAvailableForAction({
    program,
    physicianFormAvailable,
    biometricAvailable,
    appointment,
    physicianForm,
  }) &&
  hasBioscreeningRequiredAction({
    biometricStatus,
    physicianForm,
    appointment,
  });
}

export function isHraRequired({
  program,
  hraSummary,
}: {
  program: MyProgramResponse | null;
  hraSummary: HRASummary | null;
}): boolean {
  return isHraAvailableForAction({
    program,
    hraSummary,
  }) && hasHraRequiredAction(hraSummary);
}
