<template>
  <empty-state
    :image="emptyStateImage"
    title="Coming soon!"
    description="New features, designed just for you, coming soon."
  />
</template>
<script lang="ts">
import EmptyState from "@/vue/atoms/empty-state.vue";
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {LoadingMixin} from "@/ts/mixins/loading-mixin";
import {CachedApiMixin} from "@/ts/mixins/cached-api-mixin";
import wipImage from "@/assets/img/wip-placeholder-soon.svg";

@Component({
  name: "PlaceholderSoon",
  components: {
    EmptyState,
  },
})
export default class PlaceholderSoon extends mixins(LoadingMixin, CachedApiMixin) {
  get emptyStateImage(): string {
    return wipImage;
  }
}

</script>
