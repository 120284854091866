import type {BiometricScreeningStatus} from "@/ts/types/dto/biometric-screening.dto";

export enum AlertText {
  cannotSchedule = "cannotSchedule",
  deadline = "deadline",
  resultsSent = "resultsSent",
  scheduled = "scheduled",
  screeningFailed = "screeningFailed",
}

export enum BiometricScreeningResultCardTheme {
  white = "white",
  gray = "gray",
}

export interface BiometricScreeningData {
  location: string;
  status: BiometricScreeningStatus;
  timestamp?: string;
  deadlineDate: string;
  submittedDate: string;
}
