<template>
  <time :datetime="dateTime" class="text-body2 text-primary-blue-50">{{ dateTxt }}</time>
</template>

<script lang="ts" setup>

import {computed} from "vue";
import {
  get12HourTime,
  getMinutesDiffToNow,
} from "@/ts/utils/date-pure-functions";

const props = defineProps<{
  date: Date;
}>();

const dateTxt = computed(() => {
  const minutes = getMinutesDiffToNow(props.date);
  if (minutes < 2) {
    return "Just now";
  }

  if (minutes < 60) { // eslint-disable-line @typescript-eslint/no-magic-numbers
    return `${minutes} min ago`;
  }

  if (minutes < 120) { // eslint-disable-line @typescript-eslint/no-magic-numbers
    return "1h ago";
  }

  if (minutes < 180) { // eslint-disable-line @typescript-eslint/no-magic-numbers
    return "2h ago";
  }

  return get12HourTime(props.date);
});

const dateTime = computed(() => {
  return props.date.toISOString();
});

</script>
