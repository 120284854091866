<template>
  <box-row class="buttons-row">
    <template #left>
      <button-primary
        v-if="hasDelete"
        theme="white"
        class="large"
        :disabled="todoAdjustmentDisabled"
        @click="deleteTask"
      >
        <icon-chameleon :icon="SvgTrashIcon" color="primary100" aria-hidden="true"/>
        Remove from To-Do's
      </button-primary>
      <button-primary
        v-else
        :loading="submittingData"
        :disabled="todoAdjustmentDisabled"
        @click="addToToDo"
      >
        <icon-chameleon
          :icon="SvgPlusIcon"
          color="white"
          alt="plus icon"
          aria-hidden="true"
        />
        Add to My To-Do's
      </button-primary>
    </template>
    <template #right>
      <div class="right">
        <button-primary
          v-if="!hideSkipBtn"
          theme="white"
          :disabled="skipDisabled"
          class="small"
          @click="skipTask"
        >
          <img src="@/assets/img/skip-icon.svg" aria-hidden="true"/>
          Skip
        </button-primary>
        <button-primary
          v-if="!hideDoneBtn"
          class="small"
          :disabled="doneDisabled"
          @click="taskDone"
        >
          <img src="@/assets/img/icon-check-w.svg" aria-hidden="true"/>
          Done
        </button-primary>
      </div>
    </template>
  </box-row>
</template>

<script lang="ts" setup>
import BoxRow from "@/vue/atoms/box-row.vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import IconChameleon from "@/vue/atoms/icon-chameleon.vue";
import SvgTrashIcon from "@/assets/img/delete-trash-icon.svg";
import SvgPlusIcon from "@/assets/img/plus-icon.svg";

const props = defineProps<{
  hasDelete: boolean;
  skipDisabled: boolean;
  doneDisabled: boolean;
  hideSkipBtn?: boolean;
  hideDoneBtn?: boolean;
  submittingData?: boolean;
  todoAdjustmentDisabled?: boolean;
}>();

const emit = defineEmits(["deleteTask", "skipTask", "taskDone", "addToToDo"]);

const addToToDo = (): void => {
  emit("addToToDo");
};

const deleteTask = (): void => {
  emit("deleteTask");
};

const skipTask = (): void => {
  emit("skipTask");
};

const taskDone = (): void => {
  emit("taskDone");
};
</script>

<style lang="sass" scoped>
.buttons-row
  padding: 12px 0

.small
  width: 130px

.large
  width: 260px

.right
  display: flex
  gap: 24px
</style>
