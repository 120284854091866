import type {ChallengeParticipant} from "@/ts/types/dto/leaderboard-challenge.dto";

export interface ChallengeWinner {
  challengeParticipant: ChallengeParticipant;
  progress: number;
  position: number;
  points: number;
}

export enum ChallengeStatus {
  Available = "available",
  Joined = "joined",
  Active = "active",
  GracePeriod = "grace_period",
  Completed = "completed",
}


