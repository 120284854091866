<template>
  <div>
    <div class="header">
      <div>Health Risk Assessment</div>
      <router-link :to="$pagesPath.myHealthPath.myhealth" class="close">
        <img src="@/assets/img/x-mark.svg" alt="close"/>
      </router-link>
    </div>
    <loading-bar :current="step" :steps="4"/>
    <div class="slot">
      <h1>{{ title }}</h1>
      <slot/>
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Prop,
} from "vue-property-decorator";
import {Vue} from "vue-class-component";
import LoadingBar from "@/vue/atoms/loading-bar.vue";

@Component({
  name: 'HraPage',
  components: {LoadingBar},
})
export default class HraPage extends Vue {
  @Prop()
  public step!: number;

  @Prop()
  public title!: string;
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>
.header
  @include Asap700
  font-size: 40px
  line-height: 28px
  text-align: center
  color: $color-primary-hc-blue-100

  display: grid
  grid-template-columns: 1fr repeat(1, auto) 1fr
  grid-column-gap: 5px
  justify-items: center
  align-items: center
  padding: 60px 70px 27px

  > div
    grid-column-start: 2

.close
  margin-left: auto
  cursor: pointer

.slot
  display: flex
  flex-direction: column
  align-items: center

h1
  color: $color-primary-hc-blue-100
  font-size: 28px
  @include Asap700
  margin: 51px 0 56px

</style>
