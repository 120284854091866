import {DefaultStore} from "@/ts/store/default/default-store";
import {getModule} from "vuex-module-decorators";
import {vueStore} from "@/ts/store/vue-store";
import {OnboardingStore} from "@/ts/store/onboarding/onboarding-store";

// eslint-disable-next-line
export default function() {
  const defaultStore: DefaultStore = getModule(DefaultStore, vueStore);
  const onboardingStore: OnboardingStore = getModule(OnboardingStore, vueStore);

  return {
    defaultStore,
    onboardingStore,
  };
}
