import type {Router} from "vue-router";
import {
  createRouter,
  createWebHistory,
} from "vue-router";

import type {Logger} from "lines-logger";
import {loggerFactory} from "@/ts/instances/logger-factory";
import type {SessionHolder} from "@/ts/types";
import NotFoundPage from "@/vue/pages/not-found.page.vue";
import {authorizationPages} from "@/ts/router/pages/authorization-pages";
import {mainPages} from "@/ts/router/pages/main-pages";
import {pagesPath} from "@/ts/router/pages-path";
import {DefaultStore} from "@/ts/store/default/default-store";
import {getModule} from "vuex-module-decorators";
import {vueStore} from "@/ts/store/vue-store";

export function routerFactory(sessionHolder: SessionHolder): Router {
  const logger: Logger = loggerFactory.getLogger("router"); // eslint-disable-line @typescript-eslint/no-unused-vars
  const router = createRouter({
    history: createWebHistory(),
    routes: [
      mainPages,
      ...authorizationPages,
      {
        path: "/:catchAll(.*)",
        component: NotFoundPage,
      },
    ],
  });

  const defaultStore: DefaultStore = getModule(DefaultStore, vueStore);
  router.beforeEach((to, from, next) => {
    if (to.matched[0]?.meta?.loginRequired && !sessionHolder.sessionToken && to.path !== pagesPath.auth.verification) {
      logger.log("Redirection condition met for path: {}", to.path)();
      logger.log("Session doesn't exist for page {}, redirecting to onboarding page", to.matched[0])();
      next(pagesPath.auth.onboarding);
    } else if (to.path === pagesPath.auth.accountSuccessfullyDeleted && !defaultStore.accountJustDeleted) {
      // this page should be only available if the account was just deleted
      next(pagesPath.auth.onboarding);
    } else {
      next();
    }
  });

  return router;
}
