<template>
  <navbar-page :title="pathwayResponse.title" :close-link="true">
    <loading-bar :steps="pathwayResponse.sections.length" :current="1"/>
    <div class="content">
      <img class="image" src="@/assets/img/pathway-icons/pathway-fail.svg"/>
      <span class="description-title">
        Let's try again
      </span>
      <span class="good-effort">Good effort! Give it another go to pass the test.</span>
      <button-link :to="getStartPage">
        Restart Pathway
        <bigger-sign/>
      </button-link>
    </div>
  </navbar-page>
</template>

<script lang="ts">
import {
  Component,
  Prop,
} from "vue-property-decorator";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import LoadingBar from "@/vue/atoms/loading-bar.vue";
import type {GetPathwayResponseDTO} from "@/ts/types/dto/pathway.dto";
import NavbarPage from "@/vue/templates/navbar-page.vue";
import {mixins} from "vue-class-component";
import {mixinClassBodyFactory} from "@/ts/mixins/bg-mixin";
import BiggerSign from "@/vue/atoms/bigger-sign.vue";
import ButtonLink from "@/vue/atoms/button-link.vue";

@Component({
  components: {
    ButtonLink,
    BiggerSign,
    NavbarPage,
    ButtonPrimary,
    LoadingBar
  }
})
export default class PathwayQuizFailPage extends mixins(mixinClassBodyFactory("global-onboarding-bg-color")) {
  @Prop()
  pathwayResponse!: GetPathwayResponseDTO;

  get getStartPage(): string {
    return this.$pagesPath.pathway.pathwayTask
      .replace(":id", this.pathwayResponse.id)
      .replace(":number", "1");
  }
}
</script>

<style lang="sass" scoped>
.content
  display: flex
  flex-direction: column
  align-items: center

.image
  max-width: fit-content
  margin-top: 96px

.description
  @include Roboto400
  font-size: 16px
  color: $color-primary-hc-blue-100

.description-title
  @include Asap700
  color: $color-primary-hc-blue-100
  margin-top: 40px
  font-size: 28px

.good-effort
  @include Roboto400
  margin-top: 8px
  font-size: 16px
  margin-bottom: 187px
  color: $color-primary-hc-blue-100
</style>
