<template>
  <div class="select">
    <tabs-navigation :items="links"/>
  </div>
  <router-view/>
</template>

<script lang="ts">
import {
  Component,
  Vue,
} from "vue-property-decorator";
import TabsNavigation from "@/vue/atoms/tabs-navigation.vue";
import type {Tab} from "@/ts/types/component/tabs-navigation";

@Component({
  name: "CommunityPageSelect",
  components: {
    TabsNavigation,
  },
})
export default class ProgramPageSelect extends Vue {
  get links(): Tab[] {
    return [
      {
        link: this.$pagesPath.community.communityFeed,
        title: "Feed",
      },
      {
        link: this.$pagesPath.community.communityFriends,
        title: "Friends",
      },
      {
        link: this.$pagesPath.community.communityGroups,
        title: "Groups",
      },
    ];
  }
}
</script>

<style lang="sass" scoped></style>
