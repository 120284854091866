<!-- eslint-disable @typescript-eslint/no-magic-numbers -->
<template>
  <router-link :to="to">
    <card-wrapper size="small">
      <div class="image-frame">
        <position-medal :position="challenge.myLeaderboardPosition?.position ?? 0"/>
      </div>
      <div class="content">
        <challenge-type :type="challenge.type"/>
        <h1>{{ challenge.name }}</h1>
        <p class="duration">
          {{ endPosition }} {{ finishedDate }}
        </p>
      </div>
      <div class="footer">
        <badge-rounded
          v-if="pointsEarned"
          theme="dark"
          size="small"
          :value="`${pointsEarned} PTS`"
        />
      </div>
    </card-wrapper>
  </router-link>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";
import type {ChallengeDTO} from "@/ts/types/dto/challenges.dto";
import {formatDateShortMonthName} from "@/ts/utils/date-pure-functions";
import BadgeRounded from "@/vue/atoms/badge-rounded.vue";
import CardWrapper from "@/vue/atoms/card-wrapper.vue";
import PositionMedal from "@/vue/atoms/position-medal.vue";
import ChallengeType from "@/vue/molecules/challenge/challenge-type.vue";
import type {RouteLocationRaw} from "vue-router";
import {ChallengeStatus} from "@/ts/types/component/challenges";
import {determineChallengeStatus} from "@/ts/utils/pure-functions";

@Component({
  name: "ChallengeCardCompleted",
  components: {
    BadgeRounded,
    PositionMedal,
    CardWrapper,
    ChallengeType,
  },
})
export default class ChallengeCardCompleted extends Vue {
  @Prop() challenge!: ChallengeDTO;

  get pointsEarned(): number | null {
    switch (this.challenge.myLeaderboardPosition?.position) {
      case 1:
        return this.challenge.pointsFirstPositionReward;
      case 2:
        return this.challenge.pointsSecondPositionReward;
      case 3:
        return this.challenge.pointsThirdPositionReward;

      default:
        return null;
    }
  }

  get to(): RouteLocationRaw {
    return {
      path: this.$pagesPath.challenge.challenge.replace(
        ":id",
        this.challenge.id,
      ),
      query: {type: this.challenge.type},
    };
  }

  get finishedDate(): string {
    return formatDateShortMonthName(this.challenge.endDate);
  }

  get endPosition(): string {
    switch (this.challenge.myLeaderboardPosition?.position) {
      case 1:
        return "1st place on ";
      case 2:
        return "2nd place on ";
      case 3:
        return "3rd place on ";

      default:
        return "Ended on ";
    }
  }
}
</script>

<style lang="sass" scoped>

h1
  @include Asap700
  color: $color-primary-hc-blue-100
  font-size: 1rem
  margin: 0
  /* stylelint-disable */
  display: -webkit-box
  -webkit-box-orient: vertical
  -webkit-line-clamp: 2
  overflow: hidden

.duration
  @include Roboto400
  font-size: 12px
  line-height: 16px
  color: $color-primary-hc-blue-50
  margin-top: 8px

.footer
  margin-top: 10px
</style>
