<template>
  <section class="feed">
    <loading-suspense :loading="loading && !feedItems.length">
      <empty-state
        v-if="!feetItemClusters.length"
        :image="SvgPersonPracticingYoga"
        title="You’re early to the party!"
        description="You’re a pioneer, and the first one of your company to sign in. Give them some time."
        description-max-width="440px"
      />

      <div v-for="(feedItemCluster, clusterIndex) in feetItemClusters" :key="clusterIndex">
        <h2 class="text-h4 headline" :aria-label="feedItemCluster.ariaHeadline">
          <display-day :date="feedItemCluster.date"/>
        </h2>
        <feed-item-card
          v-for="(feedItem, feedItemIndex) in feedItemCluster.items"
          :key="feedItem.id"
          :feed-item-data="feedItem"
          :index="feedItemCluster.firstItemIndex + feedItemIndex"
        />
      </div>

      <div class="more-btn-container">
        <button-primary
          v-if="numTotalFeedItems > feedItems.length"
          :text="'Load more feed items'"
          :loading="loading"
          @click="fetchFeed"
        />
      </div>
    </loading-suspense>
  </section>
</template>
<script lang="ts" setup>

import type {Ref} from "vue";
import {computed, inject, ref} from "vue";
import type {Api} from "@/ts/classes/api";
import type {FeedItem} from "@/ts/types/dto/feed.dto";
import type {FeedItemCluster} from "@/ts/types/component/feed";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import FeedItemCard from "@/vue/molecules/feed-item-card.vue";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import {
  formatDateShortMonthName,
  getStartOfTheDay,
  isSameDay,
  parseDate,
} from "@/ts/utils/date-pure-functions";
import DisplayDay from "@/vue/atoms/display-day.vue";
import SvgPersonPracticingYoga from "@/assets/img/challenge-card-icons/person-practicing-yoga.svg";
import EmptyState from "@/vue/atoms/empty-state.vue";

const $api = inject("$api")! as Api;

const feedItems: Ref<FeedItem[]> = ref<FeedItem[]>([]);
const numTotalFeedItems: Ref<number> = ref(0);
const loading: Ref<boolean> = ref(false);

const feetItemClusters = computed((): FeedItemCluster[] => {
  const clusters: FeedItemCluster[] = [];
  feedItems.value.forEach((item, index) => {
    if (clusters.length > 0 && isSameDay(clusters[clusters.length - 1].date, item.createdAt)) {
      // add item to existing cluster
      clusters[clusters.length - 1].items.push(item);
    } else {
      // create new cluster
      const date = getStartOfTheDay(parseDate(item.createdAt));
      clusters.push({
        items: [item],
        date,
        ariaHeadline: `All posts from ${formatDateShortMonthName(date)}`,
        firstItemIndex: index,
      });
    }
  });
  return clusters;
});

const fetchFeed = async(): Promise<void> => {
  loading.value = true;
  // load 10 items first time and 100 more on demand
  const limit = feedItems.value.length ? 100 : 10; // eslint-disable-line @typescript-eslint/no-magic-numbers
  const offset = feedItems.value.length;
  const feedData = await $api.getFeed({
    limit,
    offset,
  });

  const existingItems = feedItems.value;
  const newItems = feedData.items.map((newItem) => {
    /*
     * remove doubled items with same ID
     * doubled items will occur if somebody creates a new post after loading the first bunch of items and
     * before clicking the "more" button
     */
    const alreadyExist = Boolean(existingItems.find((existingItem) => existingItem.id === newItem.id));
    if (alreadyExist) {
      return null;
    }
    return newItem;
  }).filter((item) => Boolean(item)) as FeedItem[];

  feedItems.value = [
    ...existingItems,
    ...newItems,
  ];

  numTotalFeedItems.value = feedData.pagination.total;
  loading.value = false;
};

void fetchFeed();

</script>

<style lang="sass" scoped>
.feed
  max-width: 681px
  margin: 32px auto
  padding: 0 16px

.more-btn-container
  display: flex
  justify-content: center
  margin-top: 32px

.headline
  margin: 32px 0 8px 0

</style>
