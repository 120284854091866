export function validatePassword(password: string): boolean {
  const regex = new RegExp([ // eslint-disable-line require-unicode-regexp
    /^/u,
    // at least 1 upper case letter
    /(?=.*[A-Z])/u,
    // at least 1 lower case letter
    /(?=.*[a-z])/u,
    // at least 1 digit
    /(?=.*\d)/u,
    // at least 1 symbol
    /(?=.*[!@#$%^&*)(+=._\-{[}\]|:;"'<,>?/\\])/u,
    // at least 12 characters
    /[A-Za-z\d!@#$%^&*)(+=._\-{[}\]|:;"'<,>?/\\]{12,}/u,
    /$/u,
  ].map((rx) => rx.source).join(""));

  return Boolean(regex.exec(password));
}
