<template>
  <youtube-video v-if="isYoutube" :link="object.mediaReference"/>
  <protected-image
    v-else
    :src="object.mediaReference"
    :aria-hidden="true"
    :alt="''"
  />
</template>

<script lang="ts" setup>
import type {PathwayMedia} from "@/ts/types/dto/pathway.dto";
import {MediaProvider} from "@/ts/types/dto/pathway.dto";
import YoutubeVideo from "@/vue/molecules/youtube-video.vue";
import ProtectedImage from "@/vue/atoms/protected-image.vue";
import {computed} from "vue";

const props = defineProps<{
  object: PathwayMedia;
}>();

const isYoutube = computed((): boolean => {
  return props.object.mediaProvider === MediaProvider.youtube;
});
</script>

