<template>
  <popup-modal :model-value="modelValue" @update:model-value="close">
    <h1>
      Please confirm your appointment
    </h1>

    <biometric-appointment-details
      :timestamp="selectedTime"
      :location="location"
    />
    <button-primary
      text="Confirm appointment"
      :loading="loading"
      class="button"
      @click="postAppointment"
    >
      Confirm appointment <img src="@/assets/img/icon-check-w.svg"/>
    </button-primary>
    <error-text :error="error"/>
  </popup-modal>
</template>

<script lang="ts">
import {
  Component,
  Emit,
  Prop,
} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import ButtonLink from "@/vue/atoms/button-link.vue";
import {
  SchedulingBiometricState,
  SchedulingBiometricStoreMixin,
} from "@/ts/store/scheduling-biometric/scheduling-biometric-store-instance";
import TimeSelector from "@/vue/molecules/time-selector.vue";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import PopupModal from "@/vue/atoms/popup-modal.vue";
import ErrorText from "@/vue/atoms/error-text.vue";
import {DefaultState} from "@/ts/store/default/default-store-instance";
import type {Profile} from "@/ts/types/store/default-store-types";
import type {
  BiometricScreeningLocation,
  PostBiometricScreeningScheduleAppointmentResponse,
} from "@/ts/types/dto/biometric-screening.dto";
import BiometricAppointmentDetails from "@/vue/molecules/biometric-appointment-details.vue";

@Component({
  name: 'ScheduleAppointmentModal',
  components: {
    ErrorText,
    PopupModal,
    LoadingSuspense,
    TimeSelector,
    ButtonPrimary,
    ButtonLink,
    BiometricAppointmentDetails,
  },
})
export default class ScheduleAppointmentModal extends mixins(LoadingMixin, SchedulingBiometricStoreMixin) {
  @SchedulingBiometricState
  location!: BiometricScreeningLocation;

  @DefaultState
  profile!: Profile;

  @Prop()
  selectedTime!: Date;

  @Prop()
  modelValue!: boolean;

  @Emit('update:modelValue')
  close(): boolean {
    return false;
  }

  @DefaultGrowlError
  async postAppointment(): Promise<void> {
    const resp: PostBiometricScreeningScheduleAppointmentResponse = await this.$api.postBiometricScheduleAppointment({
      locationId: this.location!.id,
      phoneNumber: this.profile.phoneNumber,
      timestamp: this.selectedTime!.toISOString(),
    });
    this.schedulingBiometricStore.setAppointment(resp);
    void this.$router.push(this.$pagesPath.biometricScheduling.appointmentConfirmed);
  }
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>
h1
  color: $color-primary-hc-blue-100
  @include Asap700
  font-size: 32px
  margin: -20px 50px 32px 0

.date
  @include Asap700
  color: $color-primary-hc-blue-100
  font-size: 16px
  letter-spacing: 0.15px

.location
  @include Asap400
  font-size: 16px
  color: $color-primary-hc-blue-100
  letter-spacing: 0.15px
  margin-top: 24px
  margin-bottom: 8px

.button
  margin-top: 48px
  margin-left: auto
</style>
