<template>
  <loading-suspense :loading="loading" :error="error">
    <div v-if="relationExists">
      <related-activity-list :relateds="availableActivities"/>
    </div>
  </loading-suspense>
</template>

<script lang="ts">
import {Component, Prop} from "vue-property-decorator";
import type {GetActivitiesResponseDTO, ActivityDTO} from "@/ts/types/dto/activity.dto";
import RelatedActivityList from "@/vue/molecules/tracking/related-activity-list.vue";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import {ActivityStatus} from "@/ts/types/dto/activity.dto";
import {mixins} from "vue-class-component";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";

@Component({
  name: "ActivityRelationLog",
  components: {
    RelatedActivityList,
    LoadingSuspense,
  },
})

export default class ActivityRelationLog extends mixins(LoadingMixin) {
  activities: ActivityDTO[] = [];

  @Prop() type!: string;

  @Prop() relateds!: ActivityDTO[];

  get relationExists(): boolean {
    return this.availableActivities.length > 0;
  }

  get availableActivities(): ActivityDTO[] {
    return this.activities.filter(
      (activity) => activity.status === ActivityStatus.available && activity.task?.type === this.type,
    );
  }

  @DefaultGrowlError
  async created(): Promise<void> {
    const activityResponse: GetActivitiesResponseDTO = await this.$api.getActivities();
    this.activities = activityResponse.items;
  }
}
</script>

<style lang="sass" scoped>

</style>
