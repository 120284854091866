/* eslint-disable id-length */
/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/no-unsafe-call */

// TODO refactor: use date pure-functions and day.js! Replace all 'new Date()'-statements

import type {Weeks} from "@/ts/types/component/datepicker";
import {parseDate} from "@/ts/utils/date-pure-functions";

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
const FOCUSABLE = [
  "a[href]:not([tabindex^=\"-\"])",
  "area[href]:not([tabindex^=\"-\"])",
  "input:not([disabled]):not([type=\"hidden\"]):not([aria-hidden]):not([tabindex^=\"-\"])",
  "select:not([disabled]):not([aria-hidden]):not([tabindex^=\"-\"])",
  "textarea:not([disabled]):not([aria-hidden]):not([tabindex^=\"-\"])",
  "button:not([disabled]):not([aria-hidden]):not([tabindex^=\"-\"]):not([tabindex^=\"-\"])",
  "iframe:not([tabindex^=\"-\"])",
  "object:not([tabindex^=\"-\"])",
  "embed:not([tabindex^=\"-\"])",
  "[contenteditable]:not([tabindex^=\"-\"])",
  "[tabindex]:not([tabindex^=\"-\"])",
];

export const daysOfWeek = Object.freeze({
  Su: "Sunday",
  Mo: "Monday",
  Tu: "Tuesday",
  We: "Wednesday",
  Th: "Thursday",
  Fr: "Friday",
  Sa: "Saturday",
});

export const monthLabels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const buttonLabels = Object.freeze({
  selectDate: "Select Date",
  showCalendar: "show calendar",
  previousMonth: "previous month",
  nextMonth: "next month",
});

/**
 * To trap focus on tab key
 */
export function applyFocusTrap(el: HTMLElement, event: KeyboardEvent): void {
  const focusable = Array.from(el.querySelectorAll(FOCUSABLE as any));

  if (!focusable.length) {
    event.preventDefault();
    return;
  }

  // eslint-disable-next-line no-negated-condition
  if (!el.contains(document.activeElement)) {
    event.preventDefault();
    focusable[0].focus();
  } else {
    const focusedItemIndex = focusable.indexOf(document.activeElement);

    if (event.shiftKey && focusedItemIndex === 0) {
      focusable[focusable.length - 1].focus();
      event.preventDefault();
    }

    if (!event.shiftKey && focusedItemIndex === focusable.length - 1) {
      focusable[0].focus();
      event.preventDefault();
    }
  }
}

export function sameDays(first: Date, second: Date): boolean {
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  );
}

export function sameDaysUTC(first: Date, second: Date): boolean {
  return (
    first.getUTCFullYear() === second.getUTCFullYear() &&
    first.getUTCMonth() === second.getUTCMonth() &&
    first.getUTCDate() === second.getUTCDate()
  );
}
/*
 * gets previous or next month
 */
export function incrementMonthBy(inc: number, focusedDate: Date): Date {
  // get last day of prev/next month
  const last = new Date(focusedDate);
  last.setMonth(last.getMonth() + inc + 1);
  last.setDate(0);

  const fd = new Date(focusedDate);
  // Must happen before month change`
  fd.setDate(Math.min(fd.getDate(), last.getDate()));
  fd.setMonth(fd.getMonth() + inc);

  return fd;
}

/*
 * Checks if date is an available date
 */
export function isAvailableDate(date: Date, availableDates: Date[]): boolean {
  return Boolean(
    availableDates.find(
      (value: Date) => sameDaysUTC(value, date),
    ),
  );
}

/*
 * Sets the days of the week on order of week columns
 */
export function daysByWeeks(
  focusedDate: Date,
  selectedDate: Date,
  min: Date | string,
  max: Date | string,
  availableDates: string[],
): Weeks[][] {
  // Format the available dates in the same way as firstDayOfMonth
  const availableDatesFormatted = availableDates.map((dateStr: string) => {
    return parseDate(dateStr);
  });

  const firstDayOfMonth = new Date(
    focusedDate.getFullYear(),
    focusedDate.getMonth(),
    1,
  );
  const dayOfWeek = firstDayOfMonth.getDay();
  firstDayOfMonth.setDate(firstDayOfMonth.getDate() - dayOfWeek);

  const daysInMonth = new Date(
    focusedDate.getFullYear(),
    focusedDate.getMonth() + 1,
    0,
  ).getDate();

  const day = new Date(firstDayOfMonth);
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const maxWeeks = dayOfWeek + daysInMonth < 36 ? 5 : 6;
  const weeks: Weeks[][] = [];

  for (let i = 0; i < maxWeeks; i++) {
    weeks.push([]);

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    for (let j = 0; j < 7; j++) {
      const date = new Date(day);
      let disabled = false;
      if (min) {
        disabled = date < new Date(min);
      }
      if (max && !disabled) {
        disabled = date > new Date(max);
      }

      weeks[i].push({
        date,
        isFocused: sameDays(date, focusedDate),
        isSelected: sameDays(date, selectedDate),
        disabled,
        available: isAvailableDate(date, availableDatesFormatted),
      });
      day.setDate(day.getDate() + 1);
    }
  }
  return weeks;
}


