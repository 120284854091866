<template>
  <time :datetime="dateTime">{{ dateTxt }}</time>
</template>

<script lang="ts" setup>

import {computed} from "vue";
import {
  isSameDay,
  isSameYear,
  subtractFromDate,
  today,
  formatDateLongMonthName,
} from "@/ts/utils/date-pure-functions";

const props = defineProps<{
  date: Date;
}>();

const dateTxt = computed(() => {
  if (isSameDay(props.date, today())) {
    return "Today";
  }

  if (isSameDay(props.date, subtractFromDate(today(), {days: 1}))) {
    return "Yesterday";
  }

  return formatDateLongMonthName(props.date, !isSameYear(props.date, today()));
});

const dateTime = computed(() => {
  return props.date.toISOString();
});

</script>
