<template>
  <div class="container">
    <h1>My Goals</h1>
    <button-primary theme="white" @click="showCreateModal">
      <icon-chameleon
        :icon="SvgPlusIcon"
        color="primary100"
        alt="plus icon"
        aria-hidden="true"
      />
      Create a Goal
    </button-primary>

    <div class="goals-container">
      <div class="goals-list-container">
        <div class="goals-title">
          <h2>Active Goals</h2>
          <div class="list-length">
            {{ activeGoals.length }}
          </div>
        </div>
        <div v-if="activeGoals.length === 0" class="no-goals">
          <span>No active goals yet. Create a goal to get started!</span>
        </div>
        <div v-else class="goals-list">
          <goal-card v-for="goal in activeGoals" :key="goal.id" :goal="goal"/>
        </div>
      </div>

      <div class="goals-list-container">
        <div class="goals-title">
          <h2>Completed Goals</h2>
          <div class="list-length">
            {{ completedGoals.length }}
          </div>
        </div>
        <div v-if="completedGoals.length === 0" class="no-goals">
          <span>No completed goals yet. Keep working on your active goals!</span>
        </div>
        <div v-else class="goals-list">
          <goal-card v-for="goal in completedGoals" :key="goal.id" :goal="goal"/>
        </div>
      </div>
    </div>

    <create-goal-modal
      :show-modal="showCreateModalFlag"
      @toggleModal="toggleCreateModal"
      @goalCreated="fetchGoals"
    />
  </div>
</template>


<script lang="ts" setup>
import type {Ref} from "vue";
import {ref, onMounted, inject, computed} from "vue";
import CreateGoalModal from "@/vue/organisms/create-goal-modal.vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import IconChameleon from "@/vue/atoms/icon-chameleon.vue";
import SvgPlusIcon from "@/assets/img/plus-icon.svg";
import GoalCard from "@/vue/molecules/goals/goal-card.vue";
import type {Api} from "@/ts/classes/api";
import type {Goal} from "@/ts/types/dto/goal.dto";

const $api = inject("$api")! as Api;

const showCreateModalFlag = ref(false);
const activeGoals: Ref<Goal[]> = ref([]);
const completedGoals: Ref<Goal[]> = ref([]);

const showCreateModal = () => {
  showCreateModalFlag.value = true;
};

const toggleCreateModal = () => {
  showCreateModalFlag.value = !showCreateModalFlag.value;
};

const fetchGoals = async() => {
  try {
    const responseActive = await $api.getGoals(100, 0, false, true);
    activeGoals.value = responseActive.items;

    const responseCompleted = await $api.getGoals(100, 0, true, false);
    completedGoals.value = responseCompleted.items;
  } catch (error) {
    console.error("Error fetching goals:", error);
  }
};

onMounted(fetchGoals);
</script>

<style lang="sass" scoped>
.container
  display: flex
  flex-direction: column
  align-items: center
  padding: 20px

.goals-container
  display: flex
  justify-content: space-evenly
  flex-wrap: wrap
  width: 100%

.goals-list
  display: flex
  flex-direction: column
  align-items: center
  max-height: 1200px
  overflow-y: auto
  width: 100%
  max-width: 400px
  margin: 10px
  padding-right: 10px
  box-sizing: border-box

.goals-list-container
  display: flex
  flex-direction: column

.goals-title
  align-self: center
  display: flex
  flex-direction: row
  align-items: center

.list-length
  margin-left: 10px
  font-size: 1rem
  color: #888

.no-goals
  color: $color-primary-hc-blue-100
  @include Roboto400
  font-weight: normal

h1, h2
  color: $color-primary-hc-blue-100
  margin-bottom: 20px

  // Media Query for smaller screens
@media (max-width: 880px)
  .goals-container
    flex-direction: column
    align-items: center

  .goals-list-container
    margin-top: 2rem

  .goals-list
    max-width: 100%

  .goals-title, .no-goals, .list-length
    text-align: center
</style>

