<template>
  <iframe v-if="iframe" :src="youtubeLink" allow="fullscreen;"/>
  <div v-else class="preview">
    <img :src="imageShortband" class="main-image"/>
    <img src="@/assets/img/play-video.svg" class="play-image" @click="iframe = true"/>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";

@Component({
  name: "YoutubeVideo",
})
export default class YoutubeVideo extends Vue {
  @Prop()
  link!: string;

  iframe: boolean = false;

  get imageShortband(): string {
    return `https://i.ytimg.com/vi/${this.link}/hqdefault.jpg`;
  }

  get youtubeLink(): string {
    return `https://youtube.com/embed/${this.link}?autoplay=1&start=0`;
  }
}
</script>

<style lang="sass" scoped>
.preview
  position: relative
  display: inline-block

.play-image
  position: absolute
  cursor: pointer
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
</style>
