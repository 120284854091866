<template>
  <alert-icon-card
    v-if="showScheduledBioscreeningInFuture"
    theme="success"
  >
    <div class="text">
      You have scheduled the following biometric screening
    </div>
  </alert-icon-card>
</template>

<script lang="ts">
import {Component, Vue, Prop} from "vue-property-decorator";
import AlertIconCard from "@/vue/atoms/alert-icon-card.vue";
import {isAppointmentScheduledInFuture} from "@/ts/utils/my-health/bioscreening";
import type {BiometricScreeningAppointment} from "@/ts/types/dto/biometric-screening.dto";

@Component({
  name: "ScheduledFutureCard",
  components: {AlertIconCard}
})
export default class ScheduledFutureCard extends Vue {
  @Prop({default: null})
  appointment!: BiometricScreeningAppointment | null;

  get isAppointmentScheduledInFuture(): boolean {
    return isAppointmentScheduledInFuture(this.appointment);
  }

  get showScheduledBioscreeningInFuture(): boolean {
    return this.isAppointmentScheduledInFuture;
  }
}
</script>

<style lang="sass" scoped>
.bold
  @include Roboto700

.text
  @include Roboto400
  color: $color-primary-hc-blue-100
  font-size: 14px
  letter-spacing: 0.2px
  line-height: 22px
  margin: 0 0 4px
</style>
