<template>
  <div class="activity-info">
    <div class="activity-info-list">
      <pathway-info
        name="Reward"
        :details="rewardText"
        :icon="pathRewardIcon"
      />
      <pathway-info
        name="Duration"
        :details="duration"
        :icon="pathRewardIcon"
      />
    </div>
    <markdown-it :text="description" class="description"/>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";
import PathwayInfo from "@/vue/molecules/pathway/pathway-info.vue";
import pathRewardIcon from "@/assets/img/pathway-icons/path-reward.svg";
import pathDurationIcon from "@/assets/img/pathway-icons/path-duration.svg";
import ErrorText from "@/vue/atoms/error-text.vue";
import MarkdownIt from "@/vue/atoms/markdown-it.vue";
import type {Reward} from "@/ts/types/dto/reward.dto";
import {getRewardAmountFormatted} from "@/ts/utils/pure-functions";

@Component({
  name: 'ActivityDescription',
  components: {
    MarkdownIt,
    PathwayInfo,
    ErrorText,
  },
})

export default class ActivityDescription extends Vue {
  @Prop()
  reward!: Reward;

  @Prop()
  description!: string;

  @Prop()
  duration!: string;

  get pathRewardIcon(): string {
    return pathRewardIcon;
  }

  get pathDurationIcon(): string {
    return pathDurationIcon;
  }

  get rewardText(): string {
    return getRewardAmountFormatted(this.reward);
  }
}
</script>

<style lang="sass" scoped>
.activity-info
  display: flex
  justify-content: center
  margin: 5rem

.activity-info-list
  display: flex
  flex-direction: column
  gap: 16px
  padding: 1rem

.description
  @include Roboto400
  color: $color-primary-hc-blue-100
  font-size: 16px
  width: 50%
  margin-left: 1rem

</style>
