<template>
  <popup-modal :model-value="displayed" @update:model-value="closePopup">
    <div class="content">
      <h3 class="title">
        Forgot my password
      </h3>
      <template v-if="!requestSent">
        <p class="text">
          Type in your <span>username</span> and
          we’ll send you an email with instructions to change your password.
        </p>
        <form @submit.prevent="sendPasswordReset">
          <error-text :error="error"/>
          <field-set id="username" class="label" label="My username">
            <input-text
              id="username"
              v-model="username"
              placeholder="Enter your username"
              required
            />
          </field-set>
          <button-primary
            class="button"
            :loading="loading"
            type="submit"
            text="Send password reset"
            theme="dark"
          />
        </form>
      </template>

      <template v-else>
        <p class="text">
          Your request has been successfully sent.
          Please check your confirmed email address.
        </p>
        <img src="@/assets/img/request-sent.svg" alt="Request sent"/>
        <button-primary class="button" text="Got it!" @click="closePopup"/>
      </template>
    </div>
  </popup-modal>
</template>

<script lang="ts">
import {Component, Prop, Emit} from "vue-property-decorator";
import PopupModal from "@/vue/atoms/popup-modal.vue";
import ErrorText from "@/vue/atoms/error-text.vue";
import FieldSet from "@/vue/molecules/field-set.vue";
import InputText from "@/vue/atoms/input-text.vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import {mixins} from "vue-class-component";
import {LoadingMixin, DefaultGrowlError} from "@/ts/mixins/loading-mixin";

@Component({
  name: "ForgotPasswordPopup",
  components: {
    ButtonPrimary,
    ErrorText,
    FieldSet,
    InputText,
    PopupModal,
  }
})
export default class ForgotPasswordPopup extends mixins(LoadingMixin) {
  @Prop()
  displayed!: boolean;

  username: string = "";

  requestSent: boolean = false;

  @Emit('closePopup')
  closePopup(): boolean {
    this.requestSent = false;
    return false;
  }

  @DefaultGrowlError
  async sendPasswordReset(): Promise<void> {
    await this.$api.requestRecoveryEmail({
      username: this.username,
    });
    this.username = "";
    this.requestSent = true;
  }
}

</script>

<style lang="sass" scoped>
.content
  display: flex
  flex-direction: column
  max-width: 652px

.title
  @include Asap700
  color: $color-primary-hc-blue-100
  font-size: 28px
  line-height: 36px
  margin-top: 0
  margin-bottom: 16px

.text
  @include Roboto400
  color: $color-primary-hc-blue-100
  font-size: 16px
  line-height: 24px
  letter-spacing: 0.1px

  span
    @include Roboto700

form
  display: flex
  flex-direction: column
  margin-top: 40px

.label
  font-size: 16px
  line-height: 22px
  letter-spacing: 0.15px

.button
  margin-top: 48px
  align-self: flex-end

img
  align-self: center
  height: 132px
  width: 183px
</style>
