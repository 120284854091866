import MyProgramPage from "@/vue/templates/my-program-page.vue";
import ProgramPathwayPage from "@/vue/pages/program/pathway-program.page.vue";
import ProgramOverviewPage from "@/vue/pages/program/overview-program.page.vue";
import ProgramActivityPage from "@/vue/pages/program/activity-program.page.vue";
import {pagesPath} from "@/ts/router/pages-path";
import ProgramChallengePage from "@/vue/pages/program/challenge-program.page.vue";
import type {RouteRecordRaw} from "vue-router";

export const programPages: RouteRecordRaw = {
  path: pagesPath.program.program,
  component: MyProgramPage,
  children: [
    {
      path: "",
      redirect: pagesPath.program.programPathway,
    },
    {
      path: pagesPath.program.programPathway,
      component: ProgramPathwayPage,
    },
    {
      path: pagesPath.program.programOverview,
      component: ProgramOverviewPage,
    },
    {
      path: pagesPath.program.programChallenges,
      component: ProgramChallengePage,
    },
    {
      path: pagesPath.program.programActivities,
      component: ProgramActivityPage,
    },
  ],
};
