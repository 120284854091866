<template>
  <popup-modal :model-value="showModal" @update:model-value="toggleModal">
    <div class="wrap">
      <h1>Create a Goal</h1>
      <h2>How are you going to accomplish your goal?</h2>
      <div class="input-section">
        <nicotine-icon v-if="selectedActivityType === 'nicotine_use'" :type="iconType"/>
        <activity-icon v-else :type="iconType"/>
        <div class="type">
          <label>Type of Activity</label>
          <select-dropdown
            v-model="selectedActivityType"
            :options="activityTypes"
            placeholder="Select an activity"
            required
          />
        </div>
        <div v-if="selectedActivityType === 'nicotine_use'" class="nicotine-message">
          <p>Congrats on taking your first step towards quitting nicotine use! Below enter your personal Nicotine quit date.</p>
        </div>
        <div v-else class="goal-frequency">
          <div class="goal">
            <label>Goal</label>
            <div class="goal-input-with-unit">
              <input-text
                v-model="goalValue"
                placeholder=""
                type="number"
                required
                :min="0"
              />
              <span class="unit">{{ getUnitForActivityType }}</span>
            </div>
          </div>
          <div class="frequency">
            <label>Frequency</label>
            <select-dropdown
              v-model="selectedFrequencyType"
              :options="frequencyTypes"
              placeholder="Select a frequency"
              required
            />
          </div>
        </div>
        <span v-if="goalValueError" class="error-message">{{ goalValueError }}</span>
        <div class="date">
          <label>End Date</label>
          <input-date
            v-model="goalDate"
            class="input-date"
            :min="tomorrowDate"
            required
          />
        </div>
      </div>
      <div class="button-holder">
        <button-primary
          theme="dark"
          :disabled="!isCreateButtonEnabled && selectedActivityType !== 'nicotine_use'"
          :loading="isLoading"
          @click="createGoal"
        >
          <template v-if="isLoading">
            Creating...
          </template>
          <template v-else>
            Create Goal
            <icon-chameleon
              :icon="CheckIcon"
              color="white"
              alt="check icon"
              aria-hidden="true"
            />
          </template>
        </button-primary>
      </div>
    </div>
  </popup-modal>
</template>


<script lang="ts" setup>
import {ref, inject, computed, watch, onMounted, defineProps} from "vue";
import type {Api} from "@/ts/classes/api";
import PopupModal from "@/vue/atoms/popup-modal.vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import SelectDropdown from "@/vue/atoms/select-dropdown.vue";
import InputText from "@/vue/atoms/input-text.vue";
import InputDate from "@/vue/atoms/input-date.vue";
import {convertDateToServerFormat, today} from "@/ts/utils/date-pure-functions";
import IconChameleon from "@/vue/atoms/icon-chameleon.vue";
import CheckIcon from "@/assets/img/checkmark.svg";
import {TaskTypeNames} from "@/ts/types/component/tracking-list";
import {TaskType} from "@/ts/types/dto/activity.dto";
import type {GoalCreateType} from "@/ts/types/dto/goal.dto";
import {GoalStatus, GoalSubmissionFrequency} from "@/ts/types/dto/goal.dto";
import ActivityIcon from "@/vue/atoms/activity-icon.vue";
import NicotineIcon from "@/vue/atoms/nicotine-icon.vue";
import { calculateFlOzToMilliliter, calculateMilesToCm, calculatePoundToGram } from "@/ts/utils/pure-functions";

const $api = inject("$api")! as Api;
const isLoading = ref(false);

const props = defineProps({
  showModal: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["toggleModal", "goalCreated"]);

const selectedActivityType = ref("");
const selectedFrequencyType = ref("");
const goalValue = ref(0);
const goalStatus = ref(GoalStatus.active);
const goalDate = ref(new Date());
const goalValueError = ref("");
const tomorrowDate = ref("");

onMounted(() => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrowDate.value = convertDateToServerFormat(tomorrow);
  goalDate.value = tomorrow;
});

const activityTypes = computed(() => {
  return Object.entries(TaskTypeNames)
    .filter(([key, _]) => key !== TaskType.mood && key !== TaskType.general_physical_activity_time_or_steps)
    .map(([key, value]) => ({
      value: key,
      name: value,
    }));
});

const frequencyTypes = computed(() => [
  {value: GoalSubmissionFrequency.once,
name: "Once"},
  {value: GoalSubmissionFrequency.week_7_times,
name: "Daily"},
  {value: GoalSubmissionFrequency.week_3_times,
name: "3x per week"},
  {value: GoalSubmissionFrequency.week_2_times,
name: "2x per week"},
]);

const iconType = computed(() => {
    return selectedActivityType.value as TaskType ?? "generic";
});

const getUnitForActivityType = computed(() => {
  switch (selectedActivityType.value) {
    case "biking_distance":
    case "hiking_distance":
    case "jogging_distance":
      return "miles";
    case "steps":
      return "steps";
    case "sleep_time":
      return "hours";
    case "nicotine_use":
      return "units";
    case "heart_rate":
      return "beats per minute";
    case "calorie_intake":
      return "calories";
    case "weight":
      return "lbs.";
    case "water_drinking_volume":
      return "fl.oz.";
    default:
      return "minutes";
  }
});

const validateGoalValue = () => {
  goalValueError.value = goalValue.value > 0 ? "" : "Goal must be greater than 0.";
};

watch(goalValue, () => {
  if (selectedActivityType.value === "nicotine_use") {
    goalValueError.value = "";
  } else {
    validateGoalValue;
  }
});

watch(selectedActivityType, (newValue) => {
  if (newValue === "nicotine_use") {
    goalValue.value = 0;
    selectedFrequencyType.value = GoalSubmissionFrequency.once;
    goalStatus.value = GoalStatus.completed;
  }
});


const isCreateButtonEnabled = computed(() => {
  if (selectedActivityType.value === "nicotine_use") {
    return goalDate.value >= new Date(tomorrowDate.value);
  }
  return selectedActivityType.value &&
      goalValue.value > 0 &&
      selectedFrequencyType.value &&
      goalDate.value >= new Date(tomorrowDate.value) &&
      !goalValueError.value;
});

const resetForm = () => {
  selectedActivityType.value = "";
  selectedFrequencyType.value = "";
  goalValue.value = 0;
  goalDate.value = new Date(tomorrowDate.value);
  goalValueError.value = "";
};

const toggleModal = () => {
  emit("toggleModal");
};

const createGoal = async() => {
  if (isCreateButtonEnabled.value) {
    const goalData: GoalCreateType = {
      endTimestamp: goalDate.value.toISOString(),
      submissionFrequency: selectedFrequencyType.value as GoalSubmissionFrequency,
      type: selectedActivityType.value as TaskType,
      goal: Number(goalValue.value),
      status: goalStatus.value,
    };

    try {
      isLoading.value = true;
      await $api.createGoal(goalData);
      emit("goalCreated");
      resetForm();
      toggleModal();
    } catch (error) {
      console.error("Error creating goal:", error);
      isLoading.value = false;
    } finally {
      isLoading.value = false;
    }
  } else {
    console.log("Create goal button is disabled or required fields are missing.");
  }
};

</script>

<style lang="sass" scoped>
.wrap
  display: flex
  flex-direction: column

.button-holder
  display: flex
  align-self: center
  margin-top: 20px

.input-section
  margin: 20px

.type
  margin-top: 24px
.nicotine-message
  max-width: 400px
  color: $color-primary-hc-blue-100
  @include Roboto400
  font-weight: normal

.goal-frequency
  display: flex
  flex-direction: row
  gap: 24px
  margin-top: 24px

.frequency
  display: flex
  flex-direction: column
  align-items: start
  width: 100%

.goal-input-with-unit
  display: flex
  align-items: center

.unit
  margin-left: 8px

.date
  margin-top: 24px

.error-message
  margin-top: 8px
  color: red

label
  display: block
  margin-bottom: 5px

.input-section input,
.input-section select,
.input-section .select-dropdown,
.input-section .input-text,
.input-section .input-date
  height: 49px
  padding: 8px
  border: 1px solid transparent
  border-radius: 8px
  width: 100%

</style>
