<template>
  <a :href="addToCalLink" target="_blank">
    <button-primary class="large">
      <img src="@/assets/img/icons/calendar.svg" alt="export"/> Export to calendar
    </button-primary>
  </a>
</template>

<script lang="ts" setup>
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import {getAddToGoogleCalLink} from "@/ts/utils/pure-functions";
import {parseDate} from "@/ts/utils/date-pure-functions";

import type {SchedulingBiometricAppointment} from "@/ts/types/store/scheduling-biometric-store-types";
import {computed} from "vue";

const props = defineProps<{
  appointment: SchedulingBiometricAppointment;
}>();

const addToCalLink = computed(() => {
  const {room, address1, address2, city, stateCode, zipCode} = props.appointment.location;
  const address = [
    room ? `Room ${room}` : null,
    address1,
    address2,
    city,
    stateCode ?? zipCode ? `${stateCode ?? ""} ${zipCode ?? ""}` : null,
  ].filter(Boolean).join(", ");

  const details =
      "<ul>" +
      "<li>Fast 8-12 hours before your screening. Do not eat or drink anything other than water prior to your screening</li>" +
      "<li>Drink plenty of water the night before and the morning of your screening</li>" +
      "<li>Talk with your personal physician before fasting if you are diabetic, pregnant, or taking any prescribed medications</li>" +
      "<li>Please wear a short-sleeved shirt or one that can be easily rolled up to the upper arm</li>" +
      "<li>You will receive an email reminder 24 hours prior to your scheduled appointment time</li>" +
      "</ul>";

  return getAddToGoogleCalLink(
    "Biometric Screening Appointment",
    parseDate(props.appointment.timestamp),
    details,
    address,
  );
});
</script>

<style lang="sass" scoped>
.large
  font-size: 18px
</style>
