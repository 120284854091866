<template>
  <html-rich-text :text="formattedText"/>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";
import MD from "markdown-it";
import HtmlRichText from "@/vue/atoms/html-rich-text.vue";

@Component({
  name: "MarkdownIt",
  components: {
    HtmlRichText,
  },
})
export default class MarkdownIt extends Vue {
  @Prop()
  text!: string;

  get formattedText(): string {
    return new MD().render(this.text);
  }
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>

</style>
