<template>
  <gatekeeper-icon v-if="isGatekeeper" class="todo-icon"/>
  <activity-icon v-else-if="isActivity" :type="type" class="todo-icon"/>
  <pathway-icon v-else-if="isPathway" class="todo-icon"/>
  <challenge-icon v-else-if="isChallenge" :type="type" class="todo-icon"/>
  <coaching-icon v-else-if="isCoaching && !isGatekeeper" class="todo-icon"/>
  <bio-screening-icon v-else-if="isBioScreening && !isGatekeeper" class="todo-icon"/>
  <hra-icon v-else-if="isHra && !isGatekeeper" class="todo-icon"/>
</template>

<script lang="ts" setup>
import type {TodoItemDto} from "@/ts/types/dto/todo.dto";
import {ContentReferenceType} from "@/ts/types/dto/content-reference.dto";
import PathwayIcon from "@/vue/atoms/pathway-icon.vue";
import ActivityIcon from "@/vue/atoms/activity-icon.vue";
import ChallengeIcon from "@/vue/atoms/challenge-icon.vue";
import GatekeeperIcon from "@/vue/atoms/gatekeeper-icon.vue";
import CoachingIcon from "@/vue/atoms/coaching-icon.vue";
import BioScreeningIcon from "@/vue/atoms/bio-screening-icon.vue";
import HraIcon from "@/vue/atoms/hra-icon.vue";
import {computed} from "vue";
import type {ActivityIconType} from "@/ts/types/component/activity-card";

const props = defineProps<{
  item: TodoItemDto;
}>();

const isPathway = computed((): boolean => {
  return props.item.contentReference.type === ContentReferenceType.Pathway;
});

const isActivity = computed((): boolean => {
  return props.item.contentReference.type === ContentReferenceType.Activity ||
    props.item.contentReference.type === ContentReferenceType.PlatformActivity;
});

const isGatekeeper = computed((): boolean => {
  return Boolean((props.item as TodoItemDto).isGateKeeper);
});

const isChallenge = computed((): boolean => {
  switch (props.item.contentReference.type) {
    case ContentReferenceType.ChallengeLeaderboard:
    case ContentReferenceType.ChallengeMapRace:
    case ContentReferenceType.ChallengeRelayRace:
      return true;
    default:
      return false;
  }
});

const isCoaching = computed((): boolean => {
  return props.item.contentReference.type === ContentReferenceType.Coaching;
});

const isHra = computed((): boolean => {
  return props.item.contentReference.type === ContentReferenceType.HealthRiskAssessment;
});

const isBioScreening = computed((): boolean => {
  return props.item.contentReference.type === ContentReferenceType.BiometricScreening;
});

const type = computed((): ActivityIconType => {
  if (props.item.contentReference.type === ContentReferenceType.PlatformActivity) {
    return "platform";
  }
  return props.item?.task?.type || "generic";
});

</script>

<style lang="sass" scoped>

</style>
