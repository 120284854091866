<template>
  <div class="menu-list">
    <router-link class="menu-link" :to="link">
      <div class="image-wrapper">
        <img :src="image" class="image-link"/>
      </div>
      {{ title }}
    </router-link>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";

@Component({
  name: "DropDownItem",
})
export default class DropDownItem extends Vue {
  @Prop()
  image!: string;

  @Prop()
  title!: string;

  @Prop()
  link!: string;
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>
.menu-list
  display: flex
  flex-direction: column
  font-size: 20px
  justify-content: center

.menu-link
  text-decoration: none
  @include Asap700
  font-size: 24px
  color: $color-primary-hc-blue-100
  display: flex
  margin-bottom: 30px

.image-wrapper
  width: 45px

.image-link
  margin-right: 17px

</style>
