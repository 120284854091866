<template>
  <alert-icon-card
    v-if="showScheduledInPast"
    theme="info"
  >
    <div class="text">
      You had scheduled a screening on the <span class="bold">{{ appointmentDate }}</span>
      <br/>
      You will be informed when your results are ready.
    </div>
  </alert-icon-card>
</template>

<script lang="ts">
import {Component, Vue, Prop} from "vue-property-decorator";
import AlertIconCard from "@/vue/atoms/alert-icon-card.vue";
import type {BiometricScreeningAppointment} from "@/ts/types/dto/biometric-screening.dto";
import {isAppointmentScheduledInPast} from "@/ts/utils/my-health/bioscreening";
import {formatDateShortMonthName, parseDate} from "@/ts/utils/date-pure-functions";

@Component({
  name: "ScheduledPastCard",
  components: {AlertIconCard}
})
export default class ScheduledPastCard extends Vue {
  @Prop({default: null})
  appointment!: BiometricScreeningAppointment | null;

  get isAppointmentScheduledInPast(): boolean {
    return isAppointmentScheduledInPast(this.appointment);
  }

  get showScheduledInPast(): boolean {
    return this.isAppointmentScheduledInPast;
  }

  get appointmentDate(): string {
    return formatDateShortMonthName(this.appointment!.timestamp);
  }
}
</script>

<style lang="sass" scoped>
.bold
  @include Roboto700

.text
  @include Roboto400
  color: $color-primary-hc-blue-100
  font-size: 14px
  letter-spacing: 0.2px
  line-height: 22px
  margin: 0 0 4px
</style>
