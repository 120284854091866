<template>
  <alert-icon-card
    v-if="showDeadlineForBioscreening"
    theme="info"
  >
    <div class="text">
      The deadline to schedule your biometric screening is <span class="bold"> {{ bioscreeningEndDate }} </span>
    </div>
  </alert-icon-card>
</template>

<script lang="ts">
import {Component, Vue, Prop} from "vue-property-decorator";
import AlertIconCard from "@/vue/atoms/alert-icon-card.vue";
import {formatDateShortMonthName} from "@/ts/utils/date-pure-functions";
import type {MyProgramResponse} from "@/ts/types/dto/program.dto";
import {hasProgramBioscreeningAvailableWithEndDate} from "@/ts/utils/my-health/program-bioscreening";
import {isBioscreeningAvailableAndNotSubmitted} from "@/ts/utils/my-health/bioscreening";
import type {BiometricScreeningAvailable, BiometricScreeningStatus} from "@/ts/types/dto/biometric-screening.dto";

@Component({
  name: "DeadlineFutureCard",
  components: {AlertIconCard}
})
export default class DeadlineFutureCard extends Vue {
  @Prop({default: null})
  biometricStatus!: BiometricScreeningStatus | null;

  @Prop({default: null})
  biometricAvailable!: BiometricScreeningAvailable | null;

  get isBioscreeningAvailableAndNotSubmitted(): boolean {
    return isBioscreeningAvailableAndNotSubmitted({
      biometricAvailable: this.biometricAvailable,
      biometricStatus: this.biometricStatus,
    });
  }

  get showDeadlineForBioscreening(): boolean {
    return this.isBioscreeningAvailableAndNotSubmitted;
  }

  get bioscreeningEndDate(): string {
    return formatDateShortMonthName(this.biometricAvailable?.deadline ?? "");
  }
}
</script>

<style lang="sass" scoped>
.bold
  @include Roboto700

.text
  @include Roboto400
  color: $color-primary-hc-blue-100
  font-size: 14px
  letter-spacing: 0.2px
  line-height: 22px
  margin: 0 0 4px
</style>
