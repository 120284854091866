<template>
  <div class="card">
    <div class="image-container">
      <img
        :src="fullImagePath"
        @error="handleImageError"
      />
    </div>
    <div>
      <h2>{{ name }}</h2>
      <h5>{{ provider }}</h5>
    </div>
    <p>{{ description }}</p>
    <h4>Website  <button-link
        :to="websiteUrl"
      :link="true" >
    </button-link> </h4>
      <img
        class="external-link"
        src="@/assets/img/icons/external-link.svg"
      />
      <a class="URL" :href="websiteUrl">Click here</a>

  </div>
</template>

<script lang="ts" setup>
import ButtonLink from "@/vue/atoms/button-link.vue";
import PlaceholderImage from "@/assets/img/no_data_placeholder.svg";
import {ref, inject, onMounted} from "vue";
import type {Api} from "@/ts/classes/api";

const $api: Api = inject("$api")! as Api;

const props = defineProps<{
  name: string;
  provider: string;
  description: string;
  imagePath: string;
  websiteUrl: string;
}>();

const fullImagePath = ref(PlaceholderImage);

const fetchImage = async() => {
  try {
    if (props.imagePath) {
      const imageUrl = await $api.getImage(props.imagePath);
      fullImagePath.value = imageUrl;
    } else {
      fullImagePath.value = PlaceholderImage;
    }
  } catch (error) {
    console.error("Error fetching image:", error);
    fullImagePath.value = PlaceholderImage;
  }
};

const handleImageError = (event: any) => {
  event.target.src = PlaceholderImage;
};

onMounted(() => {
  fetchImage();
});

</script>

<style lang="sass" scoped>
.card
  padding: 16px
  box-shadow: 0 0 10px rgba(15, 38, 78, 0.15)
  border-radius: 10px
  background-color: $color-white

.image-container
  display: flex
  justify-content: center
  align-items: center
  overflow: hidden

  img
    max-width: 100%
    max-height: 100%
    object-fit: cover



</style>
