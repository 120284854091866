<template>
  <div class="options">
    <div
      v-for="option in options"
      :key="option.value"
      :class="{selected: option.value === modelValue, option: true}"
      @click="updateValue(option)"
    >
      {{ option.label }}
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Emit,
  Prop,
  Vue,
} from "vue-property-decorator";
import type {InputSelectOption} from "@/ts/types/component/input.select";


@Component({
  name: "InputSelect",
})
export default class InputSelect extends Vue {
  @Prop()
  public readonly modelValue!: string;

  @Prop() options!: InputSelectOption[];

  @Prop({default: ""}) autocomplete!: string;

  @Emit('update:modelValue')
  updateValue(value: InputSelectOption): string {
    return value.value;
  }
}
</script>

<style lang="sass" scoped>

.options
  display: flex
  flex-wrap: wrap
  gap: 5px
  width: 100%

.option
  background-color: $color-white
  border: 1.5px solid $color-neutral-platinum-100
  @include Roboto400
  border-radius: 8px
  color: $color-primary-hc-blue-100
  cursor: pointer
  flex: 1
  height: 52px
  line-height: 52px
  text-align: center
  justify-content: space-between
  font-size: 14px
  min-width: fit-content
  padding-left: 20px
  padding-right: 20px

  &.selected
    background-color: $color-secondary-state-blue-100
    color: $color-white
</style>
