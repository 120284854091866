<template>
  <div class="input-wrapper">
    <input
      :id="id"
      v-validity="validity"
      :name="name"
      :value="modelValue"
      :type="type"
      :autocomplete="autocomplete"
      :required="required"
      :placeholder="placeholder"
      :minlength="minlength"
      :maxlength="maxlength"
      :min="min"
      :max="max"
      :step="step"
      :title="title"
      :pattern="pattern"
      :disabled="disabled"
      @input="updateValue"
    />
    <span v-if="errorLine" class="error-message">{{ errorLine }}</span>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Emit,
  Prop,
  Vue,
} from "vue-property-decorator";

@Component({
  name: "InputText",
})
export default class InputText extends Vue {
  @Prop() name!: string;

  @Prop() readonly modelValue!: string;

  @Prop() placeholder!: string;

  @Prop({default: false}) required!: boolean;

  @Prop({default: false}) disabled!: boolean;

  @Prop({default: undefined}) minlength!: number | undefined;

  @Prop({default: undefined}) maxlength!: number | undefined;

  @Prop({default: undefined}) min!: number | undefined;

  @Prop({default: undefined}) max!: number | undefined;

  @Prop({default: undefined}) step!: number | undefined;

  @Prop({default: undefined}) id!: string | undefined;

  @Prop({default: ""}) validity!: string;

  @Prop({default: undefined}) errorLine!: string;

  @Prop({default: ""}) autocomplete!: string;

  @Prop({default: "text"}) type!: "email" | "number" | "text";

  @Prop({default: ""}) title!: string;

  @Prop({default: undefined}) pattern!: string;

  @Emit('update:modelValue')
  updateValue(event: any): string {
    return event.target.value as string; // eslint-disable-line @typescript-eslint/no-unsafe-member-access
  }
}
</script>

<style lang="sass" scoped>
input
  @include input-field

.input-wrapper
  position: relative

.error-message
  @include Roboto400
  position: absolute
  bottom: -15px
  left: 3px
  max-width: 100%
  color: $color-status-error-red
  font-size: 12px

</style>
