<template>
  <div class="input-wrapper">
    <span class="country-code">+1</span>
    <input
      :id="id"
      v-model="inputValue"
      :name="name"
      :type="'tel'"
      :autocomplete="autocomplete"
      :required="required"
      :placeholder="placeholder"
      :disabled="disabled"
    />
    <span v-if="errorLine" class="error-message">{{ errorLine }}</span>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator";

@Component({
  name: "InputPhone"
})
export default class InputPhone extends Vue {
  @Prop({ default: '' }) readonly modelValue!: string;

  @Prop({ default: '' }) readonly placeholder!: string;

  @Prop({ default: false }) readonly required!: boolean;

  @Prop({ default: false }) readonly disabled!: boolean;

  @Prop({ default: '' }) readonly id!: string;

  @Prop({ default: '' }) readonly name!: string;

  @Prop({ default: '' }) readonly autocomplete!: string;

  errorLine: string | null = null;

  get inputValue(): string {
    return this.modelValue;
  }

  set inputValue(value: string) {
    this.$emit("update:modelValue", value);
    this.validatePhoneNumber(value);
  }

  validatePhoneNumber(value: string): void {
    const digitsOnly = value.replace(/[\(\)\-\.\s]/g, "");
    if ((/^\d{10}$/).test(digitsOnly)) {
      this.errorLine = null;
    } else {
      this.errorLine = "Please enter a valid phone number.";
    }
  }
}
</script>

<style lang="sass" scoped>
input
  @include input-field

.input-wrapper
  display: flex
  align-items: center
  position: relative

.country-code
  margin-right: 8px
  font-weight: bold

.error-message
  position: absolute
  bottom: -20px
  left: 30px
  max-width: 100%
  color: $color-status-error-red
  font-size: 12px
</style>
