<template>
  <section class="dropdown-container">
    <select-dropdown
      :model-value="selectedBioScreeningId"
      class="dropdown"
      :options="bioScreenings"
      placeholder="Select Screening Period"
      @update:model-value="onSelectScreening"
    />
  </section>
  <section class="main">
    <h2>My Score</h2>
    <b>{{ biometricResult?.date ? formatDateShortMonthName(biometricResult?.date) : '' }}</b>
    <my-score-circle :biometric-result="biometricResult"/>
  </section>
  <loading-suspense :error="error" :loading="loading">
    <div class="results-wrapper">
      <div class="results">
        <biometric-screening-results
          v-if="biometricResult"
          :biometric-result="biometricResult"
          :hra-summary="null"
        />
      </div>
    </div>
  </loading-suspense>
</template>

<script lang="ts" setup>
import type {Ref} from "vue";
import {inject, onMounted, ref} from "vue";
import type {Api} from "@/ts/classes/api";
import type {BiometricScreening, GetBiometricScreeningResultCurrentDTO} from "@/ts/types/dto/biometric-screening.dto";
import SelectDropdown from "@/vue/atoms/select-dropdown.vue";
import BiometricScreeningResults from "@/vue/organisms/my-health/biometric-screening-results.vue";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import MyScoreCircle from "@/vue/molecules/my-health/my-score-circle.vue";
import {formatDateShortMonthName} from "@/ts/utils/date-pure-functions";

const $api = inject("$api")! as Api;

const loading = ref<boolean>(false);
const error = ref<string | null>(null);
const selectedBioScreeningId: Ref<string> = ref("");
const bioScreenings: Ref<{value: string; name: string}[]> = ref([]);
const biometricResult = ref<GetBiometricScreeningResultCurrentDTO | null>(null);

onMounted(async() => {
  const data = await $api.getBiometricScreenings();
  bioScreenings.value = data.map((bioScreening: BiometricScreening) => ({
    value: bioScreening.id.toString(),
    name: bioScreening.period,
  }));
  // Init with the latest screening
  if (bioScreenings.value.length > 0) {
    onSelectScreening(bioScreenings.value[0].value);
  }
});

const onSelectScreening = (bioScreeningId: string): void => {
  selectedBioScreeningId.value = bioScreeningId;
  void fetchBioScreening(parseInt(bioScreeningId, 10));
};

const fetchBioScreening = async(bioScreeningId: number): Promise<void> => {
  loading.value = true;
  try {
    biometricResult.value = await $api.getBiometricScreening(bioScreeningId);
  } catch (err) {
    error.value = err as string;
  } finally {
    loading.value = false;
  }
};

</script>

<style lang="sass" scoped>
.dropdown-container
  display: flex
  justify-content: center
  padding: 22px 0

.dropdown
  width: 260px

.main
  max-width: 400px
  margin: 0 auto 1rem
  display: flex
  flex-direction: column
  align-items: center
  gap: 16px

h2
  @include Asap700
  font-size: 28px
  color: $color-primary-hc-blue-100
  text-align: center
  margin: 0

.results-wrapper
  background-color: $color-neutral-platinum-40

.results
  margin: 0 auto
  max-width: 684px
  align-items: flex-start
  background-color: $color-neutral-platinum-40
  display: flex
  flex: 1
  flex-wrap: wrap
  gap: 26px
  justify-content: center
  padding-top: 24px
  padding-bottom: 45px
</style>
