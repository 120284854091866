<template>
  <div :class="className">
    <div class="name">
      {{ result.name }}
    </div>
    <div class="value">
      {{ result.value }}
      <span v-if="result.unitOfMeasure" class="unit">{{ result.unitOfMeasure }}</span>
    </div>
    <div v-if="result.riskLevel" class="score-result">
      <score-result-pill :risk-level="result.riskLevel" :risk-color="result.riskColor"/>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type {BiometricScreeningResultCardTheme} from "@/ts/types/component/biometric-screening";
import ScoreResultPill from "@/vue/atoms/score-result-pill.vue";
import {computed} from "vue";
import type {BiometricScreeningAdditionalResult, BiometricScreeningResult} from "@/ts/types/dto/biometric-screening.dto";

const props = defineProps<{
  result: BiometricScreeningAdditionalResult | BiometricScreeningResult;
  theme: BiometricScreeningResultCardTheme;
}>();

const className = computed(() => [
  "biometric-screening-result",
  props.theme,
  (props.result.id !== 0) && "clickable",
]);
</script>

<style lang="sass" scoped>
.biometric-screening-result
  border-radius: 16px
  display: flex
  flex-direction: column
  gap: 16px
  height: 156px
  padding: 16px
  position: relative
  width: 159px

  &.clickable
    cursor: pointer

  &.white
    background-color: $color-white

  &.gray
    background-color: $color-primary-hc-blue-10

.name
  @include Asap700
  color: $color-primary-hc-blue-100
  font-size: 16px
  line-height: 22px

.value
  @include Roboto400
  color: $color-secondary-state-blue-100
  font-size: 20px
  font-weight: 500
  line-height: 22px

.unit
  @include Roboto400
  color: $color-primary-hc-blue-50
  font-size: 14px
  line-height: 22px

.score-result
  bottom: 23px
  position: absolute

</style>
