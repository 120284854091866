<template>
  <field-set id="motivation" class="edit-motivation">
    <input-textarea
      id="motivation"
      :model-value="modelValue"
      autocomplete="motivation"
      placeholder="Motivation"
      @update:model-value="updateValue"
    />
  </field-set>
</template>

<script lang="ts" setup>
import FieldSet from "@/vue/molecules/field-set.vue";
import InputTextarea from "@/vue/atoms/input-textarea.vue";

const props = defineProps<{
  modelValue: string;
}>();

const emit = defineEmits(["update:modelValue"]);

const updateValue = (value: string): void => {
  emit("update:modelValue", value);
};

</script>
<!-- eslint-disable -->
<style lang="sass" scoped>
.edit-motivation
  width: 100%
</style>
