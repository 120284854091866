<template>
  <biometric-appointment-details
    v-if="isAppointmentScheduledInFuture"
    :timestamp="appointment?.timestamp"
    :location="appointment?.location"
  />
</template>

<script lang="ts">
import {Component, Vue, Prop} from "vue-property-decorator";
import type {BiometricScreeningAppointment} from "@/ts/types/dto/biometric-screening.dto";
import BiometricAppointmentDetails from "@/vue/molecules/biometric-appointment-details.vue";
import {isAppointmentScheduledInFuture} from "@/ts/utils/my-health/bioscreening";

@Component({
  name: "AppointmentDetails",
  components: {BiometricAppointmentDetails},
})
export default class AppointmentDetails extends Vue {
  @Prop({default: null})
  appointment!: BiometricScreeningAppointment | null;

  get isAppointmentScheduledInFuture(): boolean {
    return isAppointmentScheduledInFuture(this.appointment);
  }
}
</script>

<style lang="sass" scoped></style>
