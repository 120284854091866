<template>
  <div class="row">
    <div>
      <slot name="left"/>
    </div>
    <div>
      <slot name="right"/>
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Vue,
} from "vue-property-decorator";

@Component({
  name: "BoxRow",
})
export default class BoxRow extends Vue {
}
</script>
<style lang="sass" scoped>
.row
  display: flex
  justify-content: space-between
  align-items: center
  padding: 12px
  margin: 0
</style>
