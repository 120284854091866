<template>
  <div class="time-selector">
    <time-selector-row
      v-if="dayBefore.length"
      :values="dayBefore"
      :title="yesterdayTitle"
      :model-value="modelValue"
      @update:modelValue="updateValue"
    />
    <time-selector-row
      v-if="morning.length"
      :values="morning"
      title="Morning"
      :model-value="modelValue"
      @update:modelValue="updateValue"
    />
    <time-selector-row
      v-if="afternoon.length"
      :values="afternoon"
      title="Afternoon"
      :model-value="modelValue"
      @update:modelValue="updateValue"
    />
    <time-selector-row
      v-if="evening.length"
      :values="evening"
      title="Evening"
      :model-value="modelValue"
      @update:modelValue="updateValue"
    />
    <time-selector-row
      v-if="dayAfter.length"
      :values="dayAfter"
      :title="tomorrowTitle"
      :model-value="modelValue"
      @update:modelValue="updateValue"
    />
  </div>
</template>
<script lang="ts">
import {
  Component,
  Emit,
  Prop,
  Vue,
} from "vue-property-decorator";
import TimeSelectorRow from "@/vue/molecules/time-selector-row.vue";
import {
  addToDate,
  formatDate,
  formatDateShortMonthName, getTimezoneAbbr, isDayAfterReferenceDay,
  isDayBeforeReferenceDay,
  subtractFromDate,
  today,
} from "@/ts/utils/date-pure-functions";

const MORNING_TIMES = 12;
const EVENING_TIMES = 18;

@Component({
  name: "TimeSelector",
  components: {TimeSelectorRow},
})
export default class TimeSelector extends Vue {
  @Prop()
  values!: Date[];

  @Prop() modelValue!: Date;

  @Prop() selectedDay!: Date;

  get morning(): Date[] {
    return this.values.filter(
      (date) => date.getHours() < MORNING_TIMES && date.getDate() === this.selectedDay.getDate(),
    );
  }

  get afternoon(): Date[] {
    return this.values.filter(
      (date) => date.getHours() >= MORNING_TIMES && date.getHours() < EVENING_TIMES && date.getDate() === this.selectedDay.getDate(),
    );
  }

  get evening(): Date[] {
    return this.values.filter(
      (date) => date.getHours() >= EVENING_TIMES && date.getDate() === this.selectedDay.getDate(),
    );
  }

  get dayBefore(): Date[] {
    // we display local time, so it could be possible that some slots are available at the local day before
    return this.values.filter((date) => isDayBeforeReferenceDay(date, this.selectedDay));
  }

  get dayAfter(): Date[] {
    // we display local time, so it could be possible that some slots are available at the local day after
    return this.values.filter((date) => isDayAfterReferenceDay(date, this.selectedDay));
  }

  get yesterdayTitle(): string {
    return `${formatDateShortMonthName(subtractFromDate(this.selectedDay, {days: 1}))} (${getTimezoneAbbr()})`;
  }

  get tomorrowTitle(): string {
    return `${formatDateShortMonthName(addToDate(this.selectedDay, {days: 1}))} (${getTimezoneAbbr()})`;
  }

  @Emit('update:modelValue')
  updateValue(event: Date): Date {
    return event;
  }
}
</script>

<style lang="sass" scoped>
.time-selector
  display: flex
  flex-direction: column
  gap: 32px
</style>
