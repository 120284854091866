<template>
  <alert-icon-card
    v-if="showNotContainScore"
    theme="info"
  >
    <div class="alert-text">
      Your program does not contain my score.
    </div>
  </alert-icon-card>
</template>

<script lang="ts">
import {Component, Vue, Prop} from "vue-property-decorator";
import AlertIconCard from "@/vue/atoms/alert-icon-card.vue";
import {hasProgramHra} from "@/ts/utils/my-health/program-hra";
import type {MyProgramResponse} from "@/ts/types/dto/program.dto";
import {hasProgramBioscreening} from "@/ts/utils/my-health/program-bioscreening";

@Component({
  name: "ProgramWithoutScoreCard",
  components: {
    AlertIconCard
  }
})
export default class ProgramWithoutScoreCard extends Vue {
  @Prop({default: null})
  program!: MyProgramResponse | null;

  get isHraNotIncludedInProgram(): boolean {
    return !hasProgramHra(this.program);
  }

  get isBioscreeningNotIncludedInProgram(): boolean {
    return !hasProgramBioscreening(this.program);
  }

  get showNotContainScore(): boolean {
    return this.isHraNotIncludedInProgram && this.isBioscreeningNotIncludedInProgram;
  }
}
</script>

<style lang="sass" scoped>

</style>
