<template>
  <button
    class="button-round keyboard-focus-border"
    :class="{'button-round--loading': loading, 'button-round--totally-purple': (theme === 'totally-purple')}"
    :aria-label="ariaLabel ?? label"
    :disabled="disabled || loading"
  >
    <div v-if="loading" class="spinner"/>
    <icon-chameleon
      v-else
      class="icon"
      :icon="icon"
      :color="color"
      :hover-color="hoverColor"
      :alt="`Icon for ${label}`"
      aria-hidden="true"
    />
    <span
      v-if="displayLabel"
      class="visible-label"
      :class="{'visible-label--totally-purple': (theme === 'totally-purple')}"
    >
      {{ label }}
    </span>
  </button>
</template>

<script lang="ts" setup>
import IconChameleon from "@/vue/atoms/icon-chameleon.vue";
import {computed} from "vue";
import type {IconChameleonColors} from "@/ts/types/component/icon-chameleon";

// TODO support different color themes

const props = defineProps<{
  label: string;
  icon: string;
  displayLabel?: boolean;
  ariaLabel?: string;
  loading?: boolean;
  disabled?: boolean;
  theme?: "default" | "totally-purple";
}>();

const color = computed((): IconChameleonColors => {
  if (props.theme === "totally-purple") {
    return "white";
  }

  return "primary100";
});

const hoverColor = computed((): IconChameleonColors => {
  return "white";
});

</script>

<style lang="sass" scoped>

.button-round
  border: none
  padding: 0 0 0 24px
  position: relative
  height: 24px
  background-color: transparent

  &::before
    content: ""
    position: absolute
    top: 0
    left: 0
    border-radius: 50%
    width: 24px
    height: 24px
    background-color: $color-primary-hc-blue-10

  &--totally-purple::before
    background-color: $color-secondary-state-blue-100

  &:not(.button-round--loading):hover::before
    background-color: $color-secondary-state-blue-100

.icon
  position: absolute
  top: 2px
  left: 2px
  height: calc(100% - 4px)

.visible-label
  padding-left: 8px
  color: $color-primary-hc-blue-100
  @include Roboto600
  font-size: 14px

  &--totally-purple
    color: $color-secondary-state-blue-100

  :hover > &
    text-decoration: underline

.spinner
  @include lds-spinner(8px, "", true, $color-primary-hc-blue-100)
  position: absolute
  top: 4px
  left: 4px

</style>
