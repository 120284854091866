<template>
  <navbar-page title="My Benefits" :back-link="$pagesPath.profile.main" class="body">
    <div class="container">
      <div v-if="hasBenefits" class="charts">
        <benefits-card
          v-for="(card, index) in benefitsData"
          :key="index"
          :name="card.name"
          :provider="card.provider"
          :description="card.description"
          :image-path="card.imagePath"
          :website-url="card.websiteUrl"
        />
      </div>
      <div v-else>
        <no-benefits-placeholder/>
      </div>
    </div>
  </navbar-page>
</template>

<script lang="ts" setup>
import {inject, onMounted, ref, computed} from "vue";
import NavbarPage from "@/vue/templates/navbar-page.vue";
import BenefitsCard from "@/vue/molecules/benefits/benefits-card.vue";
import NoBenefitsPlaceholder from "@/vue/molecules/benefits/no-benefits-placeholder.vue";
import type {Api} from "@/ts/classes/api";
import type {BenefitsDTO} from "@/ts/types/dto/benefits.dto";

const $api: Api = inject("$api")! as Api;

const benefitsData = ref<BenefitsDTO[]>([]);

const loadBenefits = async() => {
  try {
    const response = await $api.getBenefits();
    benefitsData.value = response.items;
  } catch (error) {
    console.error("Error fetching benefits: ", error);
  }
};

onMounted(() => {
  loadBenefits();
});

const hasBenefits = computed(() => benefitsData.value.length > 0);

</script>
<style lang="sass" scoped>
.container
  @include container

.charts
  display: grid
  grid-template-columns: 1fr 1fr
  grid-gap: 20px
  padding-bottom: 50px
</style>
