<template>
  <placeholder-soon/>
</template>
<script lang="ts">
import PlaceholderSoon from "@/vue/molecules/placeholder-soon.vue";
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {LoadingMixin} from "@/ts/mixins/loading-mixin";
import {CachedApiMixin} from "@/ts/mixins/cached-api-mixin";

@Component({
  name: "CommunityGroups",
  components: {
    PlaceholderSoon,
  },
})
export default class OverviewProgram extends mixins(LoadingMixin, CachedApiMixin) {

}

</script>
