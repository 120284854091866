import {pagesPath} from "@/ts/router/pages-path";
import BiometricSchedulingParentPage from "@/vue/pages/scheduling/biometric-scheduling-parent.page.vue";
import SchedulingPersonalInformationPage from "@/vue/pages/scheduling/scheduling-personal-information.page.vue";
import SchedulingSelectLocationPage from "@/vue/pages/scheduling/scheduling-select-location.page.vue";
import type {RouteRecordRaw} from "vue-router";
import SchedulingSelectTimePage from "@/vue/pages/scheduling/scheduling-select-time.page.vue";
import SchedulingAppointmentConfirmedPage from "@/vue/pages/scheduling/scheduling-appointment-confirmed.page.vue";
import FaxResults from "@/vue/pages/biometric-screening/biometric-screening-fax-results.vue";

export const biometricSchedulingPages: RouteRecordRaw = {
  path: pagesPath.biometricScheduling.parent,
  component: BiometricSchedulingParentPage,
  children: [
    {
      path: pagesPath.biometricScheduling.personalInformation,
      component: SchedulingPersonalInformationPage,
    },
    {
      path: pagesPath.biometricScheduling.time,
      component: SchedulingSelectTimePage,
    },
    {
      path: pagesPath.biometricScheduling.location,
      component: SchedulingSelectLocationPage,
    },
    {
      path: pagesPath.biometricScheduling.appointmentConfirmed,
      component: SchedulingAppointmentConfirmedPage,
    },
  ],
};
