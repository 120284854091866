import type {TaskType} from "./activity.dto";
import type {ContentReferenceType} from "@/ts/types/dto/content-reference.dto";
import type {Pagination} from "./pagination.dto";

export interface GetTrackingListResponseDTO {
  id: string;
  provider: TrackingProvider;
  trackedAmount: number;
  date: string;
  createdAt: string;
  taskType: TaskType;

  /**
   * @deprecated we store no meaningful time value in this field, do not use it, learn more: HVV-2660
   */
  trackedAtUTC: string;
}

export interface GetTrackingListRequestDTO {
  startDate?: string;
  endDate?: string;
  taskType?: string;
}

export interface GetPaginatedTrackingResponseDTO {
  items: GetTrackingListResponseDTO[];
  pagination: Pagination;
}

export interface GetPaginatedTrackingRequestDTO {
  startDate?: string;
  endDate?: string;
  taskType?: string;
  limit?: number;
  offset?: number;
}

export interface GetTrackingMetricsRequestDTO {
  date?: string;
  taskType?: string;
}

export interface GetTrackingMetricsResponseDTO {
  total: number;
  yearly: TrackingMetricDTO[];
  monthly: TrackingMetricDTO[];
  weekly: TrackingMetricDTO[];
}

export interface TrackingMetricDTO {
  startDate: string;
  endDate: string;
  value: number;
}

export interface PutTrackingItemDTO {
  provider: TrackingProvider;
  taskType: TaskType;
  trackedAmount: number;
  date: string;

  /**
   * @deprecated we store no meaningful time value in this field, do not use it, learn more: HVV-2660
   */
  trackedAtUTC: string;
}

export type PutTrackingRequestDTO = PutTrackingItemDTO[];

export interface GetTrackingContentEligibilityDTO {
  contentReferenceType: ContentReferenceType;
  isEligible: boolean;
}

export enum TrackingProvider {
  manual = "manual",
  googleFit = "googleFit",
  appleHealth = "appleHealth",
  fitbit = "fitbit",
  garmin = "garmin",
}
