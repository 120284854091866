<template>
  <div>
    <div class="quiz-header">
      {{ pathwayResponse.title }}
      <router-link :to="pathwayLink" class="button-go-start">
        <img src="@/assets/img/x-mark.svg" alt="close"/>
      </router-link>
    </div>
    <loading-bar :steps="pathwayResponse.quiz?.questions.length" :current="questionIndex" theme="pink"/>
    <div>
      <div class="title-wrapper">
        <h1 class="title">
          {{ question.stem }}
        </h1>
        <h3 v-if="isMultiple" class="description">
          You may select one or more answers
        </h3>
      </div>
      <div class="option">
        <button-link
          :to="previousQuestion"
          class="button-container button-back"
          theme="white"
          :disabled="!previousQuestion"
        >
          Back
        </button-link>
        <multiple-checkbox
          v-model="answers"
          :allow-multiple="isMultiple"
          :values="optionList"
          class="multiple-checkbox"
        />
        <button-primary class="button-next" :loading="loading" @click="next">
          Next
          <bigger-sign/>
        </button-primary>
      </div>
    </div>
    <error-text :error="error"/>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Prop,
} from "vue-property-decorator";
import LoadingBar from "@/vue/atoms/loading-bar.vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import type {
  GetPathwayResponseDTO,
  Option,
  PostQuizRequestItemDTO,
  PostQuizResponse,
  Question,
} from "@/ts/types/dto/pathway.dto";
import {
  QuestionType,
  QuizStatus,
} from "@/ts/types/dto/pathway.dto";
import MultipleCheckbox from "@/vue/molecules/multiple-checkbox.vue";
import type {MultipleCheckboxItem} from "@/ts/types/component/input.select";
import ErrorText from "@/vue/atoms/error-text.vue";
import BiggerSign from "@/vue/atoms/bigger-sign.vue";
import {mixins} from "vue-class-component";
import {mixinClassBodyFactory} from "@/ts/mixins/bg-mixin";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import ButtonLink from "@/vue/atoms/button-link.vue";

@Component({
  name: "PathwayQuizPage",
  components: {
    ButtonLink,
    BiggerSign,
    ErrorText,
    MultipleCheckbox,
    ButtonPrimary,
    LoadingBar,
  },
})

export default class PathwayQuizPage extends mixins(mixinClassBodyFactory("global-onboarding-bg-color"), LoadingMixin) {
  @Prop()
  readonly pathwayResponse!: GetPathwayResponseDTO;

  answers: MultipleCheckboxItem<Option>[] = [];

  collectedAllQuestions: PostQuizRequestItemDTO[] = [];

  get isMultiple(): boolean {
    return this.question.questionType === QuestionType.multiple;
  }

  get questionIndex(): number {
    return parseInt(this.$route.params.number as string, 10) - 1;
  }

  get question(): Question {
    return this.pathwayResponse.quiz!.questions[this.questionIndex];
  }

  get optionList(): MultipleCheckboxItem<Option>[] {
    return this.pathwayResponse.quiz!.questions[this.questionIndex].options.map((el) => ({
      text: el.text,
      id: el.id,
      data: el,
    }));
  }

  get pathwayLink(): string {
    return this.$pagesPath.pathway.pathwayStart.replace(":id", this.pathwayResponse.id);
  }

  // Probably need to extract these two, the entire behaviour of the quiz next/back buttons is the same as the one in the pathway task page

  get nextQuestionPath(): string {
    // currentPage=currentIndex+1, nextPage=CurrentIndex+2
    return this.$pagesPath.pathway.pathwayQuiz
      .replace(":id", this.pathwayResponse.id)
      .replace(":number", `${this.questionIndex + 2}`); // eslint-disable-line @typescript-eslint/no-magic-numbers
  }

  get previousQuestion(): string {
    if (this.questionIndex === 0) {
      return "";
    }
    return this.$pagesPath.pathway.pathwayQuiz
      .replace(":id", this.pathwayResponse.id)
      .replace(":number", `${this.questionIndex}`);
  }

  created(): void {
    if (this.pathwayResponse.quizStatus === QuizStatus.completed || !this.pathwayResponse.quiz) {
      void this.$router.push(this.$pagesPath.pathway.pathwayTaskFinish.replace(":id", this.pathwayResponse.id));
    }
  }

  @DefaultGrowlError
  async next(): Promise<void> {
    const index = this.collectedAllQuestions.findIndex((el) => el.questionId === this.question.id);
    if (index > 0) {
      // remove duplicates in case of going back
      this.collectedAllQuestions.splice(index, 1);
    }
    this.collectedAllQuestions.push({
      questionId: this.question.id,
      selectedOptions: this.answers.map((answer) => ({
        id: answer.data.id,
        isCorrectAnswer: true,
      })),
    });
    if (this.questionIndex + 1 >= this.pathwayResponse.quiz!.questions.length) {
      const response: PostQuizResponse = await this.$api.submitQuiz(this.pathwayResponse.id, this.collectedAllQuestions);
      if (response.passed) {
        void this.$router.push(this.$pagesPath.pathway.pathWayCongratulations.replace(":id", this.pathwayResponse.id));
      } else {
        void this.$router.push(this.$pagesPath.pathway.pathwayFailed.replace(":id", this.pathwayResponse.id));
      }
    } else {
      void this.$router.push(this.nextQuestionPath);
    }
  }
}
</script>
<style lang="sass" scoped>
.quiz-header
  @include Asap700
  color: $color-primary-hc-blue-100
  font-size: 20px
  display: flex
  justify-content: center
  margin: 2rem

.button-go-start
  right: 50px
  position: absolute

.button-container
  display: flex
  align-items: center

.button-back,
.button-next
  width: 130px
  flex-shrink: 0

.title-wrapper
  @include Asap700
  color: $color-primary-hc-blue-100
  display: flex
  flex-direction: column
  align-items: center
  font-size: 20px
  margin-top: 5rem
  margin-bottom: 2.5rem

.description
  @include Roboto400
  color: $color-primary-hc-blue-100
  font-size: 14px

.option
  display: flex
  justify-content: center
  max-width: 1200px
  margin: auto
  padding: 0 12px
  align-items: center

.multiple-checkbox
  max-width: 447px
  padding: 0 12px
</style>
