<template>
  <!-- eslint-disable -->
  <div class="content-wrapper">
    <router-link :to="$pagesPath.main.home">
      <hc-logo/>
    </router-link>

    <h1 class="headline">
      Terms of Service
    </h1>
    <dl>
      <dt>Agreement Between User and myHealthCheck360.com</dt>
      <dd>
        THESE TERMS OF USE, AS AMENDED FROM TIME TO TIME ("Terms"), ARE A BINDING CONTRACT BETWEEN HealthCheck360 ("myHealthCheck360.com" or "we") AND YOU ("you"). YOU MUST READ AND AGREE TO THESE TERMS, INCLUDING THE PRIVACY POLICY, BEFORE USING THE myHealthCheck360.com WEBSITE (the "Site") OR ANY SOFTWARE, TOOLS, APPLICATIONS, MOBILE APPLICATIONS, FEATURES OR FUNCTIONALITY AVAILABLE ON OR THROUGH THE SITE (COLLECTIVELY, THE "SERVICE"). BY USING THE SERVICE, YOU AGREE TO THESE TERMS. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT USE THE SERVICE. THIS SITE AND ITS SERVICES ARE FOR CONSUMER EDUCATIONAL USE ONLY. NOTHING CONTAINED IN THIS SITE OR APP IS OR SHOULD BE CONSIDERED, OR USED AS A SUBSTITUTE FOR, MEDICAL ADVICE, DIAGNOSIS OR TREATMENT. WE ADVISE USERS TO ALWAYS SEEK THE ADVICE OF A PHYSICIAN OR OTHER QUALIFIED HEALTH CARE PROVIDER WITH ANY QUESTIONS REGARDING PERSONAL HEALTH OR MEDICAL CONDITIONS. PLEASE CLICK HERE FOR MORE INFORMATION.
      </dd>
      <dt>Modification of These Terms of Use</dt>
      <dd>
        HealthCheck360 reserves the right to change the terms, conditions, notices, which include the Privacy Policy, under which the myHealthCheck360.com Services are offered, including but not limited to the charges associated with the use of the myHealthCheck360.com site. We encourage you to check back regularly to review these Terms at least once every 30 days.
      </dd>
      <dt>Privacy Policy</dt>
      <dd>
        Please see our <router-link :to="$pagesPath.auth.privacyPolicy">Privacy Policy</router-link>. Our Privacy Policy is part of and incorporated into these Terms. We reserve the right to contact you about these Terms or any Content or activities relating to the Service.
      </dd>
      <dt>Modifications To Service</dt>
      <dd>
        We reserve the right to change the URL, modify or discontinue, and restrict or block access to, the Service without notice to you. We may modify or remove any Content from the Service at any time without notice to you, including removal of Content that we believe to be obscene, lewd, lascivious, filthy, excessively violent, harassing, or otherwise objectionable.
      </dd>
      <dt>Materials Provided to myHealthCheck360.com or Posted At Any myHealthCheck360.com Site</dt>
      <dd>
        A. Content Protected by Intellectual Property Rights; No Warranty.<br/>
        Any content available through the Service, including tools, applications, software, text, audio, video, pictures, graphics, music, sound clips, images, likenesses, personal information and other works of authorship (collectively, "Content") is protected by the intellectual property rights of myHealthCheck360.com or its licensors. myHealthCheck360.com ITS LICENSORS, AND ITS SUPPLIERS MAKE NO REPRESENTATIONS OR WARRANTIES ABOUT:<br/>
        i. THE ACCURACY, RELIABILITY, COMPLETENESS, OR TIMELINESS OF THE CONTENT; OR<br/>
        ii. THE SATISFACTION OF ANY LAWS REQUIRING DISCLOSURE OF INFORMATION ON PRESCRIPTION DRUGS OR OTHER HEALTH-RELATED PRODUCTS AND SERVICES.<br/>
        B. Your Warranties Regarding Your Content.<br/>
        To post Content on the Site, you must first complete the Site registration process to create an account with a user name and password (the "Account"). To protect your Account, do not use your actual first and last names as your user name. Do not share your password with anyone. You must always provide accurate, current, and complete information to myHealhtCheck360.com for the Service. You must update such information in a timely manner to maintain its accuracy and completeness. Any use of the Service through your Account will be deemed as being used by you. myHealhtcheck360.com is entitled to rely on the contact and other information that is supplied to us through your Account. Your Account is non-transferable and non-assignable.<br/>
        myHealthCheck360.com does not claim ownership of the materials you provide to myHealthCheck360.com (including feedback and suggestions) or post, upload, input or submit to any myHealthCheck360.com Site or its associated services (collectively "Submissions").<br/>
        No compensation will be paid with respect to the use of your Submissions, as provided herein. myHealthCheck360.com is under no obligation to post or use any Submissions you may provide and may remove any Submissions at any time in myHealthCheck360.com's sole discretion. By posting, uploading, inputting, providing or submitting your Submissions you warrant and represent that you own or otherwise control all of the rights to your Submissions as described in this section including, without limitation, all the rights necessary for you to provide, post, upload, input or submit the Submissions.
        C. Your Right to Terminate the License.<br/>
        At any time you may modify or remove from the Service any Content consisting of photos, graphics, audio or video that you have submitted or made available for inclusion on or through the Service (but not, for clarity, any Content solely in the form of text that you have posted to the public areas of the site). Your removal of such Content from the Service will terminate our license to such Content. But if prior to such removal or modification, myHealthCheck360.com has distributed, or developed specific plans to distribute, any electronic, printed, or other materials containing such Content (e.g., in advertising, promotion or otherwise), then myHealthCheck360.com and its licensees have a limited right to continue to distribute those materials. You may terminate this limited license by sending us written notice to stop distributing the materials, in which event we will stop distributing the materials within 30 days. Your notice must be sent by email to support@healthcheck360.com, have a subject heading of "REMOVE CONTENT," and include your telephone number and email address. In addition, the notice must clearly identify the Content at issue and contain statements that you own or have an exclusive right to the Content and that all the information in the notice is true and correct. The 30 day period for myHealthCheck360.com to stop distributing your Content begins only once myHealthCheck360.com has received a notice complying with the requirements of this Section 5 ( C ).
      </dd>
      <dt>Food and Activity Database and Nutritional Information</dt>
      <dd>
        Nutrition information powering the calculators was either provided: (i) directly from each restaurant, (ii) from the restaurants’ website, (iii) entered directly by myHealthCheck360.com members or (iv) by the USDA website. Please be advised that nutritional information found in myHealthCheck360.com’s food database has not been reviewed by persons with the expertise required to provide you with complete, accurate, or reliable information.<br/>
        myHealthCheck360.com does not (i) guarantee the accuracy, completeness, or usefulness of any nutritional information in the food database; or (ii) adopt, endorse or accept responsibility for the accuracy or reliability of any such nutritional information. Under no circumstances will myHealthCheck360.com be responsible for any loss or damage resulting from your reliance on nutritional information. You are solely responsible for ensuring that any nutritional information in the Food and Activity Database is accurate, complete and useful.
      </dd>
      <dt>Links to Third Party Sites</dt>
      <dd>
        The myHealthCheck360.com Site may contain links to other sites ("Linked Sites"). The Linked Sites are not under the control of myHealthCheck360.com and myHealthCheck360.com is not responsible for the contents of any Linked Sites, including without limitation any link contained in any Linked Sites, or any changes or updates to any Linked Sites. myHealthCheck360.com is not responsible for webcasting or any other form of transmission received from any Linked Sites. myHealthCheck360.com is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by myHealthCheck360.com of any linked sites or any association with its operators.<br/>
        A. Advertisements and Links.<br/>
        The Service may contain advertisements, offers, or other links to websites of third parties that we do not control. Advertisements and other information provided by third parties may not be wholly accurate. myHealthCheck360.com does not endorse or recommend any of the products or services advertised on the service. myHealthCheck360.com is not responsible or liable for (i) the availability or accuracy of such sites or advertisements; or (ii) the content, products or services available from such sites. The inclusion of any link on the Service does not imply that we endorse the Linked Sites. You use the links at your own risk. myHealthCheck360.com Privacy Policy is applicable only when you are on our Site. Once you link to another website, its privacy statement applies to any personal information you supply.<br/>
        B. Transactions with Third Parties.<br/>
        Your transactions and other dealings with third party merchants or advertisers that are found on or through the Service, including "click to purchase," "co-registration," and other similar programs, including payment and delivery of related goods or services, are solely between you and such merchant or advertiser.
      </dd>
      <dt>No Unlawful or Prohibited Use</dt>
      <dd>
        As a condition of your use of the myHealthCheck360.com Site, you warrant to myHealthCheck360.com that you will not use the myHealthCheck360.com Site for any purpose that is unlawful or prohibited by these Terms. You may not use the myHealthCheck360.com Site in any manner which could damage, disable, overburden, or impair the myHealthCheck360.com Site or interfere with any other party's use and enjoyment of the myHealthCheck360.com Site. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the myHealthCheck360.com Site.<br/>
        You must not post to the Service any Content that, as reasonably determined by myHealthCheck360.com is:<br/>
        A. untrue, misleading, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, invasive to another person's privacy or protected data, hateful, or racially or otherwise objectionable;<br/>
        B. infringing upon a third party's intellectual property rights, including any patent, trademark, trade secret, copyright, or right of publicity, or content that is the subject of any claim of infringement;<br/>
        C. of a type that you do not have a right to transmit under any law or under contractual or fiduciary relationships, such as proprietary and confidential information;<br/>
        D. unsolicited, undisclosed or unauthorized advertising;<br/>
        E. software viruses or any other computer code, files, or programs designed to interrupt, destroy, or limit the functionality of any computer software or hardware or telecommunications equipment;<br/>
        F. data or information obtained through access that was not authorized by the owner, or that you are not authorized to post; or<br/>
        G. in violation of any applicable local, state, national or international law (including export laws).
      </dd>
      <dt>Use of Communication Services</dt>
      <dd>
        The myHealthCheck360.com Service may contain bulletin board services, chat areas, news groups, forums, communities, personal web pages, calendars, and/or other message or communication facilities designed to enable you to communicate with the public at large or with a group (collectively, "Communication Services"). You agree to use the Communication Services only to post, send and receive messages and material that are proper and related to the particular Communication Service. By way of example, and not as a limitation, you agree that when using the Communication Services, you will not:<br/>
        A. Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy and publicity) of others.<br/>
        B. Publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful topic, name, material or information.<br/>
        C. Upload files that contain software or other material protected by intellectual property laws (or by rights of privacy of publicity) unless you own or control the rights thereto or have received all necessary consents.<br/>
        D. Upload files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of another's computer.<br/>
        E. Advertise or offer to sell or buy any goods or services for any business purpose, unless such Communication Service specifically allows such messages.<br/>
        F. Conduct or forward surveys, contests, pyramid schemes or chain letters.<br/>
        G. Download any file posted by another user of the Communication Services that you know, or reasonably should know, cannot be legally distributed in such manner.<br/>
        H. Falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded.<br/>
        I. Restrict or inhibit any other user from using and enjoying the Communication Services.<br/>
        J. Violate any code of conduct or other guidelines which may be applicable for any particular Communication Service.<br/>
        K. Harvest or otherwise collect information about others, including e-mail addresses, without their consent.<br/>
        L. Violate any applicable laws or regulations.<br/>
        M. myHealthCheck360.com has no obligation to monitor the Communication Services. However, myHealthCheck360.com reserves the right to review materials posted to the Communication Services and to remove any materials in its sole discretion. myHealthCheck360.com reserves the right to terminate your access to any or all of the Communication Services at any time without notice for any reason whatsoever.<br/>
        N. myHealthCheck360.com reserves the right at all times to disclose any information as necessary to satisfy any applicable law, regulation, legal process or governmental request, or to edit, refuse to post or to remove any information or materials, in whole or in part, in myHealthCheck360.com's sole discretion.<br/>
        O. Always use caution when giving out any personally identifying information about yourself or your children in the Communication Services. myHealthCheck360.com does not control or endorse the content, messages or information found in the Communication Services and, therefore, myHealthCheck360.com specifically disclaims any liability with regard to the Communication Services and any actions resulting from your participation in the Communication Services. Managers and hosts are not authorized myHealthCheck360.com spokespersons, and their views do not necessarily reflect those of myHealthCheck360.com.<br/>
        Materials uploaded to the Communication Services may be subject to posted limitations on usage, reproduction and/or dissemination. You are responsible for adhering to such limitations if you download the materials.
      </dd>
      <dt>Monitoring of Service Content and Resolution of User Disputes</dt>
      <dd>
        A. No Duty to Monitor.<br/>
        We are under no obligation to restrict or monitor the Service, any Content or any submission in any way. IT WOULD BE IMPOSSIBLE FOR myHealthCheck360.com TO MONITOR THE ACCURACY OR RELIABILITY OF ALL OF THE CONTENT AVAILABLE THROUGH THE SERVICE. However, we reserve the right to modify or remove any Content at or Submission any time. Any opinions, advice, statements, services, offers, or other information, Content or Submission expressed or made available by third parties, including other users, are those of the respective author(s) and not of myHealhCheck360.com. myHealhCheck360.com neither endorses nor is responsible for the accuracy or reliability of any opinion, advice, information, or statement made on the Service.<br/>
        B. Right to Resolve Disputes.<br/>
        myHealthCheck360.com has the right, but not the obligation to attempt to resolve disputes between users relating to the Service and myHealthCheck360.com’s resolution of a particular dispute does not create an obligation to resolve any other dispute. myHealthCheck360.com's resolution of any dispute is final with respect to the Service.
      </dd>
      <dt>Protection of Content</dt>
      <dd>
      A. License by myHealthCheck360.com to You.<br/>
      You must respect the intellectual property laws protecting our Service. myHealthCheck360.com grants you a limited, revocable, non-transferable, non-sublicensable, non-exclusive license, under the rights myHealthCheck360.com has in the Content, to privately display and perform the Content on your computer for your own personal, noncommercial purposes.<br/>
      B. Reservation of Rights.<br/>
      On its own behalf and the behalf of its licensors, myHealhCheck360.com reserves all rights in the Content, including any software, not expressly granted in this Section 11. myHealthCheck360.com does not in any way grant any other rights to you. Except as expressly stated in this Section 11, you may not reproduce, distribute, modify, publicly perform or display, or prepare derivative works of any Content, including any software, without prior written consent from myHealthCheck360.com or other third-party owner of the rights in that Content (if any).
      </dd>
      <dt>
      Termination or Cancellation
      </dt>
      <dd>
        A. Right to Terminate.<br/>
        We may, at any time, with or without notice to you, terminate your access to the Service, and your Account, or block your access to the Service if:<br/>
        i. We believe in our sole discretion that you may have violated these Terms or have otherwise engaged in any activities that may harm or damage the reputation, rights, person, or property of myHealthCheck360.com, our users, or any other person;<br/>
        ii. Requested by law enforcement or other government agencies;<br/>
        iii. Your Account has extended periods of inactivity; or<br/>
        iv. The employer or company sponsoring the wellness program associated with your access terminates their relationship with HealthCheck360 or myHealthCheck360.com.<br/>
        B. Notice of Termination.<br/>
        HealthCheck360 has no responsibility to provide notice of termination or cancellation to myHealthCheck360.com. Should notice be provided, HealthCheck360 will send any relevant communication to any address (email or otherwise) that we have for you in our records.<br/>
        C. Effect of Termination.<br/>
        Upon termination of your Account or the Service, your agreement with myHealthCheck360.com pursuant to these Terms will also terminate, except that the following provisions survive the termination: Section 5 "Materials Provided to myHealthCheck360.com or Posted At Any myHealthCheck360.com Site,” Section 11 "Protection of Content," Section 14 “Disclaimer, Warranties, Exclusion of Damages, and Limitation of Liability,” Section 13 "Indemnification," and Section 18 "Additional Terms" and the Privacy Policy. If your Account or access to the Service is terminated, you may no longer have access to the Content you posted on the Service.
      </dd>
      <dt>Indemnification</dt>
      <dd>
        You agree to hold HealthCheck360 and its subsidiaries, affiliates, officers, directors, employees, agents, attorneys, independent contractors, licensors, experts, content providers, advisors, vendors, and suppliers, and each of their respective successors and assigns (collectively, the "Indemnified Persons"), harmless from, and indemnify them for all damages, costs, expenses and other liabilities, including reasonable attorneys' fees and expenses, relating to any claim arising out of or related to: (i) your access to and use of the tools, software, and other aspects of the Service and the Content; (ii) your violation of these Terms, and any applicable law or the rights of another person or party; (iii) any dispute you have with any user of the Service and any dispute you have related to any merchant or advertising found on or through the Service; (iv) myHealthCheck360.com's resolution (if any) of any dispute you have with any user of the Service; (v) your improper authorization for myHealthCheck360.com to collect, use or disclose any Submission or Content provided by you; and (vi) any disclosures made with your permission.
      </dd>
      <dt>Disclaimer, Warranties, Exclusion of Damages, and Limitation of Liability.</dt>
      <dd>
        A. THE SERVICE DOES NOT PROVIDE MEDICAL ADVICE, EXCLUSION OF DAMAGES AND LIMITATION OF LIABILITY.<br/>
        THE SERVICE IS FOR CONSUMER EDUCATIONAL USE ONLY. NOTHING CONTAINED IN THIS SITE OR THE SERVICE IS OR SHOULD BE CONSIDERED, OR USED AS A SUBSTITUTE FOR, MEDICAL ADVICE, DIAGNOSIS OR TREATMENT.THE SERVICES AND THE SITE ARE HERE TO EDUCATE CONSUMERS ON HEALTH CARE AND MEDICAL ISSUES THAT MAY AFFECT THEIR DAILY LIVES. THIS SITE AND THE SERVICES DO NOT CONSTITUTE THE PRACTICE OF ANY MEDICAL, NURSING OR OTHER PROFESSIONAL HEALTH CARE ADVICE, DIAGNOSIS OR TREATMENT. WE ADVISE USERS TO ALWAYS SEEK THE ADVICE OF A PHYSICIAN OR OTHER QUALIFIED HEALTH CARE PROVIDER WITH ANY QUESTIONS REGARDING PERSONAL HEALTH OR MEDICAL CONDITIONS. NEVER DISREGARD, AVOID OR DELAY IN OBTAINING MEDICAL ADVICE FROM YOUR DOCTOR OR OTHER QUALIFIED HEALTH CARE PROVIDER BECAUSE OF SOMETHING YOU HAVE READ ON THE SERVICE. IF YOU HAVE OR SUSPECT THAT YOU HAVE A MEDICAL PROBLEM OR CONDITION, PLEASE CONTACT A QUALIFIED HEALTH CARE PROFESSIONAL IMMEDIATELY. IF YOU ARE IN THE UNITED STATES AND ARE EXPERIENCING A MEDICAL EMERGENCY, PLEASE CALL 911 OR CALL FOR EMERGENCY MEDICAL HELP ON THE NEAREST TELEPHONE. Health and medical information changes constantly. Therefore, the information provided on this Service or on the linked sites should not be considered current, complete or exhaustive, nor should you rely on such information to recommend a course of treatment for you or any other individual. Reliance on any information provided on the Service or the linked sites is solely at your own risk.<br/>
        B. NO ENDORSEMENT<br/>
        myHealthCheck360.com DOES NOT RECOMMEND OR ENDORSE ANY SPECIFIC TESTS, PHYSICIANS, PRODUCTS, PROCEDURES, OPINIONS, OR OTHER INFORMATION THAT MAY BE MENTIONED ON THE SERVICE. RELIANCE ON ANY INFORMATION PROVIDED BY myHealthCheck360.com, HealthCheck360 EMPLOYEES, OTHERS APPEARING ON THE SITE AT THE INVITATION OF myHealthCheck360.com, OR OTHER VISITORS TO THE SERVICE IS SOLELY AT YOUR OWN RISK.<br/>
        C. YOU ACCEPT THE SERVCIE "AS-IS"<br/>
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL myHealthCheck360.com AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE myHealthCheck360.com SITE, WITH THE DELAY OR INABILITY TO USE THE myHealthCheck360.com SITE OR SERVICE, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE myHealthCheck360.com SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE myHealthCheck360.com SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF myHealthCheck360.com OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE myHealthCheck360.com SITE, SERVICE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE myHealthCheck360.com SITE AND SERVICE. WITHOUT LIMITING THE FOREGOING, IN NO EVENT WILL THE AGGREGATE LIABILITY TO YOU OF THE INDEMNIFIED PERSONS EXCEED, IN TOTAL, THE AMOUNTS PAID BY YOU TO US.<br/>
        WE, AND OUR CONTENT PROVIDERS, CANNOT AND DO NOT GUARANTEE OR WARRANT AGAINST ERRORS, OMISSIONS, DELAYS, INTERRUPTIONS OR LOSSES, INCLUDING LOSS OF DATA. USERS OF THE SERVICE ARE RESPONSIBLE FOR MAINTAINING A MEANS EXTERNAL TO myHealthCheck360.com FOR THE RECONSTRUCTION OF ANY LOST DATA. myHealthCheck360.com PROVIDES THE SERVICE "AS IS" AND "AS AVAILABLE". THAT MEANS THAT THE INFORMATION CONTAINED ON OR PROVIDED THROUGH THIS SERVICE IS INTENDED FOR GENERAL CONSUMER UNDERSTANDING AND EDUCATION. ANY ACCESS TO THIS SITE IS VOLUNTARY. WE WILL REGARD ALL ACCESS AS VOLUNTARY AND AT THE SOLE RISK OF THE USER. TO THE MAXIMUM EXTENT PERMITTED BY LAW, myHealthCheck360.com MAKES NO REPRESENTATIONS, WARRANTIES OR CONDITIONS OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE SERVICE OR THE INFORMATION, TOOLS, SOFTWARE, OR CONTENT INCLUDED IN THE SERVICE. myHealthCheck360.com MAKES NO REPRESENTATIONS OR WARRANTIES THAT THE SERVICE WILL BE UNINTERRUPTED, ERROR-FREE, VIRUS-FREE, SECURE, OR TIMELY. TO THE MAXIMUM EXTENT PERMITTED BY LAW, myHealthCheck360.com EXPRESSLY DISCLAIMS ALL REPRESENTATIONS, WARRANTIES OR CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING THE IMPLIED REPRESENTATIONS, WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT AND THOSE ARISING FROM A COURSE OF DEALING, TRADE, USAGE OR PERFORMANCE. SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES, AND THEREFORE SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. WE URGE YOU TO KEEP BACKUP COPIES OF YOUR PERSONAL CONTENT, IF ANY, THAT YOU MAINTAIN ON OR USE WITH THE SERVICE. IF YOUR USE OF THE SERVICE RESULTS IN THE NEED FOR SERVICING OR REPLACING PROPERTY, MATERIAL, EQUIPMENT OR DATA, WE ARE NOT RESPONSIBLE FOR ANY RESULTING COSTS OR DAMAGES.
      </dd>
      <dt>Copyright</dt>
      <dd>
        A. All Content of the myHealthCheck360.com Site is copyright 2013 HealthCheck360 and/or its suppliers. All rights reserved.<br/>
        B. myHealthCheck360.com respects the intellectual property rights of others and requests that users of the Service do the same. If you believe that your work is being used on the Service in a way that constitutes copyright infringement, you may notify us by providing our copyright agent with the following information in writing:<br/>
        i. The electronic or physical signature of the owner of the copyright or the person authorized to act on the owner's behalf;<br/>
        ii. Identification of the copyrighted work that you claim has been infringed;<br/>
        iii. Identification of the material that is claimed to be infringing and information reasonably sufficient to permit myHealthCheck360.com to locate the material (for example, by providing a URL to the material);<br/>
        iv. Your name, address, telephone number, and email address;<br/>
        v. A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and<br/>
        vi. A statement that the information in your notification is accurate and a statement, made under penalty of perjury, that you are the copyright owner or are authorized to act on the copyright owner's behalf.<br/>
        C. Our designated agent to receive notification of claimed infringement can be reached at:<br/>
        <address>
          HealthCheck360<br/>
          800Main Street<br/>
          P.O. Box 28<br/>
          Dubuque, IA 52004-0028<br/>
          Attn: Director<br/>
        </address>
        D. It is our policy to terminate in appropriate circumstances any Account or user for repeated infringement of intellectual property rights, including copyrights, and we also reserve the right to terminate an Account or user for even one instance of infringement.<br/>
        E. myHealthCheck360.com may access, preserve and disclose to third parties any of your information or data (including personally identifiable information and private communications) related to a written complaint of copyright infringement if we believe in our sole discretion that such access, preservation, or disclosure is necessary or useful to respond or otherwise address such complaint.
      </dd>
      <dt>Customer Support</dt>
      <dd>
        We have no obligation to provide customer support. However, we may provide you with customer support from time to time, at our sole discretion, if you have created an Account and you submit your customer support questions using your Account.
      </dd>
      <dt>Notices and Contact Information</dt>
      <dd>
        Since notice of any material change to the Terms will be posted to the Site for at least 30 days, prior to the effective date of change we encourage you to visit the Site at least that often. You also authorize myHealthCheck360.com to send notices (including notice of subpoenas or other legal process, if any) via electronic mail to the email address we have on record for you. You must check the Site for notices, and you will be considered to have received a notice when it is made available to you by posting on the Site or when sent by myHealthCheck360.com via electronic mail, whether or not received by you. myHealthCheck360.com may provide notice to any email or other address that you provide to us. You must keep your address current and any notice sent by myHealthCheck360.com to the address that you have most recently provided is effective notice. With the exception of notices related to removal of licensed material and to copyright infringement as described in Sections 5 and 15 above, respectively, you must send us any notice by mailing it to our address for legal notices which is: HealthCheck360, 800 Main Street, P.O. Box 28, Dubuque, IA 52004-0028, Attn: Director.
      </dd>
      <dt>Additional Terms</dt>
      <dd>
        A. Agreement to Conduct Transactions Electronically<br/>
        All of your transactions with or through the Service may, at our option, be conducted electronically from start to finish. If we decide to proceed non-electronically, those services will still be governed by the remainder of these Terms unless you enter into different terms on a form provided by us. If the law allows you to withdraw this consent or if we are ever required to deal with you non-electronically, we reserve the right to charge or increase fees and you agree to print or make an electronic copy of the Terms and any other contract or disclosure that we are required to provide to you.<br/>
        B. No Agency; No Third Party Beneficiary<br/>
        These Terms do not create any agency, partnership, joint venture, employee-employer or franchisor-franchisee relationship. Except for the Indemnified Persons, there are no third party beneficiaries of these Terms.<br/>
        C. Severance<br/>
        If any part of the Terms is held by a court of competent jurisdiction to be invalid or unenforceable, the invalid or unenforceable part will be given effect to the greatest extent possible and the remainder will remain in full effect, provided that the allocation of risks described in these Terms is given effect to the fullest extent possible.<br/>
        D. Assignment<br/>
        These Terms are personal to you and you may not transfer, assign or delegate them to anyone without the express written permission of myHealthCheck360.com. Any attempt by you to assign, transfer or delegate these Terms without the express written permission of myHealthCheck360.com will be null and void. myHealthCheck360.com has the right to transfer, assign and delegate these Terms to one or more third parties without your permission.<br/>
        E. Jurisdiction; Choice of Law; Export Limitations<br/>
        This Service is controlled by us from our offices in the United States of America and is directed to U.S. users. If you access the Service from locations outside the U.S., you do so at your own risk and you are responsible for compliance with applicable local laws. You may not use or export anything from the Service in violation of U.S. export laws and regulations or the Terms. These Terms and all performances and claims of every nature between us are governed by the laws of the State of Iowa, U.S.A., without regard to any conflicts of laws principles that would result in the application of the law of a different jurisdiction. You and myHealthCheck360.com submit to the exclusive personal jurisdiction and venue of the state and federal courts located within Dubuque County, Iowa.<br/>
        F. Limitations on Actions<br/>
        Any action concerning any dispute with respect to the Service must be commenced within one year after the cause of the dispute arises, or the cause of action is barred.<br/>
        G. Interpretation<br/>
        The paragraph headings in these Terms are included to help make these Terms easier to read and have no binding effect. As used in these Terms, the words "include" and "including" are meant to be illustrative and not exhaustive.<br/>
        H. Entire Agreement<br/>
        These Terms (including terms incorporated into them, e.g., the Privacy Policy) and any policies and guidelines posted to the Service by myHealthCheck360.com comprise the entire agreement (the "Entire Agreement") between you and myHealthCheck360.com with respect to the use of the Service and supersede all contemporaneous and prior agreements between the parties regarding the subject matter contained herein, and neither party has relied on any representations made by the other that are not expressly set forth in the Entire Agreement.<br/>
        I. No Waiver<br/>
        The failure of any party to exercise or enforce any right or provision of these Terms, including any failure to act with respect to a breach, will not constitute a waiver of such right or provision or that party's right to act with respect to subsequent or similar breaches. We suggest that you print out a copy of these Terms for your records.
      </dd>
      <dt>Legal Notices</dt>
      <dd>
        A. Notice of Availability of Filtering Software<br/>
        All users are hereby informed by the provider of this interactive computer service that parental control protections (such as computer hardware, software, or filtering services) are commercially available that may assist in limiting access to material that is harmful to minors. A report detailing some of those protections can be found athttp://www.ntia.doc.gov/ntiahome/ntiageneral/cipa2003/index.html(Children's Internet Protection Act: Report on the Effectiveness of Internet Protection Measures and Safety Policies).<br/>
        B. Notice: No Harvesting or Dictionary Attacks Allowed<br/>
        YOU MAY VIOLATE FEDERAL LAW IF YOU: (i) INITIATE THE TRANSMISSION TO HealthCheck360 COMPUTERS OR DEVICES OF A COMMERCIAL ELECTRONIC MAIL MESSAGE (AS DEFINED IN THE U.S. "CAN-SPAM ACT OF 2003") THAT DOES NOT MEET THE MESSAGE TRANSMISSION REQUIREMENTS OF THAT ACT; OR (ii) ASSIST IN THE ORIGINATION OF SUCH MESSAGES THROUGH THE PROVISION OR SELECTION OF ADDRESSES TO WHICH THE MESSAGES WILL BE TRANSMITTED.<br/>
        C. Notice Regarding Trademarks<br/>
        The names of actual companies and products mentioned herein may be the trademarks of their respective owners. Any rights not expressly granted herein are reserved.<br/>
        D. All rights not expressly waived or granted herein are reserved by HealthCheck360.<br/>
        E. myHealthCheck360.com is owned and operated by HealthCheck360.
      </dd>
    </dl>
  </div>
</template>

<script lang="ts">
import {Component} from "vue-property-decorator";
import HcLogo from "@/vue/molecules/hc-logo.vue";
import {mixins} from "vue-class-component";
import {mixinClassBodyFactory} from "@/ts/mixins/bg-mixin";

@Component({
  name: "TermsOfServicePage",
  components: {
    HcLogo,
  },
})
export default class TermsOfServicePage extends mixins(mixinClassBodyFactory("global-onboarding-bg-color")) {
}
</script>

<style lang="sass" scoped>
.content-wrapper
  margin: 50px auto
  padding: 0 20px 0 40px
  width: 100%
  max-width: 750px
  @include Roboto400
  color: $color-primary-hc-blue-100

.headline
  text-align: center
  margin-bottom: 50px

address
  margin: 5px 0
  padding: 0 0 0 10px

dl
  counter-reset: css-counter 0

dt
  padding: 0
  margin-bottom: 10px
  list-style-type: decimal
  counter-increment: css-counter 1
  position: relative

  &::before
    content: counter(css-counter) ". "
    position: absolute
    left: -1.7em


dd
  margin-inline-start: 0
  margin-bottom: 25px


</style>
