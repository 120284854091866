<template>
  <div>
    <img v-if="proofImage && !isPdf" :src="proofImage" class="preview"/>
    <a v-else-if="proofImage && isPdf" :href="proofImage" target="_blank">
      {{ proofFileName }}
    </a>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Vue,
  Prop,
} from "vue-property-decorator";

@Component({
  name: "ActivityProofDisplay",
  components: {},
})
export default class ActivityProofDisplay extends Vue {
  @Prop()
  proofImage: string | undefined;

  @Prop()
  proofFileName: string | undefined;

  get isPdf() {
    return this.proofFileName?.toLowerCase().endsWith(".pdf") ?? false;
  }
}
</script>

<style lang="sass" scoped>
.preview
  height: auto
  border-radius: 8px
  max-width: 380px
</style>
