<template>
  <div class="growls">
    <div v-for="growl in growls" :key="`${growl.id}`" :class="growl.type">
      {{ growl.text }}
      <img src="@/assets/img/x-mark-white.svg" alt="close" @click="close(growl)"/>
    </div>
  </div>
</template>

<script lang="ts">
import {Component} from "vue-property-decorator";
import type {GrowlModel} from "@/ts/types/store/default-store-types";
import {GrowlsStoreMixin, GrowlsState} from "@/ts/store/growl/growl-store-instance";
import {mixins} from "vue-class-component";


@Component({
  name: "AppGrowls"
})
export default class AppGrowls extends mixins(GrowlsStoreMixin) {
  @GrowlsState
  growls!: GrowlModel[];

  close(growl: GrowlModel): void {
    this.growlsStore.removeGrowl(growl);
  }
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>
.growl-success
  background-color: $color-status-success-green
  box-shadow: 3px 3px 3px rgba($color-status-success-green, 0.5)

.growl-info
  background-color: $color-secondary-state-blue-100
  box-shadow: 3px 3px 3px rgba($color-secondary-state-blue-100, 0.5)

.growl-error
  background-color: $color-status-error-red
  box-shadow: 3px 3px 3px rgba($color-status-error-red, 0.5)

img
  cursor: pointer
  margin-left: 10px

.growls
  @include Roboto600
  position: fixed
  right: 20px
  top: 20px

  > *
    color: $color-white
    padding: 20px
    border-radius: 5px
    margin-bottom: 5px
</style>
