<template>
  <img src="@/assets/img/big-sing-for-button-white.svg"/>
</template>

<script lang="ts">
import {
  Component,
  Vue,
} from "vue-property-decorator";

@Component({})
export default class BiggerSign extends Vue {

}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>
img
  margin-left: 10px
</style>
