import {
  Action,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import type {GrowlModel} from "@/ts/types/store/default-store-types";
import {GrowlType} from "@/ts/types/store/default-store-types";
import {sleep} from "@/ts/utils/pure-functions";

@Module({
  name: 'growls'
})
export class GrowslStore extends VuexModule {
  public growls: GrowlModel[] = [];

  @Mutation
  public addGrowl(growlModel: GrowlModel): void {
    this.growls.push(growlModel);
  }

  @Mutation
  public removeGrowl(growlModel: GrowlModel): void {
    const index = this.growls.indexOf(growlModel, 0);
    if (index > -1) {
      this.growls.splice(index, 1);
    }
  }

  @Action
  public async showGrowl({text, type, time}: {text: string; type: GrowlType; time: number}): Promise<void> {
    const growl: GrowlModel = {
      id: Date.now(),
      text,
      type,
    };
    this.addGrowl(growl);
    await sleep(time);
    this.removeGrowl(growl);
  }

  @Action
  public async growlError(text: string): Promise<void> {
    await this.showGrowl({
      text,
      type: GrowlType.ERROR,
      time: 6000,
    });
  }

  @Action
  public async growlInfo(text: string): Promise<void> {
    await this.showGrowl({
      text,
      type: GrowlType.INFO,
      time: 6000,
    });
  }
}
