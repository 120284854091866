<template>
  <div class="finish-page">
    <img src="@/assets/img/reward-image.svg" alt="reward-image" class="image-table"/>
    <img src="@/assets/img/pathway-img.svg" alt="pathway-image" class="image-fire"/>
    <div class="title">
      You finished the course!
    </div>
    <div v-if="pathwayResponse.reward && quizAvailable" class="take-quiz-reward">
      Now take a quiz to earn a reward!
    </div>
    <div v-if="quizAvailable" class="button-holder">
      <button-link :to="$pagesPath.main.home" theme="white">
        Do It Later
      </button-link>
      <button-link :to="quizLink">
        Start The Quiz
        <bigger-sign/>
      </button-link>
    </div>
    <div v-else-if="pathwayResponse.reward" class="rewards">
      <p class="reward-type">
        + {{ rewardType }}
      </p>
      <button-link :to="$pagesPath.main.home">
        Collect reward
      </button-link>
    </div>
    <button-link
      v-else
      :to="$pagesPath.main.home"
      class="no-rewards"
      theme="dark"
    >
      Got it!
    </button-link>
  </div>
</template>

<script lang="ts">
import {mixins} from "vue-class-component";
import {
  Component,
  Prop,
} from "vue-property-decorator";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import type {GetPathwayResponseDTO} from "@/ts/types/dto/pathway.dto";
import {QuizStatus} from "@/ts/types/dto/pathway.dto";
import {getRewardAmountFormatted} from "@/ts/utils/pure-functions";
import BiggerSign from "@/vue/atoms/bigger-sign.vue";
import {mixinClassBodyFactory} from "@/ts/mixins/bg-mixin";
import ButtonLink from "@/vue/atoms/button-link.vue";

@Component({
  name: 'PathwayPageFinish',
  components: {ButtonLink, BiggerSign, ButtonPrimary}
})
export default class PathwayFinishPage extends mixins(mixinClassBodyFactory("global-tmp-bg-pink")) {
  @Prop()
  pathwayResponse!: GetPathwayResponseDTO;

  get quizLink(): string {
    return this.$pagesPath.pathway.pathwayQuiz
      .replace(":id", this.pathwayResponse.id)
      .replace(":number", "1");
  }

  get rewardType(): string {
    return getRewardAmountFormatted(this.pathwayResponse.reward);
  }

  get quizAvailable(): boolean {
    return Boolean(this.pathwayResponse.quiz) && this.pathwayResponse.quizStatus === QuizStatus.pending;
  }
}
</script>
<style lang="sass" scoped>
.finish-page
  background-color: $color-tertiary-pink-10
  display: flex
  flex-direction: column
  align-items: center

img
  max-width: fit-content

.image-table
  margin-top: 81px

.image-fire
  margin-top: 20px

.title
  @include Asap700
  color: $color-primary-hc-blue-100
  font-size: 28px
  display: flex
  flex-direction: column
  align-items: center
  margin-top: 16px

.description
  @include Roboto400
  color: $color-primary-hc-blue-100
  size: 16px

.button-holder
  display: flex
  align-items: center
  justify-content: center
  margin-top: 136px

  > *:first-child
    margin-right: 30px

.no-rewards
  display: flex
  align-items: center
  justify-content: center
  margin-top: 168px

.take-quiz-reward
  @include Roboto700
  color: $color-primary-hc-blue-100
  font-size: 16px
  text-align: center
  margin-top: 20px

.reward-type
  @include Asap700
  color: $color-primary-hc-blue-100
  font-size: 40px
  text-align: center
</style>
