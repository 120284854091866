/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import {TaskType} from "@/ts/types/dto/activity.dto";

export const TaskTypeNames: Record<TaskType, string> = {
  [TaskType.biking_distance]: "Biking (Distance)",
  [TaskType.biking_time]: "Biking (Time)",
  [TaskType.calorie_intake]: "Nutrition",
  [TaskType.dancing_time]: "Dancing",
  [TaskType.ergometer_time]: "Ergometer",
  [TaskType.fitness_time]: "Fitness",
  [TaskType.gardening_time]: "Gardening",
  [TaskType.general_physical_activity_time_or_steps]: "General Physical Activity (Time or Steps)",
  [TaskType.golf_time]: "Golf",
  [TaskType.gymnastics_time]: "Gymnastics",
  [TaskType.handbiking_time]: "Handbiking",
  [TaskType.heart_rate]: "Heart Rate",
  [TaskType.hiking_distance]: "Hiking (Distance)",
  [TaskType.hiking_time]: "Hiking (Time)",
  [TaskType.housework_time]: "Housework",
  [TaskType.jogging_distance]: "Jogging (Distance)",
  [TaskType.jogging_time]: "Jogging (Time)",
  [TaskType.meditation_time]: "Meditation",
  [TaskType.general_physical_activity_time]: "Minutes of Activity",
  [TaskType.mood]: "Mood",
  [TaskType.nicotine_use]: "Nicotine Use",
  [TaskType.pilates_time]: "Pilates",
  [TaskType.rowing_time]: "Rowing",
  [TaskType.sleep_time]: "Sleep",
  [TaskType.steps]: "Steps",
  [TaskType.swimming_time]: "Swimming",
  [TaskType.team_sports_time]: "Team Sports",
  [TaskType.water_drinking_volume]: "Water Drinking",
  [TaskType.weight]: "Weight",
  [TaskType.weightlifting_time]: "Weightlifting",
  [TaskType.wheelchair_time]: "Wheelchair",
  [TaskType.yoga_time]: "Yoga",
};

export enum TaskTypeUnitCategory {
  duration = "duration",
  distance = "distance",
  steps = "steps",
  volume = "volume",
  weight = "weight",
  none = "none",
}

export enum TaskTypeActivities {
  biking_distance = "biking_distance",
  biking_time = "biking_time",
  dancing_time = "dancing_time",
  ergometer_time = "ergometer_time",
  fitness_time = "fitness_time",
  gardening_time = "gardening_time",
  golf_time = "golf_time",
  gymnastics_time = "gymnastics_time",
  handbiking_time = "handbiking_time",
  hiking_distance = "hiking_distance",
  hiking_time = "hiking_time",
  housework_time = "housework_time",
  jogging_distance = "jogging_distance",
  jogging_time = "jogging_time",
  meditation_time = "meditation_time",
  pilates_time = "pilates_time",
  rowing_time = "rowing_time",
  steps = "steps",
  swimming_time = "swimming_time",
  team_sports_time = "team_sports_time",
  water_drinking_volume = "water_drinking_volume",
  weightlifting_time = "weightlifting_time",
  wheelchair_time = "wheelchair_time",
  yoga_time = "yoga_time",
}

export enum TaskTypeHealth {
  heart_rate = "heart_rate",
  nicotine_use = "nicotine_use",
  sleep_time = "sleep_time",
  weight = "weight",
}

export enum TaskTypeNutrition {
  calorie_intake = "calorie_intake",
}

export enum TaskTypeMood {
  mood = "mood",
}
