import type {BiometricScreeningAvailable, BiometricScreeningStatus, PhysicianFormAvailable} from "@/ts/types/dto/biometric-screening.dto";
import type {MyProgramResponse} from "@/ts/types/dto/program.dto";
import type {SchedulingBiometricAppointment} from "@/ts/types/store/scheduling-biometric-store-types";
import {isTimestampInFuture} from "@/ts/utils/date-pure-functions";
import {hasProgramBioscreeningAvailable} from "@/ts/utils/my-health/program-bioscreening";
import {isPhysicianFormUnavailableWithoutStatus} from "@/ts/utils/my-health/physician-form";
import type {GetPhysicianFormBiometricResponse} from "@/ts/types/dto/physician-form.dto";

export function isBioscreeningAvailable(biometricAvailable: BiometricScreeningAvailable | null): boolean {
  return Boolean(biometricAvailable?.deadline);
}

export function isBioscreeningSubmitted(biometricStatus: BiometricScreeningStatus | null): boolean {
  return Boolean(biometricStatus?.submittedDate);
}

export function isAppointmentScheduled(appointment: SchedulingBiometricAppointment | null): boolean {
  return Boolean(appointment);
}

export function isAppointmentScheduledInFuture(appointment: SchedulingBiometricAppointment | null): boolean {
  return Boolean(appointment && isTimestampInFuture(appointment.timestamp));
}

export function isAppointmentScheduledInPast(appointment: SchedulingBiometricAppointment | null): boolean {
  return Boolean(appointment && !isTimestampInFuture(appointment.timestamp));
}

export function isBioscreeningUnavailableBecauseSubmitted({
  biometricAvailable,
  biometricStatus,
}: {
  biometricAvailable: BiometricScreeningAvailable | null;
  biometricStatus: BiometricScreeningStatus | null;
}): boolean {
  return !isBioscreeningAvailable(biometricAvailable) &&
    isBioscreeningSubmitted(biometricStatus);
}

export function isBioscreeningUnavailableBecauseAppointmentScheduled({
  biometricAvailable,
  appointment,
}: {
  biometricAvailable: BiometricScreeningAvailable | null;
  appointment: SchedulingBiometricAppointment | null;
}): boolean {
  return !isBioscreeningAvailable(biometricAvailable) &&
    isAppointmentScheduled(appointment);
}

export function isBioscreeningAvailableAndNotSubmitted({
  biometricAvailable,
  biometricStatus,
}: {
  biometricAvailable: BiometricScreeningAvailable | null;
  biometricStatus: BiometricScreeningStatus | null;
}): boolean {
  return isBioscreeningAvailable(biometricAvailable) && !isBioscreeningSubmitted(biometricStatus);
}

export function isBioscreeningUnavailable({
  biometricAvailable,
  appointment,
  biometricStatus,
}: {
  biometricAvailable: BiometricScreeningAvailable | null;
  appointment: SchedulingBiometricAppointment | null;
  biometricStatus: BiometricScreeningStatus | null;
}): boolean {
  return !isBioscreeningAvailable(biometricAvailable) &&
    !isBioscreeningUnavailableBecauseAppointmentScheduled({
      biometricAvailable,
      appointment,
    }) &&
    !isBioscreeningUnavailableBecauseSubmitted({
      biometricAvailable,
      biometricStatus,
    });
}

export function isBioscreeningUnavailableAndPhysicianFormUnavailable({
  biometricAvailable,
  appointment,
  biometricStatus,
  physicianFormAvailable,
  physicianForm,
}: {
  biometricAvailable: BiometricScreeningAvailable | null;
  appointment: SchedulingBiometricAppointment | null;
  biometricStatus: BiometricScreeningStatus | null;
  physicianFormAvailable: PhysicianFormAvailable | null;
  physicianForm: GetPhysicianFormBiometricResponse | null;
}): boolean {
  return isBioscreeningUnavailable({
    biometricAvailable,
    biometricStatus,
    appointment,
  }) &&
  isPhysicianFormUnavailableWithoutStatus({
    physicianForm,
    physicianFormAvailable,
  });
}

export function isBioscreeningUnavailableOrNotAvailableInProgram({
  program,
  biometricAvailable,
  appointment,
  biometricStatus,
  physicianFormAvailable,
  physicianForm,
}: {
  program: MyProgramResponse | null;
  biometricAvailable: BiometricScreeningAvailable | null;
  appointment: SchedulingBiometricAppointment | null;
  biometricStatus: BiometricScreeningStatus | null;
  physicianFormAvailable: PhysicianFormAvailable | null;
  physicianForm: GetPhysicianFormBiometricResponse | null;
}): boolean {
  return !hasProgramBioscreeningAvailable(program) || isBioscreeningUnavailableAndPhysicianFormUnavailable({
    biometricAvailable,
    appointment,
    biometricStatus,
    physicianFormAvailable,
    physicianForm,
  });
}
