<template>
  <profile-block name="Contact Information">
    <profile-field-address/>
    <profile-field-email/>
    <profile-field-phone/>
  </profile-block>
</template>

<script lang="ts">

import {
  Component,
  Vue,
} from "vue-property-decorator";
import ProfileBlock from "@/vue/templates/profile-block.vue";
import ProfileFieldAddress from "@/vue/organisms/profile/field/profile-field-address.vue";
import ProfileFieldEmail from "@/vue/organisms/profile/field/profile-field-email.vue";
import ProfileFieldPhone from "@/vue/organisms/profile/field/profile-field-phone.vue";


@Component({
  name: "ContactInformation",
  components: {
    ProfileBlock,
    ProfileFieldAddress,
    ProfileFieldEmail,
    ProfileFieldPhone,

  },
})
export default class ContactInformation extends Vue {
}
</script>

<style lang="sass" scoped>

</style>

