<template>
  <div class="image-container">
    <img :src="icon" alt="icon"/>
  </div>
  <div class="background-icon">
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40 20C40 31.0457 34.4952 40 22.2222 40C9.94923 40 0 31.0457 0 20C0 8.95431 11.0603 0 23.3333 0C35.6063 0 40 8.95431 40 20Z"
        fill="currentColor"
      />
    </svg>
  </div>
</template>
<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import biking from "@/assets/img/icons/biking.svg";
import dancing from "@/assets/img/icons/dancing.svg";
import gardening from "@/assets/img/icons/gardening.svg";
import hiking from "@/assets/img/icons/hiking.svg";
import jogging from "@/assets/img/icons/jogging.svg";
import ergometer from "@/assets/img/icons/ergometer.svg";
import fitness from "@/assets/img/icons/fitness.svg";
import generic from "@/assets/img/icons/generic.svg";
import golf from "@/assets/img/icons/golf.svg";
import gymnastics from "@/assets/img/icons/gymnastics.svg";
import handbiking from "@/assets/img/icons/hand-biking.svg";
import housework from "@/assets/img/icons/housework.svg";
import mediation from "@/assets/img/icons/meditation.svg";
import pilates from "@/assets/img/icons/pilates.svg";
import rowing from "@/assets/img/icons/rowing.svg";
import sleep from "@/assets/img/icons/sleep.svg";
import steps from "@/assets/img/icons/steps.svg";
import swimming from "@/assets/img/icons/swimming.svg";
import teamSport from "@/assets/img/icons/team-sport.svg";
import waterdrinking from "@/assets/img/icons/water-drinking.svg";
import wheelchair from "@/assets/img/icons/wheel-chair.svg";
import yoga from "@/assets/img/icons/yoga.svg";
import weight from "@/assets/img/icons/weight.svg";
import nutrition from "@/assets/img/icons/nutrition.svg";
import heartRate from "@/assets/img/icons/heart-rate.svg";
import weightLifting from "@/assets/img/icons/weight-lifting.svg";
import smoking from "@/assets/img/icons/smoking.svg";
import platform from "@/assets/img/icons/platform.svg";
import book from "@/assets/img/icons/book.svg";
import warning from "@/assets/img/icons/warning.svg";
import gatekeeper from "@/assets/img/icons/gatekeeper.svg";
import duration from "@/assets/img/duration-clock-icon.svg";
import comboActivity from "@/assets/img/icons/combo-activity.svg";
import {TaskType} from "@/ts/types/dto/activity.dto";


type IconType =
  TaskType | "book" | "duration" | "gatekeeper" | "generic" | "platform" | "warning";

@Component({
  name: "RoundedIcons",
  components: {},
})
export default class RoundedIcons extends Vue {
  @Prop({default: "generic"})
  iconType!: IconType;

  get icon(): string {
    const TaskIconMap: Record<IconType, string> = {
      [TaskType.biking_time]: biking,
      [TaskType.biking_distance]: biking,
      [TaskType.dancing_time]: dancing,
      [TaskType.ergometer_time]: ergometer,
      [TaskType.gardening_time]: gardening,
      [TaskType.golf_time]: golf,
      [TaskType.hiking_time]: hiking,
      [TaskType.hiking_distance]: hiking,
      [TaskType.housework_time]: housework,
      [TaskType.meditation_time]: mediation,
      [TaskType.fitness_time]: fitness,
      [TaskType.pilates_time]: pilates,
      [TaskType.rowing_time]: rowing,
      [TaskType.jogging_time]: jogging,
      [TaskType.jogging_distance]: jogging,
      [TaskType.steps]: steps,
      [TaskType.swimming_time]: swimming,
      [TaskType.team_sports_time]: teamSport,
      [TaskType.weightlifting_time]: weightLifting,
      [TaskType.wheelchair_time]: wheelchair,
      [TaskType.water_drinking_volume]: waterdrinking,
      [TaskType.yoga_time]: yoga,
      [TaskType.nicotine_use]: smoking,
      [TaskType.heart_rate]: heartRate,
      [TaskType.calorie_intake]: nutrition,
      [TaskType.weight]: weight,
      [TaskType.mood]: sleep,
      [TaskType.general_physical_activity_time]: generic,
      [TaskType.handbiking_time]: handbiking,
      [TaskType.gymnastics_time]: gymnastics,
      [TaskType.sleep_time]: sleep,
      [TaskType.general_physical_activity_time_or_steps]: comboActivity,
      generic,
      platform,
      book,
      warning,
      gatekeeper,
      duration,
    };
    return TaskIconMap[this.iconType] ?? generic;
  }
}
</script>

<style lang="sass" scoped>
.background-icon
  width: 100%
  height: 100%

.image-container
  width: inherit
  height: inherit
  position: absolute
  display: flex
  justify-content: center

  > img
    max-width: 60%
</style>
