import {pagesPath} from "@/ts/router/pages-path";
import type {RouteRecordRaw} from "vue-router";
import CoachingPage from "@/vue/pages/coaching/coaching.page.vue";
import CoachingSchedulePage from "@/vue/pages/coaching/coaching-schedule.page.vue";
import CoachingSchedulingParentPage from "@/vue/pages/coaching/coaching-scheduling-parent.page.vue";
import CoachingAppointmentConfirmedPage from "@/vue/pages/coaching/coaching-appointment-confirmed.page.vue";

export const coachingPages: RouteRecordRaw = {
  path: pagesPath.coaching.parent,
  component: CoachingSchedulingParentPage,
  children: [
    {
      path: pagesPath.coaching.info,
      component: CoachingPage,
    },
    {
      path: pagesPath.coaching.coachingSchedule,
      component: CoachingSchedulePage,
    },
    {
      path: pagesPath.coaching.appointmentConfirmed,
      component: CoachingAppointmentConfirmedPage,
    },
  ],
};
