<template>
  <loading-suspense :error="error" :loading="loading">
    <loading-suspense :error="error" :loading="loading">
      <section class="main">
        <h2>My Score</h2>
        <b>{{ biometricResult?.date ? formatDateShortMonthName(biometricResult?.date) : '' }}</b>
        <my-score-circle :biometric-result="biometricResult"/>
        <my-score-message
          v-if="!biometricResult"
          :hra-summary="hraSummary"
          :biometric-result="biometricResult"
          :biometric-status="biometricStatus"
          :program="program"
          :biometric-available="biometricAvailable"
          :physician-form-available="physicianFormAvailable"
          :appointment="appointment"
          :physician-form="physicianForm"
        />
      </section>
    </loading-suspense>
    <div class="health-content-container">
      <div v-if="biometricResult" class="results">
        <biometric-screening-results
          :biometric-result="biometricResult"
          :hra-summary="hraSummary"
        />
      </div>
      <div v-else class="health-content">
        <div class="col-1">
          <health-risk-assessment
            v-if="program?.includeHra && hraSummary?.available"
            :hra-summary="hraSummary"
            :program="program"
          />
          <biometric-screening-test-kit
            v-if="isTestKitAvailable"
            :biometric-screening-test-kit="biometricScreeningTestKit"
          />
        </div>
        <div class="col-2">
          <biometric-screening
            v-if="program?.includeBiometricScreening && (biometricAvailable?.deadline || isAppointmentScheduledInFuture(appointment))"
            :biometric-status="biometricStatus"
            :appointment="appointment"
            :physician-form="physicianForm"
            :program="program"
            :biometric-available="biometricAvailable"
            :physician-form-available="physicianFormAvailable"
          />
          <physician-form
            v-if="program && physicianFormAvailable?.available"
            :physician-form="physicianForm"
            :program="program"
            :physician-form-available="physicianFormAvailable"
          />
        </div>
      </div>
    </div>
  </loading-suspense>
</template>

<script lang="ts" setup>
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import type {
  GetBiometricScreeningScheduleAppointmentDTO,
  GetBiometricScreeningResultCurrentDTO,
  GetBiometricScreeningStatusDTO,
  BiometricScreeningAvailable,
  PhysicianFormAvailable,
  BiometricScreeningTestKitDTO,
} from "@/ts/types/dto/biometric-screening.dto";
import BiometricScreening from "@/vue/molecules/my-health/biometric-screening.vue";
import BiometricScreeningResults from "@/vue/organisms/my-health/biometric-screening-results.vue";
import HealthRiskAssessment from "@/vue/molecules/my-health/health-risk-assessment.vue";
import type {HRASummary} from "@/ts/types/dto/health-risk-assessment.dto";
import type {GetPhysicianFormBiometricResponse} from "@/ts/types/dto/physician-form.dto";
import type {MyProgramResponse} from "@/ts/types/dto/program.dto";
import MyScoreCircle from "@/vue/molecules/my-health/my-score-circle.vue";
import MyScoreMessage from "@/vue/molecules/my-health/my-score-message.vue";
import {computed, inject, onMounted, ref} from "vue";
import type {Api} from "@/ts/classes/api";
import {formatDateShortMonthName} from "@/ts/utils/date-pure-functions";
import type {CachedApi} from "@/ts/classes/cached-api";
import BiometricScreeningTestKit from "@/vue/molecules/my-health/biometric-screening-test-kit.vue";
import {TestKitStatus} from "@/ts/types/dto/biometric-screening.dto";
import PhysicianForm from "@/vue/molecules/my-health/physician-form.vue";
import {isAppointmentScheduledInFuture} from "@/ts/utils/my-health/bioscreening";

const $api = inject("$api")! as Api;
const $cachedApi: CachedApi = inject("$cachedApi")! as CachedApi;

const loading = ref<boolean>(true);
const error = ref<string | null>(null);
const appointment = ref<GetBiometricScreeningScheduleAppointmentDTO | null>(null);
const biometricStatus = ref<GetBiometricScreeningStatusDTO | null>(null);
const biometricResult = ref<GetBiometricScreeningResultCurrentDTO | null>(null);
const biometricAvailable = ref<BiometricScreeningAvailable | null>(null);
const hraSummary = ref<HRASummary | null>(null);
const physicianFormAvailable = ref<PhysicianFormAvailable | null>(null);
const physicianForm = ref<GetPhysicianFormBiometricResponse | null>(null);
const program = ref<MyProgramResponse | null>(null);
const biometricScreeningTestKit = ref<BiometricScreeningTestKitDTO | null>(null);

onMounted(async(): Promise<void> => {
  try {
    biometricStatus.value = await $api.getBiometricScreeningStatus();
    appointment.value = await $api.getBiometricScreeningScheduleAppointment();
    biometricResult.value = await $api.getBiometricScreeningResultCurrent();
    biometricAvailable.value = await $api.getBiometricScreeningAvailable();
    hraSummary.value = await $api.getHRASummary();
    physicianFormAvailable.value = await $api.getPhysicianFormAvailable();
    physicianForm.value = await $api.getPhysicianBiometricForm();
    program.value = await $api.getMyHealthProgram();
    biometricScreeningTestKit.value = await $api.getBiometricScreeningTestKit();
  } catch (err) {
    error.value = err as string;
  } finally {
    loading.value = false;
  }
});

const isTestKitAvailable = computed(() => biometricScreeningTestKit.value && biometricScreeningTestKit.value.status !== TestKitStatus.unavailable);
</script>

<style lang="sass" scoped>
.main
  max-width: 400px
  margin: 2.5rem auto 1rem
  display: flex
  flex-direction: column
  align-items: center
  gap: 16px

h2
  @include Asap700
  font-size: 28px
  color: $color-primary-hc-blue-100
  text-align: center
  margin: 0

.health-content-container
  background-color: $color-neutral-platinum-40

.results
  margin: 0 auto
  max-width: 684px
  padding-top: 24px
  padding-bottom: 45px

.health-content
  margin: 0 auto
  display: flex
  gap: 26px
  justify-content: center
  max-width: 904px
  padding-top: 24px
  padding-bottom: 45px

.col-1, .col-2
  display: flex
  flex-direction: column
  gap: 26px
  width: 50%

</style>
