<template>
  <section class="challenge">
    <div class="info">
      <challenge-info
        :image="leaderboardChallenge.taskType"
        title="Type"
        :text="challengeTitle"
      />
      <challenge-info
        image="duration"
        title="Duration"
        :text="`${startDate} - ${endDate}`"
      />
    </div>
    <div class="description">
      <markdown-it :text="leaderboardChallenge.description"/>
    </div>
  </section>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import ChallengeInfo from "@/vue/molecules/challenge/challenge-info.vue";
import MarkdownIt from "@/vue/atoms/markdown-it.vue";
import type {LeaderboardChallengeDTO} from "@/ts/types/dto/leaderboard-challenge.dto";
import {TaskTypeNames} from "@/ts/types/dto/leaderboard-challenge.dto";
import {DefaultState} from "@/ts/store/default/default-store-instance";
import {formatDateShortMonthName, getRemainNumberOfDays} from "@/ts/utils/date-pure-functions";

@Component({
  name: "ChallengeDescription",
  components: {ChallengeInfo, MarkdownIt},
})
export default class ChallengeDescription extends Vue {
  @DefaultState
  leaderboardChallenge!: LeaderboardChallengeDTO;

  get challengeTitle(): string {
    return TaskTypeNames[this.leaderboardChallenge.taskType];
  }

  get durationDateDays(): number {
    return getRemainNumberOfDays(this.leaderboardChallenge.endDate, this.leaderboardChallenge.startDate);
  }

  get startDate(): string {
    const fullDate = formatDateShortMonthName(this.leaderboardChallenge.startDate);
    if (fullDate.length === 12) {
      return fullDate.slice(0, 6);
    } else {
      return fullDate.slice(0, 5);
    }
  }

  get endDate(): string {
    return formatDateShortMonthName(this.leaderboardChallenge.endDate);
  }
}
</script>
<style lang="sass" scoped>
.challenge
  display: flex
  gap: 40px
  max-width: 916px
  margin: 2.5rem auto

  @include max-device(md)
    flex-direction: column-reverse
    padding: 0 16px

.info
  display: flex
  flex-direction: column
  gap: 16px

.description
  font-size: 16px
  line-height: 24px
  color: $color-primary-hc-blue-100
  @include Roboto400
  max-width: 600px
</style>
