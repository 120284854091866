import {getModule} from "vuex-module-decorators";
import {stateDecoratorFactory} from "@/ts/utils/decorators";
import {OnboardingStore} from "@/ts/store/onboarding/onboarding-store";
import {vueStore} from "@/ts/store/vue-store";
import {Vue} from "vue-property-decorator";

export const onboardingStore: OnboardingStore = getModule(OnboardingStore, vueStore);

export const OnboardingState = stateDecoratorFactory(onboardingStore);

export class OnboardingStoreMixin extends Vue {
  onboardingStore!: OnboardingStore;

  created(): void {
    this.onboardingStore = onboardingStore;
  }
}
