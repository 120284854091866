import type {Router} from "vue-router";
import type {DefaultStore} from "@/ts/store/default/default-store";
import type {GrowslStore} from "@/ts/store/growl/growl-store";
import type {Api} from "@/ts/classes/api";
import type {PagesPath} from "@/ts/router/pages-path";
import type {AuthRefreshResponseDTO} from "@/ts/types/dto/auth.dto";
import {loggerFactory} from "@/ts/instances/logger-factory";
import type {Logger} from "lines-logger";
import {FetchOnce} from "@/ts/utils/decorators";

export class Auth {
  private readonly logger: Logger;

  // eslint-disable-next-line max-params
  constructor(
    private readonly router: Router,
    private readonly defaultStore: DefaultStore,
    private readonly growlStore: GrowslStore,
    private readonly api: Api,
    private readonly pagesPath: PagesPath,
  ) {
    this.logger = loggerFactory.getLogger("auth");
  }

  // If multiple request is sent simultaneously and fail with 401, we want to refresh token only once
  @FetchOnce
  public async refreshToken(): Promise<AuthRefreshResponseDTO> {
    this.logger.debug("Getting new session")();
    try {
      const response = await this.api.refreshToken();
      this.defaultStore.setAuthTokens(response);
      this.logger.log("Set new auth session to ls {}", response)();
      return response;
    } catch (err) {
      /*
       * lets not parse the error,
       * even if we were not able to refresh the token due to NETWORK Connection error or http 500
       * we either should fulfill parent api request to properly display the page, either log out the user
       */
      this.defaultStore.setAuthTokens(null);
      void this.growlStore.growlError("Token has been expiring. Logging out");
      void this.router.push(this.pagesPath.auth.signIn);
      throw err;
    }
  }
}
