<template>
  <div>
    <button class="calendar-day" :class="{selected: isActive}">
      <div class="calendar-weekday">
        {{ dayOfWeek }}
      </div>
      <div class="calendar-weekdate">
        {{ dayOfMonth }}
      </div>
    </button>
  </div>
</template>

<script lang="ts">
import {
  Prop,
  Vue,
} from "vue-property-decorator";
import {getShortDayOfWeek} from "@/ts/utils/date-pure-functions";

export default class CalendarDay extends Vue {
  @Prop() isActive!: boolean;

  @Prop() date!: Date;

  get dayOfMonth(): string {
    return `${this.date.getDate()}`;
  }

  get dayOfWeek(): string {
    return getShortDayOfWeek(this.date);
  }
}

</script>

<style lang="sass" scoped>
.calendar-day
  align-items: center
  background-color: transparent
  border: none
  color: $color-primary-hc-blue-100
  display: flex
  flex-direction: column
  justify-content: center
  text-decoration: none
  height: 54px
  width: 55px
  cursor: pointer

  &.selected
    background-image: url(/assets/img/day-selected.svg)
    background-repeat: no-repeat
    background-size: cover
    background-position: center
    border: none
    color: $color-white

.calendar-weekday
  @include Roboto700
  line-height: 14px
  font-size: 15px

.calendar-weekdate
  @include Roboto400
  font-size: 14px
</style>
