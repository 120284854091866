<template>
  <button-primary v-if="disabled" v-bind="args">
    <slot/>
  </button-primary>
  <a
    v-else-if="externalHref"
    :href="externalHref"
    target="_blank"
    class="anchor"
  >
    <button-primary v-bind="args">
      <slot/>
    </button-primary>
  </a>
  <router-link v-else :to="to" class="anchor">
    <button-primary v-bind="args">
      <slot/>
    </button-primary>
  </router-link>
</template>

<script lang="ts" setup>
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import type {RouteLocationRaw} from "vue-router";
import {computed} from "vue";

const props = withDefaults(defineProps<{
  to: RouteLocationRaw | string;

  // If you change of any these properties, the properties of <button-primary> should change as well
  type?: "button" | "reset" | "submit";
  loading?: boolean;
  theme?: "dark" | "white-with-secondary-blue" | "white";
  disabled?: boolean;
  flexibleWidth?: boolean;
  link?: boolean;
}>(), {
  type: "button",
  theme: "dark",
  link: false,
});

const externalHref = computed((): string | undefined => {
  if (typeof props.to === "string" && props.to?.startsWith("https://")) {
    return props.to;
  }
  return "";
});

const args = computed((): Record<string, any> => {
  const {to, ...rest} = props;
  if (props.link) {
    rest.theme = "white-with-secondary-blue";
  }
  return rest;
});

</script>

<style lang="sass" scoped>
.anchor
  display: inline-block
</style>
